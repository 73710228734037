import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IoMenu } from "react-icons/io5";
import { Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import NumberItem from '../item/NumberItem';
import { IoClose } from "react-icons/io5";
import { useMediaQuery } from '@mui/material';

export default function MoMenuPart({menu,menuClick,menuId}:any) {
    const [menuState,setMenuState] = useState(false);
    const mediaQuery = useMediaQuery(addTheme.media.m);
    const mQuery = useMediaQuery(addTheme.media.s);

    useEffect(()=>{
        if(!mediaQuery){
            document.body.style.overflow = 'auto';
            setMenuState(false);
        }
    },[mediaQuery]);

    useEffect(()=>{
        if(menuState){
            document.body.style.overflow = 'hidden';
        }else{
            document.body.style.overflow = 'auto';
        }
    },[menuState]);

    return (
        <Wrap>
            <IconWrap onClick={()=>setMenuState(true)}>
                <IoMenu size={mQuery ? 28 : 32}></IoMenu>
            </IconWrap>
            {menuState &&
                <MenuWrap>
                    <MenuBg onClick={()=>setMenuState(false)}></MenuBg>
                    <MenuInnerWrap>
                        <CloseWrap onClick={()=>setMenuState(false)}>
                            <IoClose color='#fff' size={34}></IoClose>
                        </CloseWrap>
                        <MenuItemWrap>
                            {menu === undefined || menu.length === 0 ?
                                <></> :
                                menu.map((data:any,index:any)=>{
                                    if(data.id === 0) return;
                                    return(
                                        <MenuItem key={`m-menu-${index}`} onClick={()=>menuClick(data)}>
                                            <BasicText color={menuId === data.id ? addTheme.color : '#000'} mFontSize={17} >{data.name}</BasicText>
                                        </MenuItem>
                                    )
                                })
                            }
                        </MenuItemWrap>
                        <NumberItem textSize={16} numSize={32}/>
                    </MenuInnerWrap>
                </MenuWrap>
            }
        </Wrap>
    )
}

const Wrap = styled.div`
    position: relative;
`;
const IconWrap = styled.div``;
const MenuWrap = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    z-index: 9999;
`;

const MenuBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
`;

const MenuInnerWrap = styled(RowBetween)`
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
    height: 100%;
    flex-direction: column;
    padding: 30px;
    align-items: start;
`;

const MenuItemWrap = styled(Row)`
    flex-direction: column;
    align-items: start;
    gap: 15px;
   
`;

const MenuItem = styled.div`
    cursor: pointer;
`;

const CloseWrap = styled.div`
    position: absolute;
    top: 20px;
    left: -40px;
    cursor: pointer;
`;