import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { AdminWrap, AdmBtnItem, Center, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { useParams } from 'react-router';
import { addTheme } from '../../../../style/addTheme';
import StarPart from '../../../common/part/StarPart';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { apiAdmGetReviewDetail, apiAdmSetReviewUpdate } from '../../../../api/api_adm_review';
import { apiSetReview } from '../../../../api/api_review';


export default function AdmReviewUpdatePage() {
    const {id} = useParams();
    const adminUser = useAdminUser();
    const [type,setType] = useState('등록');
    const fileRef = useRef<any>(null);
    const [info,setInfo] = useState({
        review_id: 0,
        review_title: '',
        review_content: '',
        review_image: '',
        review_rate: '',
        review_user_name: '',
        fileName: '',
    })

    useEffect(()=>{
        if(id === undefined){
            setType('등록');
        }else{
            setType('수정');
            getInfo();
        }
    },[id]);

    const imgUploadClick = () =>{
        if(fileRef){
            fileRef.current.click();
        }
    }

    const valueChange = (name:string,val:any) =>{
        setInfo((obj:any)=>({...obj,[name]:val}));
    }

    // 이미지 선택
    const fileChange = (e:any) =>{
        const file = e.target.files[0];
        if(file){
            const reader = new FileReader();
            setInfo((obj:any)=>({...obj,fileName:file.name}));
            reader.onload = () => {
                const result = reader.result;
                setInfo((obj:any)=>({...obj,review_image:result}));
            };

            reader.readAsDataURL(file);
        }
    }

    // 상세보기 데이터 가져오기
    const getInfo = async() =>{
        const res = await apiAdmGetReviewDetail(adminUser.admin_token,adminUser.admin_id,id);
        if(res.result){
            setInfo(res.data)
        }
    }

    // 추가, 수정
    const btnAction = async() =>{
        if(info.review_title === ''){
            alert('작성자를 입력해주세요')
            return
        }
        if(info.review_title === '제목을 입력해주세요.'){
            alert('제목을 입력해주세요');
            return;
        }
        if(info.review_rate === ''){
            alert('별점을 선택해주세요');
            return;
        }

        if(type === '등록'){
            const res = await apiSetReview(info);
            if(res.result){
                alert('후기를 등록했습니다.');
                location.reload();
            }else{
                alert(res.error);
            }
        }
        if(type === '수정'){
            if(window.confirm('후기를 수정하시겠습니까?')){
                const res = await apiAdmSetReviewUpdate(adminUser.admin_token,adminUser.admin_id,info);
                if(res.result){
                    alert('후기를 수정했습니다.');
                    location.reload();
                }else{
                    alert(res.error);
                }
            }
        }
    }

    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={18} mFontSize={16} fontWeight={700}>후기{type}</BasicText>
                <InnerWrap>
                    <ContentWrap gap={'15px'}>
                        <ItemWrap pWidth={120}>
                            <BasicText>작성자</BasicText>
                            <InputWrap>
                                <input value={info.review_user_name} onChange={(e:any)=>valueChange('review_user_name',e.target.value)}></input>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap pWidth={120}>
                            <BasicText>제목</BasicText>
                            <InputWrap>
                                <input value={info.review_title} onChange={(e:any)=>valueChange('review_title',e.target.value)}></input>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap pWidth={120}>
                            <BasicText>별점</BasicText>
                            <StarPart size={20} count={info.review_rate} colorStar={addTheme.admBtn2} colorText={addTheme.admBtn2} setCount={(val:any)=>valueChange('review_rate',val)}></StarPart>
                        </ItemWrap>
                        <ItemWrap pWidth={120} align='start'>
                            <BasicText>후기내용</BasicText>
                            <div style={{width:'100%'}}>
                                <InputWrap>
                                    <textarea value={info.review_content} onChange={(e:any) => valueChange('review_content',e.target.value)} rows={12} maxLength={1000}></textarea>
                                </InputWrap>
                                <ImgBtnWrap onClick={imgUploadClick}>이미지 업로드</ImgBtnWrap>
                                <input ref={fileRef} type='file' style={{display:'none'}} accept='.png,.jpg' onChange={fileChange}></input>
                                {info.review_image === '' ?
                                    <></> :
                                    <FilePreviewWrap>
                                        <img src={info.review_image} alt='file-img'></img>
                                    </FilePreviewWrap>
                                }
                            </div>
                        </ItemWrap>
                    </ContentWrap>
                    <Row style={{marginTop:'20px'}}>
                        <AdmBtnItem onClick={btnAction} bg={addTheme.admBtn2} padding='14px 25px'>후기 {type}</AdmBtnItem>
                    </Row>
                </InnerWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    width: 100%;
    max-width: 580px;
    margin-top: 30px;
`;

const ImgBtnWrap = styled(Center)`
    cursor: pointer;
    border: 1px solid #e6e6e6;
    padding: 12px 14px;
    border-radius: 6px;
    margin-top: 15px;
`;

const FilePreviewWrap = styled.div`
    margin-top: 10px;
    width: 100%;
    max-width: 350px;
    &>img{
        width: 100%;
    }
`;
