import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import AdminPageTotalPart from '../../common/part/AdminPageTotalPart';
import { AdmBtnItem, AdminWrap, Center, Row } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';
import { ADM_PRODUCT_BUNDLE_LIST, ADM_PRODUCT_CCTV_LIST, ADM_PRODUCT_LIST, ADM_PRODUCT_PHONE_LIST, ADM_PRODUCT_TV_LIST } from '../../data/table';
import AdminTablePart from '../../common/part/AdminTablePart';
import OpenBasicDataPart from '../part/OpenBasicDataPart';
import { apiAdmGetBundleList, apiAdmGetProductList, apiAdmSetBundleDel, apiAdmSetProductStatus } from '../../../../api/api_adm_product';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { useNavigate, useParams } from 'react-router';
import AdmProductListInternetItem from '../item/AdmProductListInternetItem';
import AdmProductListTvItem from '../item/AdmProductListTvItem';
import AdmProductListPhoneItem from '../item/AdmProductListPhoneItem';
import AdmProductListCctvItem from '../item/AdmProductListCctvItem';
import OpenBundlePart from '../part/OpenBundlePart';
import AdmProductBundleHomeListItem from '../item/AdmProductBundleHomeListItem';

const TOP_MENU = [{id: 1, name: '기본 데이터 관리'},{id: 2, name: '상품 관리'}];
const BOTTOM_MENU = [
    {id: 1, name: '인터넷', type: 'internet'},
    {id: 2, name: 'TV', type: 'tv'},
    {id: 3, name: '전화', type: 'landline'},
    {id: 4, name: 'CCTV', type: 'cctv'},
    // {id: 5, name: '테이블 오더'},
]
const BOTTOM_MENU2 = [
    {id: 1, name: '홈', type: 'home'},
    {id: 2, name: '매장', type: 'store'},
    {id: 3, name: '오피스', type: 'office'},
]

export default function AdmProductPage() {
    const adminUser = useAdminUser();
    const navigate = useNavigate();
    const {top,bottom,page} = useParams();
    const [list,setList] = useState([]);
    const [total,setTotal] = useState(0);
    const [open,setOpen] = useState(false);
    const [bundleOpen,setBundleOpen] = useState(false);
    const [titleTotal,setTitleTotal] = useState(false);

    useEffect(()=>{
        if(top === undefined || top === '1'){
            getList();
        }else{
            getBundleList();
        }
    },[page,top,bottom])

    const setPage = (p:any)=>{
        navigate(`/adm/product/${top}/${bottom}/${p}`);
    }
    // 메뉴 변경
    const menuChange = (name: string, id:any) =>{
        if(name === 'top'){
            navigate(`/adm/product/${id}/1/1`);
        }else{
            navigate(`/adm/product/${top ?? '1'}/${id}/1`);
        }
    }
    // 기본 리스트 가져오기
    const getList = async() =>{
        const typeItem = BOTTOM_MENU.filter((data:any)=>data.id == (bottom ?? '1'));
        let type = 'internet';
        if(typeItem.length !== 0){
            type = typeItem[0].type;
        }
        const res = await apiAdmGetProductList(adminUser.admin_token,adminUser.admin_id,type,page ?? 1, 15);
        
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    // 기본 상세
    const update = (type:any,data:any) =>{
        navigate(`/adm/productUpdate/${type}/${data.product_code}`);
    }

    // 기본 삭제
    const del = async(type:any,data:any)=>{
        if(window.confirm('기본데이터를 삭제 하시겠습니까?')){
            const res = await apiAdmSetProductStatus(adminUser.admin_token,adminUser.admin_id,type,data.product_code,'2');
            if(res.result){
                alert('기본데이터를 삭제했습니다.');
                getList();
                setTitleTotal(!titleTotal);
            }else{
                alert(res.error);
            }
        }
    }
    
    // 상품(번들) 리스트 가져오기
    const getBundleList = async() =>{
        const type = `0${bottom ?? '1'}`;
        const res = await apiAdmGetBundleList(adminUser.admin_token,adminUser.admin_id,page ?? 1, 15, type);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    // 번들 상세
    const bundleUpdate = (type:any,code:any) =>{
        navigate(`/adm/productBundleUpdate/${type}/${code}`);
    }

    // 번들 삭제
    const bundleDel = async(data:any)=>{
        if(window.confirm('상품을 삭제 하시겠습니까?')){
            const res = await apiAdmSetBundleDel(adminUser.admin_token,adminUser.admin_id,data.bundle_code);
            if(res.result){
                alert('상품을 삭제했습니다.');
                getBundleList();
                setTitleTotal(!titleTotal);
            }else{
                alert(res.error);
            }
        }
    }
    return (
        <Wrap>
            <AdminPageTotalPart title='전체 상품' part='product' reload={titleTotal}>
                <AdmBtnItem bg={addTheme.admBtn3} onClick={()=>setOpen(true)}>기본 데이터 등록</AdmBtnItem>
                <AdmBtnItem bg={addTheme.admBtn1} onClick={()=>setBundleOpen(true)}>상품 등록</AdmBtnItem>
            </AdminPageTotalPart>
            <AdminWrap>
                <TitleWrap>
                    {TOP_MENU.map((data:any,index:any)=>{
                        return(
                            <MenuItem key={`top-menu-${index}`} onClick={()=>menuChange('top',data.id)} state={(top === undefined ? '1' : top) == data.id}>{data.name}</MenuItem>
                        )
                    })}
                </TitleWrap>
                {(top === undefined || top === '1') &&
                    <TitleWrap>
                        {BOTTOM_MENU.map((data:any,index:any)=>{
                            return(
                                <MenuItem key={`top-menu-${index}`} onClick={()=>menuChange('bottom',data.id)} state={(bottom === undefined ? '1' : bottom) == data.id}>{data.name}</MenuItem>
                            )
                        })}
                    </TitleWrap>
                }
                {top === '2' &&
                    <TitleWrap>
                        {BOTTOM_MENU2.map((data:any,index:any)=>{
                            return(
                                <MenuItem key={`top-menu-${index}`} onClick={()=>menuChange('bottom',data.id)} state={bottom == data.id}>{data.name}</MenuItem>
                            )
                        })}
                </TitleWrap>
                }
                {(top === undefined || top === '1') ?
                    <>
                        {(bottom === undefined || bottom === '1') &&
                            <TableWrap>
                                <AdminTablePart bg={addTheme.adminColor} color='#fff' title={ADM_PRODUCT_LIST} minWidth={1200} page={page ?? 1} total={total} setPage={setPage}>
                                    {list === undefined || list.length === 0 ?
                                        <></> :
                                        list.map((data:any,index:any)=>{
                                            return(
                                                <AdmProductListInternetItem key={`product-${index}`} data={data} update={()=>update(1,data)} del={()=>del('internet',data)}/>
                                            )
                                        })
                                    }
                                </AdminTablePart>
                            </TableWrap>
                        }
                        {bottom === '2' &&
                            <TableWrap>
                                <AdminTablePart bg={addTheme.adminColor} color='#fff' title={ADM_PRODUCT_TV_LIST} minWidth={1200} page={page ?? 1} total={total} setPage={setPage}>
                                    {list === undefined || list.length === 0 ?
                                        <></> :
                                        list.map((data:any,index:any)=>{
                                            return(
                                                <AdmProductListTvItem key={`product-${index}`} data={data} update={()=>update(2,data)} del={()=>del('tv',data)}/>
                                            )
                                        })
                                    }
                                </AdminTablePart>
                            </TableWrap>
                        }
                        {bottom === '3' &&
                            <TableWrap>
                                <AdminTablePart bg={addTheme.adminColor} color='#fff' title={ADM_PRODUCT_PHONE_LIST} minWidth={1200} page={page ?? 1} total={total} setPage={setPage}>
                                    {list === undefined || list.length === 0 ?
                                        <></> :
                                        list.map((data:any,index:any)=>{
                                            return(
                                                <AdmProductListPhoneItem key={`product-${index}`} data={data} update={()=>update(3,data)} del={()=>del('landline',data)}/>
                                            )
                                        })
                                    }
                                </AdminTablePart>
                            </TableWrap>
                        }
                        {bottom === '4' &&
                            <TableWrap>
                                <AdminTablePart bg={addTheme.adminColor} color='#fff' title={ADM_PRODUCT_CCTV_LIST} minWidth={1200} page={page ?? 1} total={total} setPage={setPage}>
                                    {list === undefined || list.length === 0 ?
                                        <></> :
                                        list.map((data:any,index:any)=>{
                                            return(
                                                <AdmProductListCctvItem key={`product-${index}`} data={data} update={()=>update(4,data)} del={()=>del('cctv',data)}/>
                                            )
                                        })
                                    }
                                </AdminTablePart>
                            </TableWrap>
                        }
                    </> :
                    <>
                        {bottom === '1' &&
                            <TableWrap>
                                <AdminTablePart bg={addTheme.adminColor} color='#fff' title={ADM_PRODUCT_BUNDLE_LIST} minWidth={1300} page={page ?? 1} total={total} setPage={setPage}>
                                    {list === undefined || list.length === 0 ?
                                        <></> :
                                        list.map((data:any,index:any)=>{
                                            return(
                                                <AdmProductBundleHomeListItem key={`bundle-home-${index}`} data={data} update={()=>bundleUpdate('1',data.bundle_code)} del={()=>bundleDel(data)}/>
                                            )
                                        })
                                    }
                                </AdminTablePart>
                            </TableWrap>
                        }
                        {bottom === '2' &&
                            <TableWrap>
                                <AdminTablePart bg={addTheme.adminColor} color='#fff' title={ADM_PRODUCT_BUNDLE_LIST} minWidth={1300} page={page ?? 1} total={total} setPage={setPage}>
                                    {list === undefined || list.length === 0 ?
                                        <></> :
                                        list.map((data:any,index:any)=>{
                                            return(
                                                <AdmProductBundleHomeListItem key={`bundle-home-${index}`} data={data} update={()=>bundleUpdate('2',data.bundle_code)} del={()=>bundleDel(data)}/>
                                            )
                                        })
                                    }
                                </AdminTablePart>
                            </TableWrap>
                        }
                        {bottom === '3' &&
                            <TableWrap>
                                <AdminTablePart bg={addTheme.adminColor} color='#fff' title={ADM_PRODUCT_BUNDLE_LIST} minWidth={1300} page={page ?? 1} total={total} setPage={setPage}>
                                    {list === undefined || list.length === 0 ?
                                        <></> :
                                        list.map((data:any,index:any)=>{
                                            return(
                                                <AdmProductBundleHomeListItem key={`bundle-home-${index}`} data={data} update={()=>bundleUpdate('3',data.bundle_code)} del={()=>bundleDel(data)}/>
                                            )
                                        })
                                    }
                                </AdminTablePart>
                            </TableWrap>
                        }
                    </>
                }
            </AdminWrap>
            <OpenBasicDataPart open={open} setOpen={setOpen}/>
            <OpenBundlePart open={bundleOpen} setOpen={setBundleOpen}/>
        </Wrap>
    )
}

const Wrap = styled.div``;
const TitleWrap = styled(Row)``;

const TableWrap = styled.div`
    margin-top: 20px;
`;

const MenuItem = styled(Center)<{state: boolean}>`
    border: 1px solid ${({state}) => state ? '#898f98': '#e9e9e9'};
    border-bottom: none;
    width: 160px;
    height: 56px;
    cursor: pointer;
    text-align: center;
`;