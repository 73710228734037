export const LIST = [
    {id: 1, name: '인터넷',type:'internet'},
    {id: 2, name: 'TV',type:'tv'},
    {id: 3, name: '전화',type:'landline'},
    {id: 4, name: 'CCTV',type:'cctv'},
]

export const BUNDLE_CATE = [
    {id: 1, name: '홈', code: '01'},
    {id: 2, name: '매장', code: '02'},
    {id: 3, name: '오피스', code: '03'},
]

export const VISIBLE_KOR:any = {
    '0': '비활성화',
    '1': '활성화',
}
