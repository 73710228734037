import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { ADM_POINT_WITHDRAWAL_LIST } from '../../data/table'
import { AdmBtnItem, Center } from '../../../../style/basic/commonStyle'
import { momentFormat } from '../../../../utils/format/time'
import { PARTNER_STATUS, PARTNER_TYPE } from '../../partner/data/dummy'
import { numberWithComma } from '../../../../utils/format/number'

export default function AdmPointWithdrawalItem({data,update}:any) {
    return (
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_WITHDRAWAL_LIST[0].align}>{data.point_payment_id}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_WITHDRAWAL_LIST[1].align}>{PARTNER_TYPE[data.partner_account_type]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_WITHDRAWAL_LIST[2].align}>{data.partner_account_type === '1' ? data.partner_account_name : data.partner_account_business_name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_WITHDRAWAL_LIST[3].align}>{numberWithComma(data.point_payment_amount)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_WITHDRAWAL_LIST[4].align}>{PARTNER_STATUS[data.point_payment_status]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_WITHDRAWAL_LIST[5].align}>{momentFormat('YYYY-MM-DD hh:mm',data.point_payment_req_timestamp)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_WITHDRAWAL_LIST[6].align}>{momentFormat('YYYY-MM-DD hh:mm',data.point_payment_process_timestamp)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_WITHDRAWAL_LIST[7].align}>
                <Center style={{gap:'8px'}}>
                    <AdmBtnItem bg='#ff7600' round={30} padding='6px 16px' onClick={update}>상세</AdmBtnItem>
                </Center>
            </TableCell>
        </TableRow>
    )
}
