import React from 'react'
import styled from 'styled-components'
import { Center, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { numberWithComma } from '../../../utils/format/number';
import { addTheme } from '../../../style/addTheme';
import { useGlobalPartner } from '../../../utils/custom-hooks/common/useGlobalPartner';
import { USER_TYPE_VAL } from '../../applicationForm/data/dummy';
import { useNavigate } from 'react-router';

const AUTH = {
    '0': '미인증',
    '1': '인증',
}

export default function PartnerMyInfoPart() {
    const navigate = useNavigate();
    const partner = useGlobalPartner();
    
    return (
        <Wrap>
            <ContentWrap>
                <ContentItemWrap>
                    <ContentItem>
                        <ContentTitleWrap>회원 유형</ContentTitleWrap>
                        <BasicText>{USER_TYPE_VAL[partner.partner_type]}</BasicText>
                    </ContentItem>
                    <ContentItem>
                        <ContentTitleWrap>내 코드</ContentTitleWrap>
                        <BasicText>{partner.partner_code}</BasicText>
                    </ContentItem>
                </ContentItemWrap>
                <ContentItemWrap>
                    <ContentItem>
                        <ContentTitleWrap>이름</ContentTitleWrap>
                        <BasicText>{partner.partner_name}</BasicText>
                    </ContentItem>
                    <ContentItem>
                        <ContentTitleWrap>연락처</ContentTitleWrap>
                        <BasicText>{partner.partner_phone_number}</BasicText>
                    </ContentItem>
                </ContentItemWrap>
                {partner.partner_type === '2' &&
                    <ContentItemWrap>
                        <ContentItem>
                            <ContentTitleWrap>상호명</ContentTitleWrap>
                            <BasicText>{partner.partner_business_name}</BasicText>
                        </ContentItem>
                        <ContentItem>
                            <ContentTitleWrap>사업자</ContentTitleWrap>
                            <BasicText>{partner.partner_business_number}</BasicText>
                        </ContentItem>
                    </ContentItemWrap>
                }
                <ContentItemWrap>
                    <ContentItem>
                        <ContentTitleWrap>포인트</ContentTitleWrap>
                        <BasicText>{numberWithComma(partner.partner_point)}</BasicText>
                    </ContentItem>
                    <ContentItem>
                        <ContentTitleWrap>출금예정</ContentTitleWrap>
                        <BasicText>{numberWithComma(partner.exp_withdrawal_point)}</BasicText>
                    </ContentItem>
                </ContentItemWrap>
                <ContentItemWrap>
                    <ContentItem>
                        <ContentTitleWrap>연동계좌</ContentTitleWrap>
                        <BasicText>{partner.partner_bank} / {partner.partner_bank_account_number}</BasicText>
                    </ContentItem>
                    <ContentItem>
                        <ContentTitleWrap>인증여부</ContentTitleWrap>
                        {partner.partner_is_auth  === '0' ?
                            <Row style={{gap:'8px'}}>
                                <BasicText>미인증</BasicText>
                                <BtnItem onClick={()=>navigate('/partnerWithdrawalChk')}>인증하기</BtnItem>
                            </Row> :
                            <BasicText>인증완료</BasicText>
                        }
                    </ContentItem>
                </ContentItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled.div``;
const ContentItemWrap = styled(Row)`
    border-bottom: 1px solid #c4c4c4;
    padding: 18px;
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
        gap: 8px;
    }
`;
const ContentItem = styled(Row)`
    width: 50%;
    @media ${addTheme.media.s}{
        width: 100%;
    }
`;

const ContentTitleWrap = styled.div`
    width: 100px;
    flex-shrink: 0;
`;
const BtnItem = styled(Center)`
    color: #fff;
    background-color: ${addTheme.color};
    padding: 4px 10px;
    font-size: 13px;
    border-radius: 30px;
    cursor: pointer;
`;
