import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { apiGetBanner } from '../../../api/api_banner';
import { addTheme } from '../../../style/addTheme';

// 1: 홈 / 2: 다회선 / 3: 매장 / 4: 오피스 / 100: 팝업
const TYPE:any = {
    'home': '1',
    'multi': '2',
    'store': '3',
    'office': '4',
}

export default function BannerPart({type}:any) {
    const [list,setList] = useState<any>([]);
    useEffect(()=>{
        getList();
    },[type])

    const getList = async() =>{
        const res = await apiGetBanner(TYPE[type],1,50);
        if(res.result){
            setList(res.data);
        }
    }
    const clickAction = (link:any) =>{
        if(link === undefined || link === '') return;
        window.open(link);
    }
    return (
        <Wrap>
            {list === undefined || list.length === 0 ?
                <></> :
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    modules={[Pagination,Autoplay]}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    pagination={{ clickable: true }}
                >
                    {list.map((data:any,index:any)=>{
                        return(
                            <SwiperSlide key={`banner-${type}-${index}`}>
                                <ItemWrap onClick={()=>clickAction(data.banner_link_url)}>
                                    <img className='banner_img_web' alt='banner' src={data.banner_img_web}></img>
                                    <img className='banner_img_mo' alt='banner' src={data.banner_img_mo}></img>
                                </ItemWrap>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            }
            
        </Wrap>
    )
}

const Wrap = styled.div`
    & .swiper-pagination-bullet{
        background: #fff;
        opacity: 0.2;
        border-radius: 2px;
        height: 5px;
        width: 18px;
    }
    & .swiper-pagination-bullet-active{
        background: #fff;
        width: 48px;
        opacity: 1;
    }
`;
const ItemWrap = styled.div`
    width: 100%;
    & img{
        width: 100%;
    }
    .banner_img_mo{
        display: none;
    }
    @media ${addTheme.media.s}{
        .banner_img_mo{
            display: block;
        }
        .banner_img_web{
            display: none;
        }
    }
`;