import { axiosConfigInstance } from "./config";

// 최소 출금 포인트 가져오기
export const apiAdmGetMinWithdrawal = async(token:string,admin_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/config',token).post('',{
            method: 'getAdminMinWithDrawalConfigInfo',
            params:{
                admin_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 최소 출금 포인트 수정
export const apiAdmSetMinWithdrawal = async(token:string,admin_id:any,min_point_amount:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/config',token).post('',{
            method: 'adminModifyPointWithDrawalConfigInfo',
            params:{
                admin_id,min_point_amount
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 포인트 리스트 - 전체
export const apiAdmGetPointListTotal = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/point',token).post('',{
            method: 'adminGetPointPartnerList',
            params:{
                admin_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}
// 포인트 리스트 - 출금
export const apiAdmGetPointListWithdrawal = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/point',token).post('',{
            method: 'adminGetWithdrawalPointTxList',
            params:{
                admin_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}
// 포인트 리스트 - 전송
export const apiAdmGetPointListSend = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/point',token).post('',{
            method: 'adminGetSendPointTxList',
            params:{
                admin_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 포인트 전송
export const apiAdmSetPointSend = async(token:string,admin_id:any,point_amount:any,partner_code:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/point',token).post('',{
            method: 'adminSendPoint',
            params:{
                admin_id,point_amount,partner_code
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 포인트 출금 디테일
export const apiAdmGetPointWithdrawalDetail = async(token:string,admin_id:any,payment_point_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/point',token).post('',{
            method: 'adminGetPartnerPointWithdrawalInfo',
            params:{
                admin_id,payment_point_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 포인트 출금 승인
export const apiAdmSetPointWithdrawal = async(token:string,admin_id:any,payment_point_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/point',token).post('',{
            method: 'adminApprovalWithdrawalPoint',
            params:{
                admin_id,payment_point_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

