import { axiosConfigInstance } from "./config";

// 배너 / 팝업 가져오기
// part_type: (1: 홈 / 2: 다회선 / 3: 매장 / 4: 오피스 / 100: 팝업)
export const apiGetBanner = async(part_type: '1'|'2'|'3'|'4'|'100',page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/banner/info').post('',{
            method: 'getBannerList',
            params:{
                part_type,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}
