import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { ADM_INQUIRY_LIST } from '../../data/table'
import { INQUIRY_STATE } from '../../../../api/data'
import { momentFormat } from '../../../../utils/format/time'
import { AdmBtnItem, Center } from '../../../../style/basic/commonStyle'

export default function AdmInquiryProductListItem({data,update,del}:any) {
    return (
        
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={ADM_INQUIRY_LIST[0].align}>{data.inquiry_product_id}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_INQUIRY_LIST[1].align}>{data.product_category_title}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_INQUIRY_LIST[2].align}>{data.inquiry_product_user_name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_INQUIRY_LIST[3].align}>{data.inquiry_product_phone_number}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_INQUIRY_LIST[4].align}>{INQUIRY_STATE[data.inquiry_product_status]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_INQUIRY_LIST[5].align}>{momentFormat('YYYY-MM-DD hh:mm',data.inquiry_product_timestamp) }</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_INQUIRY_LIST[6].align}>{momentFormat('YYYY-MM-DD hh:mm',data.inquiry_product_answer_timestamp) }</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_INQUIRY_LIST[7].align}>
                <Center style={{gap:'8px'}}>
                    <AdmBtnItem bg='#ff7600' round={30} padding='6px 16px' onClick={update}>상세</AdmBtnItem>
                    <AdmBtnItem bg='#9e9e9e' round={30} padding='6px 16px' onClick={del}>삭제</AdmBtnItem>
                </Center>
            </TableCell>
        </TableRow>
    )
}
