import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import styled from 'styled-components';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { apiAdmGetFaqCategory, apiAdmGetFaqDetail, apiAdmSetFaq, apiAdmSetFaqUpdate } from '../../../../api/api_adm_faq';
import BasicText from '../../../basic/BasicText';
import { AdmBtnItem, AdminWrap, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import { MenuItem } from '@mui/material';
import SelectItem from '../../../common/item/SelectItem';
import { addTheme } from '../../../../style/addTheme';

export default function AdmFaqUpdatePage() {
    const {id} = useParams();
    const adminUser = useAdminUser();
    const [type,setType] = useState('등록');
    const [cateList,setCateList] = useState<any>([]);
    const [info,setInfo] = useState({
        faq_category_id: 1,
        faq_category_title: '',
        faq_id: '',
        faq_question: '',
        faq_answer: '',
    })

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        getCate();
    },[adminUser.admin_id]);

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        if(id === undefined){
            setType('등록');
        }else{
            setType('수정');
            getInfo();
        }
    },[adminUser.admin_id,id]);
    
    // 카테고리 리스트
    const getCate = async() =>{
        const res = await apiAdmGetFaqCategory(adminUser.admin_token,adminUser.admin_id,1,100);
        
        if(res.result){
            setInfo((obj:any)=>({
                ...obj,
                faq_category_id: res.data[0].faq_category_id,
            }));
            setCateList(res.data);
        }
    }

    // 정보 가져오기
    const getInfo = async() =>{
        const res = await apiAdmGetFaqDetail(adminUser.admin_token,adminUser.admin_id,id);
        if(res.result){
            setInfo(res.data[0]);
        }
    }

    // value 변경
    const valueChange = (name:string,value:any) =>{
        setInfo((obj:any)=>({...obj,[name]:value}));
    }

    // 수정, 추가
    const btnAction = async() =>{
        if(info.faq_question === ''){
            alert('질문을 입력해주세요.')
            return;
        }
        if(info.faq_answer === ''){
            alert('답변을 입력해주세요.')
            return;
        }
        
        if(type === '등록'){
            const res = await apiAdmSetFaq(adminUser.admin_token,adminUser.admin_id,info);
            if(res.result){
                alert('faq가 등록 되었습니다.');
                location.reload();
            }else{
                alert(res.error);
            }
        }else if(type === '수정'){
            if(window.confirm('faq를 수정하시겠습니까?')){
                const res = await apiAdmSetFaqUpdate(adminUser.admin_token,adminUser.admin_id,info);
                if(res.result){
                    alert('수정 되었습니다.');
                    location.reload();
                }else{
                    alert(res.error);
                }
            }
        }
    }
    
    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={18} mFontSize={16} fontWeight={700}>FAQ {type}</BasicText>
                <InnerWrap>
                    <ContentWrap>
                        <ItemWrap pWidth={120}>
                            <BasicText>카테고리</BasicText>
                            <InputWrap style={{padding:0}}>
                                {cateList === undefined || cateList.length === 0 ? 
                                    <></> :
                                    <SelectItem value={info.faq_category_id} setValue={(val:any)=>valueChange('faq_category_id',val)} none='no'>
                                        {cateList.map((data:any,index:any)=>{
                                            return(
                                                <MenuItem key={`faq-cate-${index}`} value={data.faq_category_id}>{data.faq_category_title}</MenuItem>
                                            )
                                        })}
                                    </SelectItem>
                                }
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap pWidth={120} align='start'>
                            <BasicText>질문내용</BasicText>
                            <InputWrap>
                                <textarea rows={6} value={info.faq_question} onChange={(e:any)=>(valueChange('faq_question',e.target.value))}></textarea>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap pWidth={120} align='start'>
                            <BasicText>답변내용</BasicText>
                            <InputWrap>
                                <textarea rows={12} value={info.faq_answer} onChange={(e:any)=>(valueChange('faq_answer',e.target.value))}></textarea>
                            </InputWrap>
                        </ItemWrap>
                    </ContentWrap>
                    <Row style={{marginTop:'20px'}}>
                        <AdmBtnItem bg={addTheme.admBtn2} padding='14px 25px' onClick={btnAction}>FAQ {type}</AdmBtnItem>
                    </Row>
                </InnerWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    width: 100%;
    max-width: 580px;
    margin-top: 30px;
`;