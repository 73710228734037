import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { AdmBtnItem, Center } from '../../../../style/basic/commonStyle'
import { addTheme } from '../../../../style/addTheme'
import { ADM_ORDER_LIST } from '../../data/table'
import { momentFormat } from '../../../../utils/format/time'
import { ORDER_STATUS, ORDER_TYPE } from '../../../../api/api_adm_order'

export default function AdmOrderListItem({data,update,del}:any) {
    return (
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={ADM_ORDER_LIST[0].align}>{data.order_id}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_ORDER_LIST[1].align}>{data.category_name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_ORDER_LIST[2].align}>{data.company_name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_ORDER_LIST[3].align}>{data.bundle_code}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_ORDER_LIST[4].align}>{ORDER_TYPE[data.order_orderer_type]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_ORDER_LIST[5].align}>{data.order_orderer_name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_ORDER_LIST[6].align}>{data.order_orderer_phone_number}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_ORDER_LIST[7].align}>{ORDER_STATUS[data.order_status]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_ORDER_LIST[8].align}>{momentFormat('YYYY-MM-DD hh:mm',data.order_timestamp)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_ORDER_LIST[9].align}>{momentFormat('YYYY-MM-DD hh:mm',data.order_process_timestamp)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_ORDER_LIST[10].align}>
                <Center style={{gap:'8px'}}>
                    <AdmBtnItem bg={addTheme.admBtn2} round={30} padding='6px 16px' onClick={update}>상세</AdmBtnItem>
                    <AdmBtnItem bg='#9e9e9e' round={30} padding='6px 16px' onClick={del}>삭제</AdmBtnItem>
                </Center>
            </TableCell>
        </TableRow>
    )
}
