import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { MaxWidthWrap, Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import { useLocation, useNavigate } from 'react-router';
import { useGlobalCompanyInfo } from '../../../utils/custom-hooks/common/useGlobalCompanyInfo';

export default function FooterPage() {
    const navigate = useNavigate();
    const companyInfo = useGlobalCompanyInfo();
    const location = useLocation();
    const [footerMore,setFooterMore] = useState(false);
    useEffect(()=>{
        if(location.pathname === '/home'){
            setFooterMore(true);
        }else{
            setFooterMore(false)
        }
    },[location.pathname]);
    
    const goPage = (path:string) =>{
        navigate(path);
    }
    const phoneClick = () =>{
        if(companyInfo['config_company_call_number'] === undefined) return;
        window.location.href = 'tel:' + companyInfo['config_company_call_number'];
    }
    const kakaoClick = () =>{
        if(companyInfo['config_social_kakao_link'] === undefined) return;
        window.open(companyInfo['config_social_kakao_link']);
    }
    const youtubeClick = () =>{
        if(companyInfo['config_social_youtube_link'] === undefined) return;
        window.open(companyInfo['config_social_youtube_link']);
    }
    const instaClick = () =>{
        if(companyInfo['config_social_instagram_link'] === undefined) return;
        window.open(companyInfo['config_social_instagram_link']);
    }
    
    const goTerms = (router:string) =>{
        navigate(router);
    }

    return (
        <Wrap footerMore={footerMore}>
            <MaxWidthWrap>
                <TopWrap>
                    <TopLeftWrap>
                        <img alt='logo' src='/img/asset/logo-g.png'></img>
                    </TopLeftWrap>
                    <TopRightWrap>
                        <RightItem onClick={phoneClick}>
                            <img src='/img/asset/fsns01.png'></img>
                        </RightItem>
                        <RightItem onClick={kakaoClick}>
                            <img src='/img/asset/fsns02.png'></img>
                        </RightItem>
                        <RightItem onClick={youtubeClick}>
                            <img src='/img/asset/fsns03.png'></img>
                        </RightItem>
                        <RightItem onClick={instaClick}>
                            <img src='/img/asset/fsns04.png'></img>
                        </RightItem>
                    </TopRightWrap>
                </TopWrap>
                <ListWrap>
                    <TermsItem onClick={()=>goTerms('/termsUse')}>이용약관</TermsItem>
                    <TermsItem onClick={()=>goTerms('/termsPersonal')}>개인정보처리방침</TermsItem>
                    <TermsItem onClick={()=>goTerms('/termsMall')}>쇼핑몰 이용약관</TermsItem>
                    <TermsItem onClick={()=>goPage('/partner')}>현주친구 파트너 신청</TermsItem>
                </ListWrap>
                <DescWrap>
                    <BasicText fontSize={14} color='#5c5c5c'>상호: {companyInfo['c_config_business_name']}</BasicText>
                    <BasicText fontSize={14} color='#5c5c5c'>회사명: {companyInfo['config_company_name']}</BasicText>
                    <BasicText fontSize={14} color='#5c5c5c'>사업자번호: {companyInfo['config_business_number']}</BasicText>
                    <BasicText fontSize={14} color='#5c5c5c'>대표자: {companyInfo['config_company_repre_name']}</BasicText>
                    <BasicText fontSize={14} color='#5c5c5c'>대표전화: {companyInfo['config_company_call_number']}</BasicText>
                    <BasicText fontSize={14} color='#5c5c5c'>개인정보책임자: {companyInfo['config_private_info_name']}</BasicText>
                    <BasicText fontSize={14} color='#5c5c5c'>사업장 주소: {companyInfo['config_company_address']}</BasicText>
                    <BasicText fontSize={13} mFontSize={12} color='#939393' marginTop={2}>Copyright 2024. {companyInfo['config_company_name']} co. all rights reserved</BasicText>
                </DescWrap>
            </MaxWidthWrap>
        </Wrap>
    ) 
}

const Wrap = styled.div<{footerMore:boolean}>`
    background-color: #f9f9f9;
    padding: ${({footerMore}) => footerMore ? '46px 0 275px' : '46px 0'};
    @media ${addTheme.media.s}{
        padding: ${({footerMore}) => footerMore ? '46px 0 250px' : '46px 0'};
    }
`;
const TopWrap = styled(RowBetween)`
    align-items: start;
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
        &>div{
            width: 100%;
            :nth-last-of-type(1){
                margin-top: 15px;
            }
        }
    }
`;
const TopLeftWrap = styled.div`
    & img{
        height: 42px;
        @media ${addTheme.media.s}{
            height: 32px;
        }
    }
`;
const ListWrap = styled(Row)`
    margin-top: 15px;
    gap: 12px;
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
    }
`;
const TermsItem = styled.div`
    cursor: pointer;
    text-align: center;
    @media ${addTheme.media.s}{
        font-size: 15px;
    }
`;
const TopRightWrap = styled(Row)`
    width: auto;
    gap: 12px;
`;

const RightItem = styled.div`
    cursor: pointer;
    & img{
        width: 42px;
        @media ${addTheme.media.s}{
            width: 36px;
        }
    }
`;

const DescWrap = styled.div`
    margin-top: 20px;
`;