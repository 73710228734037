import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_PARTNER= 'SUBOX/SET_PARTNER';
const SET_PARTNER_LOGOUT = 'SUBOX/SET_PARTNER_LOGOUT';
export const globalPartnerCreator = createAction(SET_PARTNER);
export const globalPartnerLogoutCreator = createAction(SET_PARTNER_LOGOUT);

export function setGlobalPartner(data: any): ThunkAction<void, RootState, null, ReturnType<typeof globalPartnerCreator>> {
    return async function (dispatch) {
    try {
      dispatch(globalPartnerCreator({
        data: data
      }));
    } catch (error) {
      
    }
  };
}

export interface PartnerState {
  partner_code: string,
  "partner_type": string,
  "partner_email": string,
  "partner_sign_type": string,
  "partner_name": string,
  "partner_phone_number": string,
  "partner_business_number": string,
  "partner_business_name": string,
  "partner_bank": string,
  "partner_bank_account_number": string,
  "partner_apply_timestamp": string,
  "partner_reg_timestamp": string,
  "partner_point": string,
  "partner_status": string,
  "partner_comment": string,
  "partner_is_auth": string,
  "exp_withdrawal_point": number,
  partner_resident_number: string,
  partner_password: string,
}

const initialState: PartnerState = {
  "partner_code": "",
  "partner_type": "1",
  "partner_email": "",
  "partner_sign_type": "1",
  "partner_name": "",
  "partner_phone_number": "",
  "partner_business_number": "",
  "partner_business_name": "",
  "partner_bank": "",
  "partner_bank_account_number": "",
  "partner_apply_timestamp": "",
  "partner_reg_timestamp": "",
  "partner_point": "0",
  "partner_status": "1",
  "partner_comment": "",
  "partner_is_auth": "0",
  "exp_withdrawal_point": 0,
  partner_resident_number: '',
  partner_password: '',
}

export const globalPartner = handleActions<PartnerState, any>({
  [SET_PARTNER]: (state, action: ReturnType<typeof globalPartnerCreator>) => ({
    partner_code: action.payload.data.partner_code ?? state.partner_code,
    partner_resident_number: action.payload.data.partner_resident_number ?? state.partner_resident_number,
    partner_password: action.payload.data.partner_password ?? state.partner_password,
    "partner_type": action.payload.data.partner_type ?? state.partner_type,
    "partner_email": action.payload.data.partner_email ?? state.partner_email,
    "partner_sign_type": action.payload.data.partner_sign_type ?? state.partner_sign_type,
    "partner_name": action.payload.data.partner_name ?? state.partner_name,
    "partner_phone_number": action.payload.data.partner_phone_number ?? state.partner_phone_number,
    "partner_business_number": action.payload.data.partner_business_number ?? state.partner_business_number,
    "partner_business_name": action.payload.data.partner_business_name ?? state.partner_business_name,
    "partner_bank": action.payload.data.partner_bank ?? state.partner_bank,
    "partner_bank_account_number": action.payload.data.partner_bank_account_number ?? state.partner_bank_account_number,
    "partner_apply_timestamp": action.payload.data.partner_apply_timestamp ?? state.partner_apply_timestamp,
    "partner_reg_timestamp": action.payload.data.partner_reg_timestamp ?? state.partner_reg_timestamp,
    "partner_point": action.payload.data.partner_point ?? state.partner_point,
    "partner_status": action.payload.data.partner_status ?? state.partner_status,
    "partner_comment": action.payload.data.partner_comment ?? state.partner_comment,
    "partner_is_auth": action.payload.data.partner_is_auth ?? state.partner_is_auth,
    "exp_withdrawal_point": action.payload.data.exp_withdrawal_point ?? state.exp_withdrawal_point,
  }),
  [SET_PARTNER_LOGOUT]: (state, action: ReturnType<typeof globalPartnerLogoutCreator>) => (initialState),
}, initialState);
