import React from 'react'
import { Center, MaxWidthWrap, SectionWrap } from '../../../style/basic/commonStyle'
import TitlePart from '../../common/part/TitlePart'
import styled from 'styled-components'
import { addTheme } from '../../../style/addTheme'
import BtnItem from '../item/BtnItem'
import RectItem from '../../common/item/RectItem'
import BasicText from '../../basic/BasicText'
import { useNavigate } from 'react-router'

const LIST = [
    {id: 1, title: '가입 상담', desc: '불필요한 부가 서비스를 강요하지 않습니다. 필요한 부부이 있으면 언제든지 편하게 문의해주세요.', img: '/img/asset/thumb04.png'},
    {id: 2, title: '상품 설치', desc: '인터넷 상품, 속도만 선택하시면 고객님이 원하는 일정에 맞춰 가장 빠른 날짜로 설치해드려요.', img: '/img/asset/thumb05.png'},
    {id: 3, title: '현금 입금', desc: '현금 사은품의 경우 설치 후 7일 이내에 입금되며, 별도 요청시 당일 송금이 가능합니다.', img: '/img/asset/thumb06.png'},
]

export default function CounselingPart() {
    const navigate = useNavigate();

    const clickEvent = () =>{
        navigate('/benefitInquiry');
    }
    return (
        <SectionWrap>
            <MaxWidthWrap>
                <Wrap>
                    <TitlePart desc='단 1번의 상담으로<br/>만족할 수 밖에 없는 이유' desc2='상담부터 설치까지 현주친구가 다 손쉽게 알려드릴게요' />
                    <ContentWrap>
                        {LIST.map((data:any,index:any)=>{
                            return(
                                <ContentItemWrap key={`c-${index}`}>
                                    <RectItem imgUrl={data.img} paddingBottom={115}></RectItem>
                                    <TextWrap>
                                        <BasicText align='center' color='#fff' fontSize={24} mFontSize={20} fontWeight={700}>{data.title}</BasicText>
                                        <BasicText align='center' color='#fff' fontSize={19} mFontSize={16} marginTop={2}>{data.desc}</BasicText>
                                    </TextWrap>
                                </ContentItemWrap>
                            )
                        })}
                    </ContentWrap>
                    <BtnItem clickEvent={clickEvent} title='빠른 상담 받기' bg='#fff' titleColor={addTheme.color} border/>
                </Wrap>
            </MaxWidthWrap>
        </SectionWrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled(Center)`
    margin-top: 30px;
    gap: 30px;
    &>div{
        width: 31%;
    }
    @media (max-width: 960px){
        flex-wrap: wrap;
        &>div{
            width: 100%;
        }
    }
`;
const ContentItemWrap = styled(Center)`
    flex-direction: column;
    background-color: #fff;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
`;

const TextWrap = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    padding: 30px 40px;
`;