import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { FormControlLabel, MenuItem, Radio } from '@mui/material';
import { onlyNumber } from '../../../../utils/format/string';
import SelectItem from '../../../common/item/SelectItem';
import { BANK } from '../../../applicationForm/data/dummy';
import { numberWithComma } from '../../../../utils/format/number';

export default function AdmOrderUpdateBenefitPart({info,valueChange}:any) {

    return (
        <Wrap>
            <ContentWrap gap={15}>
                <ItemWrap pWidth={120}>
                    <BasicText>받는이</BasicText>
                    <InputWrap>
                        <input value={info?.order_freegift?.order_freegift_name ?? ''} onChange={(e:any)=>valueChange('order_freegift','order_freegift_name',e.target.value)}/>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>관계</BasicText>
                    <Row>
                        <FormControlLabel control={<Radio name='relation-type' value={'1'} checked={info?.order_freegift?.order_freegift_relation === '1'} onChange={(e:any)=>valueChange('order_freegift','order_freegift_relation',e.target.value)} />} label="본인" />
                        <FormControlLabel control={<Radio name='relation-type' value={'2'} checked={info?.order_freegift?.order_freegift_relation === '2'} onChange={(e:any)=>valueChange('order_freegift','order_freegift_relation',e.target.value)} />} label="배우자" />
                        <FormControlLabel control={<Radio name='relation-type' value={'3'} checked={info?.order_freegift?.order_freegift_relation === '3'} onChange={(e:any)=>valueChange('order_freegift','order_freegift_relation',e.target.value)} />} label="가족" />
                        <FormControlLabel control={<Radio name='relation-type' value={'4'} checked={info?.order_freegift?.order_freegift_relation === '4'} onChange={(e:any)=>valueChange('order_freegift','order_freegift_relation',e.target.value)} />} label="기타" />
                    </Row>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>주민번호</BasicText>
                    <InputWrap>
                        <input value={info?.order_freegift?.order_freegift_resident_number ?? ''} onChange={(e:any)=>valueChange('order_freegift','order_freegift_resident_number',onlyNumber(e))}/>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={120} align='start'>
                    <BasicText marginTop={1}>계좌번호</BasicText>
                    <div style={{width: '100%'}}>
                        <Row>
                            {BANK === undefined || BANK.length === 0 ?
                                <></> :
                                <SelectItem width={'auto'} value={info?.order_freegift?.order_freegift_bank ?? ''} setValue={(val:any)=>valueChange('order_freegift','order_freegift_bank',val)}>
                                    {BANK.map((data:any,index:any)=>{
                                        return(
                                            <MenuItem key={`bank-${index}`} value={data.name}>{data.name}</MenuItem>
                                        )
                                    })}
                                </SelectItem>
                            }
                        </Row>
                        <InputWrap margin='8px 0 0 0'>
                            <input value={info?.order_freegift?.order_freegift_account_number ?? ''} onChange={(e:any)=>valueChange('order_freegift','order_freegift_account_number',onlyNumber(e))}/>
                        </InputWrap>
                    </div>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>현금</BasicText>
                    <InputWrap>
                        <input value={numberWithComma(info?.order_freegift?.order_freegift_cash_price ?? '')} onChange={(e:any)=>valueChange('order_freegift','order_freegift_cash_price',onlyNumber(e))}/>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>상품권</BasicText>
                    <InputWrap>
                        <input value={numberWithComma(info?.order_freegift?.order_freegift_price ?? '')} onChange={(e:any)=>valueChange('order_freegift','order_freegift_price',onlyNumber(e))}/>
                    </InputWrap>
                </ItemWrap>
                {/* <ItemWrap pWidth={120}>
                    <BasicText>포인트</BasicText>
                    <InputWrap>
                        <input value={info?.order_freegift?.order_freegift_point ?? ''} onChange={(e:any)=>valueChange('order_freegift','order_freegift_point',onlyNumber(e))}/>
                    </InputWrap>
                </ItemWrap> */}
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;