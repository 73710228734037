
export const TAB_MENU = [
    {id: '1', name: '주문 관리',path: '/adm/order', router: '/adm/order/1', },
    {id: '2', name: '상품 관리',path: '/adm/product', router: '/adm/product/1/1/1', },
    {id: '3', name: '파트너 관리',path: '/adm/partner', router: '/adm/partner/1', },
    {id: '4', name: '포인트 관리',path: '/adm/point', router: '/adm/point/1/1', },
    {id: '5', name: '후기 관리',path: '/adm/review', router: '/adm/review/1',},
    {id: '6', name: '문의 관리',path: '/adm/inquiry', router: '/adm/inquiry/1/1', },
    {id: '7', name: 'FAQ 관리',path: '/adm/faq', router: '/adm/faq', },
    {id: '8', name: '배너 관리',path: '/adm/banner', router: '/adm/banner/1', },
    {id: '9', name: '팝업 관리',path: '/adm/popup', router: '/adm/popup/1', },
    {id: '10', name: '링크 관리',path: '/adm/link', router: '/adm/link', },
];