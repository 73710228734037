import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row, SectionWrap } from '../../../style/basic/commonStyle';
import TitlePart from '../../common/part/TitlePart';
import { addTheme } from '../../../style/addTheme';
import { momentFormat } from '../../../utils/format/time';
import { CATE, LIST_DATA, NAME } from '../../../api/listData';
import { useMediaQuery } from '@mui/material';

const Item = ({data}:any) =>{
    const mediaQuery = useMediaQuery(addTheme.media.s);
    return (
        <ListItemWrap>
            <ListItem w={15}>{momentFormat(mediaQuery ? 'MM-DD' : 'YYYY-MM-DD',data.time)}</ListItem>
            <ListItem w={mediaQuery ? 45 : 50}>
                {data.state === '접수 완료' &&
                    <NewItem>NEW</NewItem> 
                }
                {mediaQuery ? '' : `${data.productType} | `}
                {data.productName}
            </ListItem>
            <ListItem w={20} center='center'>{data.name}</ListItem>
            <ListItem w={mediaQuery ? 20 : 15} center='center'>
                <BtnItem stateOn={data.state === '상담 완료'}>{data.state}</BtnItem>
            </ListItem>
        </ListItemWrap>
    )
}

export default function UserListPart() {
    const mediaQuery = useMediaQuery(addTheme.media.s);
    const [list,setList] = useState<any>([]);
    
    useEffect(()=>{
        getList();
    },[]);

    useEffect(()=>{
        if(list.length === 0) return;
        const timer = setInterval(() => {
            addList();
        }, 1500);
        return ()=> clearInterval(timer);
    },[list])

    // 리스트 생성
    const getList = () =>{
        if(list.length === 0){
            const time = new Date();
            const yesterDay = time.setDate(time.getDate() - 1);
            const arr = Array.from({length: 10},(_,index)=>{
                const {name,productType,productName} = ranData();
                return {name: name, time: yesterDay,productType,productName, state: '상담 완료'}
            })
            setList(arr);
        }
    }

    // 랜덤으로 데이터 생성
    const ranData = () =>{
        const nameRanNum = Math.floor(Math.random() * NAME.length);
        const name = NAME[nameRanNum];

        const cateRanNum = Math.floor(Math.random() * CATE.length);
        const cate = CATE[cateRanNum];
        const cateList = LIST_DATA[cate.key];
        const productRanNum = Math.floor(Math.random() * cateList.length);
        const productType = cate.name;
        const productName = cateList[productRanNum];
        return {name,productType,productName}
    }

    // 리스트 추가
    const addList = () =>{
        const nowTime = new Date().getTime();
        const {name,productType,productName} = ranData();
        const newItem = {name: name, time: nowTime,productType,productName, state: '접수 완료'}
        setList((arr:any)=>[newItem,...arr.slice(0, -1)]);
    }
    
    return (
        <SectionWrap bg={addTheme.section_color}>
            <MaxWidthWrap>
                <Wrap>
                    <TitlePart desc='확실하게 고객님께 필요한 정보와<br/>받을 수 있는 모든 혜택을 알려드려요' />
                    <ContentWrap>
                        <TitleWrap>
                            <TitleItemWrap w={15}>날짜</TitleItemWrap>
                            <TitleItemWrap w={mediaQuery ? 45 : 50}>신청상품</TitleItemWrap>
                            <TitleItemWrap w={20} center='center'>이름</TitleItemWrap>
                            <TitleItemWrap w={mediaQuery ? 20 : 15} center='center'>신청 현황</TitleItemWrap>
                        </TitleWrap>
                        <ListWrap>
                            {list.length === 0 ?
                                <></> :
                                list.map((data:any,index:any)=>{
                                    return(
                                        <Item key={`user-${index}`} data={data} />
                                    )
                                })
                            }
                        </ListWrap>
                    </ContentWrap>
                </Wrap>
            </MaxWidthWrap>
        </SectionWrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled.div`
    margin-top: 70px;
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 30px;
    @media ${addTheme.media.s}{
        padding: 15px;
        border-radius: 15px;
    }
`;

const TitleWrap = styled(Row)`
    border-bottom: 1px solid #333;
    padding: 10px 0;
`;

const TitleItemWrap = styled.div<{w?:number,center?:string}>`
    width: ${({w}) => w ?? 25}%;
    display: flex;
    align-items: center;
    justify-content: ${({center}) => center ?? 'start' };
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    @media ${addTheme.media.s}{
        font-size: 16px;
    }
`;

const ListWrap = styled.div`
    max-height: 360px;
    overflow: hidden;
`;
const ListItemWrap = styled(Row)`
    padding: 10px;
    @media ${addTheme.media.s}{
        padding: 10px 0;
    }
`;

const ListItem = styled.div<{w?:number,center?:string}>`
    width: ${({w}) => w ?? 25}%;
    display: flex;
    align-items: center;
    justify-content: ${({center}) => center ?? 'start' };
    font-size: 18px;
    gap: 8px;
    @media ${addTheme.media.s}{
        font-size: 15px;
    }
`;

const BtnItem = styled.div<{stateOn?:boolean}>`
    padding: 12px;
    background-color: ${({stateOn}) => stateOn ? addTheme.color : addTheme.section_color};
    color: ${({stateOn}) => stateOn ? '#fff' : addTheme.color};
    border-radius: 5px;
    text-align: center;
`;

const NewItem = styled(Center)`
    width: 30px;
    height: 30px;
    background-color: ${addTheme.color};
    color: #fff;
    border-radius: 30px;
    font-size: 10px;
    margin-left: 5px;
    @media ${addTheme.media.s}{
        width: 26px;
        height: 26px;
        font-size: 8px;
    }
`;