import { axiosConfigInstance } from "./config"

// order 상태
export const ORDER_STATUS:any = {
    "0": "주문접수",
    "1": "상담완료",
    "2": "계약서 작성완료",
    "3": "설치 접수",
    "4": "설치 완료",
    "5": "지급 완료",
    "100": "주문 취소",
}

// 고객 유형
export const ORDER_TYPE:any ={
    "1": "개인",
    "2": "사업자",
    "3": "외국인",
}

// 주문 리스트
// search_query 주문자 이름 / 주문자 전화번호
export const apiAdmGetOrderList = async(token:any,admin_id:any,page:any,count:any,search_query:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/order',token).post('',{
            method: 'getAdminOrderList',
            params: {
                admin_id,page,count,search_query
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 주문 디테일
export const apiAdmGetOrderDetail = async(token:any,admin_id:any,order_code:any,) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/order',token).post('',{
            method: 'getAdminOrderDetailInfo',
            params: {
                admin_id,order_code
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 주문 상태 변경
export const apiAdmSetOrderUpdateStatus = async(token:any,admin_id:any,order_code:any,order_status:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/order',token).post('',{
            method: 'adminOrderChangeStatus',
            params: {
                admin_id,order_code,order_status
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 주문 삭제
export const apiAdmSetOrderDel = async(token:any,admin_id:any,order_code:any,) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/order',token).post('',{
            method: 'adminOrderDelete',
            params: {
                admin_id,order_code,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 주문 메모 수정
// comment_type customer: 고객 처리 메모 / admin: 관리자 처리 메모
export const apiAdmSetOrderUpdateMemo = async(token:any,admin_id:any,order_code:any,comment_type: 'customer' | 'admin',comment_content:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/order',token).post('',{
            method: 'adminOrderCommentEdit',
            params: {
                admin_id,order_code,comment_type,comment_content
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 주문 고객정보 수정
export const apiAdmSetOrderUpdateOrderer = async(token:any,admin_id:any,info:any) =>{
    try {
        const {order_basic,order_orderer} = info;
        const res = await axiosConfigInstance('/api/admin/order',token).post('',{
            method: 'adminOrderOrdererInfoEdit',
            params: {
                admin_id,
                ...order_basic,
                ...order_orderer,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 주문 상품정보 수정
export const apiAdmSetOrderUpdateProduct = async(token:any,admin_id:any,info:any) =>{
    try {
        const {order_basic,order_orderer} = info;
        const res = await axiosConfigInstance('/api/admin/order',token).post('',{
            method: 'adminOrderProductInfoEdit',
            params: {
                admin_id,
                ...order_basic,
                ...order_orderer,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 주문 납부자정보 수정
export const apiAdmSetOrderUpdatePayment = async(token:any,admin_id:any,info:any) =>{
    try {
        const {order_basic,order_payment} = info;
        const res = await axiosConfigInstance('/api/admin/order',token).post('',{
            method: 'adminOrderPaymentInfoEdit',
            params: {
                admin_id,
                ...order_basic,
                ...order_payment,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 주문 혜택정보 수정
export const apiAdmSetOrderUpdateBenefit = async(token:any,admin_id:any,info:any) =>{
    try {
        const {order_basic,order_freegift} = info;
        const res = await axiosConfigInstance('/api/admin/order',token).post('',{
            method: 'adminOrderFreegiftInfoEdit',
            params: {
                admin_id,
                ...order_basic,
                ...order_freegift,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

