import React from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import { numberWithComma } from '../../../utils/format/number';

const NAME = [{name:"TV",en:'tv'},{name:"전화",en:'landline'}];

const MoreWrap = ({data,item,selectChk,setSelectData}:any) =>{
    const itemClick = () =>{
        setSelectData((obj:any)=>({
            ...obj,
            [NAME[data-2].en]: item
        }))
    }
    
    return(
        <ContentItemWrap selectChk={selectChk} onClick={itemClick}>
            {data === 2 &&
                <>
                    <BasicText fontWeight={700} spColor='#000' fontSize={24}>{item.product_channel_count} <span style={{fontSize:'18px'}}>채널</span></BasicText>
                    <BasicText fontWeight={700} fontSize={15} color='#333'>{item.product_name}</BasicText>
                    <BasicText align='center' fontSize={22} mFontSize={20} fontWeight={700} color={addTheme.color}>월 {numberWithComma(Number(item.product_basic_price)+Number(item.product_settop_price_gap))}원</BasicText>
                </>
            }
            {data === 3 &&
                <>
                    <BasicText fontWeight={700} spColor='#000' fontSize={24}>{item.product_name}</BasicText>
                    <BasicText fontWeight={700} fontSize={15} color='#333'>{item.product_summary}</BasicText>
                    <BasicText fontWeight={700} fontSize={15} color='#333'>{item.product_description}</BasicText>
                    <BasicText align='center' fontSize={22} mFontSize={20} fontWeight={700} color={addTheme.color}>월 {numberWithComma(item.product_basic_price)}원</BasicText>
                </>
            }
        </ContentItemWrap>
    )
}

export default function MorePart({selectData,setSelectData,more,payList}:any) {
    const tvList = payList?.tv;
    const phoneList = payList?.landline;
    const list:any = {
        2: tvList,
        3: phoneList,
    }

    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    {more.length === 0 ?
                        <></> :
                        more.map((data:any,index:any)=>{
                            if(data === 1) return;
                            return(
                                <ScWrap key={`more-${index}`}>
                                    <BasicText fontSize={26} mFontSize={22} fontWeight={700}>{NAME[data-2].name}</BasicText>
                                    <ContentWrap>
                                        {list[data] === undefined || list[data].length === 0 ?
                                            <></> :
                                            list[data].map((item:any,idx:any)=>{
                                                const selectChk = selectData[NAME[data-2].en].product_id === item.product_id;
                                                
                                                return(
                                                    <MoreWrap key={`more-${index}-${idx}`} item={item} data={data} selectChk={selectChk} setSelectData={setSelectData} />
                                                )
                                            })
                                        }
                                    </ContentWrap>
                                </ScWrap>
                            )
                        })
                    }
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div``;
const ScWrap = styled.div`
    padding-bottom: 50px;
`;
const ContentWrap = styled(Row)`
    flex-wrap: wrap;
    margin-top: 15px;
    gap: 15px;
    align-items: start;
    &>div{
        width: calc(33% - 7.5px);
    }
    @media (max-width:800px){
        &>div{
            width: calc(50% - 7.5px);
        }
    }
`;

const ContentItemWrap = styled(Center)<{selectChk:boolean}>`
    flex-direction: column;
    border: 2px solid ${({selectChk}) => selectChk ? addTheme.color : '#bebebe'};
    border-radius: 6px;
    padding: 20px;
    cursor: pointer;
    gap: 4px;
    & img{
        height: 38px;
    }
`;
