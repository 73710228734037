import React,{useEffect, useState} from 'react'
import { useLocation } from 'react-router'
import { apiVisit } from '../../../api/api_main';

export default function ScrollToTop() {
  const {pathname} = useLocation();
  const [visit,setVisit] = useState(false);

  useEffect(() => {
    if(pathname.includes('/home')){
      const linkArr = pathname.split('/').filter((data:any)=>data);
      if(linkArr[0] === 'home') return;
    };
    window.scrollTo(0,0);
  }, [pathname])

  useEffect(()=>{
    if(visit) return;
    if(pathname.includes('/adm')) return;
    visitAction();
  },[pathname,visit]);

  const visitAction = async() =>{
    const res = await apiVisit();
    if(res.result){
      setVisit(true);
    }
  }
  
  return null;
}
