import React from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { Center, RowBetween } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { useNavigate } from 'react-router';

export default function PartnerBtnPart() {
    const navigate = useNavigate();
    const goPage = (path:string) =>{
        navigate(path);
    }
    return (
        <Wrap>
            <BasicText align='center' fontWeight={700} fontSize={38} mFontSize={22}>파트너스 신청 절차</BasicText>
            <ContentWrap>
                <BasicText fontSize={18} mFontSize={15}>영업일 기준 3일 이내(*기준일 미포함) 담당자 연락처로 승인 문자가 발송되며, 다음 절차를 안내드리고 있어요.</BasicText>
                <BasicText fontSize={18} mFontSize={15}>계정 정보의 이메일 주소는 추후 변경 할 수 없어요.</BasicText>
                <BasicText fontSize={18} mFontSize={15}>사업자 정보와 정산 정보는 모두 동일</BasicText>
            </ContentWrap>
            <BtnWrap>
                <BtnItem onClick={()=>goPage('/partnerJoin')} color={addTheme.color} bg='#fff'>파트너스 회원가입</BtnItem>
                <BtnItem onClick={()=>goPage('/partnerLogin')} color='#fff' bg={addTheme.color}>파트너스 로그인</BtnItem>
            </BtnWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 160px;
`;

const ContentWrap = styled.div`
    margin-top: 25px;
    background-color: #fff7ed;
    padding: 30px;
    border-radius: 12px;
    min-height: 170px;
`;

const BtnWrap = styled(RowBetween)`
    margin-top: 30px;
    gap: 15px;
    padding: 15px 0;
`;

const BtnItem = styled(Center)<{color:string,bg:string}>`
    width: 48%;
    color: ${({color}) => color ?? '#000'};
    background-color: ${({bg}) => bg ?? '#fff'};
    border: 1px solid ${addTheme.color};
    gap: 12px;
    height: 48px;
    font-size: 22px;
    border-radius: 5px;
    cursor: pointer;
    & img{
        width: 32px;
    }
    @media ${addTheme.media.s}{
        font-size: 16px;
    }
    & img{
        width: 26px;
    }
`;