import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import AdminPageTotalPart from '../../common/part/AdminPageTotalPart';
import { AdmBtnItem, AdminWrap, Center, Row } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';
import { useNavigate, useParams } from 'react-router';
import AdminTablePart from '../../common/part/AdminTablePart';
import { ADM_POINT_LIST, ADM_POINT_SEND_LIST, ADM_POINT_WITHDRAWAL_LIST } from '../../data/table';
import AdmPointTotalItem from '../item/AdmPointTotalItem';
import AdmPointWithdrawalItem from '../item/AdmPointWithdrawalItem';
import AdmPointSendItem from '../item/AdmPointSendItem';
import { apiAdmGetPointListSend, apiAdmGetPointListTotal, apiAdmGetPointListWithdrawal } from '../../../../api/api_adm_point';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';

const MENU_LIST = [
    {id:1,name:'전체 파트너 내역'},
    {id:2,name:'출금 신청 내역'},
    {id:3,name:'포인트 전송 내역'},
]
export default function AdmPointPage() {
    const {page,menu} = useParams();
    const navigate = useNavigate();
    const [titleTotalReload,setTitleTotalReload] = useState(false);
    const [list,setList] = useState([]);
    const [total,setTotal] = useState(0);
    // const [menu,setMenu] = useState(1);
    const adminUser = useAdminUser();

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        if(menu === undefined || menu === '1'){
            getListTotal();
        }
        if(menu === '2'){
            getListWithdrawal();
        }
        if(menu === '3'){
            getListSend();
        }
    },[page,adminUser.admin_id,menu])

    const getListTotal = async() =>{
        const res = await apiAdmGetPointListTotal(adminUser.admin_token,adminUser.admin_id,page ?? 1,15);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }
    const getListWithdrawal = async() =>{
        const res = await apiAdmGetPointListWithdrawal(adminUser.admin_token,adminUser.admin_id,page ?? 1,15);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }
    const getListSend = async() =>{
        const res = await apiAdmGetPointListSend(adminUser.admin_token,adminUser.admin_id,page ?? 1,15);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    const setPage = (p:any) =>{
        navigate(`/adm/point/${menu}/${p ?? 1}`);
    }
    // 설정 페이지
    const goSetting = () =>{
        navigate(`/adm/pointSetting`);
    }

    // 전송 페이지
    const goSend = () =>{
        navigate('/adm/pointSend');
    }

    const tabChange = (id:any) =>{
        navigate(`/adm/point/${id}/1`);
    }

    // 전체 파트너 내역 상세
    const partnerDetail = (code:string) =>{
        navigate(`/adm/partnerUpdate/${code}`);
    }

    // 출금신청 디테일
    const goWithdrawalDetail = (data:any) =>{
        navigate(`/adm/pointWithdrawal/${data.point_payment_id}`);
    }

    return (
        <Wrap>
            <AdminPageTotalPart title='전체 전송 포인트' part='sendPoint' reload={titleTotalReload}>
                <AdmBtnItem bg={addTheme.admBtn3} onClick={()=>goSetting()}>포인트 설정</AdmBtnItem>
                <AdmBtnItem bg={addTheme.admBtn1} onClick={()=>goSend()}>포인트 전송</AdmBtnItem>
            </AdminPageTotalPart>
            <AdminWrap>
                <TitleWrap>
                    {MENU_LIST.map((data:any,index:any)=>{
                        return(
                            <MenuItem state={data.id == menu} key={`menu-${index}`} onClick={()=>tabChange(data.id)}>{data.name}</MenuItem>
                        )
                    })}
                </TitleWrap>
                <TableWrap>
                    {(menu === undefined || menu === '1') &&
                        <AdminTablePart bg={addTheme.adminColor} color='#fff' title={ADM_POINT_LIST} minWidth={1000} page={page ?? 1} total={total} setPage={setPage}>
                            {list === undefined || list.length === 0 ?
                                <></> :
                                list.map((data:any,index:any)=>{
                                    return(
                                        <AdmPointTotalItem key={`review-item-${index}`} data={data} update={()=>{partnerDetail(data.partner_account_code)}} />
                                    )
                                })
                            }
                        </AdminTablePart>
                    }
                    {menu === '2' &&
                        <AdminTablePart bg={addTheme.adminColor} color='#fff' title={ADM_POINT_WITHDRAWAL_LIST} minWidth={1000} page={page ?? 1} total={total} setPage={setPage}>
                            {list === undefined || list.length === 0 ?
                                <></> :
                                list.map((data:any,index:any)=>{
                                    return(
                                        <AdmPointWithdrawalItem key={`review-item-${index}`} data={data} update={()=>goWithdrawalDetail(data)} />
                                    )
                                })
                            }
                        </AdminTablePart>
                    }
                    {menu === '3' &&
                        <AdminTablePart bg={addTheme.adminColor} color='#fff' title={ADM_POINT_SEND_LIST} minWidth={1000} page={page ?? 1} total={total} setPage={setPage}>
                            {list === undefined || list.length === 0 ?
                                <></> :
                                list.map((data:any,index:any)=>{
                                    return(
                                        <AdmPointSendItem key={`review-item-${index}`} data={data} update={()=>{}} />
                                    )
                                })
                            }
                        </AdminTablePart>
                    }
                </TableWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const TitleWrap = styled(Row)``;
const MenuItem = styled(Center)<{state: boolean}>`
    border: 1px solid ${({state}) => state ? '#898f98': '#e9e9e9'};
    border-bottom: none;
    width: 160px;
    height: 56px;
    cursor: pointer;
    text-align: center;
`;
const TableWrap = styled.div`
    margin-top: 20px;
`;