import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import styled from 'styled-components';
import AdminPageTotalPart from '../../common/part/AdminPageTotalPart';
import { AdmBtnItem, AdminWrap, Center, RowBetween } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';
import BasicText from '../../../basic/BasicText';
import AdminTablePart from '../../common/part/AdminTablePart';
import { ADM_POPUP_LIST } from '../../data/table';
import { TableCell, TableRow } from '@mui/material';
import { apiAdmGetBannerList, apiAdmSetDelBanner } from '../../../../api/api_adm_popupAndBenner';

const ListItem = ({data,update,del}:any) =>{
    return (
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={ADM_POPUP_LIST[0].align}>{data.banner_id}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POPUP_LIST[1].align}>{data.banner_title}</TableCell>
            <TableCell sx={{padding:'10px','& img':{width:'100%'}}} align={ADM_POPUP_LIST[2].align}>
                <img alt='popup-img' src={data.banner_img_mo}></img>
            </TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POPUP_LIST[3].align}>{data.banner_link_url}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POPUP_LIST[4].align}>
                <Center style={{gap:'8px'}}>
                    <DetailBtnItem bg='#ff7600' round={30} padding='6px 16px' onClick={update}>상세</DetailBtnItem>
                    <DelBtnItem bg='#9e9e9e' round={30} padding='6px 16px' onClick={del}>삭제</DelBtnItem>
                </Center>
            </TableCell>
        </TableRow>
    )
}

export default function AdmPopupPage() {
    const {page} = useParams();
    const adminUser = useAdminUser();
    const navigate = useNavigate();
    const [list,setList] = useState([]);
    const [total,setTotal] = useState(0);
    const [titleTotalReload,setTitleTotalReload] = useState(false);

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        getList();
    },[page,adminUser.admin_id]);

    const setPage = (p:any) =>{
        navigate(`/adm/popup/${p}`);
    }

    // 수정 클릭 -> 수정 페이지로 이동
    const goUpdate = (id?:any) =>{
        navigate(`/adm/popupUpdate/${id ?? ''}`)
    }

    // 리스트
    const getList = async() =>{
        const res = await apiAdmGetBannerList(adminUser.admin_token,adminUser.admin_id,page ?? 1, 15,'popup');
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    // 삭제
    const delClick = async(id:any) =>{
        if(window.confirm('팝업을 삭제 하시겠습니까?')){
            const res = await apiAdmSetDelBanner(adminUser.admin_token,adminUser.admin_id,id);
            if(res.result){
                alert('팝업을 삭제했습니다.');
                getList();
                setTitleTotalReload(!titleTotalReload);
            }else{
                alert(res.error);
            }
        }
    }

    return (
        <Wrap>
            <AdminPageTotalPart title='전체 팝업' part='popup' reload={titleTotalReload}>
                <AdmBtnItem bg={addTheme.admBtn1} onClick={()=>goUpdate()}>팝업 등록</AdmBtnItem>
            </AdminPageTotalPart>
            <AdminWrap>
                <TitleWrap>
                    <BasicText fontSize={18} mFontSize={16} fontWeight={700}>팝업 관리</BasicText>
                </TitleWrap>
                <TableWrap>
                    <AdminTablePart bg={addTheme.adminColor} color='#fff' title={ADM_POPUP_LIST} minWidth={1000} page={page ?? 1} total={total} setPage={setPage}>
                        {list === undefined || list.length === 0 ?
                            <></> :
                            list.map((data:any,index:any)=>{
                                return(
                                    <ListItem key={`review-item-${index}`} data={data} del={()=>delClick(data.banner_id)} update={()=>goUpdate(data.banner_id)}/>
                                )
                            })
                        }
                    </AdminTablePart>
                </TableWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;

const TitleWrap = styled(RowBetween)`
    flex-wrap: wrap;
`;

const TableWrap = styled.div`
    margin-top: 20px;
`;

const DetailBtnItem = styled(AdmBtnItem)``;
const DelBtnItem = styled(AdmBtnItem)``;