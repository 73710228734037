import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { ADM_PRODUCT_PHONE_LIST } from '../../data/table'
import { AdmBtnItem, Center } from '../../../../style/basic/commonStyle'
import { momentFormat } from '../../../../utils/format/time'
import { VISIBLE_KOR } from '../data/dummy'
import { numberWithComma } from '../../../../utils/format/number'

export default function AdmProductListPhoneItem({data,update,del}:any) {
    return (
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={ADM_PRODUCT_PHONE_LIST[0].align}>{data?.company_name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PRODUCT_PHONE_LIST[1].align}>{data?.product_code}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PRODUCT_PHONE_LIST[2].align}>{data?.product_name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PRODUCT_PHONE_LIST[3].align}>{data?.product_summary}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PRODUCT_PHONE_LIST[4].align}>{data?.product_description}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PRODUCT_PHONE_LIST[5].align}>{numberWithComma(data?.product_basic_price)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PRODUCT_PHONE_LIST[6].align}>{momentFormat('YYYY-MM-DD hh:mm:ss',data.product_reg_timestamp) }</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PRODUCT_PHONE_LIST[7].align}>{momentFormat('YYYY-MM-DD hh:mm:ss',data.product_update_timestamp)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PRODUCT_PHONE_LIST[8].align}>{VISIBLE_KOR[data.product_visible]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PRODUCT_PHONE_LIST[9].align}>
                <Center style={{gap:'8px'}}>
                    <AdmBtnItem bg='#ff7600' round={30} padding='6px 16px' onClick={update}>상세</AdmBtnItem>
                    <AdmBtnItem bg='#9e9e9e' round={30} padding='6px 16px' onClick={del}>삭제</AdmBtnItem>
                </Center>
            </TableCell>
        </TableRow>
    )
}

