import React from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { Center, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';

const LIST = [
    {title: '파트너스 회원가입'},
    {title: '파트너스 코드 발급'},
    {title: '블로그, 카페, SNS, 커뮤니티에 현주친구 홍보'},
    {title: '파트너스 컨텐츠로 유저 유입'},
    {title: '유입된 유저들이 현주친구에서 상품 구매 및 코드 입력'},
    {title: '현주친구 파트너스에서 판매 수익금 확인'},
]

export default function PartnerProcess() {
    return (
        <Wrap>
            <BasicText align='center' fontWeight={700} fontSize={38} mFontSize={26}>누구나 할 수 있는<br/>초 간단 프로세스!</BasicText>
            <BasicText align='center' marginTop={2} fontSize={19} mFontSize={17}>현주친구 파트너스 어렵지 않아요!<br/>단 10분으로, 수익 창출의 기회를 잡아보세요.</BasicText>
            <ContentWrap>
                {LIST.map((data:any,index:any)=>{
                    return(
                        <ContentItemWrap key={`process-${index}`}>
                            <BasicText align='center' fontSize={17} mFontSize={15}>STEP 0{index+1}</BasicText>
                            <BasicText align='center' ls={1} line={2} fontWeight={700} fontSize={19} mFontSize={16}>{data.title}</BasicText>
                        </ContentItemWrap>
                    )
                })}
            </ContentWrap>
            <LastItemWrap>
                <BasicText color='#fff' align='center' fontSize={17} mFontSize={15}>STEP 07</BasicText>
                <BasicText color='#fff' align='center' ls={1} line={2} fontWeight={700} fontSize={19} mFontSize={16}>현주친구 파트너스에서 수익금 신청 및 정산</BasicText>
            </LastItemWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 100px;
`;

const ContentWrap = styled(Row)`
    margin-top: 40px;
    gap: 18px;
    flex-wrap: wrap;
    &>div{
        width: calc(33% - 9px);
    }
    @media ${addTheme.media.m}{
        &>div{
            width: calc(50% - 9px);
        }
    }
    @media ${addTheme.media.s}{
        &>div{
            width: calc(100% - 9px);
        }
    }
`;

const ContentItemWrap = styled.div`
    background-color: #fff7ed;
    overflow: hidden;
    border-radius: 12px;
    padding: 20px 35px 15px;
`;

const LastItemWrap = styled(ContentItemWrap)`
    margin-top: 18px;
    background-color: ${addTheme.color};
    padding: 20px 35px 15px;
`;