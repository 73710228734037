import React from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, SectionWrap } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';
import BtnItem from '../item/BtnItem';
import { useNavigate } from 'react-router';

export default function PhoneNumberPart() {
    const navigate = useNavigate();
    
    const clickEvent = () =>{
        navigate('/benefitInquiry');
    }
    return (
        <SectionWrap bg={addTheme.color} padding='75px 0'>
            <MaxWidthWrap>
                <Wrap>
                    <BasicText color='#fff' align='center' fontSize={38} mFontSize={23} fontWeight={700}>오직 현주친구에서만</BasicText>
                    <BasicText color='#fff' align='center' fontSize={38} mFontSize={23} fontWeight={700}>누릴 수 있는 특별한 서비스 혜택!</BasicText>
                    <BasicText color='#fff' align='center' fontSize={26} mFontSize={18} marginTop={4}>24시간 365일 언제든 문의 가능</BasicText>
                    <NumberWrap>
                        <img alt='phone-icon' src='/img/asset/fnav02.png'></img>
                        <BasicText color='#fff' fontSize={48} mFontSize={28} fontWeight={700}>1533-9360</BasicText>
                    </NumberWrap>
                    <BtnItem clickEvent={clickEvent} title='실시간 상담 문의하기' titleColor={addTheme.color} bg={'#fff'} h={58} mh={48}/>
                </Wrap>
            </MaxWidthWrap>
        </SectionWrap>
    )
}

const Wrap = styled(Center)`
    flex-direction: column;
`;

const NumberWrap = styled(Center)`
    gap: 15px;
    & img{
        width: 40px;
        @media ${addTheme.media.s}{
            width: 34px;
        }
    }
`;