import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import LayoutPage from '../../common/page/LayoutPage';
import { Center, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import { useLocation, useNavigate } from 'react-router';
import { Checkbox, FormControlLabel, MenuItem } from '@mui/material';
import { fileTypeRes, onlyNumber } from '../../../utils/format/string';
import validator from 'validator';
import SelectItem from '../../common/item/SelectItem';
import { BANK } from '../../applicationForm/data/dummy';
import { apiGetKakaoInfo, apiGetPartnerKakaoLogin, apiSetPartnerApply, apiSetPartnerEmailChk, apiSetPartnerPhoneChk } from '../../../api/api_partner';
import { KAKAO_REG_LINK } from '../../../api/config';
import { setGlobalPartner } from '../../../store/common/globalPartner';
import { useDispatch } from 'react-redux';

const INIT = {
    "partner_type": "1",
    "partner_sign_type": "1",
    "partner_email": "",
    "partner_password": "",
    "partner_password2": "",
    "partner_social_id": "",
    "partner_name": "",
    "partner_phone_number": "",
    "partner_birth": "",
    "partner_business_number": "",
    "partner_business_name": "",
    "partner_bank": "",
    "partner_bank_account_number": "",
    "partner_business_cert_image": "",
    "partner_account_comment": '',
}

export default function PartnerJoinPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const fileRef = useRef<any>(null);
    const [agreed,setAgreed] = useState(false);
    const [data,setData] = useState(INIT);
    const [chkList,setChkList] = useState({email:false,phone:false});
    const location = useLocation();
    const pc = new URLSearchParams(location.search).get('pc') ?? '';

    useEffect(()=>{
        if(pc === '') {
            valChange('partner_social_id','');
            valChange('partner_sign_type', '1');
            return
        };
        if(data.partner_type === '1'){
            getKakaoInfo();
        }else{
            valChange('partner_social_id','');
            valChange('partner_sign_type', '1');
        }
    },[data.partner_type,pc]);

    const getKakaoInfo = async() =>{
        const res = await apiGetKakaoInfo(pc);
        if(res.result){
            setData((obj:any)=>({
                ...obj,
                partner_birth: res.data.birth,
                partner_email: res.data.email,
                partner_name: res.data.name,
                partner_phone_number: res.data.phone,
                partner_social_id:res.data.social_id,
                partner_sign_type: '2',
            }))
        }else{
            alert(res.error);
            navigate('/partnerJoin');
        }
    }

    // 회원가입
    const joinAction = async(e:any) =>{
        e.preventDefault();
        if(!agreed){
            alert('개인정보 수집·이용에 동의를 해주세요.');
            return;
        }
        if(!validator.isEmail(data.partner_email)){
            alert('이메일을 확인해주세요.');
            return
        }
        if(!chkList['email']){
            alert('이메일의 중복검사를 해주세요.');
            return;
        }
        if(!chkList['phone']){
            alert('휴대폰번호의 중복검사를 해주세요.');
            return;
        }
        if(data.partner_password !== data.partner_password2){
            alert('비밀번호가 다릅니다');
            return;
        }
        
        const res = await apiSetPartnerApply(data);
        if(res.result){
            alert('회원가입 신청이 되었습니다');
            navigate('/partnerJoin');
            window.location.reload();
        }else{
            alert(res.error);
        }
    }
    // 데이터 변경
    const valChange = (name:string,val:any) =>{
        if(name === 'partner_type' && val === '2'){
            setData((obj:any)=>({
                ...obj,
                partner_sign_type: '1',
            }))
        }
        if(name === 'partner_email'){
            setChkList((obj:any)=>({...obj,['email']:false}))
        }
        if(name === 'partner_phone_number'){
            setChkList((obj:any)=>({...obj,['phone']:false}))
        }
        setData((obj:any)=>({
            ...obj,
            [name]: val,
        }))
    }

    //  중복 검사
    const duplicateChk = async(type:string) => {
        if(type === 'email'){
            if(data.partner_email === '') return;
            const res = await apiSetPartnerEmailChk(data.partner_email);
            if(res.result){
                setChkList((obj:any)=>({...obj,['email']:true}))
            }else{
                setChkList((obj:any)=>({...obj,['email']:false}))
                alert('이미 등록된 이메일입니다.')
            }
        }
        if(type === 'phone'){
            if(data.partner_phone_number === '') return;
            const res = await apiSetPartnerPhoneChk(data.partner_phone_number);
            if(res.result){
                setChkList((obj:any)=>({...obj,['phone']:true}))
            }else{
                setChkList((obj:any)=>({...obj,['phone']:false}))
                alert('이미 등록된 휴대폰 번호입니다.')
            }
        }
    }

    // 파일 업로드 클릭
    const imgUploadClick = () =>{
        if(fileRef){
            fileRef.current.click();
        }
    }
    // 파일 선택
    const fileChange = (e:any) =>{
        const file = e.target.files[0];
        if(file){
            const fileFormat = fileTypeRes(file.name);
            if(fileFormat === 'png' || fileFormat === 'jpg'){
                const reader = new FileReader();

                reader.onload = () => {
                    const result = reader.result;
                    setData((obj:any)=>({...obj,['partner_business_cert_image']:result}))
                };

                reader.readAsDataURL(file);
            }else{
                alert('확장자가 png, jpg인 이미지 파일만 가능합니다.');
                fileRef.current.value = null;
            }
        }
    }

    const kakaoClick = () =>{
        window.location.href = `${KAKAO_REG_LINK}/api/social/oauth/kakao`;
    }

    return (
        <LayoutPage>
            <MaxWidthWrap>
                <TitleWrap>
                    <BasicText fontSize={36} mFontSize={22} fontWeight={700} align='center'>현주친구 파트너스 회원가입</BasicText>
                </TitleWrap>
                <Wrap>
                    {data.partner_type === '1' &&
                        <LoginBtnItem bg='#ffe500' onClick={kakaoClick}>
                            <img src="/img/asset/icon15.png" alt="kakao-icon" />
                            카카오로 간편 회원가입
                        </LoginBtnItem>
                    }
                    <form onSubmit={joinAction}>
                        <ContentWrap>
                            <ItemWrap>
                                <BasicText fontSize={17}>가입유형</BasicText>
                                <JoinTypeWrap>
                                    <JoinTypeItem typeState={data.partner_type === '1'} onClick={()=>valChange('partner_type','1')}>개인</JoinTypeItem>
                                    <JoinTypeItem typeState={data.partner_type === '2'} onClick={()=>valChange('partner_type','2')}>사업자</JoinTypeItem>
                                </JoinTypeWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText fontSize={17}>이메일</BasicText>
                                <ItemInputWrap>
                                    <input name='email' value={data?.partner_email ?? ''} onChange={(e:any)=> valChange('partner_email',e.target.value)} required></input>
                                    <ChkItem color={chkList['email'] ? addTheme.admBtn2 : addTheme.color} onClick={()=>duplicateChk('email')}>
                                        {chkList['email'] ? '사용가능' : '중복검사'}
                                    </ChkItem>
                                </ItemInputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText fontSize={17}>비밀번호</BasicText>
                                <ItemInputWrap>
                                    <input name='pw' type='password' value={data?.partner_password ?? ''} onChange={(e:any)=> valChange('partner_password',e.target.value)} required></input>
                                </ItemInputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText fontSize={17}>비밀번호 확인</BasicText>
                                <ItemInputWrap>
                                    <input name='pwChk' type='password' value={data?.partner_password2 ?? ''} onChange={(e:any)=> valChange('partner_password2',e.target.value)} required></input>
                                </ItemInputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText fontSize={17}>이름</BasicText>
                                <ItemInputWrap>
                                    <input name='name' value={data?.partner_name ?? ''} onChange={(e:any)=> valChange('partner_name',e.target.value)} required></input>
                                </ItemInputWrap>
                            </ItemWrap>
                            {data.partner_type === '2' &&
                                <>
                                    <ItemWrap>
                                        <BasicText fontSize={17}>사업자명</BasicText>
                                        <ItemInputWrap>
                                            <input name='bName' value={data?.partner_business_name ?? ''} onChange={(e:any)=> valChange('partner_business_name',e.target.value)} ></input>
                                        </ItemInputWrap>
                                    </ItemWrap>
                                    <ItemWrap>
                                        <BasicText fontSize={17}>사업자번호</BasicText>
                                        <ItemInputWrap>
                                            <input name='bNumber' value={data?.partner_business_number ?? ''} onChange={(e:any)=> valChange('partner_business_number',onlyNumber(e))} ></input>
                                        </ItemInputWrap>
                                    </ItemWrap>
                                    <ItemWrap align='start'>
                                        <BasicText fontSize={17} marginTop={1}>사업자등록증</BasicText>
                                        <div style={{width:'100%'}}>
                                            <ImgBtnWrap onClick={imgUploadClick}>파일 업로드</ImgBtnWrap>
                                            <input ref={fileRef} type='file' style={{display:'none'}} accept='.png,.jpg' onChange={fileChange}></input>
                                            {data.partner_business_cert_image === '' ?
                                                <></> :
                                                <FilePreviewWrap>
                                                    <img src={data.partner_business_cert_image} alt='file-img'></img>
                                                </FilePreviewWrap>
                                            }
                                        </div>
                                    </ItemWrap>
                                </>
                            }
                            <ItemWrap>
                                <BasicText fontSize={17}>휴대폰번호</BasicText>
                                <ItemInputWrap>
                                    <input maxLength={15} name='phone' value={data?.partner_phone_number ?? ''} onChange={(e:any)=> valChange('partner_phone_number',onlyNumber(e))} required></input>
                                    <ChkItem color={chkList['phone'] ? addTheme.admBtn2 : addTheme.color} onClick={()=>duplicateChk('phone')}>
                                        {chkList['phone'] ? '사용가능' : '중복검사'}
                                    </ChkItem>
                                </ItemInputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText fontSize={17}>생년월일</BasicText>
                                <ItemInputWrap>
                                    <input maxLength={7} name='birth' value={data?.partner_birth ?? ''} onChange={(e:any)=> valChange('partner_birth',onlyNumber(e))} required></input>
                                </ItemInputWrap>
                            </ItemWrap>
                            <ItemWrap style={{alignItems:'start'}}>
                                <BasicText fontSize={17} marginTop={1}>계좌번호</BasicText>
                                <BankWrap>
                                    <SelectItem value={data?.partner_bank ?? ''} setValue={(val:any)=>valChange('partner_bank',val)} none='none' required>
                                        <MenuItem value={''} disabled>은행선택</MenuItem>
                                        {BANK.map((data:any,index:any)=>{
                                            return(
                                                <MenuItem key={`bank-${index}`} value={data.name}>{data.name}</MenuItem>
                                            )
                                        })}
                                    </SelectItem>
                                    <ItemInputWrap>
                                        <input value={data?.partner_bank_account_number ?? ''} onChange={(e:any)=>valChange('partner_bank_account_number',onlyNumber(e))} placeholder='계좌번호 입력' required></input>
                                    </ItemInputWrap>
                                </BankWrap>
                            </ItemWrap>
                            <ItemWrap align='start'>
                                <BasicText fontSize={17} marginTop={1}>메모</BasicText>
                                <ItemInputWrap>
                                    <textarea rows={4} value={data?.partner_account_comment ?? ''} onChange={(e:any)=>valChange('partner_account_comment',e.target.value)}></textarea>
                                </ItemInputWrap>
                            </ItemWrap>
                        </ContentWrap>
                        <FormControlLabel sx={{marginTop:'15px'}} control={<Checkbox sx={{color:addTheme.color,'&.Mui-checked': {color:addTheme.color}}} checked={agreed} onChange={()=>{setAgreed(!agreed)}} />} label="개인 정보 수집·이용에 동의합니다." />
                        <BtnWrap type='submit'>회원가입</BtnWrap>
                    </form>
                </Wrap>
            </MaxWidthWrap>
        </LayoutPage>
    )
}

const TitleWrap = styled.div`
    margin-top: 150px;
`;

const Wrap = styled.div`
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    padding-bottom: 80px;
`;

const ContentWrap = styled(Row)`
    margin-top: 50px;
    align-items: start;
    flex-direction: column;
    gap: 15px;
`;

const ItemWrap = styled(Row)<{align?:string}>`
    align-items: ${({align}) => align ?? 'center'};
    &>p{
        width: 185px;
        flex-shrink: 0;
    }
    @media ${addTheme.media.sx}{
        flex-wrap: wrap;
    }
`;

const ItemInputWrap = styled(Row)`
    border: 1px solid #e6e6e6;
    padding: 10px 14px;
    border-radius: 6px;
    & input{
        width: 100%;
        padding: 4px;
    }
    & textarea{
        width: 100%;
    }
    &>div{
        flex-shrink: 0;
    }
`;

const BtnWrap = styled.button`
    width: 100%;
    background-color: ${addTheme.color};
    height: 52px;
    color: #fff;
    border-radius: 6px;
    margin-top: 30px;
`;

const JoinTypeWrap = styled(Row)`
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    overflow: hidden;
    height: 48px;
`;

const JoinTypeItem = styled(Center)<{typeState:boolean}>`
    width: 50%;
    height: 100%;
    cursor: pointer;
    background-color: ${({typeState}) => typeState ? addTheme.color : '#fff'};
    color: ${({typeState}) => typeState ? '#fff' : '#000'};
`;

const ChkItem = styled.div<{color?:string}>`
    color: ${({color}) => color ?? addTheme.color};
    padding: 0 5px;
    cursor: pointer;
`;

const ImgBtnWrap = styled(Center)`
    cursor: pointer;
    border: 1px solid #e6e6e6;
    padding: 12px 14px;
    border-radius: 6px;
    /* margin-top: 15px; */
`;

const FilePreviewWrap = styled.div`
    margin-top: 10px;
    width: 100%;
    max-width: 350px;
    &>img{
        width: 100%;
    }
`;

const LoginBtnItem = styled(Center)<{bg?:string}>`
    gap: 8px;
    margin-top: 20px;
    border-radius: 6px;
    background-color: ${({bg}) => bg ?? '#fff'};
    padding: 10px;
    cursor: pointer;
    & img{
        width: 28px;
    }
`;

const BankWrap = styled(Row)`
    flex-direction: column;
    align-items: start;
    gap: 10px;
`;