import React from 'react'
import styled from 'styled-components'
import { Center, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';

export default function StepInnerTitleItem({num,title}:any) {
    return (
        <Wrap>
            <NumWrap>{num}</NumWrap>
            <BasicText fontWeight={700} fontSize={27} mFontSize={20}>{title}</BasicText>
        </Wrap>
    )
}

const Wrap = styled(Row)`
    gap: 12px;
`;
const NumWrap = styled(Center)`
    color: #fff;
    background-color: #21272a;
    width: 34px;
    height: 34px;
    font-weight: 700;
    border-radius: 5px;
    @media ${addTheme.media.s}{
        width: 30px;
        height: 30px;
    }
`;