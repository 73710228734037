import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { ADM_POINT_SEND_LIST } from '../../data/table'
import { AdmBtnItem, Center } from '../../../../style/basic/commonStyle'
import { PARTNER_TYPE } from '../../partner/data/dummy'
import { numberWithComma } from '../../../../utils/format/number'
import { momentFormat } from '../../../../utils/format/time'

export default function AdmPointSendItem({data,update}:any) {
    return (
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_SEND_LIST[0].align}>{data.point_payment_id}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_SEND_LIST[1].align}>{PARTNER_TYPE[data.partner_account_type]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_SEND_LIST[2].align}>{data.partner_account_type === '1' ? data.partner_account_name : data.partner_account_business_name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_SEND_LIST[3].align}>{numberWithComma(data.point_payment_amount)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_SEND_LIST[4].align}>{momentFormat('YYYY-MM-DD hh:mm',data.point_payment_process_timestamp)}</TableCell>
        </TableRow>
    )
}
