import React from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';

export default function PartnerBannerPart() {
    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    <BasicText lh={1} ls={-.5} fontWeight={700} fontSize={56} mFontSize={36} color='#fff' align='center'>현주친구 파트너스</BasicText>
                    <BasicText lh={1} ls={-.5} fontWeight={700} fontSize={56} mFontSize={36} color='#fff' align='center'>GRAND OPEN</BasicText>
                    <BasicText marginTop={0.8} fontSize={18} mFontSize={16} color='#fff' align='center'>선착순 N분에게만 드리느 초특급 혜택 서둘러 지원금 받아가세요.</BasicText>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    background-color: ${addTheme.color};
`;
const InnerWrap = styled(Center)`
    flex-direction: column;
    padding: 90px 0;
`;