import React from 'react'
import styled from 'styled-components'
import { Center } from '../../../style/basic/commonStyle'
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';

export default function TitlePart({title,desc,desc2}:any) {
    return (
        <Wrap>
            {title === undefined || title === '' ?
                <></> :
                <BasicText fontSize={18} mFontSize={16} align='center' color={addTheme.color}>{title}</BasicText>
            }
            {desc === undefined || desc === '' ?
                <></> :
                <DescWrap dangerouslySetInnerHTML={{__html:desc}} />
            }
            {desc2 === undefined || desc2 === '' ?
                <></> :
                <BasicText marginTop={1.5} align='center' fontSize={22} mFontSize={16} color={'#797979'}>{desc2}</BasicText>
            }
        </Wrap>
    )
}

const Wrap = styled(Center)`
    flex-direction: column;
`;
const DescWrap = styled.div`
    text-align: center;
    font-weight: 700;
    margin-top: 15px;
    font-size: 31px;
    line-height: 1.2;
    @media ${addTheme.media.s}{
        font-size: 22px;
    }
`;