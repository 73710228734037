import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import { apiGetQnaList } from '../../../api/api_qna';
import { Tab, Tabs } from '@mui/material';

const ListItem = ({data}:any) =>{
    const [state,setState] = useState(false);
    const clickAction = () =>{
        setState(!state);
    }
    return (
        <ItemWrap>
            <ItemTitleWrap onClick={clickAction}>
                <BasicText fontWeight={700} fontSize={24}>Q</BasicText>
                <BasicText marginTop={0.9} color={'#555'} fontSize={15}>{data.faq_question}</BasicText>
            </ItemTitleWrap>
            <ItemContentWrap state={state}>
                <BasicText fontWeight={700} fontSize={24}>A</BasicText>
                <BasicText marginTop={0.9} color={'#555'} fontSize={15} wh='pre-wrap'>{data.faq_answer}</BasicText>
            </ItemContentWrap>
        </ItemWrap>
    )
}

export default function QnaListPart({faqCateList}:any) {
    const [list,setList] = useState<any>([]);
    const [value,setValue] = useState(0);

    useEffect(()=>{
        if(faqCateList === undefined || faqCateList.length === 0) return;
        getList();
    },[value,faqCateList])

    const getList = async() =>{
        const id = faqCateList[value]?.faq_category_id;
        if(id === undefined) return;
        const res = await apiGetQnaList(id,1,50);
        if(res.result){
            setList(res.data);
        }
    }

    const handleChange = (e:any,newVal:any) =>{
        setValue(newVal);
    }
    return (
        <Wrap>
            <TabWrap>
                {faqCateList === undefined || faqCateList.length === 0 ?
                    <></> :
                    <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons='auto' allowScrollButtonsMobile>
                        {faqCateList.map((data:any,index:any)=>{
                            return(
                                <Tab key={`tab-${index}`} label={data.faq_category_title} />
                            )
                        })}
                    </Tabs>
                }
            </TabWrap>
            <ContentWrap>
                {list === undefined || list.length === 0 ?
                    <></> :
                    list.map((data:any,index:any)=>{
                        return(
                            <ListItem key={`faq-${value}-${index}`} data={data}/>
                        )
                    })
                }
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const TabWrap = styled.div`
    width: 100%;
    & .MuiButtonBase-root{
        border: 1px solid #dcdcdc;
        color: #555;
        font-size: 15px;
        min-width: 70px;
        @media ${addTheme.media.s}{
            font-size: 14px;
        }
    }
    & .MuiTabs-indicator{
        display: none;
    }
    & .Mui-selected{
        background-color: ${addTheme.color} ;
        color: #fff !important;
    }

`;
const ContentWrap = styled.div`
    border-top: 1px solid #000;
`;

const ItemWrap = styled.div``;
const ItemTitleWrap = styled(Row)`
    align-items: start;
    gap: 10px;
    padding: 10px 15px;
    border-bottom: 1px solid #dcdcdc;
    &>p:nth-of-type(1){
        min-width: 55px;
        flex-shrink: 0;
    }
    cursor: pointer;
`;
const ItemContentWrap = styled(ItemTitleWrap)<{state:boolean}>`
    cursor: default;
    background-color: #fafafa;
    display: ${({state}) => state ? 'flex':'none'};
`;
