import React from 'react'
import styled from 'styled-components'
import { Center } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';

export default function StepTitleItem({step,title}:any) {
    return (
        <Wrap>
            {step !== undefined &&
                <StepWrap>STEP {step}</StepWrap>
            }
            {title !== undefined &&
                <BasicText marginTop={1} fontSize={38} fontWeight={700} mFontSize={22}>{title}</BasicText>
            }
        </Wrap>
    )
}

const Wrap = styled(Center)`
    flex-direction: column;
`;
const StepWrap = styled(Center)`
    background-color: ${addTheme.color};
    color: #fff;
    padding: 8px 20px;
    font-size: 19px;
    border-radius: 50px;
`;