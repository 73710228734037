import React from 'react'
import styled from 'styled-components'
import { numberWithComma } from '../../../utils/format/number'
import StepTitleItem from './StepTitleItem'
import { Row, SectionWrap } from '../../../style/basic/commonStyle'
import StepInnerTitleItem from './StepInnerTitleItem'
import BasicText from '../../basic/BasicText'
import { FaCheckCircle } from "react-icons/fa";
import { addTheme } from '../../../style/addTheme'

const LIST = [
    {name: '인터넷', channel:0, M100:{pay0:22000,pay1:16500,gift:30}, M500:{pay0:33000,pay1:23100,gift:33}, G1:{pay0:38500,pay1:25300,gift:33}},
    {name: '인터넷 + 베이직', channel:212, M100:{pay0:38500,pay1:33000,gift:40}, M500:{pay0:44000,pay1:34100,gift:47}, G1:{pay0:49500,pay1:36300,gift:47}},
    {name: '인터넷 + 프리미엄', channel:253, M100:{pay0:42900,pay1:37400,gift:40}, M500:{pay0:48400,pay1:38500,gift:47}, G1:{pay0:53900,pay1:40700,gift:47}},
    {name: '인터넷 + 프리미엄 디즈니(스탠다드)', channel:258, M100:{pay0:51000,pay1:45500,gift:40}, M500:{pay0:56500,pay1:46600,gift:47}, G1:{pay0:62000,pay1:48800,gift:47}},
    {name: '인터넷 + 프리미엄 넷플릭스HD', channel:258, M100:{pay0:54200,pay1:48700,gift:40}, M500:{pay0:59700,pay1:49800,gift:47}, G1:{pay0:65200,pay1:52000,gift:47}},
]

const DISCOUNT_FAMILY = [
    {name: '특수 상황', list: [
        {product: '신혼플러스', terms: '예비 부부, 신혼 부부 5g: 85요금제 LTE 78 요금제 이상', benefit: '모바일 요금 6개월 무료 + 월 최대 22,850원 할인'},
        {product: '펫플러스', terms: '스마트홈', benefit: '공기청정기 렌탈료 최대 21,000원 할인 구매 시 약 23만원 할인'},
        {product: '시니어 플러스', terms: '약 65세 이상 사용자', benefit: '인터넷 3,100원 추가 할인'},
    ] },
    {name: '인터넷 + 휴대폰', list: [
        {product: '투게더 결합', terms: '모바일 5회선, 인터넷 5회선까지 요금제에 따라 할인 금액 변동', benefit: '인터넷 11,000원/ 휴대폰 최대 20,000원 할인'},
        {product: '참 쉬운 가족결합', terms: '모바일 10회선,인터넷 3회선까지 요금제에 따라 할인 금액 변동', benefit: '인터넷 13,200원/ 휴대폰 최대 5,500원 할인'},
    ] },
    {name: '인터넷 + 인터넷', list: [{product: '패밀리 결합', terms: '인터넷 2대 이상', benefit: '인터넷 5,500원'}]},
]

const DISCOUNT_CARD = [
    {name: '삼성카드', list: [{name: 'LG U+ 삼성카드', benefit: '30만원 이상 실적 7,000원 할인'}]},
    {name: '현대카드', list: [{name: 'LG U+ 현대카드M Edition3 (통신할인형2.0)', benefit: '50만원 이상 실적 15,000원 할인 (1~24개월)'}]},
    {name: '하나카드', list: [{name: '더 심플 하나카드', benefit: '30만원 이상 실적 10,000원 할인'},{name: 'LG U+Family 하나카드', benefit: '30만원 이상 실적 통신료 25% 청구할인 (25개월 이후 15% 청구할인)'}]},
    {name: '신한카드', list: [{name: 'LG U+ 사장님 통할인 신한카드', benefit: '70만원 이상 실적 10,000원 할인 (25개월 이후 6,000원 할인)'}]},
    {name: '국민카드', list: [{name: 'LG U+ 심플라이트 KB국민카드', benefit: '30만원이상 실적 12,000원 할인 (25개월 이후 6,000원 할인)'}]},
    {name: '롯데카드', list: [{name: 'LG U+ x LOCA 롯데카드', benefit: '30만원 이상 실적 10,000원 할인 (25개월 이후 6,000원 할인)'}]},
    {name: 'NH카드', list: [{name: 'NH올원 LG U+ 카드', benefit: '40만원 이상 실적 5,000원 (할부와 할인 중복 불가)'}]},
    
]

export default function LgStepItem({selectData}:any) {
    return (
        <Wrap>
            <StepTitleItem step='01' title={`LG U+ 요금표 한눈에 보기`} />
            <TableWrap>
                <TableWrapItem margin='40px 0 0'>
                    <thead>
                        <tr>
                            <th className='title-bg' rowSpan={2} colSpan={2}> 인터넷 상품</th>
                            <th className='title-bg' rowSpan={2}>모바일<br/>결합여부</th>
                            <th className='title-bg' colSpan={2} rowSpan={1}>100M</th>
                            <th className='title-bg' colSpan={2} rowSpan={1}>500M</th>
                            <th className='title-bg' colSpan={2} rowSpan={1}>1G</th>
                        </tr>
                        <tr>
                            <th className='title-bg'>요금</th>
                            <th className='title-bg'>사은품</th>
                            <th className='title-bg'>요금</th>
                            <th className='title-bg'>사은품</th>
                            <th className='title-bg'>요금</th>
                            <th className='title-bg'>사은품</th>
                        </tr>
                    </thead>
                    <tbody>
                        {LIST.map((data:any,index:any)=>{
                            const evenState = (index+1) % 2 === 0;
                            return(
                                <React.Fragment key={`kt-pay-${index}`}>
                                    <tr className={evenState ? 'content-bg' : ''}>
                                        <th className='content-title' rowSpan={2} colSpan={2}>
                                            {data.name}
                                            {data.channel !== 0 &&
                                                <>
                                                    <br/><span>{data.channel}채널</span>
                                                </>
                                            }
                                        </th>
                                        <td className='first-text'>미결합</td>
                                        <td className='first-text'>{numberWithComma(data['M100']['pay0'])}원</td>
                                        <td rowSpan={2} className='gift'>{numberWithComma(data['M100']['gift'])}만원</td>
                                        <td className='first-text'>{numberWithComma(data['M500']['pay0'])}원</td>
                                        <td rowSpan={2} className='gift'>{numberWithComma(data['M500']['gift'])}만원</td>
                                        <td className='first-text'>{numberWithComma(data['G1']['pay0'])}원</td>
                                        <td rowSpan={2} className='gift'>{numberWithComma(data['G1']['gift'])}만원</td>
                                    </tr>
                                    <tr className={evenState ? 'content-bg' : ''}>
                                        <td>1대결합</td>
                                        <td>{numberWithComma(data['M100']['pay1'])}원</td>
                                        <td>{numberWithComma(data['M500']['pay1'])}원</td>
                                        <td>{numberWithComma(data['G1']['pay1'])}원</td>
                                    </tr>
                                </React.Fragment>
                            )
                        })}
                    </tbody>
                </TableWrapItem>
            </TableWrap>
            <SectionWrap padding={'80px 0 0'}>
                <StepTitleItem step='02' title={'지금까지 속으셨습니다. 할인은 오직 4가지가 전부!'} />
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={1} title='할인크기가 큰 순서' />
                    <BasicText marginTop={0.5}>1. 약정할인</BasicText>
                    <BasicText>2. 가족결합할인</BasicText>
                    <BasicText>3. 제휴카드 할인</BasicText>
                    <BasicText>4. 복지할인(아주 제한적)</BasicText>
                    <BasicText marginTop={2}>이 외 통신요금은 전부 동일하며<br/>대리점에 따라 절대 달라지지 않습니다.</BasicText>
                </SectionWrap>
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={2} title='1. 약정할인은 무조건 하세요' />
                    <BasicText marginTop={0.5}>약정 기간은 무조건 3년이 좋습니다.<br/>왜 그런지 한번 비교해볼게요.</BasicText>
                    <BasicText marginTop={2} fontWeight={700}>인터넷 100메가 + 베이직 tv 기준(와이파이 X)</BasicText>
                    <InnerStep02Wrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>1년 약정 월 58,520원 X 12 = 702,240원</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>3년 약정 월 37,400원 X 12 = 448,800원</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='transparent' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>여기에 위약금과 사은품까지 계산해보면?</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='transparent' size={22} />
                            <BasicText fontSize={17}>448,800 + 300,000원(위약금) - 420,000원(사은품)</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='transparent' size={22} />
                            <BasicText fontSize={17} fontWeight={700} color={'#e93e5d'}>= 328,800원</BasicText>
                        </TextRowWrap>
                    </InnerStep02Wrap>
                    <BasicText marginTop={2} fontSize={14} color={addTheme.subText.color}>· 위약금과 사은품은 평균 값을 계산한 금액으로 정확하지 않을 수 있습니다.</BasicText>
                    <BasicText marginTop={2} fontWeight={700}>1년 약정 = 사은품 X</BasicText>
                    <BasicText fontWeight={700} spColor='#e93e5d'>비교 결과: 1년 약정이 <span>30만원 이상 손해</span></BasicText>
                    <BasicText fontSize={18} marginTop={2} fontWeight={700} spColor='#e93e5d'><span>!</span> 주의 <span>!</span></BasicText>
                    <BasicText>12개월 미만 사용 시 받았던<br/>사은품을 돌려줘야하는 할인반환금 제도가 있습니다.</BasicText>
                    <BasicText marginTop={2}><strong>아정당에선 9개월만</strong> 쓰셔도<br/>사은품 반환의무가 사라집니다. 😉</BasicText>
                </SectionWrap>
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={3} title='2. 대부분 놓치는 가족결합 할인' />
                    <BasicText marginTop={0.5}>가장 어렵지만, 그만큼 혜택이 큽니다!<br/>(심지어 상담원조차 모르는 경우가 많아요.)</BasicText>
                    <BasicText marginTop={0.5}>내가 잘 모르면 ‘최대 100만 원’까지 손해봅니다.<br/>제가 세계에서 가장 쉽게 정리하면요,</BasicText>
                    <TableWrap>
                        <TableWrapItem margin='20px 0 0'>
                            <colgroup>
                                <col width="15.5%" /><col width="17.5%" /><col width="34%" /><col width="33%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>결합 종류</th>
                                    <th>결합 상품</th>
                                    <th>결합 조건</th>
                                    <th>혜택 요약</th>
                                </tr>
                            </thead>
                            <tbody>
                                {DISCOUNT_FAMILY.map((data:any,index:any)=>{
                                    return(
                                        <React.Fragment key={`kt-family-discount-${index}`}>
                                            <tr>
                                                <th rowSpan={data.list.length}>{data.name}</th>
                                                {data.list.map((item:any,idx:any)=>{
                                                    if(idx !== 0) return;
                                                    return(
                                                        <React.Fragment key={`kt-family-item-${index}-${idx}`}>
                                                            <td>{item['product']}</td>
                                                            <td>{item['terms']}</td>
                                                            <td>{item['benefit']}</td>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </tr>
                                            {data.list.map((item:any,idx:any)=>{
                                                if(idx === 0) return;
                                                return(
                                                    <tr key={`kt-family-item-${index}-${idx}`}>
                                                        <td>{item['product']}</td>
                                                        <td>{item['terms']}</td>
                                                        <td>{item['benefit']}</td>
                                                    </tr>
                                                )
                                            })}
                                        </React.Fragment>
                                    )
                                })}
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <BasicText marginTop={2}><strong>2명 이상 85,000원 이상 요금제를 쓰고 있다면?</strong><br/>-&gt; 투게더 결합</BasicText>
                    <BasicText marginTop={2}><strong>그 이하라면?</strong><br/>-&gt; 참 쉬운 가족 결합!</BasicText>
                    <TableWrap>
                        <TableWrapItem>
                            <colgroup>
                                <col width="21%" />
                                <col width="20%" />
                                <col width="13%" />
                                <col width="15%" />
                                <col width="15.5%" />
                                <col width="15.5%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>구분</th>
                                    <th>월 요금</th>
                                    <th>1회선</th>
                                    <th>2회선</th>
                                    <th>3회선</th>
                                    <th>4회선 ~</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th rowSpan={3}>참 쉬운 가족결합</th>
                                    <td>69,000원 미만</td>
                                    <td rowSpan={3}>할인X</td>
                                    <td>2,200원</td>
                                    <td>3,300원</td>
                                    <td>4,400원</td>
                                </tr>
                                <tr>
                                    <td>69,000원 이상</td>
                                    <td>3,300원</td>
                                    <td>5,500원</td>
                                    <td>6,600원</td>
                                </tr>
                                <tr>
                                    <td>88,000원 이상</td>
                                    <td>4,400원</td>
                                    <td>6,600원</td>
                                    <td>8,800원</td>
                                </tr>
                                <tr>
                                    <th>투게더 결합</th>
                                    <td>85,000원 이상</td>
                                    <td>할인X</td>
                                    <td>10,000원</td>
                                    <td>14,000원</td>
                                    <td>20,000원</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr className='title-bg'>
                                    <th colSpan={6}>인터넷 할인</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th></th>
                                    <td>100M</td>
                                    <td colSpan={2}>500M</td>
                                    <td colSpan={2}>1G</td>
                                </tr>
                                <tr>
                                    <th>참 쉬운 가족결합</th>
                                    <td>5,000원</td>
                                    <td colSpan={2}>9,900원</td>
                                    <td colSpan={2}>13,200원</td>
                                </tr>
                                <tr>
                                    <th>투게더 결합</th>
                                    <td>결합 불가</td>
                                    <td colSpan={4}>11,000원</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <BasicText marginTop={2}>혹시 결합 조건이나 내용이 어려우시다면 언제든 전화주세요.</BasicText>
                    <BasicText marginTop={2}>가장 유리한 조건으로 최저가 컨설팅 도와드리겠습니다. 😉</BasicText>
                    <BasicText marginTop={2} fontWeight={700}>(📞 1533-9360)</BasicText>
                    <BasicText marginTop={2}>외에도 알뜰폰 결합과 신혼 부부 등 특정 고객을 위한 할인도 존재합니다!</BasicText>
                    <BasicText marginTop={2}>혹시 어려우시다면 언제든 문의주세요!</BasicText>
                </SectionWrap>
                <SectionWrap>
                    <StepInnerTitleItem num={4} title='3. 있으면 더 좋아요, 제휴카드 할인' />
                    <BasicText marginTop={0.5}>제휴카드 발급 후 통신요금 자동이체 시 할인혜택이 적용됩니다.</BasicText>
                    <BasicText>단, <strong>실적조건</strong>이 있어요.</BasicText>
                    <TableWrap>
                        <TableWrapItem margin='15px 0 0'>
                            <colgroup>
                                <col width="17.5%" />
                                <col width="41.5%" />
                                <col width="41%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>카드사</th>
                                    <th>카드명</th>
                                    <th>할인 혜택</th>
                                </tr>
                            </thead>
                            <tbody>
                                {DISCOUNT_CARD.map((data:any,index:any)=>{
                                    return(
                                        <React.Fragment key={`kt-card-discount-${index}`}>
                                            <tr>
                                                <th rowSpan={data.list.length}>{data.name}</th>
                                                {data.list.map((item:any,idx:any)=>{
                                                    if(idx !== 0) return;
                                                    return(
                                                        <React.Fragment key={`kt-card-item-${index}-${idx}`}>
                                                            <td>{item['name']}</td>
                                                            <td>{item['benefit']}</td>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </tr>
                                            {data.list.map((item:any,idx:any)=>{
                                                if(idx === 0) return;
                                                return(
                                                    <tr key={`kt-card-item-${index}-${idx}`}>
                                                        <td>{item['name']}</td>
                                                        <td>{item['benefit']}</td>
                                                    </tr>
                                                )
                                            })}
                                        </React.Fragment>
                                    )
                                })}
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                </SectionWrap>
            </SectionWrap>
            <SectionWrap padding={'80px 0 0'}>
                <StepTitleItem step='03' title={'인터넷 전화도 필요하시다면'} />
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={1} title='설치 일정 및 사은품 지급' />
                    <BasicText marginTop={0.5}>혹시 집이나 매장에서 쓸 전화기도 함께 필요하신가요?<br/>그렇다면 인터넷선만 있으면 되는 인터넷 전화를 추천드립니다.</BasicText>
                    <TableWrap>

                        <TableWrapItem margin='10px 0 0'>
                            <colgroup>
                                <col width="24.5%" />
                                <col width="34.5%" />
                                <col width="41%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>요금제</th>
                                    <th>월 요금</th>
                                    <th>무료 서비스</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>표준형 +</td>
                                    <td>1,100원</td>
                                    <td>
                                        <strong>국내 음성</strong>3분당 41.8원<br/>
                                        <strong>휴대폰</strong>10초당 12.87원
                                    </td>
                                </tr>
                                <tr>
                                    <td>이동할인형</td>
                                    <td>2,200원</td>
                                    <td>
                                        <strong>국내 음성</strong>3분당 41.8원<br/>
                                        <strong>휴대폰</strong>10초당 7.98원
                                    </td>
                                </tr>
                                <tr>
                                    <td>이동할인형+</td>
                                    <td>3,300원</td>
                                    <td>
                                        <strong>국내 음성</strong>3분당 41.8원<br/>
                                        <strong>휴대폰</strong>10초당 7.98원
                                    </td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                </SectionWrap>
            </SectionWrap>
            <SectionWrap padding={'80px 0 0'}>
                <StepTitleItem step='04' title={'잠깐!! 설치 전 확인하셨나요? 필수 체크리스트'} />
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={1} title='대칭형/비대칭형 조회' />
                    <BasicText marginTop={0.5}>인터넷 가입 전 대칭형/비대칭형 조회는 필수입니다!</BasicText>
                    <InnerStep02Wrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>대칭형 인터넷(FTTH 회선)</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='transparent' size={22} />
                            <BasicText>업로드 및 다운로드 속도가 일정한 초고속 인터넷(고품질)</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='20px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>비대칭형 인터넷(HFC 회선)</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='transparent' size={22} />
                            <BasicText>업로드 및 다운로드 속도가 달라 불안정한 인터넷(저품질)</BasicText>
                        </TextRowWrap>
                    </InnerStep02Wrap>
                    <BasicText marginTop={2}>품질은 달라도 요금은 같기 때문에 반드시<br/><strong>대칭형 인터넷</strong>이 들어오는 지 확인해주세요!</BasicText>
                    <BasicText marginTop={2} spColor={addTheme.color}>저희 <span>현주친구</span>로 문의주시면<br/>KT, LG, SK 모두 확인해서 알려드리겠습니다^^</BasicText>
                    <BasicText marginTop={2}>(📞 1533-9360)</BasicText>
                    <BasicText marginTop={2} >전화가 부담스러우신 분은<br/>아래링크에 주소를 입력해서 확인해주세요!</BasicText>
                    <BasicText marginTop={2} fontWeight={700} deco='underline'><a href="https://www.lguplus.com/support/online/coverage-check" target="_blank" rel="noopener noreferrer">※ LG 대칭형 인터넷 설치지역 조회 링크</a></BasicText>
                    <BasicText marginTop={2} >거주지 조회 후 1기가 인터넷 설치가 가능하다면 대칭형입니다.</BasicText>
                </SectionWrap>
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={2} title='IPTV 셋톱박스/공유기' />
                    <BasicText marginTop={0.5}>현주친구에 명시된 요금은 <strong>셋톱박스, 모뎀 임대료 및 부가가치세가</strong><br/>전부 포함된 실 납부액입니다.</BasicText>
                    <BasicText marginTop={2}>공유기와 셋톱박스는 임대로 제공되며 약정 후 꼭 반납을 해야합니다.<br/>혹시나 잃어버리지 않게 주의하세요!</BasicText>
                    <BasicText marginTop={2}>TV 채널 편성이 궁금하다면 <strong><a style={{display:'inline-block'}} href="https://www.lguplus.com/iptv/channel-guide" target="_blank" rel="noopener noreferrer">여기</a></strong>를 클릭해주세요.</BasicText>
                    <TextRowWrap gap={8} margin='20px 0 0'>
                        <FaCheckCircle color='#ff7500' size={22} />
                        <BasicText fontSize={17} fontWeight={700}>주요 OTT 시청 가능 유무</BasicText>
                    </TextRowWrap>
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0'>
                            <colgroup>
                                <col />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="12%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>구분</th>
                                    <th>유튜브</th>
                                    <th>넷플릭스</th>
                                    <th>디즈니+</th>
                                    <th>웨이브</th>
                                    <th>왓챠</th>
                                    <th>티빙</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>U+tv 사운드바 블랙</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                </tr>
                                <tr>
                                    <td>U+tv UHD4</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <TextRowWrap gap={8} margin='20px 0 0'>
                        <FaCheckCircle color='#ff7500' size={22} />
                        <BasicText fontSize={17} fontWeight={700}>셋톱박스 임대료</BasicText>
                    </TextRowWrap>
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0'>
                            <colgroup>
                                <col width="36%" />
                                <col width="28.5%" />
                                <col width="35.5%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>셋톱박스</th>
                                    <th>월 임대료</th>
                                    <th>특징</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>U+tv 사운드바 블랙</td>
                                    <td>6,600원</td>
                                    <td>세계 최초 돌비비전이 탑재되어<br/>생생한 음질 제공</td>
                                </tr>
                                <tr>
                                    <td>U+tv UHD4</td>
                                    <td>4,400원</td>
                                    <td>AI음향 기술</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <TextRowWrap gap={8} margin='20px 0 0'>
                        <FaCheckCircle color='#ff7500' size={22} />
                        <BasicText fontSize={17} fontWeight={700}>공유기 임대료(3년 약정 결합기준)</BasicText>
                    </TextRowWrap>
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0'>
                            <colgroup>
                                <col width='17%' />
                                <col width='25%' />
                                <col width='18%' />
                                <col width='20%' />
                                <col width='20%' />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>구분</th>
                                    <th>특징</th>
                                    <th>100메가</th>
                                    <th>500메가</th>
                                    <th>1기가</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>기가와이파이</th>
                                    <td>100M 진행 시 1대 의무</td>
                                    <td>기본제공<br/>(추가 시 2,200원)</td>
                                    <td>추가 시 2,200원</td>
                                    <td>추가 시 2,200원</td>
                                </tr>
                                <tr>
                                    <th>기가와이파이6</th>
                                    <td>500M 진행 시 1대 의무</td>
                                    <td>추가 시 2,200원</td>
                                    <td>기본제공<br/>(추가 시 2,200원)</td>
                                    <td>기본제공<br/>(추가 시 2,200원)</td>
                                </tr>
                                <tr>
                                    <th>기가와이파이메쉬</th>
                                    <td>500M 진행 시<br/>선택 or 추가 가능</td>
                                    <td>X</td>
                                    <td>프리미엄안심 인터넷<br/>에서 선택 가능<br/>(추가 시 2,200원)</td>
                                    <td>프리미엄안심 인터넷<br/>에서 선택 가능<br/>(추가 시 2,200원)</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <BasicText marginTop={2}>참고로 기본 제공되는 공유기는<br/><strong>구매가 최소 5만원 이상 공유기와 비슷한 스펙</strong>입니다.</BasicText>
                </SectionWrap>
            </SectionWrap>
            <SectionWrap padding={'80px 0 0'}>
                <StepTitleItem step='05' title={'설치 및 사은품 지급 안내'} />
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={1} title='설치 일정 및 사은품 지급' />
                    <InnerStep02Wrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>설치는 신청 후 기사님과 조율하시면 됩니다. 평균 1~3일 내로 가능합니다</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>원하시는 날짜가 있다면 미리 말씀주세요. 최대한 맞춰드릴 수 있도록 도와드리겠습니다.</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>당일 설치는 기사님 일정에 따라 정해져요. 먼저 말씀해주시면 도와드리겠습니다!</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>설치시간은 평균 30분 내외이며 환경에 따라 조금 더 시간이 걸릴 수 있어요.</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>설치 후 1533-9360번으로 바로 연락주세요. 사은품 지급을 도와드리겠습니다.</BasicText>
                        </TextRowWrap>
                    </InnerStep02Wrap>
                    <BasicText marginTop={2} spColor={addTheme.color}>*<span>현주친구</span>는 설치 후 9개월 사용으로 사은품 반환의무가 없어진답니다.<br/>(타사는 대부분 12개월)</BasicText>
                </SectionWrap>
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={2} title='설치비용' />
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0'>
                            <colgroup>
                                <col width="10%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="15%" />
                                <col width="20%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>구분</th>
                                    <th>인터넷 단독</th>
                                    <th>TV 단독</th>
                                    <th>인터넷<br/>+ TV</th>
                                    <th>TV추가<br/>(1대 당)</th>
                                    <th>인터넷 전화<br/>(인터넷과 동시<br/>진행시)</th>
                                    <th>일반 전화<br/>(인터넷과 동시<br/>진행시)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>평일</th>
                                    <td>36,300원</td>
                                    <td>34,100원</td>
                                    <td>56,100원</td>
                                    <td>+22,000원</td>
                                    <td>+11,000원</td>
                                    <td>+11,000원</td>
                                </tr>
                                <tr>
                                    <th>주말</th>
                                    <td>45,375원</td>
                                    <td>42,625원</td>
                                    <td>70,125원</td>
                                    <td>+27,500원</td>
                                    <td>+11,000원</td>
                                    <td>+11,000원</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <TextRowWrap gap={8} margin='10px 0 0'>
                        <FaCheckCircle color='#ff7500' size={20} />
                        <BasicText>최초 가입시 1회 설치비가 발생하며, 이사를 한다면 이전설치비가 청구됩니다(비용동일)</BasicText>
                    </TextRowWrap>
                </SectionWrap>
            </SectionWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const TableWrap = styled.div`
    overflow-x: auto;
    &>table{
        min-width: 560px;
    }
`;
const TableWrapItem = styled.table<{margin?:string}>`
    margin: ${({margin}:any)=> margin ?? '0'};
`;
const InnerStep02Wrap = styled.div`
    margin-top: 10px;
    background-color: #fbf5ec;
    padding: 20px;
`;
const TextRowWrap = styled(Row)<{margin?:string,gap?:number}>`
    margin: ${(({margin}) => margin ?? '0')};
    gap: ${(({gap}) => gap ?? 0)}px;
`;