import React, { useState } from 'react'
import styled from 'styled-components'
import { Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';

export default function InputReferralPart({order_basic,valueChange}:any) {
    
    return (
        <Wrap>
            <BasicText fontWeight={700} fontSize={18}>추천인 정보</BasicText>
            <ContentWrap>
                <ItemWrap>
                    <TitleItem>추천인 코드</TitleItem>
                    <InputItem>
                        <input value={order_basic?.order_refer_code ?? ''} onChange={(e:any)=>valueChange('basic','order_refer_code',e.target.value)} placeholder='추천인 코드를 입력하세요'></input>
                    </InputItem>
                </ItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 80px;
`;

const ContentWrap = styled(Row)`
    flex-direction: column;
    gap: 25px;
    margin-top: 15px;
    align-items: start;
`;

const ItemWrap = styled(Row)`
    gap: 10px;
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
    }
`;


const TitleItem = styled.div`
    width: 20%;
    flex-shrink: 0;
    text-align: left;
    font-size: 16px;
    @media ${addTheme.media.s}{
        width: 100%;
    }
`;

const InputItem = styled.div<{w?:string}>`
    border: 1px solid #e9e9e9;
    height: 42px;
    width: ${({w}) => w ?? '100%'};
    border-radius: 4px;
    &>input{
        height: 100%;
        width: 100%;
        padding: 10px 15px;
    }
`;