import React, { useState } from 'react'
import styled from 'styled-components'
import { AdmBtnItem, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { addTheme } from '../../../../style/addTheme';
import { onlyNumber } from '../../../../utils/format/string';
import { FormControlLabel, Radio } from '@mui/material';
import { numberWithComma } from '../../../../utils/format/number';
import OpenImgPart from './OpenImgPart';

// partner_account_type 1 개인 / 2 사업자
// partner_account_sign_type 1 그냥 / 2 카카오
export default function AdmPartnerUpdateInfoPart({info,valueChange,memoUpdateAction}:any) {
    const [open,setOpen] = useState(false);
    return (
        <Wrap>
            <ContentWrap gap={15}>
                <ItemWrap pWidth={110}>
                    <BasicText>파트너 코드</BasicText>
                    <InputWrap>
                        <input readOnly onChange={(e:any)=>valueChange('partner_account_code',e.target.value)} value={info.partner_account_code} placeholder='파트너 승인 후 코드가 발급됩니다'/>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap gap='20px'>
                    <ItemWrap pWidth={110}>
                        <BasicText>파트너 명</BasicText>
                        <InputWrap>
                            <input onChange={(e:any)=>valueChange('partner_account_name',e.target.value)} value={info.partner_account_name}/>
                        </InputWrap>
                    </ItemWrap>
                    <ItemWrap pWidth={110}>
                        <BasicText>생년월일</BasicText>
                        <InputWrap>
                            <input maxLength={6} onChange={(e:any)=>valueChange('partner_account_birth',onlyNumber(e))} value={info.partner_account_birth}/>
                        </InputWrap>
                    </ItemWrap>
                </ItemWrap>
                <ItemWrap pWidth={110}>
                    <BasicText>주민번호</BasicText>
                    <InputWrap>
                        <input onChange={(e:any)=>valueChange('partner_account_resident_number',onlyNumber(e))} value={info.partner_account_resident_number} placeholder='출금 신청 시 기입됩니다'/>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={110}>
                    <BasicText>파트너 유형</BasicText>
                    <Row>
                        <FormControlLabel control={<Radio name='user-type' value={'1'} checked={info.partner_account_type === '1'} onChange={(e:any)=>valueChange('partner_account_type',e.target.value)} />} label="개인" />
                        <FormControlLabel control={<Radio name='user-type' value={'2'} checked={info.partner_account_type === '2'} onChange={(e:any)=>valueChange('partner_account_type',e.target.value)} />} label="사업자" />
                    </Row>
                </ItemWrap>
                <ItemWrap gap='20px'>
                    <ItemWrap pWidth={110}>
                        <BasicText>연락처</BasicText>
                        <InputWrap>
                            <input onChange={(e:any)=>valueChange('partner_account_phone_number',onlyNumber(e))} value={info.partner_account_phone_number}/>
                        </InputWrap>
                    </ItemWrap>
                    <ItemWrap pWidth={110}>
                        <BasicText>이메일</BasicText>
                        <InputWrap>
                            <input onChange={(e:any)=>valueChange('partner_account_email',e.target.value)} value={info.partner_account_email} />
                        </InputWrap>
                    </ItemWrap>
                </ItemWrap>
                <ItemWrap pWidth={110} align='start'>
                    <BasicText marginTop={1}>계좌주소</BasicText>
                    <div style={{width: '100%'}}>
                        <InputWrap max={'200px'}>
                            <input onChange={(e:any)=>valueChange('partner_account_bank',e.target.value)} value={info.partner_account_bank}/>
                        </InputWrap>
                        <InputWrap margin='5px 0 0 0'>
                            <input onChange={(e:any)=>valueChange('partner_account_bank_account_number',onlyNumber(e))} value={info.partner_account_bank_account_number}/>
                        </InputWrap>
                    </div>
                </ItemWrap>
                {info.partner_account_type === '2' &&
                    <ItemWrap pWidth={110} align='start'>
                        <BasicText marginTop={1}>사업자정보</BasicText>
                        <div style={{width: '100%'}}>
                            <Row style={{gap:'10px'}}>
                                <InputWrap max={'200px'}>
                                    <input onChange={(e:any)=>valueChange('partner_account_business_name',e.target.value)} value={info.partner_account_business_name}/>
                                </InputWrap>
                                {info.partner_account_business_cert_image === undefined || info.partner_account_business_cert_image === '' ?
                                    <></> :
                                    <AdmBtnItem bg='#000' onClick={()=>setOpen(true)}>사업자등록증</AdmBtnItem>
                                }
                            </Row>
                            <InputWrap margin='5px 0 0 0'>
                                <input onChange={(e:any)=>valueChange('partner_account_business_number',onlyNumber(e))} value={info.partner_account_business_number}/>
                            </InputWrap>
                        </div>
                    </ItemWrap>
                }
                <ItemWrap pWidth={110} align='start'>
                    <BasicText marginTop={1}>가입메모</BasicText>
                    <div style={{width: '100%'}}>
                        <InputWrap>
                            <BasicText minHeight='60px'>{info.partner_account_comment}</BasicText>
                        </InputWrap>
                    </div>
                </ItemWrap>
                <ItemWrap pWidth={110} align='start'>
                    <BasicText marginTop={1}>처리메모</BasicText>
                    <div style={{width: '100%'}}>
                        <InputWrap>
                            <textarea rows={4} onChange={(e:any)=>valueChange('partner_account_memo',e.target.value)} value={info.partner_account_memo}></textarea>
                        </InputWrap>
                        <Row style={{marginTop:'10px'}}>
                            <AdmBtnItem onClick={memoUpdateAction} bg={addTheme.admBtn3}>처리메모저장</AdmBtnItem>
                        </Row>
                    </div>
                </ItemWrap>
                {info?.partner_account_status === '1' &&
                    <>
                        <ItemWrap pWidth={110}>
                            <BasicText>총보유P</BasicText>
                            <InputWrap>
                                <input readOnly onChange={(e:any)=>valueChange('point_amount',onlyNumber(e))} value={numberWithComma(info.point_amount)} />
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap pWidth={110}>
                            <BasicText>출금신청P</BasicText>
                            <InputWrap>
                                <input readOnly onChange={(e:any)=>valueChange('withdrawal_point_amount',onlyNumber(e))} value={numberWithComma(info.withdrawal_point_amount)} />
                            </InputWrap>
                        </ItemWrap>
                    </>
                }
            </ContentWrap>
            <OpenImgPart open={open} setOpen={setOpen} img={info.partner_account_business_cert_image} />
        </Wrap>
    )
}

const Wrap = styled.div``;