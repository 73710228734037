import React from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { Center, Row, TextRowWrap } from '../../../style/basic/commonStyle';
import { FaCheckCircle } from 'react-icons/fa';
import { addTheme } from '../../../style/addTheme';

const LIST = ['관리대행업체에 맡긴 이후, 지원금 혜택을 모르셨던 분', '요금이 비싸게 느껴지시는 분', '가입지원금 혜택을 못받으신 분', '다회선 설치가 필요한 건물주분'];

export default function WhyPart() {
    return (
        <Wrap>
            <BasicText fontWeight={700} fontSize={26} mFontSize={20}>왜 현주친구에서 해야하나요?</BasicText>
            <ContentWrap>
                <ImgWrap>
                    <img alt='why-img' src='/img/asset/masset05.png'></img>
                </ImgWrap>
                <TextWrap>
                    {LIST.map((data:any,index:any)=>{
                        return(
                            <TextRowWrap key={`why-${index}`} gap={10}>
                                <FaCheckCircle color={addTheme.color} size={25} />
                                <BasicText fontSize={21} mFontSize={16}>{data}</BasicText>
                            </TextRowWrap>
                        )
                    })}
                </TextWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 80px;
`;

const ContentWrap = styled(Row)`
    margin-top: 20px;
    gap: 20px;
    align-items: stretch;
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
        &>div{
            width: 100%;
        }
    }
`;
const ImgWrap = styled(Center)`
    width: 50%;
    border-radius: 8px;
    overflow: hidden;
    & img{
        width: 100%;
    }
`;
const TextWrap = styled(Row)`
    width: auto;
    flex-direction: column;
    justify-content: space-around;
    gap: 15px;
`;
