import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { themeColor } from './common/theme';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import session from "redux-persist/lib/storage/session";
import {user} from './common/user';
import { globalDialog } from './common/globalDialog';
import { globalLang } from './common/globalLang';
import { globalAlert } from './common/globalAlert';
import { globalCompanyInfo } from './common/globalCompanyInfo';
import { adminUser } from './common/adminUser';
import { globalSelectData } from './common/globalSelectData';
import { globalPartner } from './common/globalPartner';

const persistConfig = {
    key: "music_webview",
    // localStorage에 저장합니다.
    storage:session,
    whitelist: ['globalLang', 'globalAlert', 'user','globalConfirm', 'adminUser', 'globalPartner'],
    blacklist: []
};

const rootReducer = combineReducers({
    themeColor,
    user,
    globalDialog,
    globalLang,
    globalAlert,
    globalCompanyInfo,
    adminUser,
    globalSelectData,
    globalPartner,
});

export type RootState = ReturnType<typeof rootReducer>;

// export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
export default function reducer() {
    return persistReducer(persistConfig, rootReducer);
}
