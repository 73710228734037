import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { Checkbox, FormControlLabel, MenuItem } from '@mui/material';
import { addTheme } from '../../../style/addTheme';
import SelectItem from '../../common/item/SelectItem';
import { BANK, PAYER_TYPE, RELATION } from '../data/dummy';
import { onlyNumber } from '../../../utils/format/string';

export default function InputPayerPart({order_payment,valueChange,freegiftToPayment}:any) {
    const [chk,setChk] = useState(false);
    useEffect(()=>{
        if(chk){
            freegiftToPayment();
        }
    },[chk]);
    
    return (
        <Wrap>
            <BasicText fontWeight={700} fontSize={18}>납부자 정보</BasicText>
            <ContentWrap>
                <FormControlLabel sx={{'span':{fontSize:'15px'}}} control={<Checkbox sx={{color:addTheme.color,'&.Mui-checked': {color:addTheme.color}}} checked={chk} onChange={()=>setChk(!chk)} />} label="가입자 정보와 동일합니다." />
                <ItemWrap>
                    <TitleItem>가입자와의 관계</TitleItem>
                    <SelectItem value={order_payment?.order_payment_relation ?? 1} setValue={(val:any)=>valueChange('payment','order_payment_relation',val)} none='no'>
                        {RELATION.map((data:any,index:any)=>{
                            return(
                                <MenuItem key={`rel-${index}`} value={data.id}>{data.name}</MenuItem>
                            )
                        })}
                    </SelectItem>
                </ItemWrap>
                <ItemWrap>
                    <TitleItem>납부 정보</TitleItem>
                    <SelectItem value={order_payment?.order_payment_method ?? 1} setValue={(val:any)=>valueChange('payment','order_payment_method',val)} none='no'>
                        {PAYER_TYPE.map((data:any,index:any)=>{
                            return(
                                <MenuItem key={`rel-${index}`} value={data.id}>{data.name}</MenuItem>
                            )
                        })}
                    </SelectItem>
                </ItemWrap>
                <ItemWrap>
                    <TitleItem>납부자 명</TitleItem>
                    <InputItem>
                        <input required value={order_payment?.order_payment_name ?? ''} onChange={(e:any)=>valueChange('payment','order_payment_name',e.target.value)} placeholder='ex) 홍길동 가입자/납부자 다를 경우 "가족관계증명서"필수'></input>
                    </InputItem>
                </ItemWrap>
                <ItemWrap>
                    <TitleItem>주민등록번호</TitleItem>
                    <InputItem>
                        <input maxLength={15} required value={order_payment?.order_payment_resident_number ?? ''} onChange={(e:any)=>valueChange('payment','order_payment_resident_number',onlyNumber(e))} placeholder='ex) 주민등록번호 앞자리-주민등록번호 뒷자리'></input>
                    </InputItem>
                </ItemWrap>
                <ItemWrap style={{alignItems:'start'}}>
                    <TitleItem style={{marginTop:'12px'}}>계좌번호</TitleItem>
                    <div style={{width: '100%'}}>
                        <ItemWrap>
                            <SelectItem width={'40%'} value={order_payment?.order_payment_bank_name ?? 'NH농협'} setValue={(val:any)=>valueChange('payment','order_payment_bank_name',val)} none='none'>
                                {BANK.map((data:any,index:any)=>{
                                    return(
                                        <MenuItem key={`bank-${index}`} value={data.name}>{data.name}</MenuItem>
                                    )
                                })}
                            </SelectItem>
                            <InputItem>
                                <input required value={order_payment?.order_payment_account_number ?? ''} onChange={(e:any)=>valueChange('payment','order_payment_account_number',onlyNumber(e))} placeholder='ex) 계좌번호 입력'></input>
                            </InputItem>
                        </ItemWrap>
                        <BasicText color='#717171' fontSize={15} marginTop={1}>현금과 달리 상품권은 본사에서 발송되므로 3~5일 정도 소요 될 수 있습니다.</BasicText>
                    </div>
                </ItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 80px;
`;
const ContentWrap = styled(Row)`
    flex-direction: column;
    gap: 25px;
    margin-top: 15px;
    align-items: start;
`;

const ItemWrap = styled(Row)`
    gap: 10px;
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
    }
`;

const TitleItem = styled.div`
    width: 20%;
    flex-shrink: 0;
    text-align: left;
    font-size: 16px;
    @media ${addTheme.media.s}{
        width: 100%;
    }
`;

const InputItem = styled.div<{w?:string}>`
    border: 1px solid #e9e9e9;
    height: 42px;
    width: ${({w}) => w ?? '100%'};
    border-radius: 4px;
    &>input{
        height: 100%;
        width: 100%;
        padding: 10px 15px;
    }
`;