import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { Center, Row } from '../../../style/basic/commonStyle';

export default function InquiryContentPart({info}:any) {
    const [imgQ,setImgQ] = useState(false);
    const [imgA,setImgA] = useState(false);
    return (
        <Wrap>
            <TitleWrap>
                <BasicText fontSize={38} mFontSize={22} fontWeight={700} align='center'>문의 답변 보기</BasicText>
            </TitleWrap>
            <ContentWrap>
                <ItemWrap>
                    <BasicText>작성자</BasicText>
                    <ItemInputWrap>
                        <BasicText>{info?.inquiry_user_name}</BasicText>
                    </ItemInputWrap>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>연락처</BasicText>
                    <ItemInputWrap>
                        <BasicText>{(info?.inquiry_user_phone_number)}</BasicText>
                    </ItemInputWrap>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>이메일</BasicText>
                    <ItemInputWrap>
                        <BasicText>{info?.inquiry_user_email}</BasicText>
                    </ItemInputWrap>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>카테고리</BasicText>
                    <ItemInputWrap>
                        <BasicText>{info?.i_category_title}</BasicText>
                    </ItemInputWrap>
                </ItemWrap>
                <ItemWrap align='start'>
                    <BasicText>문의 내용</BasicText>
                    <div style={{width:'100%'}}>
                        <ItemInputWrap style={{minHeight:'150px',alignItems:'start'}}>
                            <BasicText wrapText='word-break' wh='pre-wrap'>{info?.inquiry_question}</BasicText>
                        </ItemInputWrap>
                        {info?.inquiry_question_image === undefined || info?.inquiry_question_image === '' ?
                            <></> :
                            <ImgBtnWrap onClick={()=>setImgQ(!imgQ)}>이미지 {imgQ ? '닫기' : '확인'}</ImgBtnWrap>
                        }
                        {imgQ && <img style={{width: '100%',marginTop:'10px'}} alt='question_image' src={info?.inquiry_question_image}></img>}
                    </div>
                </ItemWrap>
                <ItemWrap align='start'>
                    <BasicText>문의 답변</BasicText>
                    <div style={{width:'100%'}}>
                        <ItemInputWrap style={{minHeight:'150px',alignItems:'start'}}>
                            <BasicText wrapText='word-break' wh='pre-wrap'>{info?.inquiry_answer}</BasicText>
                        </ItemInputWrap>
                        {info?.inquiry_answer_image === undefined || info?.inquiry_answer_image === '' ?
                            <></> :
                            <ImgBtnWrap onClick={()=>setImgA(!imgA)}>이미지 {imgA ? '닫기':'확인'}</ImgBtnWrap>
                        }
                        {imgA && <img style={{width: '100%',marginTop:'10px'}} alt='answer_image' src={info?.inquiry_answer_image}></img>}
                    </div>
                </ItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    max-width: 590px;
    margin: 0 auto;
`;

const TitleWrap = styled.div``;

const ContentWrap = styled(Row)`
    margin-top: 50px;
    align-items: start;
    flex-direction: column;
    gap: 15px;
`;

const ItemWrap = styled(Row)<{align?:string}>`
    align-items: ${({align}) => align ?? 'center'};
    &>p{
        width: 100px;
    }
`;

const ItemInputWrap = styled(Row)`
    border: 1px solid #e6e6e6;
    padding: 10px 14px;
    border-radius: 6px;
`;

const ImgBtnWrap = styled(Center)`
    cursor: pointer;
    border: 1px solid #e6e6e6;
    padding: 12px 14px;
    border-radius: 6px;
    margin-top: 15px;
`;
