import { axiosConfigInstance } from "./config"

// 유저 방문
export const apiVisit = async() =>{
    try {
        const res = await await axiosConfigInstance('/api/user/log').post('',{
            method: 'userVisitLog',
            params: {}
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 회사 정보 가져오기
export const apiGetCompanyInfo = async() =>{
    try {
        const res = await axiosConfigInstance('/api/config/info').post('',{
            method: 'getCompanyConfigInfo',
            params: {

            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}

// 혜택지원금 문의
export const apiSetBenefitInquiry = async(info:any) =>{
    try {
        const {user_name,user_phone_number,inquiry_question,product_category_code} = info;
        const res = await axiosConfigInstance('/api/inquiry/product').post('',{
            method: 'submitProductInquiry',
            params: {
                user_name,user_phone_number,inquiry_question,product_category_code
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}

// 혜택지원금 문의 카테고리
export const apiGetBenefitInquiryCate = async(page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/inquiry/product').post('',{
            method: 'getProductInquiryCategoryList',
            params: {
                page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            result: false,
            error,
        }
    }
}

