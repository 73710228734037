import React, { useEffect, useRef, useState } from 'react'
import { Center, ContentWrap, InputWrap, ItemWrap } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import styled from 'styled-components';
import SelectItem from '../../../common/item/SelectItem';
import { MenuItem } from '@mui/material';
import { apiGetInquiryCate } from '../../../../api/api_qna';
import { onlyNumber } from '../../../../utils/format/string';

export default function AdmInquiryPart({info,valueChange,cate,setCate,type}:any) {
    const [cateList,setCateList] = useState<any>([]);
    const [imgShow,setImgShow] = useState(false);
    const fileRef = useRef<any>(null);

    useEffect(()=>{
        getCateList();
    },[])

    // 카테고리 리스트 가져오기
    const getCateList = async() =>{
        const res = await apiGetInquiryCate(1,100);
        if(res.result){
            setCateList(res.data);
        }
    }

    // 카테고리 변경
    const cateChange = (val:any) =>{
        const items = cateList.filter((data:any)=>data.inquiry_category_id === val);
        if(items.length === 0) return;
        setCate(items[0]);
    }

    // 파일 선택 창
    const fileTrigger = () =>{
        if(fileRef){
            fileRef.current.click();
        }
    }

    // 파일 선택
    const fileChange = (e:any) =>{
        const file = e.target.files[0];
        if(file){
            const reader = new FileReader();
            valueChange('questionFileName',file.name);
            reader.onload = () => {
                const result = reader.result;
                valueChange('inquiry_question_image',result);
            };

            reader.readAsDataURL(file);
        }
    }

    return (
        <Wrap>
            <ContentWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>작성자</BasicText>
                    <InputWrap>
                        <input disabled={type==='답변'} value={info.inquiry_user_name} onChange={(e:any)=>valueChange('inquiry_user_name',e.target.value)}></input>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>연락처</BasicText>
                    <InputWrap>
                        <input disabled={type==='답변'} value={info.inquiry_user_phone_number} onChange={(e:any)=>valueChange('inquiry_user_phone_number',onlyNumber(e))}></input>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>이메일</BasicText>
                    <InputWrap>
                        <input disabled={type==='답변'} value={info.inquiry_user_email} onChange={(e:any)=>valueChange('inquiry_user_email',e.target.value)}></input>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>카테고리</BasicText>
                    {type === '답변' || cateList === undefined || cateList.length === 0 ? 
                        <InputWrap><BasicText>{info.i_category_title ?? '-'}</BasicText></InputWrap> :
                        <SelectItem value={cate.inquiry_category_id} setValue={cateChange} none='no'>
                            {cateList.map((data:any,index:any)=>{
                                return(
                                    <MenuItem key={`cate-${index}`} value={data.inquiry_category_id}>{data.inquiry_title}</MenuItem>
                                )
                            })}
                        </SelectItem>
                    }
                </ItemWrap>
                <ItemWrap pWidth={120} align='start'>
                    <BasicText>문의내용</BasicText>
                    <div style={{width: '100%'}}>
                        <InputWrap>
                            <textarea disabled={type==='답변'} rows={20} value={info.inquiry_question} onChange={(e:any)=>valueChange('inquiry_question',e.target.value)}></textarea>
                            <input ref={fileRef} type='file' accept='image/*' onChange={fileChange}></input>
                        </InputWrap>
                        {type === '답변' ?
                            info.inquiry_question_image !== '' && 
                                <>
                                    <ImgBtnWrap onClick={()=>setImgShow(!imgShow)}>이미지 {imgShow ? '닫기' : '확인'}</ImgBtnWrap> 
                                    {imgShow &&
                                        <img style={{width:'100%'}} alt='question-img' src={info.inquiry_question_image}></img> 
                                        
                                    }
                                </>:
                            <>
                                <ImgBtnWrap onClick={fileTrigger}>이미지 업로드</ImgBtnWrap>
                                {info.inquiry_question_image === '' ?
                                    <></> :
                                    <img style={{width:'100%'}} alt='question-img' src={info.inquiry_question_image}></img>
                                }
                            </>
                        }
                    </div>
                </ItemWrap>
            </ContentWrap>
        </Wrap>
    )
}
const Wrap = styled.div``;
const ImgBtnWrap = styled(Center)`
    cursor: pointer;
    border: 1px solid #e6e6e6;
    padding: 12px 14px;
    border-radius: 6px;
    margin-top: 15px;
`;