import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import HeaderPage from '../../header/page/HeaderPage';
import FooterPage from '../../header/page/FooterPage';
import RightBtnPart from '../part/RightBtnPart';
import PopupPart from '../part/PopupPart';

export default function LayoutPage({children}:any) {
    
    return (
        <Wrap>
            <HeaderPage />
            <ContentWrap>
                <RightBtnPart></RightBtnPart>
                {children}
            </ContentWrap>
            <FooterPage />
            <PopupPart />
        </Wrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled.div`
    min-height: 100vh;
    position: relative;
`;