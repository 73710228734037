import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row } from '../../../style/basic/commonStyle'
import { FaStar } from "react-icons/fa";
import { useMediaQuery } from '@mui/material';
import { addTheme } from '../../../style/addTheme';

export default function StarItem({count}:any) {
    const [list,setList] = useState<any>([]);
    const mediaQuery = useMediaQuery(addTheme.media.s);

    useEffect(()=>{
        if(count === undefined) return;
        let starCount = count;
        if(typeof starCount === 'string'){
            starCount = Number(count);
        }
        if(starCount === 0) return;
        const countList = Array.from({length: starCount}, (_,index) => index+1);
        setList(countList);
    },[count]);

    return (
        <Wrap>
            {list.length === 0 ?
                <></> :
                list.map((data:any,index:any)=>{
                    return (
                        <FaStar color='#ffec00' size={mediaQuery ? 24 : 36} key={`star-${index}`} />
                    )
                })
            }
        </Wrap>
    ) 
}

const Wrap = styled(Row)`
    width: auto;
    gap: 12px;
    @media ${addTheme.media.s}{
        gap: 6px;
    }
`;