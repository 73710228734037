import Decimal from 'decimal.js';

export function decimalRoundOff(value: number, decimalLength: number) {
    return new Decimal(value).mul((10 ** decimalLength)).floor().div(10 ** decimalLength).toNumber();
}

export function numberWithComma(value: number | string) {
    if(value === undefined){
        return 0
    }
    const parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
    // return value;
}

export function valueFormat(value: number, decimalLength: number) {
    const result = decimalRoundOff(value, decimalLength);
    return numberWithComma(result);
};

export function balanceFormat(amount: any, decimalLength?: number) {
    if(typeof amount === 'string'){
        amount = parseInt(amount);
    }
    const balance = parseFloat((amount / 1000000000000000000).toFixed(4));
    return balance;
};

export function kFormatter(num: any) {
    return numberWithComma(Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000)) + 'K' : Math.sign(num)*Math.abs(num) ?? '0K')
}

// 대기
export function delay(milliseconds:number) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

// 배열 자르기
export function ChunkArray(array:Array<any>, chunkSize:number) {
    const chunkedArray = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      chunkedArray.push(chunk);
    }
    return chunkedArray;
}
// 노래 길이
export const durationFormat = (sec:any) =>{
    const minutes = Math.floor(sec / 60);
    const remainingSeconds = Math.floor(sec % 60);
    const formattedTime = `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    return formattedTime;
}