import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import { ADM_PRODUCT_CCTV_LIST } from '../../data/table'
import { AdmBtnItem, Center } from '../../../../style/basic/commonStyle'
import { momentFormat } from '../../../../utils/format/time'
import { VISIBLE_KOR } from '../data/dummy'
import { numberWithComma } from '../../../../utils/format/number'

export default function AdmProductListCctvItem({data,update,del}:any) {
    return (
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={ADM_PRODUCT_CCTV_LIST[0].align}>{data?.company_name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PRODUCT_CCTV_LIST[1].align}>{data?.product_code}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PRODUCT_CCTV_LIST[2].align}>{data?.product_name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PRODUCT_CCTV_LIST[3].align}>{numberWithComma(data?.product_min_cctv_count)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PRODUCT_CCTV_LIST[4].align}>
                {!(data?.product_combine_cctv_gap_price === undefined || data?.product_combine_cctv_gap_price.length === 0) &&
                    data.product_combine_cctv_gap_price.map((item:any,index:any)=>{
                        return(
                            <React.Fragment key={`cctv-price-${index}`}>
                                {index+1}: {numberWithComma(item)} <br/>
                            </React.Fragment>
                        )
                    })
                }
            </TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PRODUCT_CCTV_LIST[5].align}>{momentFormat('YYYY-MM-DD hh:mm:ss',data.product_reg_timestamp) }</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PRODUCT_CCTV_LIST[6].align}>{momentFormat('YYYY-MM-DD hh:mm:ss',data.product_update_timestamp)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PRODUCT_CCTV_LIST[7].align}>{VISIBLE_KOR[data.product_visible]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PRODUCT_CCTV_LIST[8].align}>
                <Center style={{gap:'8px'}}>
                    <AdmBtnItem bg='#ff7600' round={30} padding='6px 16px' onClick={update}>상세</AdmBtnItem>
                    <AdmBtnItem bg='#9e9e9e' round={30} padding='6px 16px' onClick={del}>삭제</AdmBtnItem>
                </Center>
            </TableCell>
        </TableRow>
    )
}

