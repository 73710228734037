import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import NumberItem from '../item/NumberItem';
import { useLocation, useNavigate } from 'react-router';
import MoMenuPart from '../part/MoMenuPart';
import { useUserInfo } from '../../../utils/custom-hooks/common/useUserInfo';

const MENU = [
    {id:0,name: '랜딩', path: '/', router: '/', type: 'inner'},
    {id:1,name:'홈',path:'home',router:'/home',type:'inner'},
    {id:2,name:'다회선',path:'multiLine',router:'/multiLine',type:'inner'},
    {id:3,name:'매장',path:'store',router:'/',type:'inner'},
    {id:4,name:'오피스',path:'office',router:'/',type:'inner'},
    {id:5,name:'렌탈',path:'',router:'https://www.xn--2e0bn5rvunj7ewndu3k.com/',type:'out'},
    // {id:6,name:'자주묻는질문',path:'qna/1',router:'/qna/1',type:'inner'},
    {id:6,name:'자주묻는질문',path:'/faq',router:'/faq',type:'inner'},
    {id:7,name:'설치 후기',path:'review',router:'/review',type:'inner'},
    // {id:8,name:'1:1 문의',path:'qna/2',router:'/qna/2',type:'inner'},
    {id:8,name:'1:1 문의',path:'/inquiry',router:'/inquiry/1',type:'inner'},
    {id:9,name:'마이페이지',path:'myPage',router:'/myPage',type:'inner'},
]

export default function HeaderPage() {
    const navigate = useNavigate();
    const location = useLocation();
    const [menuId,setMenuId] = useState(0);
    const user = useUserInfo();

    useEffect(()=>{
        if(location.pathname.includes('/benefitInquiry') || location.pathname.includes('/partner') 
            || location.pathname.includes('/applicationForm')){
            setMenuId(99)
            return;
        }
        if(location.pathname.includes('/inquiry')){
            setMenuId(8);
            return;
        }
        
        if(location.pathname.includes('partnerMyPage')){
            setMenuId(9);
            return;
        }
        MENU.map((data:any)=>{
            if(location.pathname.includes(data.path)){
                if(data.path === '') return;
                setMenuId(data.id);
            }
        })
    },[location.pathname])

    const menuClick = (data:any) =>{
        if(data.type === 'inner'){
            if(data.path === 'myPage'){
                if(user.user_id === 0){
                    navigate(data.router);        
                }else{
                    navigate('/partnerMyPage')
                }
                return;
            }
            navigate(data.router);
        }else{
            window.open(data.router);
        }
    }
    
    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    <LeftWrap>
                        <LogoWrap onClick={()=>navigate('/')}>
                            <img src={`/img/asset/logo-o.png`}></img>
                        </LogoWrap>
                        <MenuListWrap>
                            {MENU.map((data:any,index:any)=>{
                                if(data.id === 0) return;
                                return(
                                    <MenuItem key={`menu-${index}`} onClick={()=>menuClick(data)}>
                                        <BasicText color={menuId === data.id ? addTheme.color : '#000'} fontSize={18} >{data.name}</BasicText>
                                    </MenuItem>
                                )
                            })}
                        </MenuListWrap>
                    </LeftWrap>
                    <RightWrap>
                        <NumberItem/>
                    </RightWrap>
                    <MoMenuWrap>
                        <MoMenuPart menu={MENU} menuClick={menuClick} menuId={menuId} />
                    </MoMenuWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 90;
`;
const InnerWrap = styled(RowBetween)`
    height: 75px;
    @media ${addTheme.media.s}{
        height: 60px;
    }
`;
const LeftWrap = styled(Row)`
    width: auto;
    height: 100%;
    gap: 20px;
`;
const LogoWrap = styled(Center)`
    height: 100%;
    cursor: pointer;
    & img{
        height: 37px;
        @media ${addTheme.media.s}{
            height: 28px;
        }
    }
`;
const MenuListWrap = styled(Row)`
    gap: 14px;
    @media ${addTheme.media.m}{
        display: none;
    }
`;
const MenuItem = styled.div`
    cursor: pointer;
`;

const RightWrap = styled.div`
    @media ${addTheme.media.m}{
        display: none;
    }
`;

const MoMenuWrap = styled.div`
    display: none;
    @media ${addTheme.media.m}{
        display: block;
    }
`;