import { axiosConfigInstance } from "./config";

// 통신사 리스트 가져오기
// 01 홈 /02 매장 / 03 오피스(아직없음) / 04 다회선(아직없음) / 05 cctv
export const apiGetTelList = async(product_code: '01'|'02'|'04'|'05') =>{
    try {
        const res = await axiosConfigInstance('/api/config/info').post('',{
            method: 'getSellCompanyList',
            params: {
                product_code: product_code,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 요금제 리스트 가져오기
// product_category_code 01 = 가정용 인터넷 / 02 = 매장용 인터넷 / 03 = 오피스 인터넷
export const apiGetPayment = async(company_code:any,product_category_code: '01'|'02'|'03'|'04') =>{
    try {
        const res = await axiosConfigInstance('/api/product/internet').post('',{
            method: 'getInternetProductList',
            params: {
                company_code,
                product_category_code,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 최종 가격 계산
export const apiGetTotalResPay = async(company_code:string, product_001:string, product_002:string, product_005:string, product_006:string) =>{
    try {
        const res = await axiosConfigInstance('/api/product/calculation').post('',{
            method: 'getProductCalculation',
            params: {
                company_code: company_code,
                product_001,
                product_002,
                product_003: '', // (공유기)
                product_004: '', // (셋탑)
                product_005, // (전화 상품 코드)
                product_006, // (cctv 상품 코드)
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 상품 타입 리스트
export const apiGetProductType = async(page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/config/info').post('',{
            method: 'getProductCategoryList',
            params: {
                page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 사은품 리스트 가져오기
export const apiGetGiftList = async(company_code:string) =>{
    try {
        const res = await axiosConfigInstance('/api/config/info',).post('',{
            method: 'getFreeGiftList',
            params: {
                company_code
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 주문하기
export const apiGetBundleDetail = async(bundle_code:string) =>{
    try {
        const res = await axiosConfigInstance('/api/bundle/info').post('',{
            method: 'getProductBundleDetail',
            params: {
                bundle_code
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false
        }
    }
}

// 주문하기
export const apiSetOrder = async(order_basic:any,order_orderer:any,order_freegift:any,order_payment:any) =>{
    try {
        const res = await axiosConfigInstance('/api/order').post('',{
            method: 'submitOrder',
            params: {
                order_basic,order_orderer,order_freegift,order_payment
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false
        }
    }
}

// 다회선 바로 신청
export const apiGetOuterBundle = async(company_code:any,bundle_type:'02'|'03'|'04') =>{
    try {
        const res = await axiosConfigInstance('/api/bundle/info').post('',{
            method: 'getApplyBundleInfo',
            params: {
                company_code,bundle_type
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false
        }
    }
}