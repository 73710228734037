import { axiosConfigInstance } from "./config";

// 자주하는 질문 리스트 가져오기
export const apiGetQnaList = async(faq_category_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/faq/info').post('',{
            method: 'getFaqList',
            params:{
                faq_category_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}


// 자주하는질문 카테고리 리스트 가져오기
export const apiGetQnaCate = async(page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/faq/info').post('',{
            method: 'getFaqCategoryList',
            params:{
                page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 문의 카테고리 리스트 가져오기
export const apiGetInquiryCate = async(page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/inquiry/private').post('',{
            method: 'getInquiryCategoryList',
            params:{
                page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 문의 리스트 가져오기
export const apiGetInquiry = async(page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/inquiry/private').post('',{
            method: 'getInquiryList',
            params:{
                page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 문의 디테일 가져오기
export const apiGetInquiryDetail = async(inquiry_id:any,inquiry_password:any) =>{
    try {
        const res = await axiosConfigInstance('/api/inquiry/private').post('',{
            method: 'getInquiryDetailInfo',
            params:{
                inquiry_id,inquiry_password
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 문의 작성
export const  apiSetInquiry = async(info:any,cate:any,inquiry_password:string) =>{
    try {
        const {inquiry_user_name,inquiry_user_phone_number,inquiry_user_email,inquiry_question,inquiry_question_image,questionFileName} = info;
        const {inquiry_category_id} = cate;
        const res = await axiosConfigInstance('/api/inquiry/private').post('',{
            method: 'submitInquiry',
            params: {
                user_name: inquiry_user_name,
                user_phone_number: inquiry_user_phone_number,
                user_email: inquiry_user_email,
                inquiry_question,
                inquiry_question_image: (questionFileName === '' || questionFileName === undefined) ? '' : inquiry_question_image,
                inquiry_category_id,
                inquiry_password
            }
        });
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}