import React, { useState } from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import { useNavigate } from 'react-router';
import { TEL_OBJ } from '../../applicationForm/data/dummy';

export default function TelSelectPart({selectData,telList}:any) {
    const navigate = useNavigate();
    
    const telClick = (data:any) =>{
        navigate(`/home/${TEL_OBJ[data.company_id]}`);
    }
    
    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    <TitleWrap>
                        <BasicText fontSize={26} fontWeight={700} mFontSize={18}>원하시는 상품을 선택해주세요.</BasicText>
                        <BasicText fontSize={20} mFontSize={16}>아래의 <span style={{color:addTheme.color,textDecoration:'underline'}}>필독사항</span>을 참고해주세요!</BasicText>
                    </TitleWrap>
                    <ScWrap>
                        <BasicText fontSize={26} mFontSize={22} fontWeight={700}>통신사</BasicText>
                        <TelWrap>
                            {telList === undefined || telList.length === 0 ?
                                <></> :
                                telList.map((data:any,index:any)=>{
                                    const selectChk = selectData.tel.company_id === data.company_id;
                                    return(
                                        <TelItemWrap key={`tel-${index}`} onClick={()=>telClick(data)} selectChk={selectChk}>
                                            <img alt='tel-logo' src={data.company_icon}></img>
                                            <BasicText marginTop={1} align='center'>{data.company_name}</BasicText>
                                        </TelItemWrap>
                                    )
                                })
                            }
                        </TelWrap>
                    </ScWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div``;

const TitleWrap = styled.div`
    padding: 60px 0;
`;
const ScWrap = styled.div`
    /* padding: 50px 0; */
`;
const TelWrap = styled(RowBetween)`
    flex-wrap: wrap;
    margin-top: 15px;
    gap: 15px;
    &>div{
        width: calc(33% - 7.5px);
    }
    @media (max-width:800px){
        &>div{
            width: calc(50% - 7.5px);
        }
    }
`;
const TelItemWrap = styled(Center)<{selectChk:boolean}>`
    flex-direction: column;
    border: 2px solid ${({selectChk}) => selectChk ? addTheme.color : '#e5e5e5'};
    border-radius: 6px;
    padding: 20px;
    cursor: pointer;
    & img{
        height: 38px;
    }
`;

