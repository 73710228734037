import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center } from '../../../style/basic/commonStyle';
import ReviewItem from '../item/ReviewItem';
import { IoClose } from "react-icons/io5";
import { apiGetReviewDetail } from '../../../api/api_review';

export default function ReviewDetailPart({open,setOpen,item,setItem}:any) {
    const [viewItem,setViewItem] = useState<any>();

    useEffect(()=>{
        if(item?.review_id === undefined) return;
        getDetail();
    },[item?.review_id])

    const getDetail = async() =>{
        const res = await apiGetReviewDetail(item.review_id);
        if(res.result){
            setViewItem(res.data);
        }
    }
    const close = () =>{
        setItem(undefined);
        setOpen(false)
    }
    return (
        <Wrap open={open}>
            <BgWrap onClick={close}/>
            <ContentWrap>
                <ReviewItem type='detail' data={viewItem}/>
                <CloseWrap>
                    <IoClose color='#fff' onClick={close} size={24}></IoClose>
                </CloseWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled(Center)<{open:boolean}>`
    display: ${({open}) => open ? 'flex' : 'none'};
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.4);
    z-index: 99;
`;

const BgWrap = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
`;

const ContentWrap = styled.div`
    width: 100%;
    max-width: 580px;
    position: relative;
    z-index: 5;
`;

const ArrowItem = styled.div`
    width: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 6;
`;

const CloseWrap = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
`;