import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LayoutPage from '../../common/page/LayoutPage';
import { AdmBtnItem, Center, InputWrap, ItemWrap, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { numberWithComma } from '../../../utils/format/number';
import { onlyNumber } from '../../../utils/format/string';
import { addTheme } from '../../../style/addTheme';
import { useNavigate } from 'react-router';
import { useGlobalPartner } from '../../../utils/custom-hooks/common/useGlobalPartner';
import { apiGetPartnerPoint, apiSetPartnerPoint } from '../../../api/api_partner';

export default function PartnerWithdrawalPage() {
    const navigate = useNavigate();
    const partner = useGlobalPartner();
    const [point,setPoint] = useState({
        point_amount: '0',
        withdrawal_point: '0',
        min_withdrawal_point_amount: '0',
        partner_is_auth: '0',
    })

    useEffect(()=>{
        if(partner.partner_code === '') {
            navigate('/');
            return
        };
        getPoint();
    },[partner.partner_code]);

    const getPoint = async() =>{
        const res = await apiGetPartnerPoint(partner.partner_code);
        if(res.result){
            setPoint((obj:any)=>({...obj,...res.data}))
        }
    }

    const valueChange = (val:any) =>{
        setPoint((obj:any)=>({...obj,withdrawal_point:val}));
    }

    // 출금 신청
    const btnClick = async() =>{
        if(point.partner_is_auth === '0'){
            alert('인증을 먼저해주세요.');
            navigate('/partnerWithdrawalChk');
            return;
        }
        if(point.withdrawal_point === '0') return;
        const myNum = Number(point.point_amount);
        const minNum = Number(point.min_withdrawal_point_amount);
        const withNum = Number(point.withdrawal_point);
        if(withNum<minNum){
            alert(`최소 출금 포인트 ${numberWithComma(point.min_withdrawal_point_amount)}보다 큰 포인트를 입력해주세요.`);
            return;
        }
        if(withNum>myNum){
            alert('보유한 포인트보다 큰포인트를 출금할 수 없어요');
            return;
        }
        const res = await apiSetPartnerPoint(partner.partner_code,point.withdrawal_point);
            if(res.result){
                alert('출금 신청이 완료되었습니다.');
                navigate('/partnerMyPage/myInfo');
            }else{
                alert('잠시후 다시 시도해주세요.');
            }
    }


    return (
        <LayoutPage>
            <MaxWidthWrap>
                <TitleWrap>
                    <BasicText fontSize={36} mFontSize={22} fontWeight={700} align='center'>파트너스 출금 신청</BasicText>
                </TitleWrap>
                <Wrap>
                    <ContentWrap>
                        <ItemWrap>
                            <BasicText>내 포인트</BasicText>
                            <InputWrap>
                                <input readOnly value={numberWithComma(point.point_amount)}></input>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText>출금 신청</BasicText>
                            <InputWrap>
                                <input value={numberWithComma(point.withdrawal_point)} onChange={(e:any)=>valueChange(onlyNumber(e))}></input>
                            </InputWrap>
                        </ItemWrap>
                    </ContentWrap>
                    <BtnWrap>
                        <AdmBtnItem bg={addTheme.color} padding='15px' onClick={()=>btnClick()}>출금 신청하기</AdmBtnItem>
                    </BtnWrap>
                </Wrap>
            </MaxWidthWrap>
        </LayoutPage>
    )
}

const TitleWrap = styled.div`
    margin-top: 150px;
`;

const Wrap = styled.div`
    width: 100%;
    max-width: 650px;
    margin: 0 auto 50px;
`;

const ContentWrap = styled(Row)`
    margin-top: 50px;
    align-items: start;
    flex-direction: column;
    gap: 15px;
`;

const BtnWrap = styled.div`
    margin-top: 80px;
`;