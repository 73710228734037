import React, { useRef, useState } from 'react'
import { Center, ContentWrap, InputWrap, ItemWrap } from '../../../../style/basic/commonStyle'
import BasicText from '../../../basic/BasicText'
import styled from 'styled-components';

export default function AdminInquiryAnswerPart({info,valueChange}:any) {
    const fileRef = useRef<any>(null);

    // 파일 선택
    const fileChange = (e:any) =>{
        const file = e.target.files[0];
        if(file){
            const reader = new FileReader();
            valueChange('fileName',file.name);
            reader.onload = () => {
                const result = reader.result;
                valueChange('inquiry_answer_image',result);
            };

            reader.readAsDataURL(file);
        }
    }

    // 파일 선택창
    const fileTrigger = () =>{
        if(fileRef){
            fileRef.current.click();
        }
    }

    return (
        <ContentWrap>
            <ItemWrap pWidth={120} align='start'>
                <BasicText>문의답변</BasicText>
                <div style={{width: '100%'}}>
                    <InputWrap>
                        <textarea readOnly={info?.inquiry_status === '1'} rows={25} value={info.inquiry_answer} onChange={(e:any)=>valueChange('inquiry_answer',e.target.value)}></textarea>
                        <input ref={fileRef} type='file' accept='image/*' onChange={fileChange}/>
                    </InputWrap>
                    {info?.inquiry_status === '0' &&
                        <ImgBtnWrap onClick={fileTrigger}>이미지 업로드</ImgBtnWrap>
                    }
                    {info?.inquiry_answer_image === '' ? 
                        <></> :
                        <img style={{width: '100%'}} alt='answer-img' src={info?.inquiry_answer_image}></img>
                    }
                </div>
            </ItemWrap>
        </ContentWrap>
    )
}

const ImgBtnWrap = styled(Center)`
    cursor: pointer;
    border: 1px solid #e6e6e6;
    padding: 12px 14px;
    border-radius: 6px;
    margin-top: 15px;
`;