import { Dialog, DialogContent, MenuItem } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import BasicText from '../../basic/BasicText';
import SelectItem from '../../common/item/SelectItem';
import { AdmBtnItem, Center } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setGlobalSelectData } from '../../../store/common/globalSelectData';
import { LIST } from '../data/data';
import { apiGetOuterBundle, apiGetTelList } from '../../../api/api_home';

export default function OpenSelectPart({open,setOpen}:any) {
    const ref = useRef<any>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [telList,setTelList] = useState<any>([]);
    const [selectData,setSelectData] = useState({
        place:LIST[0],
        tel:{
            "company_id": 1,
            "company_code": "1",
            "company_name": "KT",
            "company_icon": "http://192.168.11.11:13131/company_image/kt.png",
            "company_product_codes": [
                "001"
            ]
        },
    })

    useEffect(()=>{
        getTelList();
    },[])

    const close = () =>{
        setOpen(false);
    }

    const btnClick = async() =>{
        const res = await apiGetOuterBundle(selectData.tel.company_code,'04');
        if(res.result){
            const code = res.data.bundle_code
            dispatch(setGlobalSelectData({...selectData,bundle_code:code}));
            navigate(`/applicationForm/${code}/multi`);
        }
    }

    const valueChange = (name:string,val:any) =>{
        if(name === 'place'){
            const item = LIST.filter((data:any)=>data.id == val);
            if(item.length === 0) return;
            val = item[0];
        }
        if(name === 'tel'){
            const item = telList.filter((data:any)=>data.company_code == val);
            if(item.length === 0) return;
            val = item[0];
        }
        setSelectData((obj:any)=>({...obj,[name]:val}));
    }

    // 통신사 리스트 가져오기
    const getTelList = async() =>{
        const res = await apiGetTelList('01');
        if(res.result){
            setTelList(res.data);
            setSelectData((obj:any)=>({
                ...obj,
                tel: res.data[0],
            }))
        }
    }


    return (
        <Dialog
            ref={ref}
            open={open} 
            onClose={close}
            sx={{
                // '.MuiDialog-container':{alignItems:mediaQuery ? 'end' :'center',},
                '.MuiPaper-root':{margin:'8px', maxWidth:'460px',width:'100%',borderRadius: '6px'}
            }}
        >
            <DialogContent dividers={true}>
                <Wrap>
                    <BasicText marginTop={2} align='center' fontSize={24} mFontSize={22} fontWeight={700}>다회선 구매 신청</BasicText>
                    <ContentWrap>
                        <BasicText fontSize={17}>신청을 원하는 통신사를 선택해주세요</BasicText>
                        {telList === undefined || telList.length === 0 ?
                            <></> :
                            <SelectItem value={selectData.tel.company_code} setValue={(val:any)=>valueChange('tel',val)} margin='6px 0 0' none='no'>
                                {telList.map((data:any,index:any)=>{
                                    return(
                                        <MenuItem key={`tel-${index}`} value={data.company_code}>{data.company_name}</MenuItem>
                                    )
                                })}
                            </SelectItem>
                        }
                        <BasicText fontSize={17} marginTop={2}>신청을 원하는 장소를 선택해주세요</BasicText>
                        <SelectItem value={selectData.place.id} setValue={(val:any)=>valueChange('place',val)} margin='6px 0 0' none='no'>
                            {LIST.map((data:any,index:any)=>{
                                return(
                                    <MenuItem key={`place-${index}`} value={data.id}>{data.name}</MenuItem>
                                )
                            })}
                        </SelectItem>
                        <Center style={{marginTop:'35px'}}>
                            <AdmBtnItem onClick={btnClick} bg={addTheme.color}>신청하기</AdmBtnItem>
                        </Center>
                    </ContentWrap>
                </Wrap>
            </DialogContent>
        </Dialog>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled.div`
    margin-top: 20px;
`;