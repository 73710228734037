import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { AdmBtn, AdmBtnItem, AdminWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import AdmProductInternetPart from '../part/AdmProductInternetPart';
import { useParams } from 'react-router';
import { LIST } from '../data/dummy';
import AdmProductTvPart from '../part/AdmProductTvPart';
import { apiAdmSetProductCctvUpdate, apiAdmSetProductInternetUpdate, apiAdmSetProductPhoneUpdate, apiAdmSetProductTvUpdate } from '../../../../api/api_adm_product';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { apiGetPayment } from '../../../../api/api_home';
import AdmProductCctvPart from '../part/AdmProductCctvPart';
import AdmProductPhonePart from '../part/AdmProductPhonePart';

export default function AdmProductAddPage() {
    const adminUser = useAdminUser();
    const {type} = useParams();
    const item = LIST.filter((data:any)=>data.id == type);
    const name = item[0]?.name === undefined ? '' : item[0]?.name;
    const [info,setInfo] = useState<any>({});

    useEffect(()=>{
        if(type === '2' || type === '3'){
            if(info?.company_code === undefined || info?.product_category_code === undefined) return;
            tvRouterList();
        }
    },[type,info?.company_code,info?.product_category_code])

    // 등록
    const btnAction = async(e:any) =>{
        e.preventDefault();
        if(type === '1'){
            const res = await apiAdmSetProductInternetUpdate(adminUser.admin_token,adminUser.admin_id,'adminProductAdd',info);
            if(res.result){
                alert(`등록되었습니다.`);
                location.reload();
            }else{
                alert(res.error);
            }
        }else if(type === '2'){
            const res = await apiAdmSetProductTvUpdate(adminUser.admin_token,adminUser.admin_id,'adminProductAdd',info);
            if(res.result){
                alert(`등록되었습니다.`);
                location.reload();
            }else{
                alert(res.error);
            }
        }else if(type === '3'){
            const res = await apiAdmSetProductPhoneUpdate(adminUser.admin_token,adminUser.admin_id,'adminProductAdd',info);
            if(res.result){
                alert(`등록되었습니다.`);
                location.reload();
            }else{
                alert(res.error);
            }
        }else if(type === '4'){
            const res = await apiAdmSetProductCctvUpdate(adminUser.admin_token,adminUser.admin_id,'adminProductAdd',{...info,product_category_code:'05'});
            if(res.result){
                alert(`등록되었습니다.`);
                location.reload();
            }else{
                alert(res.error);
            }
        }
    }

    // 티비 공유기
    const tvRouterList = async() =>{
        const res = await apiGetPayment(info?.company_code,info?.product_category_code);
        if(res.result){
            const {internet} = res.data;
            if(internet === undefined || internet.length === 0) return;
            if(type === '2'){
                setInfo((obj:any)=>({...obj,product_combine_internet_router_price:internet}))
            }
            if(type === '3'){
                setInfo((obj:any)=>({...obj,product_combine_internet_landline_device_price:internet}))
            }
        }
        
    }

    // input 변경
    const valueChange = (name:string,val:any) =>{
        if(name.includes('product_combine_internet_router_price')){
            // tv router 가격
            const [rest,index] = name.split('-');
            const newList = info?.product_combine_internet_router_price.map((data:any,idx:any)=> (idx == index ? {...data,router_price:val} : data))
            setInfo((obj:any)=>({...obj,['product_combine_internet_router_price']:newList}));
        }else if(name.includes('product_combine_cctv_gap_price')){
            // cctv 가격
            const [rest,index] = name.split('-');
            if(info?.product_combine_cctv_gap_price === undefined){
                setInfo((obj:any)=>({...obj,['product_combine_cctv_gap_price']:['']}));
            }else{
                const newList = info?.product_combine_cctv_gap_price.map((data:any,idx:any)=> (idx == index ? val : data))
                setInfo((obj:any)=>({...obj,['product_combine_cctv_gap_price']:newList}));
            }
        }else if(name.includes('product_combine_internet_landline_device_price')){
            // 전화 기기값
            const [rest,index] = name.split('-');
            const newList = info?.product_combine_internet_landline_device_price.map((data:any,idx:any)=> (idx == index ? {...data,landline_device_price:val} : data))
            setInfo((obj:any)=>({...obj,['product_combine_internet_landline_device_price']:newList}));
        }else{
            setInfo((obj:any)=>({...obj,[name]:val}));
        }
    }
    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={18} mFontSize={16} fontWeight={700}>기본 데이터 등록-{name}</BasicText>
                <InnerWrap onSubmit={btnAction}>
                    {type === '1' &&
                        <AdmProductInternetPart info={info} valueChange={valueChange} />
                    }
                    {type === '2' &&
                        <AdmProductTvPart info={info} valueChange={valueChange} />
                    }
                    {type === '3' &&
                        <AdmProductPhonePart info={info} valueChange={valueChange} />
                    }
                    {type === '4' &&
                        <AdmProductCctvPart info={info} valueChange={valueChange} setInfo={setInfo} />
                    }
                    <Row style={{marginTop:'20px',gap:'15px'}}>
                        <AdmBtn type='submit' bg={'#ad63ff'} padding='10px 25px'>등록</AdmBtn>
                    </Row>
                </InnerWrap>
            </AdminWrap>
           
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.form`
    width: 100%;
    max-width: 640px;
    margin-top: 30px;
`;