import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { Row } from '../../../style/basic/commonStyle';
import SelectItem from '../../common/item/SelectItem';
import { MenuItem } from '@mui/material';
import { onlyNumber } from '../../../utils/format/string';
import { addTheme } from '../../../style/addTheme';
import { USER_TYPE, TEL_LIST } from '../data/dummy';

export default function InputUserInfoPart({order_basic,order_orderer,valueChange}:any) {
    
    return (
        <Wrap>
            <BasicText fontWeight={700} fontSize={18}>가입자 정보</BasicText>
            <ContentWrap>
                <ItemWrap>
                    <TitleItem>고객 유형</TitleItem>
                    <SelectItem value={order_orderer?.order_orderer_type ?? 1} setValue={(val:any)=>valueChange('orderer','order_orderer_type',val)} none='none'>
                        {USER_TYPE.map((data:any,index:any)=>{
                            return(
                                <MenuItem key={`user-type-${index}`} value={data.id}>{data.name}</MenuItem>
                            )
                        })}
                    </SelectItem>
                </ItemWrap>
                <ItemWrap>
                    <TitleItem>가입자명</TitleItem>
                    <InputItem>
                        <input required value={order_orderer?.order_orderer_name ?? ''} onChange={(e:any)=>valueChange('orderer','order_orderer_name',e.target.value)} placeholder='ex) 홍길동'></input>
                    </InputItem>
                </ItemWrap>
                <ItemWrap>
                    <TitleItem>주민등록번호</TitleItem>
                    <InputItem>
                        <input maxLength={15} required value={order_orderer?.order_orderer_resident_number ?? ''} onChange={(e:any)=>valueChange('orderer','order_orderer_resident_number',onlyNumber(e))} placeholder='ex) 주민등록번호 앞자리-주민등록번호 뒷자리'></input>
                    </InputItem>
                </ItemWrap>
                {(order_orderer?.order_orderer_type ?? 1) === 2 &&
                    <>
                        <ItemWrap>
                            <TitleItem>사업자명</TitleItem>
                            <InputItem>
                                <input value={order_orderer?.order_orderer_business_name ?? ''} onChange={(e:any)=>valueChange('orderer','order_orderer_business_name',e.target.value)} placeholder='ex) 홍길동'></input>
                            </InputItem>
                        </ItemWrap>
                        <ItemWrap>
                            <TitleItem>사업자등록번호</TitleItem>
                            <InputItem>
                                <input value={order_orderer?.order_orderer_business_number ?? ''} onChange={(e:any)=>valueChange('orderer','order_orderer_business_number',e.target.value)} placeholder='ex) 123-46-78900'></input>
                            </InputItem>
                        </ItemWrap>
                    </>
                }
                <ItemWrap>
                    <TitleItem>가입자 명의 연락처</TitleItem>
                    <SelectItem required width={'30%'} value={order_orderer?.phone1_tel} setValue={(val:any)=>valueChange('orderer','phone1_tel',val)} none='none'>
                        {TEL_LIST.map((data:any,index:any)=>{
                            return(
                                <MenuItem key={`tel1-${index}`} value={data.name}>{data.name}</MenuItem>
                            )
                        })}
                    </SelectItem>
                    <InputItem>
                        <input maxLength={13} required value={order_orderer?.phone1 ?? ''} onChange={(e:any)=>valueChange('orderer','phone1',onlyNumber(e))} placeholder='ex) 01012345678'></input>
                    </InputItem>
                </ItemWrap>
                <ItemWrap>
                    <TitleItem>추가 연락처</TitleItem>
                    <SelectItem width={'30%'}  value={order_orderer?.phone2_tel} setValue={(val:any)=>valueChange('orderer','phone2_tel',val)} none='none'>
                        {TEL_LIST.map((data:any,index:any)=>{
                            return(
                                <MenuItem key={`tel2-${index}`} value={data.name}>{data.name}</MenuItem>
                            )
                        })}
                    </SelectItem>
                    <InputItem>
                        <input maxLength={13} value={order_orderer?.phone2 ?? ''} onChange={(e:any)=>valueChange('orderer','phone2',onlyNumber(e))} placeholder='ex) 01012345678'></input>
                    </InputItem>
                </ItemWrap>
                <ItemWrap>
                    <TitleItem>이메일</TitleItem>
                    <InputItem>
                        <input required value={order_orderer?.order_orderer_email ?? ''} onChange={(e:any)=>valueChange('orderer','order_orderer_email',e.target.value)} placeholder='ex) my@email.com'></input>
                    </InputItem>
                </ItemWrap>
                <ItemWrap>
                    <TitleItem>비밀번호</TitleItem>
                    <InputItem>
                        <input required type='password' value={order_basic?.order_password ?? ''} onChange={(e:any)=>valueChange('basic','order_password',e.target.value)} placeholder='ex) 주문 확인 시 입력할 비밀번호를 입력해주세요'></input>
                    </InputItem>
                </ItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled(Row)`
    flex-direction: column;
    gap: 25px;
    margin-top: 15px;
`;

const ItemWrap = styled(Row)`
    gap: 10px;
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
    }
`;
const TitleItem = styled.div`
    width: 20%;
    flex-shrink: 0;
    text-align: left;
    font-size: 16px;
    @media ${addTheme.media.s}{
        width: 100%;
    }
`;

const InputItem = styled.div`
    border: 1px solid #e9e9e9;
    height: 42px;
    width: 100%;
    border-radius: 4px;
    &>input{
        height: 100%;
        width: 100%;
        padding: 10px 15px;
    }
`;
