import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AdmBtnItem, Center, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import SelectItem from '../../common/item/SelectItem';
import { MenuItem, Tab, Tabs } from '@mui/material';
import MyPageStepItem from '../item/MyPageStepItem';
import FormInfoPart from '../../applicationForm/part/FormInfoPart';
import MyPageInfoPart from './MyPageInfoPart';
import MyPagePaymentPart from './MyPagePaymentPart';
import MyPageBenefitPart from './MyPageBenefitPart';
import { useNavigate } from 'react-router';
import { useGlobalCompanyInfo } from '../../../utils/custom-hooks/common/useGlobalCompanyInfo';
import { apiGetBundleDetail } from '../../../api/api_home';

export default function MyPagePart({list}:any) {
    const navigate = useNavigate();
    const [info,setInfo] = useState<any>();
    const [idx,setIdx] = useState(0);
    const [menu,setMenu] = useState(1);
    const [value,setValue] = useState(0);
    const globalCompany = useGlobalCompanyInfo();
    const [detail,setDetail] = useState<any>();
    const [type,setType] = useState('home');

    useEffect(()=>{
        if(list.length === 0) return;
        setInfo(list[idx]);
        setMenu(1);
        setValue(0);
    },[list.length,idx]);

    useEffect(()=>{
        if(info?.bundle_code === undefined || info?.bundle_code === null || info?.bundle_code === '') return;
        getDetail();
    },[info?.bundle_code])

    // 문의작성페이지로
    const goInquiry = () =>{
        navigate('/inquiryCreate');
    }

    // 카카오톡
    const kakaoClick = () =>{
        if(globalCompany['config_social_kakao_link'] === undefined) return;
        window.open(globalCompany['config_social_kakao_link']);
    }
    // 전화
    const phoneClick = () =>{
        if(globalCompany['config_company_call_number'] === undefined) return;
        window.location.href = 'tel:' + globalCompany['config_company_call_number'];
    }

    // 디테일 정보 가져오기
    const getDetail = async() =>{
        const res = await apiGetBundleDetail(info.bundle_code);
        if(res.result){
            setDetail(res.data);
            setType('home');
        }else{
            setType('multi');
        }
    }

    const handleChange = (e:any,newVal:any) =>{
        setValue(newVal);
    }
    
    return (
        <Wrap>
            <SelectWrap>
                <SelectInnerWrap>
                    {list === undefined || list.length === 0 ?
                        <></> :
                        <SelectItem value={idx} setValue={setIdx} none='none'>
                            {list.map((data:any,index:any)=>{
                                return(
                                    <MenuItem key={`mypage-item-${index}`} value={index}>{data?.order_code}</MenuItem>
                                )
                            })}
                        </SelectItem>
                    }
                </SelectInnerWrap>
            </SelectWrap>
            <StepWrap>
                <MyPageStepItem info={info} />
            </StepWrap>
            <InnerWrap>
                <LeftWrap>
                    <FormInfoPart type={type} detail={detail} />
                </LeftWrap>
                <RightWrap>
                    {/* <TitleWrap>
                        <TitleWrapItem onClick={()=>setMenu(1)} state={menu === 1}>내 정보</TitleWrapItem>
                        <TitleWrapItem onClick={()=>setMenu(2)} state={menu === 2}>납부자 정보</TitleWrapItem>
                        <TitleWrapItem onClick={()=>setMenu(3)} state={menu === 3}>혜택 정보</TitleWrapItem>
                    </TitleWrap> */}
                    <TabWrap>
                        <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons='auto' allowScrollButtonsMobile>
                            <Tab label={'내 정보'}></Tab>
                            <Tab label={'납부자 정보'}></Tab>
                            <Tab label={'혜택정보'}></Tab>
                        </Tabs>
                    </TabWrap>
                    <RightContentWrap>
                        {value === 0 && <MyPageInfoPart info={info}/>}
                        {value === 1 && <MyPagePaymentPart info={info}/>}
                        {value === 2 && <MyPageBenefitPart info={info}/>}
                        <BtnWrap>
                            <BtnItemWrap>
                                <BtnItem color={addTheme.color} borderColor={addTheme.color} bg='#fff' onClick={()=>goInquiry()}>1:1 문의하기</BtnItem>
                                <BtnItem color='#000' borderColor='#ffe500' bg={'#ffe500'} onClick={()=>kakaoClick()}>카톡으로 문의하기</BtnItem>
                            </BtnItemWrap>
                            <AdmBtnItem margin='10px 0 0' padding='15px 18px' bg={addTheme.color} onClick={()=>phoneClick()}>전화로 문의하기</AdmBtnItem>
                        </BtnWrap>
                    </RightContentWrap>
                </RightWrap>
            </InnerWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const SelectWrap = styled(Row)`
    justify-content: end;
    margin-top: 60px;
`;

const SelectInnerWrap = styled.div`
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    min-width: 195px;
`;

const StepWrap = styled.div`
    margin-top: 10px;
`;
const InnerWrap = styled(Row)`
    align-items: start;
    margin-top: 35px;
    position: relative;
    @media ${addTheme.media.m}{
        flex-wrap: wrap;
        gap: 40px 0 ;
    }
`;

const LeftWrap = styled.div`
    position: sticky;
    top: 55px;
    width: 35%;
    padding: 10px 40px;
    @media ${addTheme.media.m}{
        width: 100%;
        position: static;
        padding: 0;
    }
`;
const RightWrap = styled.div`
    width: 65%;
    text-align: center;
    padding: 10px 40px;
    @media ${addTheme.media.m}{
        width: 100%;
        padding: 0;
    }
`;

// const TitleWrap = styled(Row)``;
// const TitleWrapItem = styled(Center)<{state:boolean}>`
//     border: 1px solid ${({state})=> state ? '#000':'#dbdbdb'};
//     border-bottom: none;
//     width: 20%;
//     max-width: 120px;
//     height: 38px;
//     cursor: pointer;
// `;
const TabWrap = styled.div`
    width: 100%;
    & .MuiButtonBase-root{
        border: 1px solid #dbdbdb;
        border-bottom: none;
        min-width: 70px;
    }
    & .MuiTabs-indicator{
        display: none;
    }
    & .Mui-selected{
        border-color:#000;
        color: #000 !important;
    }
`;

const RightContentWrap = styled.div`
    width: 100%;
    margin-top: 30px;
`;

const BtnWrap = styled.div`
    margin-top: 80px;
`;
const BtnItemWrap = styled(Row)`
    gap: 12px;
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
        &>div{
            width: 100%;
        }
    }
`;
const BtnItem = styled(Center)<{color:string,borderColor:string,bg:string}>`
    width: 50%;
    padding: 15px 18px;
    color: ${({color}) => color ?? '#000'};
    border: 1px solid ${({borderColor}) => borderColor ?? '#fff'};
    background-color: ${({bg}) => bg ?? '#000'};
    border-radius: 6px;
    cursor: pointer;
`;