import React from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row, RowBetween, SectionWrap } from '../../../style/basic/commonStyle';
import TitlePart from '../../common/part/TitlePart';
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';
import BtnItem from '../item/BtnItem';
import { FaCheckCircle } from "react-icons/fa";
import { useMediaQuery } from '@mui/material';

const LIST = [
    {id: 1, title: '기업 전용 네트워크로 안정적으로 인터넷 이용이 필요하신 분'},
    {id: 2, title: '빠른 속도와 상황에 따른 인터넷 통신 서비스가 필요하신 분'},
    {id: 3, title: 'IPTV, 인터넷전화, 보안, 고정IP 등 다양한 서비스와 결합한 서비스가 필요하신 분'},
]

export default function OfficeInternetPart() {
    const mediaQuery = useMediaQuery(addTheme.media.s);
    return (
        <SectionWrap>
            <MaxWidthWrap>
                <Wrap>
                    <TitlePart title='오피스 전용 인터넷' desc='오피스 환경에도 문제없는<br/>전문 인터넷 솔루션' />
                    <ContentWrap>
                        <ImgWrap>
                            <img alt='office-img' src='/img/asset/thumb01.png'></img>
                        </ImgWrap>
                        <TextWrap>
                            {LIST.map((data:any,index:any)=>{
                                return(
                                    <TextItemWrap key={`office-internet-${index}`}>
                                        <FaCheckCircle color={addTheme.color} size={mediaQuery ? 24 : 30} />
                                        <BasicText fontSize={22} mFontSize={16}>{data.title}</BasicText>
                                    </TextItemWrap>
                                )
                            })}
                        </TextWrap>
                    </ContentWrap>
                    <BtnItem title='오피스 상품 추천받기' />
                </Wrap>
            </MaxWidthWrap>
        </SectionWrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled(Row)`
    margin-top: 70px;
    gap: 40px;
    align-items: normal;
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
        &>div{
            width: 100%;
        }
    }
`;
const ImgWrap = styled(Center)`
    width: 50%;
    flex-shrink: 0;
    border-radius: 30px;
    overflow: hidden;
    & img{
        width: 100%;
    }
`;
const TextWrap = styled(RowBetween)`
    flex-direction: column;
    width: auto;
    justify-content: space-around;
    gap: 15px;
`;

const TextItemWrap = styled(Row)`
    gap: 20px;
    &>svg{
        flex-shrink: 0;
    }
`;