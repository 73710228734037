import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { Center, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { useNavigate, useParams } from 'react-router';
import { apiGetInquiryDetail } from '../../../api/api_qna';

export default function InquiryNoPwPart({setPwChkState,setInfo}:any) {
    const navigate = useNavigate();
    const {id} = useParams();
    const [pw,setPw] = useState('');

    useEffect(()=>{
        if(id === undefined){
            navigate('/qna/1');
        }
    },[id])

    const loginBtnAction = async(e:any) =>{
        e.preventDefault();
        const res = await apiGetInquiryDetail(id,pw);
        if(res.result){
            setPwChkState(true);
            setInfo(res.data);
        }else{
            window.alert('비밀번호가 다릅니다.');
        }
    }
    return (
        <Wrap>
            <TitleWrap>
                <BasicText fontSize={38} mFontSize={22} fontWeight={700} align='center'>문의 답변 보기</BasicText>
            </TitleWrap>
            <TextWrap>
                <BasicText align='center' fontSize={16} color='#fff'>문의 시 입력하신 정보로 답변 확인이 가능합니다</BasicText>
            </TextWrap>
            <form onSubmit={loginBtnAction}>
                <InputWrap>
                    <BasicText color='#121212' fontSize={17}>비밀번호</BasicText>
                    <input type='password' required value={pw} onChange={(e:any)=>setPw(e.target.value)}></input>
                </InputWrap>
                <BtnWrap type='submit'>로그인</BtnWrap>
            </form>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
`;
const TitleWrap = styled.div`
    
`;
const TextWrap = styled(Center)`
    margin-top: 30px;
    background-color: #3b3b3b;
    border-radius: 6px;
    padding: 14px;
`;

const InputWrap = styled(Row)`
    margin: 30px 0;
    flex-wrap: wrap;
    gap: 20px;
    &>p{
        flex-shrink: 0;
        width: 70px;
    }
    &>input{
        padding: 14px;
        border: 1px solid #e6e6e6;
        border-radius: 6px;
        width: calc(100% - 90px);
    }
`;

const BtnWrap = styled.button`
    width: 100%;
    background-color: ${addTheme.color};
    height: 52px;
    color: #fff;
    border-radius: 6px;
`;