import React, { useEffect, useState } from 'react'
import { apiAdmGetPartnerDetailPointList } from '../../../../api/api_adm_partner';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import styled from 'styled-components';
import AdminTablePart from '../../common/part/AdminTablePart';
import { addTheme } from '../../../../style/addTheme';
import { ADM_PARTNER_DETAIL_POINT_LIST } from '../../data/table';
import { TableCell, TableRow } from '@mui/material';
import { momentFormat } from '../../../../utils/format/time';
import { numberWithComma } from '../../../../utils/format/number';

const TYPE:any = {
    '1': '입금',
    '2': '출금',
}
const STATUS:any = {
    '0': '대기',
    '1': '완료',
}
const ListItem = ({data}:any) =>{
    return(
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={ADM_PARTNER_DETAIL_POINT_LIST[0].align}>{TYPE[data.point_payment_type]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PARTNER_DETAIL_POINT_LIST[1].align}>{numberWithComma(data.point_payment_amount)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PARTNER_DETAIL_POINT_LIST[2].align}>{STATUS[data.point_payment_status]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PARTNER_DETAIL_POINT_LIST[3].align}>{momentFormat('YYYY-MM-DD hh:mm',data.point_payment_req_timestamp)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PARTNER_DETAIL_POINT_LIST[4].align}>{momentFormat('YYYY-MM-DD hh:mm',data.point_payment_process_timestamp)}</TableCell>
        </TableRow>
    )
}

export default function AdmPartnerUpdateListPart({info}:any) {
    const adminUser = useAdminUser();
    const [list,setList] = useState<any>([]);
    const [page,setPage] = useState(1);
    const [total,setTotal] = useState(0);

    useEffect(()=>{
        if(info?.partner_account_code === undefined || info?.partner_account_code === '') return;
        getList();
    },[page,info.partner_account_code])

    const getList = async() =>{
        const res = await apiAdmGetPartnerDetailPointList(adminUser.admin_token,adminUser.admin_id,info.partner_account_code,page,15);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }
    return (
        <Wrap>
            {list === undefined || list.length === 0 ?
                <></> :
                <AdminTablePart bg={addTheme.adminColor} color='#fff' title={ADM_PARTNER_DETAIL_POINT_LIST} minWidth={500} page={page ?? 1} total={total} setPage={setPage}>
                    {list.map((data:any,index:any)=>{
                        return(
                            <ListItem key={`partner-detail-point-item-${index}`} data={data} />
                        )
                    })}
                </AdminTablePart>
            }
        </Wrap>
    )
}

const Wrap = styled.div``;