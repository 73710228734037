import React, { useState } from 'react'
import styled from 'styled-components'
import LayoutPage from '../../common/page/LayoutPage';
import InquiryNoPwPart from '../part/InquiryNoPwPart';
import InquiryContentPart from '../part/InquiryContentPart';
import { MaxWidthWrap } from '../../../style/basic/commonStyle';

export default function InquiryDetailPage() {
    const [pwChkState,setPwChkState] = useState(false);
    const [info,setInfo] = useState<any>();

    return (
        <LayoutPage>
            <MaxWidthWrap>
                <Wrap>
                    {pwChkState ? 
                        <InquiryContentPart info={info} /> :
                        <InquiryNoPwPart setPwChkState={setPwChkState} setInfo={setInfo} /> 
                    }
                </Wrap>
            </MaxWidthWrap>
        </LayoutPage>
    )
}

const Wrap = styled.div`
    margin-top: 150px;
    margin-bottom: 30px;
`;
