import React from 'react'
import { ContentWrap, InputWrap, ItemWrap } from '../../../../style/basic/commonStyle'
import BasicText from '../../../basic/BasicText'

export default function AdminInquiryProductAnswerPart({info,valueChange}:any) {
    
    return (
        <ContentWrap>
            <ItemWrap pWidth={120} align='start'>
                <BasicText>내용</BasicText>
                <div style={{width: '100%'}}>
                    <InputWrap>
                        <textarea readOnly={info?.inquiry_product_status === '1'} rows={25} value={info?.inquiry_product_answer ?? ''} onChange={(e:any)=>valueChange('inquiry_product_answer',e.target.value)}></textarea>
                    </InputWrap>
                </div>
            </ItemWrap>
        </ContentWrap>
    )
}