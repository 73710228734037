import React from 'react'
import styled from 'styled-components'
import { numberWithComma } from '../../../utils/format/number'
import StepTitleItem from './StepTitleItem'
import { Row, SectionWrap } from '../../../style/basic/commonStyle'
import StepInnerTitleItem from './StepInnerTitleItem'
import BasicText from '../../basic/BasicText'
import { FaCheckCircle } from "react-icons/fa";
import { addTheme } from '../../../style/addTheme'

const LIST = [
    {name: '인터넷', channel:0, M100:{pay0:22000,pay1:16500,gift:9}, M500:{pay0:33000,pay1:22000,gift:14}, G1:{pay0:38500,pay1:27500,gift:14}},
    {name: '인터넷 + TV 베이직', channel:236, M100:{pay0:22000,pay1:16500,gift:9}, M500:{pay0:33000,pay1:22000,gift:14}, G1:{pay0:38500,pay1:27500,gift:14}},
    {name: '인터넷 + TV 라이트', channel:240, M100:{pay0:22000,pay1:16500,gift:9}, M500:{pay0:33000,pay1:22000,gift:14}, G1:{pay0:38500,pay1:27500,gift:14}},
    {name: '인터넷 + TV 에센스', channel:263, M100:{pay0:22000,pay1:16500,gift:9}, M500:{pay0:33000,pay1:22000,gift:14}, G1:{pay0:38500,pay1:27500,gift:14}},
    {name: '인터넷 + TV키즈랜드팩 초이스', channel:266, M100:{pay0:22000,pay1:16500,gift:9}, M500:{pay0:33000,pay1:22000,gift:14}, G1:{pay0:38500,pay1:27500,gift:14}},
    {name: '인터넷+ TV 넷플릭스 HD', channel:266, M100:{pay0:22000,pay1:16500,gift:9}, M500:{pay0:33000,pay1:22000,gift:14}, G1:{pay0:38500,pay1:27500,gift:14}},
]

const DISCOUNT_FAMILY = [
    {name: '특수 상황', list: [{product: '프리미엄 싱글결합', terms: '고가 요금제 1회선 + 500메가 이상 인터넷', benefit: '인터넷 5,500원/휴대폰 요금 25% 할인'}] },
    {name: '인터넷 + 휴대폰', list: [
        {product: '프리미엄 가족결합', terms: '고가 요금제 2회선 이상 + 인터넷', benefit: '인터넷 5,500원/휴대폰 요금 25% 할인'},
        {product: '총액결합할인', terms: '요금제 상관X 휴대폰 + 인터넷', benefit: '최소 1,650원 ~ 최대 33,110원 할인'},
        {product: '3G 뭉치면올레', terms: '3G 요금제 사용 + 인터넷', benefit: '최소 1,100원 ~ 최대 22,200원 할인'},
    ] },
    {name: '인터넷 + 인터넷', list: [{product: '패밀리 결합', terms: '인터넷 2대 이상', benefit: '인터넷 5,500원'}]},
]

const DISCOUNT_CARD = [
    {name: 'KB국민카드', list: [{name: 'KB국민 CLiP 카드', benefit: '30만원 이상 실적 12,000원 할인'}]},
    {name: '현대카드', list: [{name: 'KT-현대카드M Edition3(통신할인형2.0)', benefit: '50만원 이상 실적 15,000원 할인(1~24개월)'}]},
    {name: '신한카드', list: [{name: 'KT 신한 체크카드', benefit: '30만원 이상 실적 3,000원 캐쉬백'},{name: 'KT 가족만족 DC 신한카드', benefit: '30만원 이상 실적 7,000원 할인'}]},
    {name: 'IBK 카드', list: [{name: 'olleh 슈퍼 DC IBK카드', benefit: '30만원 이상 실적 7,000원 할인'}]},
    {name: '삼성카드', list: [{name: 'KT 삼성카드', benefit: '30만원 이상 실적 7,000원 할인'}]},
    {name: '우리카드', list: [{name: 'KT NU Plus 우리카드', benefit: '40만원 이상 실적 10,000원 할인'}]},
    {name: '하나카드', list: [{name: 'KT super DC 하나카드', benefit: '30만원 이상 실적 7,000원 할인'},{name: 'KT DC Plus 고객용 더 심플 하나카드', benefit: '30만원 이상 실적 10,000원 할인'}]},
    {name: 'NH농협카드', list: [{name: 'KT 할부 Plus NH농협카드', benefit: '40만원 이상 실적 5,000원 (할부와 할인 중복 불가)'}]},
    {name: '롯데카드', list: [{name: 'KT LOCA X 구독 롯데카드', benefit: '30만원 이상 10,000원 할인'},{name: 'KT DC Plus 롯데카드', benefit: '40만원 이상 10,000원 할인'}]},
    {name: '비씨카드', list: [{name: 'KT DC Plus BC바로 카드', benefit: '30만원 이상 7,000원 할인'}]},
    {name: '케이뱅크', list: [{name: 'KT멤버십x케이뱅크 더블혜택 체크카드', benefit: '20만원 이상 실적 카드 이용금액 5% 캐시백(최대 5,000원)'}]},
]

export default function KtStepItem({selectData}:any) {
    return (
        <Wrap>
            <StepTitleItem step='01' title={`KT 요금표 한눈에 보기`} />
            <TableWrap>
                <TableWrapItem margin='40px 0 0'>
                    <thead>
                        <tr>
                            <th className='title-bg' rowSpan={2} colSpan={2}> 인터넷 상품 <p>(기가지니3 기준)</p></th>
                            <th className='title-bg' rowSpan={2}>모바일<br/>결합여부</th>
                            <th className='title-bg' colSpan={2} rowSpan={1}>100M</th>
                            <th className='title-bg' colSpan={2} rowSpan={1}>500M</th>
                            <th className='title-bg' colSpan={2} rowSpan={1}>1G</th>
                        </tr>
                        <tr>
                            <th className='title-bg'>요금</th>
                            <th className='title-bg'>사은품</th>
                            <th className='title-bg'>요금</th>
                            <th className='title-bg'>사은품</th>
                            <th className='title-bg'>요금</th>
                            <th className='title-bg'>사은품</th>
                        </tr>
                    </thead>
                    <tbody>
                        {LIST.map((data:any,index:any)=>{
                            const evenState = (index+1) % 2 === 0;
                            return(
                                <React.Fragment key={`kt-pay-${index}`}>
                                    <tr className={evenState ? 'content-bg' : ''}>
                                        <th className='content-title' rowSpan={2} colSpan={2}>
                                            {data.name}
                                            {data.channel !== 0 &&
                                                <>
                                                    <br/><span>{data.channel}채널</span>
                                                </>
                                            }
                                        </th>
                                        <td className='first-text'>미결합</td>
                                        <td className='first-text'>{numberWithComma(data['M100']['pay0'])}원</td>
                                        <td rowSpan={2} className='gift'>{numberWithComma(data['M100']['gift'])}만원</td>
                                        <td className='first-text'>{numberWithComma(data['M500']['pay0'])}원</td>
                                        <td rowSpan={2} className='gift'>{numberWithComma(data['M500']['gift'])}만원</td>
                                        <td className='first-text'>{numberWithComma(data['G1']['pay0'])}원</td>
                                        <td rowSpan={2} className='gift'>{numberWithComma(data['G1']['gift'])}만원</td>
                                    </tr>
                                    <tr className={evenState ? 'content-bg' : ''}>
                                        <td>1대결합</td>
                                        <td>{numberWithComma(data['M100']['pay1'])}원</td>
                                        <td>{numberWithComma(data['M500']['pay1'])}원</td>
                                        <td>{numberWithComma(data['G1']['pay1'])}원</td>
                                    </tr>
                                </React.Fragment>
                            )
                        })}
                    </tbody>
                </TableWrapItem>
            </TableWrap>
            <SectionWrap padding={'80px 0 0'}>
                <StepTitleItem step='02' title={'지금까지 속으셨습니다. 할인은 오직 4가지가 전부!'} />
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={1} title='할인크기가 큰 순서' />
                    <BasicText marginTop={0.5}>1. 약정할인</BasicText>
                    <BasicText>2. 가족결합할인</BasicText>
                    <BasicText>3. 제휴카드 할인</BasicText>
                    <BasicText>4. 복지할인(아주 제한적)</BasicText>
                    <BasicText marginTop={2}>이 외 통신요금은 전부 동일하며<br/>대리점에 따라 절대 달라지지 않습니다.</BasicText>
                </SectionWrap>
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={2} title='1. 약정할인은 무조건 하세요' />
                    <BasicText marginTop={0.5}>약정 기간은 무조건 3년이 좋습니다.<br/>왜 그런지 한번 비교해볼게요.</BasicText>
                    <BasicText marginTop={2} fontWeight={700}>인터넷 100메가 + 베이직 tv 기준(와이파이 X)</BasicText>
                    <InnerStep02Wrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>1년 약정 월 58,520원 X 12 = 702,240원</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>3년 약정 월 37,400원 X 12 = 448,800원</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='transparent' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>여기에 위약금과 사은품까지 계산해보면?</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='transparent' size={22} />
                            <BasicText fontSize={17}>448,800 + 300,000원(위약금) - 420,000원(사은품)</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='transparent' size={22} />
                            <BasicText fontSize={17} fontWeight={700} color={'#e93e5d'}>= 328,800원</BasicText>
                        </TextRowWrap>
                    </InnerStep02Wrap>
                    <BasicText marginTop={2} fontSize={14} color={addTheme.subText.color}>· 위약금과 사은품은 평균 값을 계산한 금액으로 정확하지 않을 수 있습니다.</BasicText>
                    <BasicText marginTop={2} fontWeight={700}>1년 약정 = 사은품 X</BasicText>
                    <BasicText fontWeight={700} spColor='#e93e5d'>비교 결과: 1년 약정이 <span>30만원 이상 손해</span></BasicText>
                    <BasicText fontSize={18} marginTop={2} fontWeight={700} spColor='#e93e5d'><span>!</span> 주의 <span>!</span></BasicText>
                    <BasicText>12개월 미만 사용 시 받았던<br/>사은품을 돌려줘야하는 할인반환금 제도가 있습니다.</BasicText>
                    <BasicText marginTop={2}><strong>아정당에선 9개월만</strong> 쓰셔도<br/>사은품 반환의무가 사라집니다. 😉</BasicText>
                </SectionWrap>
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={3} title='2. 대부분 놓치는 가족결합 할인' />
                    <BasicText marginTop={0.5}>내가 잘 모르면 ‘최대 100만 원’까지 손해봅니다.<br/>제가 세계에서 가장 쉽게 정리하면요,</BasicText>
                    <TableWrap>
                        <TableWrapItem margin='20px 0 0'>
                            <colgroup>
                                <col width="15.5%" /><col width="17.5%" /><col width="34%" /><col width="33%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>결합 종류</th>
                                    <th>결합 상품</th>
                                    <th>결합 조건</th>
                                    <th>혜택 요약</th>
                                </tr>
                            </thead>
                            <tbody>
                                {DISCOUNT_FAMILY.map((data:any,index:any)=>{
                                    return(
                                        <React.Fragment key={`kt-family-discount-${index}`}>
                                            <tr>
                                                <th rowSpan={data.list.length}>{data.name}</th>
                                                {data.list.map((item:any,idx:any)=>{
                                                    if(idx !== 0) return;
                                                    return(
                                                        <React.Fragment key={`kt-family-item-${index}-${idx}`}>
                                                            <td>{item['product']}</td>
                                                            <td>{item['terms']}</td>
                                                            <td>{item['benefit']}</td>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </tr>
                                            {data.list.map((item:any,idx:any)=>{
                                                if(idx === 0) return;
                                                return(
                                                    <tr key={`kt-family-item-${index}-${idx}`}>
                                                        <td>{item['product']}</td>
                                                        <td>{item['terms']}</td>
                                                        <td>{item['benefit']}</td>
                                                    </tr>
                                                )
                                            })}
                                        </React.Fragment>
                                    )
                                })}
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <BasicText marginTop={2} fontSize={13} color={addTheme.subText.color}>* 고가요금제(77,000원 이상)</BasicText>
                    <BasicText marginTop={2} fontWeight={700}>1. 아래 2 가지 조건 모두 해당 된다면?<br/>-&gt; 프리미엄 가족결합</BasicText>
                    <BasicText>· 2대 이상 휴대폰 결합</BasicText>
                    <BasicText>· 각 월 77,000원 이상 요금제</BasicText>
                    <BasicText marginTop={2}><strong>혜택:</strong> 휴대폰 요금 25% 할인</BasicText>
                    <BasicText fontWeight={700}>2. 월 77,000원 이하 요금제라면?<br/>-&gt; 총액결합 할인</BasicText>
                    <BasicText marginTop={2}><strong>혜택:</strong> 최대 33,100원 할인</BasicText>
                    <BasicText marginTop={2}><strong>&lt; 총액 결합 할인 표 &gt; </strong></BasicText>
                    <TableWrap>
                        <TableWrapItem>
                            <colgroup>
                                <col width="14%" />
                                <col width="14%" />
                                <col width="14%" />
                                <col width="14%" />
                                <col width="14%" />
                                <col width="14%" />
                                <col width="16%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>월 요금 합산</th>
                                    <th>~ 22,000원</th>
                                    <th>~ 64,900원</th>
                                    <th>~ 108,900원</th>
                                    <th>~ 141,900원</th>
                                    <th>~ 174,900원</th>
                                    <th>~ 174,900원</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th className='content-bg'>인터넷 할인</th>
                                    <td>1,650원<br/>@2,200원</td>
                                    <td>3,300원<br/>@5,500원</td>
                                    <td colSpan={4}>5,500원</td>
                                </tr>
                                <tr>
                                    <th className='content-bg'>휴대폰 할인</th>
                                    <td colSpan={2}>-</td>
                                    <td>33,500원<br/>@5,500원</td>
                                    <td>14,300원<br/>@5,500원</td>
                                    <td>14,300원<br/>@16,610원</td>
                                    <td>23,100원<br/>@27,610원</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <BasicText marginTop={2}>@는 <strong>500메가 이상</strong> 인터넷 사용 시 적용되는 할인액<br/>(3G 요금제는 3G 뭉치면 올레를 선택해주세요.)</BasicText>
                    <BasicText marginTop={2}>자세한 결합 조건이 궁금하시다면 언제든 전화주세요.</BasicText>
                    <BasicText marginTop={2}>가장 유리한 조건으로 최저가 컨설팅 도와드리겠습니다. 😉</BasicText>
                    <BasicText marginTop={2} fontWeight={700}>(📞 1533-9360)</BasicText>
                </SectionWrap>
                <SectionWrap>
                    <StepInnerTitleItem num={4} title='3. 있으면 더 좋아요, 제휴카드 할인' />
                    <BasicText marginTop={0.5}>제휴카드 발급 후 통신요금<br/>자동이체 시 할인혜택이 적용됩니다.</BasicText>
                    <BasicText marginTop={2}>단, <strong>실적조건</strong>이 있어요.</BasicText>
                    <TableWrap>
                        <TableWrapItem margin='15px 0 0'>
                            <colgroup>
                                <col width="17.5%" />
                                <col width="41.5%" />
                                <col width="41%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>카드사</th>
                                    <th>카드명</th>
                                    <th>할인 혜택</th>
                                </tr>
                            </thead>
                            <tbody>
                                {DISCOUNT_CARD.map((data:any,index:any)=>{
                                    return(
                                        <React.Fragment key={`kt-card-discount-${index}`}>
                                            <tr>
                                                <th rowSpan={data.list.length}>{data.name}</th>
                                                {data.list.map((item:any,idx:any)=>{
                                                    if(idx !== 0) return;
                                                    return(
                                                        <React.Fragment key={`kt-card-item-${index}-${idx}`}>
                                                            <td>{item['name']}</td>
                                                            <td>{item['benefit']}</td>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </tr>
                                            {data.list.map((item:any,idx:any)=>{
                                                if(idx === 0) return;
                                                return(
                                                    <tr key={`kt-card-item-${index}-${idx}`}>
                                                        <td>{item['name']}</td>
                                                        <td>{item['benefit']}</td>
                                                    </tr>
                                                )
                                            })}
                                        </React.Fragment>
                                    )
                                })}
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                </SectionWrap>
            </SectionWrap>
            <SectionWrap padding={'80px 0 0'}>
                <StepTitleItem step='03' title={'잠깐!! 설치 전 확인하셨나요? 필수 체크리스트'} />
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={1} title='대칭형/비대칭형 조회' />
                    <BasicText marginTop={0.5}>인터넷 가입 전,<br/>대칭형/비대칭형 조회는 필수입니다!</BasicText>
                    <InnerStep02Wrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>대칭형 인터넷(FTTH 회선)</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='transparent' size={22} />
                            <BasicText>업로드 및 다운로드 속도가 일정한 초고속 인터넷(고품질)</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='20px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>비대칭형 인터넷(HFC 회선)</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='transparent' size={22} />
                            <BasicText>업로드 및 다운로드 속도가 달라 불안정한 인터넷(저품질)</BasicText>
                        </TextRowWrap>
                    </InnerStep02Wrap>
                    <BasicText marginTop={2}>품질은 달라도 요금은 같기 때문에 반드시<br/><strong>대칭형 인터넷</strong>이 들어오는 지 확인해주세요!</BasicText>
                    <BasicText marginTop={2}>하지만 통신사에게 불리한<br/>내용인지라 조회하기가 어렵죠 😡</BasicText>
                    <BasicText marginTop={2}>일일히 찾아보기 힘드시다면,</BasicText>
                    <BasicText marginTop={2} spColor={addTheme.color}>저희 <span>현주친구</span>로 전화주세요.<br/>빠르게 조회해드리겠습니다!<br/>(📞 1533-9360)</BasicText>
                </SectionWrap>
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={2} title='IPTV 셋톱박스/공유기' />
                    <BasicText marginTop={0.5} fontWeight={700}>현주친구에 명시된 요금은?</BasicText>
                    <BasicText marginTop={2}>셋톱박스, 모뎀 임대료 및 부가가치세가<br/>전부 포함된 실 납부액입니다.</BasicText>
                    <BasicText marginTop={2}>공유기와 셋톱박스는 임대로 제공되며<br/>약정 후 꼭 반납을 해야합니다.</BasicText>
                    <BasicText marginTop={2}>잃어버리지 않게 주의하세요!</BasicText>
                    <TableWrap>
                        <TableWrapItem margin='15px 0 0'>
                            <colgroup>
                                <col width="36%" />
                                <col width="29%" />
                                <col width="35%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>셋톱박스</th>
                                    <th>월 임대료</th>
                                    <th>특징</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>기가지니A</td>
                                    <td>3,300원</td>
                                    <td>가장 저렴한 일반형</td>
                                </tr>
                                <tr>
                                    <td>기가지니3</td>
                                    <td>4,400원</td>
                                    <td>AI 블루투스 스피커형</td>
                                </tr>
                                <tr>
                                    <td>지니TV 올인원 사운드바</td>
                                    <td>8,800원</td>
                                    <td>사운드바 형태,<br/>와이파이 공유기 일체</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <TextRowWrap gap={8} margin='20px 0 0'>
                        <FaCheckCircle color='#ff7500' size={22} />
                        <BasicText fontSize={17} fontWeight={700}>주요 OTT 시청 가능 유무</BasicText>
                    </TextRowWrap>
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0'>
                            <colgroup>
                                <col width="" />
                                <col width="13%" />
                                <col width="13%" />
                                <col width="13%" />
                                <col width="13%" />
                                <col width="13%" />
                                <col width="13%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>구분</th>
                                    <th>유튜브</th>
                                    <th>넷플릭스</th>
                                    <th>디즈니+</th>
                                    <th>웨이브</th>
                                    <th>왓챠</th>
                                    <th>티빙</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>기가지니A</th>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                </tr>
                                <tr>
                                    <th>기가지니3</th>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                </tr>
                                <tr>
                                    <th>지니TV 올인원<br/>사운드바</th>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <TextRowWrap gap={8} margin='20px 0 0'>
                        <FaCheckCircle color='#ff7500' size={22} />
                        <BasicText fontSize={17} fontWeight={700}>공유기 임대료(3년 약정 결합기준)</BasicText>
                    </TextRowWrap>
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0'>
                            <colgroup>
                                <col width="23%" />
                                <col width="30%" />
                                <col width="16%" />
                                <col width="16%" />
                                <col />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>구분</th>
                                    <th>특징</th>
                                    <th>100M</th>
                                    <th>500M</th>
                                    <th>1G</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>KT GIGA WAVE2</th>
                                    <td>기본형 와이파이</td>
                                    <td>1,100원</td>
                                    <td>1,100원</td>
                                    <td>무료</td>
                                </tr>
                                <tr>
                                    <th>GIGA WIFI 홈AX</th>
                                    <td>WIFI-6 적용</td>
                                    <td>신청 불가</td>
                                    <td>1,100원</td>
                                    <td>무료</td>
                                </tr>
                                <tr>
                                    <th>GIGA WIFI<br/>BUDDY</th>
                                    <td>와이파이 증폭기기,<br/>홈 AX랑만 호환</td>
                                    <td>1,650원</td>
                                    <td>1,650원</td>
                                    <td>1,650원</td>
                                </tr>
                                <tr>
                                    <th>GIGA WIFI<br/>프리미엄 2.4</th>
                                    <td>고급형 와이파이,<br/>최대 10Gbps 속도</td>
                                    <td>4,400원</td>
                                    <td>4,400원</td>
                                    <td>4,400원</td>
                                </tr>
                                <tr>
                                    <th>GIGA WIFI<br/>프리미엄 2.4(6E)</th>
                                    <td>고급형 와이파이,<br/>최대 2.5Gbps 속도</td>
                                    <td>4,400원</td>
                                    <td>4,400원</td>
                                    <td>4,400원</td>
                                </tr>
                                <tr>
                                    <th>GIGA WIFI<br/>프리미엄 4.8</th>
                                    <td>고급형 와이파이,<br/>최대 10Gbps 속도</td>
                                    <td>4,400원</td>
                                    <td>4,400원</td>
                                    <td>4,400원</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <BasicText marginTop={2}>공유기 임대는 필수가 아니기 때문에<br/>공유기가 있다면 굳이 하지 않으셔도 됩니다.</BasicText>
                    <TextRowWrap gap={8} margin='20px 0 0'>
                        <FaCheckCircle color='#ff7500' size={22} />
                        <BasicText fontSize={17} fontWeight={700}>공유기 임대 VS 구매</BasicText>
                    </TextRowWrap>
                    <InnerStep02Wrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>임대가 나은 경우</BasicText>
                        </TextRowWrap>
                        <BasicText marginTop={3}>1. 와이파이 안될 때 도움을 요청하고 싶은 분 (A/S 편리)</BasicText>
                        <BasicText>2. 프로모션 등으로 할인, 혹은 무료 사용이 가능할 경우</BasicText>
                        <TextRowWrap gap={8} margin='20px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>구매가 더 나은 경우</BasicText>
                        </TextRowWrap>
                        <BasicText marginTop={3}>1. 고성능 공유기가 필요하거나 저가 공유기로 충분하신 분</BasicText>
                        <BasicText>2. 약정으로 묶이기 싫으신 분</BasicText>
                    </InnerStep02Wrap>
                    <BasicText marginTop={2}>참고로 기본 제공되는 공유기는<br/><strong>구매가 최소 5만원 이상 공유기와 비슷한 스펙</strong>입니다.</BasicText>
                </SectionWrap>
            </SectionWrap>
            <SectionWrap padding={'80px 0 0'}>
                <StepTitleItem step='04' title={'설치 및 사은품 지급 안내'} />
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={1} title='설치 일정 및 사은품 지급' />
                    <InnerStep02Wrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>설치는 신청 후 기사님과 조율하시면 됩니다. 평균 1~3일 내로 가능합니다</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>원하시는 날짜가 있다면 미리 말씀주세요. 최대한 맞춰드릴 수 있도록 도와드리겠습니다.</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>당일 설치는 기사님 일정에 따라 정해져요. 먼저 말씀해주시면 도와드리겠습니다!</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>설치시간은 평균 30분 내외이며 환경에 따라 조금 더 시간이 걸릴 수 있어요.</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>설치 후 1533-9360번으로 바로 연락주세요. 사은품 지급을 도와드리겠습니다.</BasicText>
                        </TextRowWrap>
                    </InnerStep02Wrap>
                </SectionWrap>
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={2} title='설치비용' />
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0'>
                            <colgroup>
                                <col width="10%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="15%" />
                                <col width="20%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>구분</th>
                                    <th>인터넷 단독</th>
                                    <th>TV 단독</th>
                                    <th>인터넷<br/>+ TV</th>
                                    <th>TV추가<br/>(1대 당)</th>
                                    <th>인터넷 전화<br/>(인터넷과 동시<br/>진행시)</th>
                                    <th>일반 전화<br/>(인터넷과 동시<br/>진행시)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>평일</th>
                                    <td>36,000원</td>
                                    <td>34,100원</td>
                                    <td>56,200원</td>
                                    <td>+15,400원</td>
                                    <td>+24,000원</td>
                                    <td>신규 +32,000원<br/>번호이동 +36,000원</td>
                                </tr>
                                <tr>
                                    <th>주말</th>
                                    <td>45,000원</td>
                                    <td>42,625원</td>
                                    <td>71,250원</td>
                                    <td>+19,250원</td>
                                    <td>+33,000원</td>
                                    <td>신규 +41,000원<br/>번호이동 +45,000원</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <TextRowWrap gap={8} margin='10px 0 0'>
                        <FaCheckCircle color='#ff7500' size={20} />
                        <BasicText>최초 가입시 1회 설치비가 발생</BasicText>
                    </TextRowWrap>
                </SectionWrap>
            </SectionWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const TableWrap = styled.div`
    overflow-x: auto;
    &>table{
        min-width: 560px;
    }
`;
const TableWrapItem = styled.table<{margin?:string}>`
    margin: ${({margin}:any)=> margin ?? '0'};
`;
const InnerStep02Wrap = styled.div`
    margin-top: 10px;
    background-color: #fbf5ec;
    padding: 20px;
`;
const TextRowWrap = styled(Row)<{margin?:string,gap?:number}>`
    margin: ${(({margin}) => margin ?? '0')};
    gap: ${(({gap}) => gap ?? 0)}px;
`;