import React, { useEffect, useRef, useState } from 'react'
import { Center, ContentWrap, InputWrap, ItemWrap } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import styled from 'styled-components';
import SelectItem from '../../../common/item/SelectItem';
import { MenuItem } from '@mui/material';
import { apiGetInquiryCate } from '../../../../api/api_qna';
import { onlyNumber } from '../../../../utils/format/string';

export default function AdmInquiryProductPart({info,valueChange,type}:any) {

    return (
        <Wrap>
            <ContentWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>작성자</BasicText>
                    <InputWrap>
                        <BasicText>{info?.inquiry_product_user_name}</BasicText>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>연락처</BasicText>
                    <InputWrap>
                        <BasicText>{info?.inquiry_product_phone_number}</BasicText>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>카테고리</BasicText>
                    <InputWrap>
                        <BasicText>{info?.product_category_title}</BasicText>
                    </InputWrap> 
                </ItemWrap>
                <ItemWrap pWidth={120} align='start'>
                    <BasicText>문의내용</BasicText>
                    <InputWrap>
                        <textarea disabled={type==='답변'} rows={20} value={info?.inquiry_product_content ?? ''} onChange={(e:any)=>valueChange('inquiry_product_content',e.target.value)}></textarea>
                    </InputWrap>                    
                </ItemWrap>
            </ContentWrap>
        </Wrap>
    )
}
const Wrap = styled.div``;
const ImgBtnWrap = styled(Center)`
    cursor: pointer;
    border: 1px solid #e6e6e6;
    padding: 12px 14px;
    border-radius: 6px;
    margin-top: 15px;
`;