import React from 'react'
import styled from 'styled-components'
import LayoutPage from '../../common/page/LayoutPage';
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import BannerPart from '../../home/part/BannerPart';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import BenefitPart from '../part/BenefitPart';
import WhyPart from '../part/WhyPart';
import CenterBannerPart from '../part/CenterBannerPart';
import NeedPart from '../part/NeedPart';
import BtnPart from '../part/BtnPart';

export default function MultiLinePage() {
    return (
        <LayoutPage>
            <Wrap>
                <BannerPart type='multi'/>
                <MaxWidthWrap>
                    <TitleWrap>
                        <BasicText fontSize={24} fontWeight={700} mFontSize={18}>지금 바로 600만원의 혜택을 받아보세요!</BasicText>
                        <BasicText fontSize={20} mFontSize={16}>아래의 <span style={{color:addTheme.color,textDecoration:'underline'}}>혜택사항</span>을 참고해주세요!</BasicText>
                    </TitleWrap>
                    <BenefitPart />
                    <WhyPart />
                    <BasicText marginTop={9} fontWeight={700} fontSize={26} mFontSize={20} spColor={addTheme.color}>다회선도 역시 <span>현주친구</span>입니다!</BasicText>
                </MaxWidthWrap>
                <CenterBannerPart />
                <MaxWidthWrap>
                    <NeedPart />
                </MaxWidthWrap>
                <BtnPart />
            </Wrap>
        </LayoutPage>
    )
}

const Wrap = styled.div`
    padding: 75px 0;
    @media ${addTheme.media.s}{
        padding: 60px 0;
    }
`;

const TitleWrap = styled.div`
    margin-top: 60px;
`;