import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router';
import { AdminWrap, Center, ContentWrap, InputWrap, ItemWrap, Row, AdmBtnItem } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { addTheme } from '../../../../style/addTheme';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { onlyNumber } from '../../../../utils/format/string';
import { apiAdmGetBannerDetail, apiAdmSetBanner, apiAdmSetBannerUpdate, apiAdmSetDelBanner } from '../../../../api/api_adm_popupAndBenner';

export default function AdmPopupUpdatePage() {
    const {id} = useParams();
    const adminUser = useAdminUser();
    const navigate = useNavigate();
    const [type,setType] = useState('등록');
    const moRef = useRef<any>(null);
    const [info,setInfo] = useState({
        banner_id: '',
        banner_img_mo: '',
        banner_img_web: '',
        banner_link_url: '',
        banner_order: '',
        banner_part_type: '',
        banner_reg_timestamp: '',
        banner_title: '',
        moFileName: '',
        pcFileName: '',
    });
    
    useEffect(()=>{
        if(id === undefined){
            setType('등록')
        }else{
            setType('수정')
        }
    },[id]);

    useEffect(()=>{
        if(id === undefined) return
        if(adminUser.admin_token === '') return;
        getDetail();
    },[adminUser.admin_token,id]);

    // 정보 가져오기
    const getDetail = async() =>{
        const res = await apiAdmGetBannerDetail(adminUser.admin_token,adminUser.admin_id,id);
        if(res.result){
            setInfo(res.data)
        }
    }

    // 이미지 버튼 클릭
    const moInputClick = () =>{
        if(moRef.current){
            moRef.current.click();
        }
    }

    // 이미지 파일 선택
    const moFileChange = (e:any) =>{
        const file = e.target.files[0];
        if(file){
            const reader = new FileReader();
            setInfo((obj:any)=>({...obj,moFileName:file.name,pcFileName:file.name}))
            reader.onload = () => {
                const result = reader.result;
                setInfo((obj:any)=>({...obj,banner_img_mo:result,banner_img_web:result}))
            };
            reader.readAsDataURL(file);
        }
    }

    // input 데이터 변경
    const valueChange = (name:string,val:any) =>{
        setInfo((obj:any)=>({
            ...obj,
            [name]: val,
        }))
    }
    
    // 등록, 수정
    const btnAction = async() =>{
        if(info.banner_title === ''){
            alert('제목을 입력해주세요.');
            return;
        }
        if(info.banner_img_mo === ''){
            alert('이미지를 선택해주세요.');
            return;
        }
        
        if(type === '등록'){
            const res = await apiAdmSetBanner(adminUser.admin_token,adminUser.admin_id,info,'100');
            if(res.result){
                alert('팝업을 등록했습니다.');
                location.reload();
            }else{
                alert(res.error);
            }
        }
        if(type === '수정'){
            if(window.confirm('팝업을 수정하시겠습니까?')){
                const res = await apiAdmSetBannerUpdate(adminUser.admin_token,adminUser.admin_id,info,'100')
                if(res.result){
                    alert('팝업을 수정했습니다.');
                    location.reload();
                }else{
                    alert(res.error);
                }
            }
        }
    }

    // 삭제
    const btnDelAction = async() =>{
        if(window.confirm('팝업을 삭제하시겠습니까?')){
            if(info.banner_id === '') return;
            const res = await apiAdmSetDelBanner(adminUser.admin_token,adminUser.admin_id,info.banner_id);
            if(res.result){
                alert('팝업을 삭제했습니다.');
                navigate(-1);
            }else{
                alert(res.error);
            }
        }
    }

    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={18} mFontSize={16} fontWeight={700}>팝업 {type === '등록' ? type : '관리'}</BasicText>
                <InnerWrap>
                    <ContentWrap>
                        <ItemWrap pWidth={120}>
                            <BasicText>제목</BasicText>
                            <InputWrap>
                                <input onChange={(e:any)=>valueChange('banner_title',e.target.value)} value={info.banner_title} placeholder='팝업 제목을 입력해주세요'/>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap pWidth={120}>
                            <BasicText>노출 순서</BasicText>
                            <InputWrap>
                                <input onChange={(e:any)=>valueChange('banner_order',onlyNumber(e))} value={info.banner_order} placeholder='노출 순서를 입력해주세요. 미입력시 자동으로 뒷순서로 배치됩니다.'/>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap pWidth={120} align='start'>
                            <BasicText marginTop={1}>이미지</BasicText>
                            <div style={{width:'100%'}}>
                                <FileInputWrap>
                                    <InputWrap>
                                        <BasicText>{(info.moFileName === '' || info.moFileName === undefined) ? '' : info?.moFileName}</BasicText>
                                        <input ref={moRef} type='file' accept='image/*' onChange={moFileChange}/>
                                    </InputWrap>
                                    <ImgShowBtn onClick={moInputClick}>
                                        이미지 업로드
                                    </ImgShowBtn>
                                </FileInputWrap>
                                {info?.moFileName === '' ?
                                    <></> : 
                                    <PreImgWrap>
                                        <img src={info?.banner_img_mo}></img>
                                    </PreImgWrap>
                                }
                            </div>
                        </ItemWrap>
                        <ItemWrap pWidth={120}>
                            <BasicText>링크 이동</BasicText>
                            <InputWrap>
                                <input onChange={(e:any)=>valueChange('banner_link_url',e.target.value)} value={info.banner_link_url} placeholder='팝업 클릭시, 이동할 링크를 입력해주세요.'/>
                            </InputWrap>
                        </ItemWrap>
                    </ContentWrap>
                </InnerWrap>
                <Row style={{marginTop:'20px',gap:'15px'}}>
                    {type === '등록' ?
                        <AdmBtnItem bg={'#ad63ff'} padding='10px 25px' onClick={btnAction}>팝업정보등록</AdmBtnItem> :
                        <>
                            <AdmBtnItem bg={'#ad63ff'} padding='10px 25px' onClick={btnAction}>팝업정보변경</AdmBtnItem>
                            <AdmBtnItem bg={'#ff7600'} padding='10px 25px' onClick={btnDelAction}>팝업 삭제</AdmBtnItem> 
                        </>
                    }
                </Row>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    width: 100%;
    max-width: 640px;
    margin-top: 30px;
`;

const FileInputWrap = styled(Row)`
    align-items: stretch;
    gap: 10px;
    @media ${addTheme.media.sx}{
        flex-wrap: wrap;
    }
    & input{
        display: none;
    }
`;
const ImgShowBtn = styled(Center)`
    flex-shrink: 0;
    border: 1px solid #d4d4d4;
    border-radius: 8px;
    min-height: 36px;
    padding: 0 18px;
    cursor: pointer;
`;

const PreImgWrap = styled.div`
    width: 100%;
    max-width: 280px;
    margin-top: 10px;
    &>img{
        width: 100%;
    }
`;
