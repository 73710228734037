import React from 'react'
import styled from 'styled-components'
import AdminTablePart from '../../common/part/AdminTablePart';
import { TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router';
import BasicText from '../../../basic/BasicText';
import { momentFormat } from '../../../../utils/format/time';
import { ADM_HOME_REVIEW } from '../../data/table';

const ListItem = ({data,itemClick}:any) =>{
    return (
        <TableRow>
            <TableCell sx={{padding:'10px',cursor:'pointer'}} align={ADM_HOME_REVIEW[0].align} onClick={itemClick}>{data.review_title}</TableCell>
            <TableCell sx={{padding:'10px',cursor:'pointer'}} align={ADM_HOME_REVIEW[1].align} onClick={itemClick}>{data.review_rate}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_HOME_REVIEW[2].align}>{momentFormat('YYYY-MM-DD hh:mm',data.review_timestamp)}</TableCell>
        </TableRow>
    )
}

export default function AdminHomeReviewPart({list}:any) {
    const navigate = useNavigate();
    const itemClick = (id:any) =>{
        navigate(`/adm/reviewUpdate/${id}`);
    }
    return (
        <Wrap>
            <AdminTablePart title={ADM_HOME_REVIEW}>
                {list === undefined || list.length === 0 ?
                    <></> :
                    list.map((data:any,index:any)=>{
                        return(
                            <ListItem key={`review-${index}`} data={data} itemClick={()=>itemClick(data.review_id)} />
                        )
                    })
                }
            </AdminTablePart>
        </Wrap>
    )
}

const Wrap = styled.div``;