import React from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row, RowBetween, SectionWrap } from '../../../style/basic/commonStyle';
import TitlePart from '../../common/part/TitlePart';
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';
import BtnItem from '../item/BtnItem';

const ItemWrap = ({data}:any) =>{
    return(
        <ContentItemWrap>
            <ImgWrap>
                <img src={data.img}></img>
            </ImgWrap>
            <TextWrap>
                <BasicText fontSize={23} mFontSize={17} fontWeight={700}>{data.title}</BasicText>
                <BasicText fontSize={17} mFontSize={14} color={'#4d4b49'}>{data.desc}</BasicText>
            </TextWrap>
        </ContentItemWrap>
    )
}

const LIST = [
    {id:1, img: '/img/asset/icon01.png', title: '매장에 필요한 인터넷 상품', desc: '우리가게에 꼭 필요한 인터넷 상품'},
    {id:2, img: '/img/asset/icon02.png', title: '매장에 필요한 부가 상품', desc: '초특급 혜택과 함께 매장에 필요한 모든 부가 상품'},
    {id:3, img: '/img/asset/icon03.png', title: '혜택이 쏟아지는 결합 상품', desc: '어떤 구성이든, 사장님을 위한 든든한 결합 상품'},
    {id:4, img: '/img/asset/icon04.png', title: '우리 매장에 꼭 필요한 솔루션', desc: '보안성, 매출 관리에 꼭 필요한 솔루션'},
]

export default function StoreInternetPart() {
    return (
        <SectionWrap>
            <MaxWidthWrap>
                <Wrap>
                    <TitlePart title='매장 전용 인터넷' desc='어디서도 확인할 수 없는<br/>우리 매장 맞춤 비교 전문 솔루션' />
                    <ContentWrap>
                        {LIST.map((data:any,index:any)=>{
                            return(
                                <ItemWrap key={`store-internet-${index}`} data={data}/>
                            )
                        })}
                    </ContentWrap>
                    <BtnItem title='매장 상품 추천받기' />
                </Wrap>
            </MaxWidthWrap>
        </SectionWrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled(RowBetween)`
    margin-top: 80px;
    flex-wrap: wrap;
    gap: 20px;
`;

const ContentItemWrap = styled(Row)`
    background-color: ${addTheme.section_color};
    width: 48%;
    padding: 30px 15px;
    border-radius: 5px;
    @media ${addTheme.media.s}{
        width: 100%;
        padding: 20px 10px 20px 0;
    }
`;

const ImgWrap = styled(Center)`
    width: 100px;
    &>img{
        height: 55px;
        max-width: 100%;
    }
    @media ${addTheme.media.s}{
        width: 86px;
        &>img{
            height: 42px;
        }
    }
`;
const TextWrap = styled.div``;