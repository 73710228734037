import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AdmBtnItem, AdminWrap, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { addTheme } from '../../../../style/addTheme';
import { onlyNumber } from '../../../../utils/format/string';
import { FormControlLabel, Radio } from '@mui/material';
import { numberWithComma } from '../../../../utils/format/number';
import { useNavigate, useParams } from 'react-router';
import { apiAdmGetPointWithdrawalDetail, apiAdmSetPointWithdrawal } from '../../../../api/api_adm_point';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { momentFormat } from '../../../../utils/format/time';


export default function AdmPointWithdrawalPage() {
    const {id} = useParams();
    const adminUser = useAdminUser();
    const navigate = useNavigate();
    const [info,setInfo] = useState<any>();

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        if(id === undefined) return;
        getDetail();
    },[adminUser.admin_id, id]);

    const getDetail = async() =>{
        const res = await apiAdmGetPointWithdrawalDetail(adminUser.admin_token,adminUser.admin_id,id);
        if(res.result){
            setInfo(res.data);
        }
    }

    const btnClick = async() =>{
        if(adminUser.admin_id === 0) return;
        if(id === undefined) return;
        if(window.confirm('출금을 승인하시겠습니까?')){
            const res = await apiAdmSetPointWithdrawal(adminUser.admin_token,adminUser.admin_id,id);
            if(res.result){
                alert('출금을 승인했습니다.');
                navigate(-1);
            }else{
                alert(res.error);
            }
        }
    }
    
    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={18} mFontSize={16} fontWeight={700}>포인트 출금</BasicText>
                <InnerWrap>
                    <ContentWrap gap={15}>
                        <ItemWrap pWidth={110}>
                            <BasicText>파트너 코드</BasicText>
                            <InputWrap>
                                <BasicText>{info?.partner_code}</BasicText>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap pWidth={110}>
                            <BasicText>파트너 명</BasicText>
                            <InputWrap>
                                <BasicText>{info?.partner_name}</BasicText>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap pWidth={110}>
                            <BasicText>주민번호</BasicText>
                            <InputWrap>
                                <BasicText>{info?.partner_resident_number}</BasicText>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap pWidth={110}>
                            <BasicText>파트너 유형</BasicText>
                            <Row>
                                <FormControlLabel control={<Radio name='user-type' value={'1'} checked={info?.partner_type === '1'} />} label="개인" />
                                <FormControlLabel control={<Radio name='user-type' value={'2'} checked={info?.partner_type === '2'} />} label="사업자" />
                            </Row>
                        </ItemWrap>
                        <ItemWrap gap='20px'>
                            <ItemWrap pWidth={110}>
                                <BasicText>연락처</BasicText>
                                <InputWrap>
                                    <BasicText>{info?.partner_phone_number}</BasicText>
                                </InputWrap>
                            </ItemWrap>
                            <ItemWrap pWidth={110}>
                                <BasicText>이메일</BasicText>
                                <InputWrap>
                                    <BasicText>{info?.partner_email}</BasicText>
                                </InputWrap>
                            </ItemWrap>
                        </ItemWrap>
                        <ItemWrap pWidth={110} align='start'>
                            <BasicText marginTop={1}>계좌주소</BasicText>
                            <div style={{width: '100%'}}>
                                <InputWrap max={'200px'}>
                                    <BasicText>{info?.partner_account_bank}</BasicText>
                                </InputWrap>
                                <InputWrap margin='5px 0 0 0'>
                                    <BasicText>{info?.partner_account_bank_number}</BasicText>
                                </InputWrap>
                            </div>
                        </ItemWrap>
                        {info?.partner_type === '2' &&
                            <ItemWrap pWidth={110} align='start'>
                                <BasicText marginTop={1}>사업자정보</BasicText>
                                <div style={{width: '100%'}}>
                                    <Row style={{gap:'10px'}}>
                                        <InputWrap max={'200px'}>
                                            <BasicText minHeight='26px'>{info?.partner_business_name}</BasicText>    
                                        </InputWrap>
                                    </Row>
                                    <InputWrap margin='5px 0 0 0'>
                                        <BasicText minHeight='26px'>{info?.partner_business_number}</BasicText>
                                    </InputWrap>
                                </div>
                            </ItemWrap>
                        }
                        <ItemWrap pWidth={110}>
                            <BasicText>총보유P</BasicText>
                            <InputWrap>
                                <BasicText>{numberWithComma(info?.partner_account_point)}</BasicText>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap pWidth={110}>
                            <BasicText>출금신청P</BasicText>
                            <InputWrap>
                                <BasicText>{numberWithComma(info?.withdrawal_point)}</BasicText>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap pWidth={110}>
                            <BasicText>신청일</BasicText>
                            <InputWrap>
                                <BasicText>{momentFormat('YYYY-MM-DD hh:mm',info?.point_payment_req_timestamp)}</BasicText>
                            </InputWrap>
                        </ItemWrap>
                    </ContentWrap>
                    <Row style={{marginTop:'30px'}}>
                        <AdmBtnItem onClick={()=>btnClick()} bg={addTheme.admBtn2}>출금 완료</AdmBtnItem>
                    </Row>
                </InnerWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    width: 100%;
    max-width: 640px;
    margin-top: 30px;
`;