import React from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';

const LIST = [
    {name: 'LG 정품 스타일러', img: '/img/asset/masset01.png'},
    {name: 'LG 정품 물걸레 청소기', img: '/img/asset/masset02.png'},
    {name: 'LG 정품 스팀형건조기', img: '/img/asset/masset03.png'},
    {name: 'CCTV 무상지원', img: '/img/asset/masset04.png'},
]
export default function BenefitPart() {
    return (
        <Wrap>
            <BasicText fontWeight={700} fontSize={26} mFontSize={20}>사장님을 위한 초특급 혜택</BasicText>
            <ContentWrap>
                {LIST.map((data:any,index:any)=>{
                    return(
                        <ContentItemWrap key={`benefit-${index}`}>
                            <img alt='benefit-img' src={data.img}></img>
                        </ContentItemWrap>
                    )
                })}
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 80px;
`;
const ContentWrap = styled(Row)`
    gap: 12px;
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
        &>div{
            width: calc(50% - 6px);
        }
    }
`;
const ContentItemWrap = styled.div`
    margin-top: 20px;
    & img{
        width: 100%;
    }
`;