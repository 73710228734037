import moment from 'moment';

export const displayedAt = (datee: any, langType?:number) => {
  const current: any = new Date();
  const milliSeconds = Math.abs(current - datee);
  const seconds = milliSeconds / 1000;
  const lang = langType === undefined ? 0 : langType;
  if (seconds < 60) return `${Math.floor(seconds)}${lang === 0 ? '초 전':'seconds ago'}`;

  const minutes = seconds / 60;
  if (minutes < 60) return `${Math.floor(minutes)}${lang === 0 ? '분 전':'minutes ago'}`;

  const hours = minutes / 60;
  if (hours < 24) return `${Math.floor(hours)}${lang === 0 ? '시 전':'hours ago'}`;

  const days = hours / 24;
  if (days < 7) return `${Math.floor(days)}${lang === 0 ? '일 전':'days ago'}`;

  const weeks = days / 7;
  if (weeks < 5) return `${Math.floor(weeks)}${lang === 0 ? '주 전':'weeks ago'}`;

  const months = days / 30;
  if (months < 12) return `${Math.floor(months)}${lang === 0 ? '달 전':'months ago'}`;

  const years = days / 365;
  return `${Math.floor(years)}${lang === 0 ? '년 전':'years ago'}`;
};

export function momentFormat(format: string, timestamp?: any): string {
  if (timestamp) {
    if(timestamp == 0){
      return '-'
    }
    return moment(Number(timestamp)).format(format);
  } else {
    return '-';
  }
}

export function momentFormatUTC(format: string, timestamp?: number): string {
    if (timestamp) {
      if(typeof timestamp === 'string'){
        timestamp = Number(timestamp);
      }
      return moment(timestamp).utc().format(format);
    } else {
      return '-';
    }
  }

export function dateFormatUTCTimeZone(timestamp?: any) {
  return {
    day: momentFormatUTC('DD/MM/YYYY', timestamp),
    time: momentFormatUTC('HH:mm:ss', timestamp)
  }
}

export function dateFormatDayColons(timestamp?: any) {
  return momentFormatUTC('DD/MM/YYYY', timestamp);
}

export function dateFormatTimeZoneColons(timestamp?: any) {
  return momentFormatUTC('HH:mm:ss', timestamp);
}

export function dateFormatMonthDay(timestamp?: number) {
  return momentFormat('MM/DD', timestamp);
}

export function dateFormatChat(timestamp?: number) {
  return momentFormat('hh:mm A', timestamp);
}

export function dateFormatMinute(timestamp?: number) {
  return momentFormat('YYYY/MM/DD HH:mm', timestamp);
}

export function dateFormatSecond(timestamp: number) {
  return momentFormat('YYYY/MM/DD HH:mm:ss', timestamp);
}

export function CountDownTimer(dt: any)
{
var end = dt;

var _second = 1000;
var _minute = _second * 60;
var _hour = _minute * 60;
var _day = _hour * 24;
var now = new Date().getTime();
var distance = end - now;
  if (distance < 0) {
    return '만료';
  }
  var days = Math.floor(distance / _day);
  var hours = Math.floor((distance % _day) / _hour) < 10 ? `0${Math.floor((distance % _day) / _hour)}` : `${Math.floor((distance % _day) / _hour)}`;
  var minutes = Math.floor((distance % _hour) / _minute) < 10 ? `0${Math.floor((distance % _hour) / _minute)}` : `${Math.floor((distance % _hour) / _minute)}`;
  var seconds = Math.floor((distance % _minute) / _second) < 10 ? `0${Math.floor((distance % _minute) / _second)}` : `${Math.floor((distance % _minute) / _second)}`;

  return `${days}일 ${hours}:${minutes}:${seconds}`;
}
