import React from 'react'
import { ADM_POINT_LIST } from '../../data/table'
import { TableCell, TableRow } from '@mui/material'
import { AdmBtnItem, Center } from '../../../../style/basic/commonStyle'
import { momentFormat } from '../../../../utils/format/time'
import { numberWithComma } from '../../../../utils/format/number'
import { PARTNER_TYPE } from '../../partner/data/dummy'

export default function AdmPointTotalItem({data,update}:any) {
    return (
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_LIST[0].align}>{data.partner_account_code}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_LIST[1].align}>{PARTNER_TYPE[data.partner_account_type]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_LIST[2].align}>{data.partner_account_name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_LIST[3].align}>{data.partner_account_phone_number}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_LIST[4].align}>{numberWithComma(data.partner_account_point)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_LIST[5].align}>{numberWithComma(data.point_withdrawal_amount)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_LIST[6].align}>{numberWithComma(data.order_count)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_LIST[7].align}>{momentFormat('YYYY-MM-DD hh:mm',data.partner_account_apply_timestamp)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_LIST[8].align}>{momentFormat('YYYY-MM-DD hh:mm',data.partner_account_reg_timestamp)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_LIST[9].align}>
                <Center style={{gap:'8px'}}>
                    <AdmBtnItem bg='#ff7600' round={30} padding='6px 16px' onClick={update}>상세</AdmBtnItem>
                </Center>
            </TableCell>
        </TableRow>
    )
}
