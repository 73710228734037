import { Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { Center } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { apiGetBanner } from '../../../api/api_banner';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { momentFormat } from '../../../utils/format/time';

export default function PopupPart() {
    const ref = useRef<any>(null);
    const [list,setList] = useState<any>([]);
    const [popupState,setPopupState] = useState(false);

    useEffect(()=>{
        const popupDate = localStorage.getItem('popupDate');
        const time = new Date().getTime();
        const resTime = momentFormat('YYYYMMDD',time);
        if(popupDate === null){
            setPopupState(true);
            localStorage.setItem('popupDate', resTime);
        }else{
            if(popupDate === resTime){
                setPopupState(false);
            }else{
                setPopupState(true);
                localStorage.setItem('popupDate', resTime);
            }
        }
        
    },[])

    const close = () =>{
        setPopupState(false);
    }

    const getList = async() =>{
        const res = await apiGetBanner('100',1,50);
        if(res.result){
            setList(res.data);
        }
    }

    useEffect(()=>{
        if(popupState){
            getList();
        }
    },[popupState]);

    const clickAction = (url:string) =>{
        if(url === undefined || url === '') return;
        window.open(url);
    }

    return (
        <Dialog
            ref={ref}
            open={popupState} 
            // onClose={close}
            sx={{
                '.MuiPaper-root':{margin:'8px', maxWidth:'420px',width:'100%',borderRadius: 0,}
            }}
        >
            <DialogContent dividers={true} sx={{padding: 0,borderTop: 'none', borderBottom: 'none'}}>
                <Wrap>
                    {list === undefined || list.length === 0 ?
                        <></> :
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            modules={[Pagination,Autoplay]}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            pagination={{ clickable: true }}
                        >
                            {list === undefined || list.length === 0 ?
                                <></> :
                                list.map((data:any,index:any)=>{
                                    return(
                                        <SwiperSlide key={`popup-${index}`}>
                                            <ImgWrap onClick={()=>clickAction(data.banner_link_url)}>
                                                <img src={data.banner_img_mo}></img>
                                            </ImgWrap>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    }
                </Wrap>
            </DialogContent>
            <DialogActions sx={{padding:0}}>
                <CloseWrap onClick={close}>
                    <BasicText color='#fff' fontSize={18}>닫기</BasicText>
                </CloseWrap>
            </DialogActions>
        </Dialog>
    )
}

const Wrap = styled.div`
    & .swiper-pagination-bullet{
        background: #fff;
    }
    & .swiper {
        background-color: #000;
    }
    & .swiper-wrapper{
        align-items: center;
    }
`;
const CloseWrap= styled(Center)`
    width: 100%;
    height: 52px;
    background-color: ${addTheme.color};
    cursor: pointer;
`;

const ImgWrap = styled.div`
    display: flex;
    & img{
        width: 100%;
    }
`;