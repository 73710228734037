import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AdmBtnItem, AdminWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { addTheme } from '../../../../style/addTheme';
import { apiAdmGetPartnerDetail, apiAdmSetPartnerMemoUpdate, apiAdmSetPartnerStatus, apiAdmSetPartnerUpdate } from '../../../../api/api_adm_partner';
import AdmPartnerUpdateInfoPart from '../part/AdmPartnerUpdateInfoPart';
import AdmPartnerUpdateListPart from '../part/AdmPartnerUpdateListPart';

export default function AdmPartnerUpdatePage() {
    const {code} = useParams();
    const adminUser = useAdminUser();
    const [info,setInfo] = useState<any>({});

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        if(code === undefined) return;
        getDetail();
    },[adminUser.admin_id,code]);

    // 디테일 정보 가져오기
    const getDetail = async() =>{
        const res = await apiAdmGetPartnerDetail(adminUser.admin_token,adminUser.admin_id,code);
        if(res.result){
            setInfo(res.data);
        }
    }

    // input 데이터 변경
    const valueChange = (name:string, val:any)=>{
        setInfo((obj:any)=>({
            ...obj,
            [name]: val,
        }))
    }

    const btnAction = async() =>{
        if(info?.partner_account_status === '1'){
            if(window.confirm('수정 하시겠습니까?')){
                const res = await apiAdmSetPartnerUpdate(adminUser.admin_token,adminUser.admin_id,info);
                if(res.result){
                    alert('수정 되었습니다.');
                }else{
                    alert(res.error);
                }
            }
        }else{
            if(window.confirm(`승인 하시겠습니까?`)){
                if(code === undefined) return;
                const res = await apiAdmSetPartnerStatus(adminUser.admin_token,adminUser.admin_id,code,'1');
                if(res.result){
                    alert(`승인 되었습니다`);
                    getDetail();
                }else{
                    alert(res.error);
                }
            }
        }
    }
    
    // 메모 저장 버튼
    const memoUpdateAction = async() =>{
        if(adminUser.admin_id === 0) return;
        if(info?.partner_account_code === undefined || info?.partner_account_code === '') return;
        if(info?.partner_account_memo === undefined || info?.partner_account_memo === ''){
            alert('메모를 입력해주세요.');
            return;
        }
        const res = await apiAdmSetPartnerMemoUpdate(adminUser.admin_token,adminUser.admin_id,info.partner_account_code,info.partner_account_memo);
        if(res.result){
            alert('저장 되었습니다.');
        }else{
            alert(res.error);
        }
    }

    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={18} mFontSize={16} fontWeight={700}>파트너 정보</BasicText>
                <InnerWrap>
                    <Grid container={true} spacing={4}>
                        <Grid item md={6} xs={12}>
                            {info?.partner_account_name !== undefined &&
                                <AdmPartnerUpdateInfoPart info={info} valueChange={valueChange} memoUpdateAction={memoUpdateAction}/>
                            }
                        </Grid>
                        <Grid item md={6} xs={12}>
                            {info?.partner_account_status === '1' &&
                                <AdmPartnerUpdateListPart info={info}/>
                            }
                        </Grid>
                    </Grid>
                    <Row style={{marginTop:'40px'}}>
                        <AdmBtnItem onClick={btnAction} bg={addTheme.admBtn2}>파트너 {info?.partner_account_status === '1' ? '수정' : '승인'}</AdmBtnItem>
                    </Row>
                </InnerWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    margin-top: 30px;
`;
