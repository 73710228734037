import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import { Checkbox, FormControlLabel, MenuItem, Select } from '@mui/material';
import { apiGetBenefitInquiryCate, apiSetBenefitInquiry } from '../../../api/api_main';
import { onlyNumber } from '../../../utils/format/string';

const INFO_INIT = {
    user_name: '',
    user_phone_number: '',
    inquiry_question: '',
    product_category_code: '',
}

export default function ApplyPart() {
    const [chk,setChk] = useState(false);
    const [cateList,setCateList] = useState([]);
    const [info,setInfo] = useState({
        user_name: '',
        user_phone_number: '',
        inquiry_question: '',
        product_category_code: '',
    })

    useEffect(()=>{
        getCateList();
    },[])

    // 카테고리 리스트 가져오기
    const getCateList = async() =>{
        const res = await apiGetBenefitInquiryCate(1,100);
        if(res.result){
            setCateList(res.data);
        }
    }

    // input value 변경
    const valueChange = (name:string,val:any) =>{
        setInfo((obj:any)=>({...obj,[name]:val}));
    }

    // 신청
    const btnClick = async() =>{
        if(!chk){
            alert('개인 정보 수집·이용에 동의해주세요.');
            return;
        }
        if(info.user_name === '' ){
            alert('이름을 입력해주세요.');
            return;
        }
        if(info.user_phone_number === ''){
            alert('휴대폰 번호를 입력해주세요.');
            return;
        }
        if(info.product_category_code === ''){
            alert('상품을 선택해주세요.')
            return;
        }
        const res = await apiSetBenefitInquiry(info);
        if(res.result){
            alert('신청되었습니다.');
            setInfo(INFO_INIT);
            setChk(false);
        }
    }

    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    <BasicText fontSize={26} mFontSize={20} fontWeight={700}>비밀 지원금 전부 알려드려요!</BasicText>
                    <ContentWrap>
                        <TextWrap>
                            <LefTextWrap>
                                <InputWrap>
                                    {cateList === undefined || cateList.length === 0 ?
                                        <></> :
                                        <Select 
                                            sx={{width: '100%', height: '100%', '& fieldset':{border: 'none'}}} 
                                            value={info.product_category_code}
                                            displayEmpty
                                            onChange={(e:any)=>valueChange('product_category_code',e.target.value)}
                                        >
                                            <MenuItem disabled value='' >{'문의 분야'}</MenuItem>
                                            {cateList.map((data:any,index:any)=>{
                                                return(
                                                    <MenuItem key={`inquiry-${index}`} value={data.product_category_code} >{data.product_category_name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    }
                                </InputWrap>
                                <InputWrap>
                                    <input placeholder='이름' value={info.user_name} onChange={(e:any)=>valueChange('user_name',e.target.value)}></input>
                                </InputWrap>
                                <InputWrap>
                                    <input placeholder='연락처(-없이)' value={info.user_phone_number} onChange={(e:any)=>valueChange('user_phone_number',onlyNumber(e))}></input>
                                </InputWrap>
                            </LefTextWrap>
                            <RightTextWrap onClick={btnClick}>
                                신청하기
                            </RightTextWrap>
                        </TextWrap>
                    </ContentWrap>
                    <ChkWrap>
                        <FormControlLabel sx={{'span':{fontSize:'15px'}}} control={<Checkbox sx={{color:addTheme.color,'&.Mui-checked': {color:addTheme.color}}} checked={chk} onChange={()=>setChk(!chk)} />} label="개인 정보 수집·이용에 동의합니다." />
                    </ChkWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    /* padding: 70px 0; */
`;

const ContentWrap = styled.div``;
const TextWrap = styled(RowBetween)`
    gap: 10px;
    margin-top: 30px;
    flex-wrap: wrap;
`;
const LefTextWrap = styled(Row)`
    gap: 15px;
    max-width: 59%;
    @media ${addTheme.media.s}{
        max-width: 100%;
        flex-wrap: wrap;
    }
`;
const RightTextWrap = styled(Center)`
    flex-shrink: 0;
    width: 39%;
    background-color: ${addTheme.color};
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    height: 48px;
    border-radius: 4px;
    cursor: pointer;
    @media ${addTheme.media.s}{
        width: 100%;
        font-size: 18px;
    }
`;

const InputWrap = styled.div`
    width: 33%;
    height: 48px;
    background-color: #fff8f1;
    border-radius: 4px;
    padding: 0 10px;
    &>input{
        height: 100%;
        width: 100%;
        ::placeholder {
            color: #000;
        }
    }
    @media ${addTheme.media.s}{
        width: 100%;
        /* width: calc(50% - 8px);
        &:nth-of-type(1){
            width: 100%;
        } */
    }
`;

const ChkWrap = styled(Row)`
    gap: 15px;
    margin-top: 12px;
`;