import React, { useState } from 'react'
import styled from 'styled-components';
import { AdmBtnItem, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { addTheme } from '../../../../style/addTheme';
import { PLATFORM_TYPES } from '../../../../api/config';
import SelectItem from '../../../common/item/SelectItem';
import { MenuItem } from '@mui/material';
import { apiAdmSetOrderUpdateMemo, apiAdmSetOrderUpdateStatus } from '../../../../api/api_adm_order';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';

export default function AdmOrderUpdateProcessPart({info,valueChange}:any) {
    const adminUser = useAdminUser();
    
    const changeStatus = async() =>{
        if(adminUser.admin_id === 0) return;
        if(info?.order_basic?.order_code === undefined) return;
        const res = await apiAdmSetOrderUpdateStatus(adminUser.admin_token,adminUser.admin_id,info?.order_basic?.order_code,info?.order_basic?.order_status);
        if(res.result){
            alert('상태가 변경되었습니다.');
        }else{
            alert(res.error);
        }
    }

    const changeMemo = async(type: 'admin' |'customer') =>{
        if(adminUser.admin_id === 0) return;
        if(info?.order_basic?.order_code === undefined) return;
        let val = type === 'admin' ? info?.order_basic?.order_private_comment : info?.order_basic?.order_process_comment;
        if(val === ''){
            alert('메모를 입력해주세요');
            return;
        }
        const res = await apiAdmSetOrderUpdateMemo(adminUser.admin_token,adminUser.admin_id,info?.order_basic?.order_code,type,val);
        if(res.result){
            alert('메모가 저장되었습니다.');
        }else{
            alert(res.error);
        }
    }
    
    return (
        <Wrap>
            <ContentWrap gap={15}>
                <ItemWrap pWidth={120}>
                    <BasicText>처리 상태</BasicText>
                    <Row style={{gap:'10px'}}>
                        {PLATFORM_TYPES?.order?.process?.data === undefined ?
                            <></> :
                            <SelectItem width={'auto'} value={info?.order_basic?.order_status ?? '0'} setValue={(val:any)=>valueChange('order_basic','order_status',val)} none='no'>
                                {PLATFORM_TYPES.order.process.data.map((data:any,index:any)=>{
                                    return(
                                        <MenuItem key={`order-status-${index}`} value={data.value}>{data.name}</MenuItem>
                                    )
                                })}
                            </SelectItem>
                        }
                        <AdmBtnItem bg={addTheme.admBtn3} onClick={()=>changeStatus()}>처리상태변경</AdmBtnItem>
                    </Row>
                </ItemWrap>
                <ItemWrap pWidth={120} align='start'>
                    <BasicText marginTop={1}>처리 메모<br/>(관리자)</BasicText>
                    <div style={{width: '100%'}}>
                        <InputWrap>
                            <textarea rows={6} onChange={(e:any)=>valueChange('order_basic','order_private_comment',e.target.value)} value={info?.order_basic?.order_private_comment ?? ''}/>
                        </InputWrap>
                        <Row style={{marginTop:'5px'}}>
                            <AdmBtnItem bg={addTheme.admBtn3} onClick={()=>changeMemo('admin')}>처리메모저장</AdmBtnItem>
                        </Row>
                    </div>
                </ItemWrap>
                <ItemWrap pWidth={120} align='start'>
                    <BasicText marginTop={1}>처리 메모<br/>(고객)</BasicText>
                    <div style={{width: '100%'}}>
                        <InputWrap>
                            <textarea rows={6} onChange={(e:any)=>valueChange('order_basic','order_process_comment',e.target.value)} value={info?.order_basic?.order_process_comment ?? ''}/>
                        </InputWrap>
                        <Row style={{marginTop:'5px'}}>
                            <AdmBtnItem bg={addTheme.admBtn3} onClick={()=>changeMemo('customer')}>처리메모저장</AdmBtnItem>
                        </Row>
                    </div>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>추천인</BasicText>
                    <InputWrap>
                        <input readOnly onChange={(e:any)=>valueChange('order_basic','order_refer_code',e.target.value)} value={info?.order_refer_code ?? ''} />
                    </InputWrap>
                </ItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;