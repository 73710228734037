import React, { useState } from 'react'
import styled from 'styled-components'
import LayoutPage from '../../common/page/LayoutPage';
import { AdmBtnItem, InputWrap, ItemWrap, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { numberWithComma } from '../../../utils/format/number';
import { onlyNumber } from '../../../utils/format/string';
import { addTheme } from '../../../style/addTheme';
import { Checkbox, FormControlLabel } from '@mui/material';
import { apiSetPartnerAuth } from '../../../api/api_partner';
import { useGlobalPartner } from '../../../utils/custom-hooks/common/useGlobalPartner';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setGlobalPartner } from '../../../store/common/globalPartner';

export default function PartnerWithdrawalChkPage() {
    const partner = useGlobalPartner();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [info,setInfo] = useState({
        number: '',
        pw: '',
    })
    const [chk,setChk] = useState(false);

    const valueChange = (name:string,val:any) =>{
        setInfo((obj:any)=>({...obj,[name]:val}));
    }

    const btnClick = async() =>{
        if(!chk){
            alert('개인정보 수집·이용에 동의에 동의해주세요');
            return;
        }
        if(partner.partner_code === '') return;
        const res = await apiSetPartnerAuth(partner.partner_code,info.number,info.pw);
        if(res.result){
            alert('인증이 되었습니다.');
            dispatch(setGlobalPartner(({
                ...partner,
                partner_is_auth: '1',
            })))
            navigate('/partnerMyPage/myInfo');
        }else{
            alert('잠시후 다시 시도해주세요');
        }
    }

    return (
        <LayoutPage>
            <MaxWidthWrap>
                <TitleWrap>
                    <BasicText fontSize={36} mFontSize={22} fontWeight={700} align='center'>파트너스 출금 인증</BasicText>
                </TitleWrap>
                <Wrap>
                    <ContentWrap>
                        <ItemWrap>
                            <BasicText>주민번호</BasicText>
                            <InputWrap>
                                <input value={info.number} onChange={(e:any)=>valueChange('number',onlyNumber(e))} placeholder='ex) 주민등록번호 앞자리-주민등록번호 뒷자리'></input>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap>
                            <BasicText>비밀번호</BasicText>
                            <InputWrap>
                                <input type='password' value={info.pw} onChange={(e:any)=>valueChange('pw',e.target.value)} placeholder='비밀번호를 입력해주세요'></input>
                            </InputWrap>
                        </ItemWrap>
                    </ContentWrap>
                    <BtnWrap>
                        <FormControlLabel required sx={{'& .MuiFormControlLabel-asterisk':{display:'none'}}} control={<Checkbox sx={{color:addTheme.color,'&.Mui-checked': {color:addTheme.color},}} checked={chk} onChange={()=>setChk(!chk)} />} label="개인정보 수집·이용에 동의합니다." />
                        <AdmBtnItem bg={addTheme.color} padding='15px' onClick={()=>btnClick()}>인증하기</AdmBtnItem>
                    </BtnWrap>
                </Wrap>
            </MaxWidthWrap>
        </LayoutPage>
    )
}

const TitleWrap = styled.div`
    margin-top: 150px;
`;

const Wrap = styled.div`
    width: 100%;
    max-width: 650px;
    margin: 0 auto 50px;
`;

const ContentWrap = styled(Row)`
    margin-top: 50px;
    align-items: start;
    flex-direction: column;
    gap: 15px;
`;

const BtnWrap = styled.div`
    margin-top: 80px;
`;