import React from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row, RowBetween, SectionWrap } from '../../../style/basic/commonStyle';
import TitlePart from '../../common/part/TitlePart';
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';
import BtnItem from '../item/BtnItem';
import { useNavigate } from 'react-router';

const LIST = [
    {id: 1, img:'/img/asset/thumb02.png', imgText: '집에 인터넷 설치가 필요한<br/>고객님도 현주친구와 함께하면<br/><strong>고민 NO! 최고 혜택 ON!</strong>', list:[{id:1, img:'/img/asset/icon05.png', name: '현금사은품 잘 챙겨주는 현주친구',desc:'요금은 가장 합리적으로, 지원금은 업계 최고로<br/>가장 믿을 수 있는 현주친구에서 혜택을 다 받아보세요!',},{id:2, img:'/img/asset/icon06.png', name: '나에게 맞는 상품만 잘 추천하는 현주친구',desc:'필요없는 부가서비스를 가용하지 않아요.<br/>고민할 필요없이 전문 매니저가 1:1 맞춤으로 서비스를 추천해드려요.',}]},
    {id: 2, img:'/img/asset/thumb03.png', imgText: '매장 운영에 맞는 상품이 필요한<br/>사장님도 현주친구와 함께하면<br/><strong>고민 ZERO! 매출 UP!</strong>', list:[{id:1, img:'/img/asset/icon07.png', name: '매장 운영에 필요한 상품만 추천하는 현주친구',desc:'사장님을 위한 실속적인 인터넷 상품만 다 알려드려요!<br/>요금은 가장 합리적으로, 지원금은 업계 최고 현주친구에서 혜택을 다 받아보세요!',},{id:2, img:'/img/asset/icon08.png', name: '우리 매장 매출도 함께 고민하는 현주친구',desc:'사장님을 위한 사장님에 의한 매장 매출 상승도 자연스럽게!<br/>지역 커뮤니티 홍보, 포털 플레이스 홍보, 블로그 & 인스타 체험단까지<br/>모든 혜택을 현주친구에서 다 받아보세요!',}]},
]

const TextItem = ({item}:any) =>{
    return(
        <TextItemWrap>
            <img alt='icon' src={item.img}></img>
            <BasicText marginTop={0.5} fontWeight={700} fontSize={28} mFontSize={20}>{item.name}</BasicText>
            <TextDescWrap dangerouslySetInnerHTML={{__html:item.desc}}/>
        </TextItemWrap>
    )
}

const ItemWrap = ({data}:any) =>{
    return(
        <ContentItemWrap>
            <ImgWrap>
                <img src={data.img}></img>
                <ImgTextWrap dangerouslySetInnerHTML={{__html: data.imgText}}></ImgTextWrap>
            </ImgWrap>
            <TextWrap>
                {data.list.map((item:any,index:any)=>{
                    return(
                        <TextItem key={`benefit-${data.id}-${index}`} item={item} />
                    )
                })}
            </TextWrap>
        </ContentItemWrap>
    )
}

export default function BenefitPart() {
    const navigate = useNavigate();
    const goPage = (path:string) =>{
        navigate(path);
    }
    return (
        <SectionWrap>
            <MaxWidthWrap>
                <Wrap>
                    <TitlePart desc='현주친구에서는<br/>모든 혜택을 알아서 최고로 드려요' />
                    <ContentWrap>
                        {LIST.map((data:any,index:any)=>{
                            return(
                                <ItemWrap key={`benefit-${index}`} data={data} />
                            )
                        })}
                    </ContentWrap>
                    <BtnItem title='비교 불가 혜택 문의하기' clickEvent={()=>goPage('/benefitInquiry')}/>
                </Wrap>
            </MaxWidthWrap>
        </SectionWrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled(Row)`
    margin-top: 70px;
    flex-direction: column;
    gap: 40px;
    align-items: normal;
`;

const ContentItemWrap = styled(Row)`
    background-color: ${addTheme.section_color};
    border-radius: 35px;
    overflow: hidden;
    align-items: stretch;
    gap: 40px;
    @media (max-width: 890px){
        flex-wrap: wrap;
        gap: 20px;
        &>div{
            width: 100%;
        }
    }
`;

const ImgWrap = styled(Center)`
    position: relative;
    width: 35%;
    flex-shrink: 0;
    overflow: hidden;
    & img{
        width: 100%;
        height: 100%;
    }
    @media (max-width: 890px){
        height: 480px;
        align-items: start;
        & img{
            width: 120%;
            height: auto;
        }
    }
`;

const ImgTextWrap = styled.div`
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100%;
    padding: 15px 40px;
    color: #fff;
    font-size: 28px;
    line-height: 1.4;
    & strong{
        font-weight: 700;
    }
    @media (max-width: 890px){
        padding: 15px;
        font-size: 22px;
    }
`;

const TextWrap = styled(RowBetween)`
    flex-direction: column;
    justify-content: space-around;
    align-items: start;
    padding: 15px;
    @media ${addTheme.media.s}{
        padding:  20px;
        gap: 25px;
    }
`;
const TextItemWrap = styled.div`
    & img{
        width: 38px;
        @media ${addTheme.media.s}{
            width: 28px;
        }
    }
`;

const TextDescWrap = styled.div`
    color: #939190;
    margin-top: 10px;
    font-size: 18px;
    line-height: 1.3;
    @media ${addTheme.media.s}{
        font-size: 16px;
    }
`;