import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import styled from 'styled-components';
import AdminPageTotalPart from '../../common/part/AdminPageTotalPart';
import { AdmBtn, AdmBtnItem, AdminWrap, Center, InputWrap, Row, RowBetween } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';
import BasicText from '../../../basic/BasicText';
import AdminTablePart from '../../common/part/AdminTablePart';
import { ADM_PARTNER_LIST } from '../../data/table';
import { TableCell, TableRow } from '@mui/material';
import { momentFormat } from '../../../../utils/format/time';
import { apiAdmGetPartnerList, apiAdmGetPartnerSearchList, apiAdmSetPartnerStatus } from '../../../../api/api_adm_partner';
import { PARTNER_STATUS, PARTNER_TYPE } from '../data/dummy';

const ListItem = ({data,update,del}:any) =>{
    return (
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={ADM_PARTNER_LIST[0].align}>{data.partner_account_code}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PARTNER_LIST[1].align}>{PARTNER_TYPE[data.partner_account_type]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PARTNER_LIST[2].align}>{data.partner_account_type == '2' ? data.partner_account_business_name : data.partner_account_name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PARTNER_LIST[3].align}>{data.partner_account_phone_number}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PARTNER_LIST[4].align}>{PARTNER_STATUS[data.partner_account_status]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PARTNER_LIST[5].align}>{momentFormat('YYYY-MM-DD hh:mm',data.partner_account_apply_timestamp)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PARTNER_LIST[6].align}>{momentFormat('YYYY-MM-DD hh:mm',data.partner_account_reg_timestamp)}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_PARTNER_LIST[7].align}>
                <Center style={{gap:'8px'}}>
                    <AdmBtnItem bg='#ff7600' round={30} padding='6px 16px' onClick={update}>상세</AdmBtnItem>
                    {data.partner_account_status === '0' &&
                        <>
                            <AdmBtnItem bg={addTheme.admBtn2} round={30} padding='6px 16px' onClick={()=>del('1','승인')}>승인</AdmBtnItem>
                            <AdmBtnItem bg='#9e9e9e' round={30} padding='6px 16px' onClick={()=>del('2','거절')}>거절</AdmBtnItem>
                        </> 
                    }
                    {data.partner_account_status === '1' &&
                        <>
                            <AdmBtnItem bg='#9e9e9e' round={30} padding='6px 16px' onClick={()=>del('2','거절')}>거절</AdmBtnItem>
                        </> 
                    }
                    {data.partner_account_status === '2' &&
                        <>
                            <AdmBtnItem bg={addTheme.admBtn2} round={30} padding='6px 16px' onClick={()=>del('1','승인')}>승인</AdmBtnItem>
                        </> 
                    }
                </Center>
            </TableCell>
        </TableRow>
    )
}

export default function AdmPartnerPage() {
    const {page} = useParams();
    const adminUser = useAdminUser();
    const navigate = useNavigate();
    const [list,setList] = useState([]);
    const [total,setTotal] = useState(0);
    const [titleTotalReload,setTitleTotalReload] = useState(false);
    const [search,setSearch] = useState({input:'',res:''});

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        if(search.res === ''){
            getList();
        }else{
            getSearchList();
        }
    },[page,adminUser.admin_id,search.res,titleTotalReload]);

    const setPage = (p:any) =>{
        navigate(`/adm/partner/${p}`);
    }

    // 리스트
    const getList = async() =>{
        const res = await apiAdmGetPartnerList(adminUser.admin_token,adminUser.admin_id, page ?? 1, 15);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    // 검색 리스트
    const getSearchList = async() =>{
        const res = await apiAdmGetPartnerSearchList(adminUser.admin_token,adminUser.admin_id, page ?? 1, 15, search.res);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    // 검색
    const searchAction = (e:any) =>{
        e.preventDefault();
        setPage(1);
        setSearch((obj:any)=>({...obj,['res']:obj.input}))
    }

    // 상세
    const update = (data:any) =>{
        navigate(`/adm/partnerUpdate/${data.partner_account_code}`);
    }

    // 삭제
    const del = async(data:any,type:any,str:any) =>{
        if(window.confirm(`${str} 하시겠습니까?`)){
            const res = await apiAdmSetPartnerStatus(adminUser.admin_token,adminUser.admin_id,data.partner_account_code,type);
            if(res.result){
                alert(`${str} 되었습니다`);
                setTitleTotalReload(!titleTotalReload);
            }
        }
    }
    
    return (
        <Wrap>
            <AdminPageTotalPart title='전체 파트너' part='partner' reload={titleTotalReload} />
            <AdminWrap>
                <TitleWrap>
                    <BasicText fontSize={18} mFontSize={16} fontWeight={700}>파트너 관리</BasicText>
                    <SearchWrap onSubmit={searchAction}>
                        <InputWrap>
                            <input value={search.input} onChange={(e:any)=>setSearch((obj:any)=>({...obj,['input']:e.target.value}))} placeholder='이름, 코드로 검색이 가능합니다.'></input>
                        </InputWrap>
                        <AdmBtn type='submit' style={{flexShrink: '0'}} bg={'#000'}>검색</AdmBtn>
                    </SearchWrap>
                </TitleWrap>
                <TableWrap>
                    <AdminTablePart bg={addTheme.adminColor} color='#fff' title={ADM_PARTNER_LIST} minWidth={1000} page={page ?? 1} total={total} setPage={setPage}>
                        {list === undefined || list.length === 0 ?
                            <></> :
                            list.map((data:any,index:any)=>{
                                return(
                                    <ListItem key={`partner-item-${index}`} data={data} update={()=>update(data)} del={(type:any,str:string)=>del(data,type,str)}/>
                                )
                            })
                        }
                    </AdminTablePart>
                </TableWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;

const TitleWrap = styled(RowBetween)`
    flex-wrap: wrap;
    gap: 10px;
`;

const TableWrap = styled.div`
    margin-top: 20px;
`;

const SearchWrap = styled.form`
    width: auto;
    display: flex;
    flex-shrink: 0;
    gap: 10px;
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
        width: 100%;
    }
`;