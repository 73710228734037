import { axiosConfigInstance } from "./config";

// 문의 리스트
export const apiAdmGetInquiry = async(token:string,admin_id:any,page:any,count:any,inquiry_category_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/inquiry',token).post('',{
            method: 'getAdminInquiryList',
            params:{
                admin_id,page,count,inquiry_category_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 문의 삭제
export const apiAdmSetInquiryDel = async(token:string,admin_id:any,inquiry_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/inquiry',token).post('',{
            method: 'adminInquiryDelete',
            params:{
                admin_id,inquiry_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 상품 문의 삭제
export const apiAdmSetInquiryProductDel = async(token:string,admin_id:any,inquiry_product_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/inquiry/product',token).post('',{
            method: 'adminProductInquiryDelete',
            params:{
                admin_id,inquiry_product_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 문의 디테일
export const apiAdmGetInquiryDetail = async(token:string,admin_id:any,inquiry_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/inquiry',token).post('',{
            method: 'getAdminInquiryDetailInfo',
            params:{
                admin_id,inquiry_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 상품 문의 디테일
export const apiAdmGetInquiryProductDetail = async(token:string,admin_id:any,inquiry_product_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/inquiry/product',token).post('',{
            method: 'getAdminProductInquiryDetailInfo',
            params:{
                admin_id,inquiry_product_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 문의 답변
export const apiAdmSetInquiryAnswer = async(token:string,admin_id:any,info:any) =>{
    try {
        const {inquiry_id,inquiry_answer,inquiry_answer_image,fileName} = info;
        const res = await axiosConfigInstance('/api/admin/inquiry',token).post('',{
            method: 'adminInquiryAddAnswer',
            params:{
                admin_id,inquiry_id,inquiry_answer,
                inquiry_answer_image: (fileName === '' || fileName === undefined) ? '' : inquiry_answer_image
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 상품 문의 답변
export const apiAdmSetInquiryProductAnswer = async(token:string,admin_id:any,info:any) =>{
    try {
        const {inquiry_product_id,inquiry_product_answer,} = info;
        const res = await axiosConfigInstance('/api/admin/inquiry/product',token).post('',{
            method: 'adminInquiryAddAnswer',
            params:{
                admin_id,inquiry_product_id,inquiry_product_answer,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 상품 문의 리스트
export const apiAdmGetProductInquiry = async(token:string,admin_id:any,page:any,count:any,) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/inquiry/product',token).post('',{
            method: 'getAdminProductInquiryList',
            params:{
                admin_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}
