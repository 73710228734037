import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AdmBtnItem, AdminWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { Grid } from '@mui/material';
import AdmInquiryPart from '../part/AdmInquiryPart';
import AdminInquiryAnswerPart from '../part/AdminInquiryAnswerPart';
import { useNavigate, useParams } from 'react-router';
import { apiAdmGetInquiryDetail, apiAdmSetInquiryAnswer } from '../../../../api/api_adm_inquiry';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { addTheme } from '../../../../style/addTheme';

export default function AdmInquiryAnswerPage() {
    const {id} = useParams();
    const adminUser = useAdminUser();
    const navigate = useNavigate();
    const [info,setInfo] = useState({
        i_category_title: '',
        inquiry_answer_image: '',
        inquiry_question_image: '',
        inquiry_id: '',
        inquiry_question: '',
        inquiry_user_name: '',
        inquiry_user_email: '',
        inquiry_user_phone_number: '',
        inquiry_answer: '',
        fileName: '',
        questionFileName: '',
        inquiry_answer_timestamp: '0',
    });

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        if(id === undefined) return;
        getDetail();
    },[adminUser.admin_id,id]);

    // 디테일 정보 가져오기
    const getDetail = async() =>{
        const res = await apiAdmGetInquiryDetail(adminUser.admin_token,adminUser.admin_id,id);
        if(res.result){
            setInfo(res.data);
        }
    }
    
    // input 데이터 변경
    const valueChange = (name:string, val:any)=>{
        setInfo((obj:any)=>({
            ...obj,
            [name]: val,
        }))
    }

    // 답변
    const answerAction = async() =>{
        if(info.inquiry_answer === ''){
            alert('답변을 입력해주세요.');
            return;
        }
        const res = await apiAdmSetInquiryAnswer(adminUser.admin_token,adminUser.admin_id,info);
        if(res.result){
            alert('답변을 작성했습니다.');
            navigate(-1);
        }else{
            alert(res.error);
        }
    }

    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={18} mFontSize={16} fontWeight={700}>문의 답변</BasicText>
                <InnerWrap>
                    <Grid container={true} spacing={4}>
                        <Grid item md={6} xs={12}>
                            <AdmInquiryPart info={info} valueChange={valueChange} type='답변' />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <AdminInquiryAnswerPart info={info} valueChange={valueChange}/>
                            {info.inquiry_answer_timestamp === '0' &&
                                <Row style={{justifyContent: 'end', marginTop: '30px'}}>
                                    <AdmBtnItem onClick={answerAction} bg={addTheme.admBtn2} padding='12px 24px'>답변 작성</AdmBtnItem>
                                </Row>
                            }
                        </Grid>
                    </Grid>
                </InnerWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    margin-top: 30px;
`;
