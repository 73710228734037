import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { AdmBtnItem, AdminWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import AdmProductInternetPart from '../part/AdmProductInternetPart';
import { useParams } from 'react-router';
import { LIST } from '../data/dummy';
import AdmProductTvPart from '../part/AdmProductTvPart';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { apiAdmGetProductDetail, apiAdmSetProductCctvUpdate, apiAdmSetProductInternetUpdate, apiAdmSetProductPhoneUpdate, apiAdmSetProductStatus, apiAdmSetProductTvUpdate, STATUS_CHANGE } from '../../../../api/api_adm_product';
import { addTheme } from '../../../../style/addTheme';
import AdmProductCctvPart from '../part/AdmProductCctvPart';
import AdmProductPhonePart from '../part/AdmProductPhonePart';

export default function AdmProductUpdatePage() {
    const {type,code} = useParams();
    const item = LIST.filter((data:any)=>data.id == type);
    const name = item[0]?.name === undefined ? '' : item[0]?.name;
    const [info,setInfo] = useState<any>({});
    const adminUser = useAdminUser();

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        if(code === undefined) return;
        getInfo();
    },[code,adminUser.admin_id])

    const getInfo = async() =>{
        if(item.length === 0) return;
        const res = await apiAdmGetProductDetail(adminUser.admin_token,adminUser.admin_id,item[0].type,code);
        if(res.result){
            if(res.data.length === undefined){
                setInfo(res.data);
            }else{
                setInfo(res.data[0]);
            }
        }
    }

    // 수정
    const btnAction = async() =>{
        if(window.confirm('수정하시겠습니까?')){
            if(type === '1'){
                const res = await apiAdmSetProductInternetUpdate(adminUser.admin_token,adminUser.admin_id,'adminProductInfoEdit',info);
                if(res.result){
                    alert(`수정했습니다.`);
                    location.reload();
                }else{
                    alert(res.error);
                }
            }else if(type === '2'){
                const res = await apiAdmSetProductTvUpdate(adminUser.admin_token,adminUser.admin_id,'adminProductInfoEdit',info);
                if(res.result){
                    alert(`수정했습니다.`);
                    location.reload();
                }else{
                    alert(res.error);
                }
            }else if(type === '3'){
                const res = await apiAdmSetProductPhoneUpdate(adminUser.admin_token,adminUser.admin_id,'adminProductInfoEdit',info);
                if(res.result){
                    alert(`수정했습니다.`);
                    location.reload();
                }else{
                    alert(res.error);
                }
            }else if(type === '4'){
                const res = await apiAdmSetProductCctvUpdate(adminUser.admin_token,adminUser.admin_id,'adminProductInfoEdit',{...info,product_category_code:'05'});
                if(res.result){
                    alert(`수정했습니다.`);
                    location.reload();
                }else{
                    alert(res.error);
                }
            }
        }
    }

    // 활성 / 비활성
    const statusChange = async()=>{
        if(info?.product_visible === undefined) return;
        if(window.confirm(`${STATUS_CHANGE[info.product_visible].resTxt ?? ''} 하시겠습니까?`)){
            const res = await apiAdmSetProductStatus(adminUser.admin_token,adminUser.admin_id,item[0].type,info?.product_code,info.product_visible);
            if(res.result){
                alert(`${STATUS_CHANGE[info.product_visible].resTxt ?? ''} 되었습니다`);
                getInfo();
            }else{
                alert(res.error);
            }
        }
    }

    // input 변경
    const valueChange = (name:string,val:any) =>{
        if(name.includes('product_combine_internet_router_price')){
            // tv router 가격
            const [rest,index] = name.split('-');
            const newList = info?.product_combine_internet_router_price.map((data:any,idx:any)=> (idx == index ? {...data,router_price:val} : data))
            setInfo((obj:any)=>({...obj,['product_combine_internet_router_price']:newList}));
        }else if(name.includes('product_combine_cctv_gap_price')){
            // cctv 가격
            const [rest,index] = name.split('-');
            if(info?.product_combine_cctv_gap_price === undefined){
                setInfo((obj:any)=>({...obj,['product_combine_cctv_gap_price']:['']}));
            }else{
                const newList = info?.product_combine_cctv_gap_price.map((data:any,idx:any)=> (idx == index ? val : data))
                setInfo((obj:any)=>({...obj,['product_combine_cctv_gap_price']:newList}));
            }
        }else if(name.includes('product_combine_internet_landline_device_price')){
            // 전화 기기값
            const [rest,index] = name.split('-');
            const newList = info?.product_combine_internet_landline_device_price.map((data:any,idx:any)=> (idx == index ? {...data,landline_device_price:val} : data))
            setInfo((obj:any)=>({...obj,['product_combine_internet_landline_device_price']:newList}));
        }else{
            setInfo((obj:any)=>({...obj,[name]:val}));
        }
    }
    
    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={18} mFontSize={16} fontWeight={700}>기본 데이터 수정-{name}</BasicText>
                <InnerWrap>
                    {type === '1' &&
                        <AdmProductInternetPart info={info} valueChange={valueChange} />
                    }
                    {type === '2' &&
                        <AdmProductTvPart info={info} valueChange={valueChange} />
                    }
                    {type === '3' &&
                        <AdmProductPhonePart info={info} valueChange={valueChange} />
                    }
                    {type === '4' &&
                        <AdmProductCctvPart info={info} valueChange={valueChange} setInfo={setInfo}/>
                    }
                </InnerWrap>
                <Row style={{marginTop:'30px',gap:'15px'}}>
                    <AdmBtnItem bg={addTheme.admBtn4} padding='10px 25px' onClick={btnAction}>수정</AdmBtnItem>
                    <AdmBtnItem bg={addTheme.admBtn1} padding='10px 25px' onClick={statusChange}>{STATUS_CHANGE[info?.product_visible]?.resTxt ?? ''}</AdmBtnItem>
                </Row>
            </AdminWrap>
           
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    width: 100%;
    max-width: 640px;
    margin-top: 30px;
`;