import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_SELECT_DATA= 'SUBOX/SET_SELECT_DATA';

export const globalSelectDataCreator = createAction(SET_SELECT_DATA);

export function setGlobalSelectData(data: any): ThunkAction<void, RootState, null, ReturnType<typeof globalSelectDataCreator>> {
    return async function (dispatch) {
    try {
      dispatch(globalSelectDataCreator({
        data: data
      }));
    } catch (error) {
      
    }
  };
}

export interface SelectDataState {
  tel: any;
  internet: any;
  tv: any;
  landline: any;
  place: any;
  totalPay: number;
  calc: any;
  bundle_code: string;
}

const initialState: SelectDataState = {
  tel:{},
  internet: {},
  tv: {},
  landline: {},
  place:{},
  totalPay: 0,
  calc:{},
  bundle_code: '',
};

export const globalSelectData = handleActions<SelectDataState, any>({
  [SET_SELECT_DATA]: (state, action: ReturnType<typeof globalSelectDataCreator>) => ({
    tel: action.payload.data.tel ?? state.tel,
    internet: action.payload.data.internet ?? state.internet,
    tv: action.payload.data.tv ?? state.tv,
    landline: action.payload.data.landline ?? state.landline,
    place: action.payload.data.place ?? state.place,
    totalPay: action.payload.data.totalPay ?? state.totalPay,
    calc: action.payload.data.calc ?? state.calc,
    bundle_code: action.payload.data.bundle_code ?? state.bundle_code,
  }),
}, initialState);
