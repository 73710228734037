export interface ThemeProps {
    theme: 'dark' | 'bright'
}

export const theme = (theme: string) => {
    const themeTotal = {
        'dark': {
            fixblack: '#000000',
            fixwhite: '#ffffff',
            black: '#000000',
            white: '#ffffff',
            red: 'red',
            signalRed: '#eb493d',
            blue: '#0071BC',
            main: '#ff7500',
            main2: '#00cf00',
            submain: '#ebd0f5',
            subtext: '#afb3c4',
            decrease: '#faad14',
            lightBlack: '#111',
            btnBg: '#3e3e3e',
            timeBg: '#616161',
            cancel: '#cd373c',
            dots:'#a8a8a8',
            searchBorder: '#eee',
            myPageBox:'#222226',
            myPageSc:'#3d3d44',
        },
        'bright': {
            fixblack: '#000000',
            fixwhite: '#ffffff',
            black: '#ffffff',
            white: '#000000',
            red: 'red',
            signalRed: '#eb493d',
            blue: '#0071BC',
            main: '#ff7500',
            main2: '#00cf00',
            submain: '#ebd0f5',
            subtext: '#afb3c4',
            decrease: '#faad14',
            lightBlack: '#111',
            btnBg: '#3e3e3e',
            timeBg: '#616161',
            cancel: '#cd373c',
            dots:'#a8a8a8',
            searchBorder: '#eee',
            myPageBox:'#222226',
            myPageSc:'#3d3d44',
        }
    };
    if(theme === 'dark'){
        return themeTotal.dark;
    } else if (theme === 'bright'){
        return themeTotal.bright;
    } else {
        return themeTotal.dark;
    }
};
