import { BrowserRouter, Route, Routes} from 'react-router-dom';
import ScrollToTop from '../utils/custom-hooks/common/ScrollToTop';
import MainPage from '../components/main/page/MainPage';
import QnaPage from '../components/Qna/page/QnaPage';
import ReviewCreatePage from '../components/review/page/ReviewCreatePage';
import BenefitInquiryPage from '../components/benefitInquiry/page/BenefitInquiryPage';
import MyPage from '../components/mypage/page/MyPage';
import HomePage from '../components/home/page/HomePage';
import MultiLinePage from '../components/multiLine/page/MultiLinePage';
import PartnerPage from '../components/partner/page/PartnerPage';
import PartnerLoginPage from '../components/partner/page/PartnerLoginPage';
import PartnerJoinPage from '../components/partner/page/PartnerJoinPage';
import ApplicationFormPage from '../components/applicationForm/page/ApplicationFormPage';
import { apiGetCompanyInfo } from '../api/api_main';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setGlobalCompanyInfo } from '../store/common/globalCompanyInfo';
import AdmLoginPage from '../components/admin/login/page/AdmLoginPage';
import AdmHomePage from '../components/admin/home/page/AdmHomePage';
import AdmLayoutPage from '../components/admin/layout/page/AdmLayoutPage';
import AdmOrderPage from '../components/admin/order/page/AdmOrderPage';
import AdmProductPage from '../components/admin/product/page/AdmProductPage';
import AdmReviewPage from '../components/admin/review/page/AdmReviewPage';
import AdmLinkPage from '../components/admin/link/page/AdmLinkPage';
import AdmFaqPage from '../components/admin/faq/page/AdmFaqPage';
import AdmFaqUpdatePage from '../components/admin/faq/page/AdmFaqUpdatePage';
import AdmFaqCateUpdatePage from '../components/admin/faq/page/AdmFaqCateUpdatePage';
import AdmPopupPage from '../components/admin/popup/page/AdmPopupPage';
import AdmBannerPage from '../components/admin/banner/page/AdmBannerPage';
import AdmPopupUpdatePage from '../components/admin/popup/page/AdmPopupUpdatePage';
import AdminBannerUpdatePage from '../components/admin/banner/page/AdmBannerUpdatePage';
import AdmReviewUpdatePage from '../components/admin/review/page/AdmReviewUpdatePage';
import AdmProductAddPage from '../components/admin/product/page/AdmProductAddPage';
import AdmInquiryPage from '../components/admin/inquiry/page/AdmInquiryPage';
import AdmInquiryAnswerPage from '../components/admin/inquiry/page/AdmInquiryAnswerPage';
import AdmInquiryUpdatePage from '../components/admin/inquiry/page/AdmInquiryUpdatePage';
import AdmPointPage from '../components/admin/point/page/AdmPointPage';
import AdmPartnerPage from '../components/admin/partner/page/AdmPartnerPage';
import InquiryCreatePage from '../components/Qna/page/InquiryCreatePage';
import InquiryDetailPage from '../components/Qna/page/InquiryDetailPage';
import AdmProductUpdatePage from '../components/admin/product/page/AdmProductUpdatePage';
import AdmPointSettingPage from '../components/admin/point/page/AdmPointSettingPage';
import AdmPointSendPage from '../components/admin/point/page/AdmPointSendPage';
import AdmPartnerUpdatePage from '../components/admin/partner/page/AdmPartnerUpdatePage';
import AdmProductBundleAddPage from '../components/admin/product/page/AdmProductBundleAddPage';
import AdmInquiryProductAnswerPage from '../components/admin/inquiry/page/AdmInquiryProductAnswerPage';
import AdmOrderUpdatePage from '../components/admin/order/page/AdmOrderUpdatePage';
import AdmPointWithdrawalPage from '../components/admin/point/page/AdmPointWithdrawalPage';
import ReviewPage from '../components/review/page/ReviewPage';
import PartnerMyPage from '../components/partner/page/PartnerMyPage';
import ApplicationDonePage from '../components/applicationForm/page/ApplicationDonePage';
import PartnerWithdrawalPage from '../components/partner/page/PartnerWithdrawalPage';
import PartnerWithdrawalChkPage from '../components/partner/page/PartnerWithdrawalChkPage';
import TermsUsePage from '../components/terms/page/TermsUsePage';
import TermsPersonalPage from '../components/terms/page/TermsPersonalPage';
import TermsMallPage from '../components/terms/page/TermsMallPage';
import AdmProductBundleUpdatePage from '../components/admin/product/page/AdmProductBundleUpdatePage';

export default function Router(){
    const dispatch = useDispatch();

    useEffect(()=>{
        getCompanyInfo();
    },[]);

    const getCompanyInfo = async() =>{
        const res = await apiGetCompanyInfo();
        if(res.data){
            dispatch(setGlobalCompanyInfo({...res.data}));
        }
    }
    
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <ScrollToTop />
            <Routes>
                <Route path='/' element={<MainPage />} />
                <Route path='/home/:tel?' element={<HomePage />} />
                <Route path='/multiLine' element={<MultiLinePage />} />
                <Route path='/faq' element={<QnaPage tab='1'/>} />
                <Route path='/inquiry/:page?' element={<QnaPage tab='2'/>} />
                <Route path='/inquiryCreate' element={<InquiryCreatePage />} />
                <Route path='/inquiryDetail/:id?' element={<InquiryDetailPage />} />
                <Route path='/review/:page?' element={<ReviewPage />}></Route>
                <Route path='/reviewCreate' element={<ReviewCreatePage />}></Route>
                <Route path='/benefitInquiry' element={<BenefitInquiryPage />}></Route>
                <Route path='/myPage' element={<MyPage />} />
                <Route path='/partner' element={<PartnerPage />} />
                <Route path='/partnerLogin' element={<PartnerLoginPage />} />
                <Route path='/partnerJoin' element={<PartnerJoinPage />} />
                <Route path='/partnerMyPage/:type?/:page?' element={<PartnerMyPage />} />
                <Route path='/partnerWithdrawal' element={<PartnerWithdrawalPage />} />
                <Route path='/partnerWithdrawalChk' element={<PartnerWithdrawalChkPage />} />
                <Route path='/applicationForm/:code/:type?' element={<ApplicationFormPage />} />
                <Route path='/ApplicationDone' element={<ApplicationDonePage />} />
                <Route path='/termsUse' element={<TermsUsePage />} />
                <Route path='/termsPersonal' element={<TermsPersonalPage />} />
                <Route path='/termsMall' element={<TermsMallPage />} />
                <Route path='/admLogin' element={<AdmLoginPage />} />
                <Route path='/adm' element={<AdmLayoutPage />}>
                    <Route path='' element={<AdmHomePage />} />
                    <Route path='order/:page' element={<AdmOrderPage />} />
                    <Route path='orderUpdate/:code' element={<AdmOrderUpdatePage />} />
                    <Route path='product/:top?/:bottom?/:page?' element={<AdmProductPage />} />
                    <Route path='productAdd/:type' element={<AdmProductAddPage/>} />
                    <Route path='productUpdate/:type/:code' element={<AdmProductUpdatePage/>} />
                    <Route path='productBundleAdd/:type' element={<AdmProductBundleAddPage/>} />
                    <Route path='productBundleUpdate/:type/:code' element={<AdmProductBundleUpdatePage/>} />
                    <Route path='partner/:page' element={<AdmPartnerPage/>} />
                    <Route path='partnerUpdate/:code' element={<AdmPartnerUpdatePage/>} />
                    <Route path='point/:menu/:page' element={<AdmPointPage/>} />
                    <Route path='pointSetting' element={<AdmPointSettingPage/>} />
                    <Route path='pointSend' element={<AdmPointSendPage/>} />
                    <Route path='pointWithdrawal/:id' element={<AdmPointWithdrawalPage/>} />
                    <Route path='review/:page' element={<AdmReviewPage />} />
                    <Route path='reviewUpdate/:id?' element={<AdmReviewUpdatePage />} />
                    <Route path='inquiry/:menu/:page' element={<AdmInquiryPage />} />
                    <Route path='inquiryAnswer/:id' element={<AdmInquiryAnswerPage />} />
                    <Route path='inquiryUpdate' element={<AdmInquiryUpdatePage />} />
                    <Route path='inquiryProductAnswer/:id' element={<AdmInquiryProductAnswerPage />} />
                    <Route path='faq' element={<AdmFaqPage />} />
                    <Route path='faqUpdate/:id?' element={<AdmFaqUpdatePage />} />
                    <Route path='faqCateUpdate/:id?' element={<AdmFaqCateUpdatePage />} />
                    <Route path='banner/:page' element={<AdmBannerPage />} />
                    <Route path='bannerUpdate/:id?' element={<AdminBannerUpdatePage />} />
                    <Route path='popup/:page' element={<AdmPopupPage />} />
                    <Route path='popupUpdate/:id?' element={<AdmPopupUpdatePage />} />
                    <Route path='link' element={<AdmLinkPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}