import React, { useState } from 'react'
import styled from 'styled-components'
import { Center, Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { Checkbox, FormControlLabel } from '@mui/material';
import { addTheme } from '../../../style/addTheme';
import { TERMS } from '../data/dummy';

const Item = ({data,setTerms}:any) =>{
    const [open,setOpen] = useState(false);
    const chkClick = () =>{
        setTerms((arr:any)=>{
            return arr.map((item:any)=>item.id === data.id ? {...item,state: !data.state} : item)
        })
    }
    
    return(
        <div style={{width: '100%'}}>
            <ItemWrap>
                <LeftWrap>
                    <BasicText fontSize={18} mFontSize={16}>{data.id}.{data.name}</BasicText>
                    <BasicText onClick={()=>setOpen(!open)} fontSize={13} color='#969696' deco='underline'>자세히 보기 {open === true ? '닫기' : ''}</BasicText>
                </LeftWrap>
                <RightWrap>
                    <FormControlLabel required sx={{'& .MuiFormControlLabel-asterisk':{display:'none'},'& .MuiCheckbox-root':{padding:'4px'}}} control={<Checkbox sx={{color:addTheme.color,'&.Mui-checked': {color:addTheme.color}}} checked={data.state} onChange={()=>{chkClick()}} />} label="동의" />
                </RightWrap>
            </ItemWrap>
            {open &&
                <BasicText fontSize={15} lh={1.3} wh={'pre-wrap'}>{data.content}</BasicText>
            }
        </div>

    )
}

export default function InputTermsPart() {
    const [terms,setTerms] = useState(TERMS);

    return (
        <Wrap>
            <BasicText fontWeight={700} fontSize={18}>약관 및 동의</BasicText>
            <ContentWrap>
                {terms.map((data:any,index:any)=>{
                    return(
                        <Item key={`terms-${index}`} data={data} setTerms={setTerms} />
                    )
                })}
            </ContentWrap>
            <BtnItem type='submit'>신청하기</BtnItem>
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 80px;
`;
const ContentWrap = styled(Row)`
    flex-direction: column;
    gap: 5px;
    margin-top: 15px;
    align-items: start;
`;

const ItemWrap = styled(RowBetween)`
    gap: 10px;
`;
const LeftWrap = styled(Row)`
    width: auto;
    gap: 6px;
`;
const RightWrap = styled.div`
    flex-shrink: 0;
`;

const BtnItem = styled.button`
    margin-top: 45px;
    background-color: ${addTheme.color};
    color: #fff;
    border-radius: 4px;
    height: 52px;
    cursor: pointer;
    width: 100%;
`;