import React from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, RowBetween, SectionWrap } from '../../../style/basic/commonStyle'
import { addTheme } from '../../../style/addTheme'
import TitlePart from '../../common/part/TitlePart'
import BasicText from '../../basic/BasicText'
import { numberWithComma } from '../../../utils/format/number'
import BtnItem from '../item/BtnItem'
import { useNavigate } from 'react-router'

const LIST = [
    {id: 2,name: 'lg', img: 'img/asset/lg.png', payment: [{name:'인터넷', pay: 16500},{name:'인터넷 + TV 베이직', pay: 33000},{name:'인터넷 + TV 프리미엄', pay: 37400},{name:'인터넷 + TV 프리미엄 라이트', pay: 45500},]},
    {id: 1,name: 'kt', img: 'img/asset/kt.png', payment: [{name:'인터넷', pay: 16500},{name:'인터넷 + TV 베이직', pay: 33000},{name:'인터넷 + TV 라이트', pay: 34100},{name:'인터넷 + TV 에센스', pay: 37400},]},
    {id: 3,name: 'sk', img: 'img/asset/sk.png', payment: [{name:'인터넷', pay: 17600},{name:'인터넷 + 이코노미', pay: 30800},{name:'인터넷 + Btv 스탠다드', pay: 34100},{name:'인터넷 + Btv 올', pay: 37400},]},
]

export default function HomeInternetPart() {
    const navigate = useNavigate();
    const goPage = (path:string) =>{
        navigate(path)
    }
    return (
        <SectionWrap bg={addTheme.section_color}>
            <MaxWidthWrap>
                <Wrap>
                    <TitlePart title='홈 인터넷' desc='비교는 손쉽게 클릭 한 번으로<br/>혜택은 언제나 최고로' />
                    <ContentWrap>
                        {LIST.map((data:any,index:any)=>{
                            return(
                                <ContentItemWrap key={`internet-${index}`}>
                                    <img alt={`${data.name}-logo`} src={data.img}></img>
                                    <PaymentWrap>
                                        {data.payment.map((item:any,idx:any)=>{
                                            return(
                                                <div key={`${index}-payment-${idx}`}>
                                                    <BasicText align='center'>{item.name}</BasicText>
                                                    <BasicText align='center' fontSize={18} fontWeight={700}>{numberWithComma(item.pay)}원</BasicText>
                                                </div>
                                            )
                                        })}
                                    </PaymentWrap>
                                    <PaymentBtnItem onClick={()=>goPage(`/home/${data.id}`)}>최대 지원금 확인하기</PaymentBtnItem>
                                </ContentItemWrap>
                            )
                        })}
                    </ContentWrap>
                    <BtnItem title='전체 비교하기' clickEvent={()=>goPage('/home/1')}/>
                </Wrap>
            </MaxWidthWrap>
        </SectionWrap>        
    )
}

const Wrap = styled.div``;
const ContentWrap = styled(Center)`
    margin-top: 30px;
    gap: 30px;
    &>div{
        width: 30%;
    }
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
        &>div{
            width: 100%;
        }
    }
`;
const ContentItemWrap = styled(Center)`
    flex-direction: column;
    padding: 20px 10px 10px;
    background-color: #fff;
    border-radius: 10px;
    & img{
        height: 38px;
    }
`;

const PaymentWrap = styled(Center)`
    flex-direction: column;
    margin-top: 20px;
    gap: 15px;
`;

const PaymentBtnItem = styled(Center)`
    border: 1px solid ${addTheme.color};
    color: ${addTheme.color};
    font-size: 17px;
    border-radius: 30px;
    margin-top: 25px;
    width: 86%;
    height: 46px;
    cursor: pointer;
`;