import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LayoutPage from '../../common/page/LayoutPage';
import BasicText from '../../basic/BasicText';
import { Center, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import FormInfoPart from '../part/FormInfoPart';
import InputUserInfoPart from '../part/InputUserInfoPart';
import InputInstallPart from '../part/InputInstallPart';
import InputBenefitPart from '../part/InputBenefitPart';
import InputPayerPart from '../part/InputPayerPart';
import InputRequestPart from '../part/InputRequestPart';
import InputTermsPart from '../part/InputTermsPart';
import InputReferralPart from '../part/InputReferralPart';
import { useNavigate, useParams } from 'react-router';
import { apiGetBundleDetail, apiSetOrder } from '../../../api/api_home';
import validator from 'validator';
import { useGlobalSelectData } from '../../../utils/custom-hooks/common/useGlobalSelectData';

const ORDER_BASIC ={
    order_request_comment: '',
}

const ORDER_ORDERER = {
    order_orderer_type: 1,
    phone1_tel: 'SKT',
    phone2_tel: 'SKT',
    order_orderer_business_name: '',
    order_orderer_business_number: '',
    order_orderer_add_phone_number: '',
}

const ORDER_FREEGIFT = {
    order_freegift_relation: 1,
    order_freegift_bank: 'NH농협',
}
const ORDER_PAYMENT = {
    order_payment_relation: 1,
    order_payment_method: 1,
    order_payment_bank_name: 'NH농협',
}
export default function ApplicationFormPage() {
    const navigate = useNavigate();
    const {code,type} = useParams();
    const selectData = useGlobalSelectData();
    const [detail,setDetail] = useState();
    const [order_basic,setOrder_basic] = useState<any>(ORDER_BASIC); //	주문 기본 정보
    const [order_orderer,setOrder_orderer] = useState<any>(ORDER_ORDERER); // 주문자 정보
    const [order_freegift,setOrder_freegift] = useState<any>(ORDER_FREEGIFT); // 사은품 정보
    const [order_payment,setOrder_payment] = useState<any>(ORDER_PAYMENT); // 납부자 정보
    
    useEffect(()=>{
        if(code === undefined || code === null || code === '') return;
        getDetail();
        setOrder_basic((obj:any)=>({...obj,bundle_code:code}));
    },[code]);

    useEffect(()=>{
        if(type === 'multi'){
            if(selectData.place?.bundle_name === undefined){
                alert('만료된 정보입니다.');
                navigate('/');
                return;
            }
            if(selectData.tel?.company_code !== undefined){
                setDetail((obj:any)=>({...obj,info:{company_code:selectData.tel?.company_code}}))
            }
        }
    },[type,selectData.place?.bundle_name,selectData.tel?.company_code])

    const getDetail = async() =>{
        if(code === undefined || code === null || code === '') return;
        const res = await apiGetBundleDetail(code);

        if(res.result){
            setDetail(res.data);
            setOrder_basic((obj:any)=>({...obj,order_total_price:res.data.calc.combine_price}));
            setOrder_freegift((obj:any)=>({...obj,order_freegift_price:res.data.calc.freegift_price}))
        }else{
            setOrder_basic((obj:any)=>({...obj,order_total_price:0}))
            setOrder_freegift((obj:any)=>({...obj,order_freegift_price:0}))
        }
    }
    
    const valueChange = (objName:string,name:string,val:any) =>{
        if(objName === 'basic'){
            setOrder_basic((obj:any)=>({...obj,[name]:val}));
        }
        if(objName === 'orderer'){
            setOrder_orderer((obj:any)=>({...obj,[name]:val}));
        }
        if(objName === 'freegift'){
            setOrder_freegift((obj:any)=>({...obj,[name]:val}));
        }
        if(objName === 'payment'){
            setOrder_payment((obj:any)=>({...obj,[name]:val}));
        }
    }

    // 폰번호
    useEffect(()=>{
        if(order_orderer?.phone1 === undefined || order_orderer?.phone1 === '') return;
        const order_orderer_phone_number = `${order_orderer?.phone1_tel ?? ''}/${order_orderer?.phone1 ?? ''}`;
        setOrder_orderer((obj:any)=>({...obj,order_orderer_phone_number:order_orderer_phone_number}))
    },[order_orderer?.phone1_tel, order_orderer?.phone1]);
    useEffect(()=>{
        if(order_orderer?.phone2 === undefined || order_orderer?.phone2 === '') return;
        const order_orderer_add_phone_number = `${order_orderer?.phone2_tel ?? ''}/${order_orderer?.phone2 ?? ''}`;
        setOrder_orderer((obj:any)=>({...obj,order_orderer_add_phone_number:order_orderer_add_phone_number}))
    },[order_orderer?.phone2_tel, order_orderer?.phone2]);

    // 가입자 정보와 동일 클릭시
    const freegiftToPayment = () =>{
        setOrder_payment((obj:any)=>({
            ...obj,
            order_payment_relation: order_freegift?.order_freegift_relation ?? 1,
            order_payment_name: order_freegift?.order_freegift_name ?? '',
            order_payment_resident_number: order_freegift?.order_freegift_resident_number ?? '',
            order_payment_bank_name: order_freegift?.order_freegift_bank ?? 'NH농협',
            order_payment_account_number: order_freegift?.order_freegift_account_number ?? '',
        }))
    }

    const orderAction = async(e:any) =>{
        e.preventDefault();
        const emailChk = validator.isEmail(order_orderer.order_orderer_email);
        if(!emailChk){
            alert('이메일을 확인해주세요.');
            return;
        }
        const res = await apiSetOrder(order_basic,order_orderer,order_freegift,order_payment);
        if(res.result){
            navigate('/applicationDone');
        }
    }
    
    return (
        <LayoutPage>
            <Wrap>
                <MaxWidthWrap>
                    <BasicText fontSize={36} mFontSize={22} fontWeight={700} align='center'>가입신청서</BasicText>
                    <InnerWrap>
                        <LeftWrap>
                            <FormInfoPart type={type} detail={detail} />
                        </LeftWrap>
                        <RightWrap>
                            <form onSubmit={orderAction}>
                                <InputUserInfoPart order_basic={order_basic} order_orderer={order_orderer} valueChange={valueChange}/>
                                <InputInstallPart order_orderer={order_orderer} valueChange={valueChange}/>
                                <InputBenefitPart detail={detail} order_freegift={order_freegift} valueChange={valueChange} />
                                <InputPayerPart order_payment={order_payment} valueChange={valueChange} freegiftToPayment={freegiftToPayment}/>
                                <InputReferralPart order_basic={order_basic} valueChange={valueChange}/>
                                <InputRequestPart order_basic={order_basic} valueChange={valueChange}/>
                                <InputTermsPart />
                            </form>
                        </RightWrap>
                    </InnerWrap>
                </MaxWidthWrap>
            </Wrap>
        </LayoutPage>
    )
}

const Wrap = styled.div`
    margin-top: 150px;
    padding-bottom: 80px;
`;

const InnerWrap = styled(Row)`
    align-items: start;
    margin-top: 35px;
    position: relative;
    @media ${addTheme.media.m}{
        flex-wrap: wrap;
        gap: 40px 0 ;
    }
`;

const LeftWrap = styled.div`
    position: sticky;
    top: 55px;
    width: 35%;
    padding: 10px 40px;
    @media ${addTheme.media.m}{
        width: 100%;
        position: static;
        padding: 0;
    }
`;
const RightWrap = styled.div`
    width: 65%;
    text-align: center;
    border-left: 1px solid #dfdfdf;
    padding: 10px 40px;
    @media ${addTheme.media.m}{
        width: 100%;
        border: none;
        padding: 0;
    }
`;
