import React from 'react'
import styled from 'styled-components'
import { Center, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { FaPhone } from "react-icons/fa6";
import { addTheme } from '../../../style/addTheme';
import { useGlobalCompanyInfo } from '../../../utils/custom-hooks/common/useGlobalCompanyInfo';

export default function NumberItem({textSize,numSize}:any) {
    const globalCompany = useGlobalCompanyInfo();
    const phoneClick = () =>{
        if(globalCompany['config_company_call_number'] === undefined) return;
        window.location.href = 'tel:' + globalCompany['config_company_call_number'];
    }
    return (
        <Wrap onClick={phoneClick}>
            <TextWrap>
                <IconWrap bg={addTheme.color }>
                    <FaPhone color={'#fff'} size={8} />
                </IconWrap>
                <BasicText fontSize={textSize ?? 12} color={'#000'}>현주친구 상담문의</BasicText>
            </TextWrap>
            <BasicText fontWeight={700} fontSize={numSize ?? 26} color={'#000'}>1533-9360</BasicText>
        </Wrap>
    )
}

const Wrap = styled.div`
    cursor: pointer;
`;
const TextWrap = styled(Row)`
    width: auto;
    gap: 6px;
`;
const IconWrap = styled(Center)<{bg:string}>`
    background-color: ${({bg}) => bg};
    width: 16px;
    height: 16px;
    border-radius: 50px;
    overflow: hidden;
`;