import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AdminWrap, Center, ContentWrap, InputWrap, ItemWrap, Row, AdmBtnItem } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { addTheme } from '../../../../style/addTheme';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { onlyNumber } from '../../../../utils/format/string';
import { numberWithComma } from '../../../../utils/format/number';
import { apiAdmSetPointSend } from '../../../../api/api_adm_point';
import { apiAdmGetPartnerSearchList } from '../../../../api/api_adm_partner';
import OpenSendSearchPart from '../part/OpenSendSearchPart';

const INIT_SEARCH = {
    input: '',
    res: '',
}

export default function AdmPointSendPage() {
    const adminUser = useAdminUser();
    const [open,setOpen] = useState(false);
    const [search,setSearch] = useState(INIT_SEARCH);
    const [searchList,setSearchList] = useState<any>([]);
    const [searchPage,setSearchPage] = useState(1);
    const [searchTotal,setSearchTotal] = useState(0);

    const [info,setInfo] = useState({
        sendPoint: '',
        code: '',
    })

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        getList();
    },[adminUser.admin_id, search.res])

    // 검색 결과 리스트
    const getList = async() =>{
        const res = await apiAdmGetPartnerSearchList(adminUser.admin_token,adminUser.admin_id,searchPage,10,search.res);
        if(res.result){
            setSearchList(res.data);
            setSearchTotal(res.total);
        }
    }

    // 검색 버튼
    const searchAction = async(e:any) =>{
        e.preventDefault();
        setSearch((obj:any)=>({...obj,['res']:obj.input}));
        setSearchPage(1);
        setOpen(true);
    }

    const valueChange = (name:string,val:any) =>{
        setInfo((obj:any)=>({...obj,[name]:val}));
    }
    
    // 전송
    const btnAction = async() =>{
        if(info.code === ''){
            alert('파트너 코드를 입력해주세요.');
            return
        };
        if(info.sendPoint === ''){
            alert('전송할 포인트를 입력해주세요.');
            return;
        }
        if(window.confirm('포인트를 전송하시겠습니까?')){
            const res = await apiAdmSetPointSend(adminUser.admin_token,adminUser.admin_id,info.sendPoint,info.code);
            if(res.result){
                alert('포인트를 전송했습니다.');
                setSearch(INIT_SEARCH);
                setInfo({
                    sendPoint: '',
                    code: '',
                })
            }
        }
    }

    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={18} mFontSize={16} fontWeight={700}>포인트 전송</BasicText>
                <InnerWrap>
                    <ContentWrap>
                        <SearchWrap onSubmit={searchAction}>
                            <InputWrap>
                                <input value={search.input} onChange={(e:any)=>setSearch((obj:any)=>({...obj,['input']:e.target.value}))} placeholder='이름, 코드로 검색이 가능합니다'/>
                            </InputWrap>
                            <SearchBtnWrap>검색</SearchBtnWrap>
                        </SearchWrap>
                        <ItemWrap pWidth={120}>
                            <BasicText>파트너 코드</BasicText>
                            <InputWrap>
                                <input onChange={(e:any)=>valueChange('code',e.target.value)} value={info.code} placeholder='전송할 파트너의 코드를 입력해주세요'/>
                            </InputWrap>
                        </ItemWrap>
                        <ItemWrap pWidth={120}>
                            <BasicText>전송 포인트</BasicText>
                            <InputWrap>
                                <input onChange={(e:any)=>valueChange('sendPoint',onlyNumber(e))} value={numberWithComma(info.sendPoint)} placeholder='전송할 포인트 양을 입력해주세요'/>
                            </InputWrap>
                        </ItemWrap>
                    </ContentWrap>
                </InnerWrap>
                <Row style={{marginTop:'20px',gap:'15px'}}>
                    <AdmBtnItem bg={addTheme.admBtn2} padding='10px 25px' onClick={btnAction}>포인트 전송</AdmBtnItem>
                </Row>
            </AdminWrap>
            <OpenSendSearchPart open={open} setOpen={setOpen} list={searchList} page={searchPage} setPage={setSearchPage} total={searchTotal} valueChange={valueChange}/>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    width: 100%;
    max-width: 640px;
    margin-top: 30px;
`;
const SearchWrap = styled.form`
    gap: 14px;
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
        gap: 6px;
    }
`;
const SearchBtnWrap = styled.button`
    flex-shrink: 0;
    background-color: #1e293b;
    color: #fff;
    height: 42px;
    width: 60px;
    border-radius: 5px;
`;
