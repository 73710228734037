import React from 'react'
import styled from 'styled-components'
import { MaxWidthWrap, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';

export default function NoticeBannerPart({selectData}:any) {
    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    <ContentWrap>
                        <TextWrap>
                            <BasicText fontWeight={700} fontSize={30} mFontSize={26}>{selectData.tel.name} 인터넷 필독사항</BasicText>
                            <BasicText fontSize={22} mFontSize={18}>딱 5분만 투자해서 아래 글을 읽어주세요!</BasicText>
                        </TextWrap>
                        <ImgWrap>
                            <img alt='notice-logo' src='/img/asset/icon10.png'></img>
                        </ImgWrap>
                    </ContentWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    padding: 40px 0;
`;
const InnerWrap = styled.div`
    background-color: #fffade;
    padding: 35px 20px;
    @media ${addTheme.media.s}{
        padding: 25px 15px;
    }
`;

const ContentWrap = styled(RowBetween)`
    justify-content: space-around;
    gap: 20px;
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
        &>div{
            width: 100%;
            text-align: center;
            >p{
                text-align: center !important;
            }
        }
    }
`;
const TextWrap = styled.div``;
const ImgWrap = styled.div`
    & img{
        width: 100%;
        max-width: 120px;
    }
`;