import CryptoJS from 'crypto-js'

export const walletMiddleSubstring = (address: string) => {
    const startString = address.substr(0, 7);
    const endString = address.substr(-7);

    return `${startString}...${endString}`;
}

export const hashMiddleClampSubstring = (hash: string) => {
    const startString = hash.substr(0, 10);
    const endString = hash.substr(-10);

    return `${startString}...${endString}`;
}

export const subStringToStart = (text: string) => {
    return `${text.substring(0, 13)}...`;
}

export const subStringToStartCount = (text: string, count: number) => {
    return `${text.substring(0, count)}...`;
}

export const subStringToStartCountNoDots = (text: string, count: number) => {
    if(text.length > count){
        return `${text.substring(0, count)}...`;
    } else {
        return `${text.substring(0, count)}`;
    }
}


export const hashMiddleClampSubstringCount = (hash: string, count: number) => {
    const startString = hash.substr(0, count);
    const endString = hash.substr(-count);

    return `${startString}...${endString}`;
}

export const cryptoSHA256Hex = (nowDate: number) => {
    const key = 'HELLO WORLD';
    var encrypted = CryptoJS.AES.encrypt(nowDate.toString(), key);
    return encrypted.ciphertext.toString().toUpperCase();
}


export const MTCcryptoSHA256Hex = (nowDate: number) => {
    const key = 'mtcZZANG';
    var encrypted = CryptoJS.AES.encrypt(nowDate.toString(), key);
    return `MTC${encrypted.ciphertext.toString().toUpperCase()}`;
}

// url 형식인지를 체크( http, https 를 포함하는 형식 )
export function checkUrlForm(strUrl:string) {
    var expUrl = /^http[s]?\:\/\//i;
    return expUrl.test(strUrl);
}


// 파일 속성
export const fileTypeRes = (fileName:string) =>{
    const fileNameDot = fileName.lastIndexOf('.');
    const fileType = fileName.substring(fileNameDot+1,fileName.length).toLowerCase();
    return fileType;
}

// 파일 수 체크
export const fileCount = (files:any,max:number) =>{
    if(files.length > max){
        return false;
    }
    return true;
}

const KEY = 'SUBOX_MUSIC';
// 로그인 암호화
export const loginEncrypt = (str:string) =>{
    const encrypt = CryptoJS.AES.encrypt(str,KEY).toString();
    return encrypt;
}

// 로그인 복호화
export const loginDecrypt = (str:string) =>{
    const bytes = CryptoJS.AES.decrypt(str,KEY);
    const decrypt = bytes.toString(CryptoJS.enc.Utf8);
    return decrypt;
}

// 인풋 숫자만남기기
export const onlyNumber = (e:any) =>{
    let newVal = e.target.value.replace(/[^0-9-]/g, '');
    return newVal;
}

// 글자 중에서 * -> O 변경
export const textStarToCircle = (txt:string) =>{
    const newTxt = txt.replace(/\*/g, 'O');
    return newTxt;
}

// 속도 변경
export const speedChange = (txt:string) =>{
    if(txt === undefined){
        return {speed: 0, unit : ''}
    }
    let speed = Number(txt);
    let unit = 'Mbps';
    if(txt.length > 3){
        speed = Number(txt) / 1000;
        unit = 'Gbps'
    }
    return {speed,unit}
}