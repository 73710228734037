import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, SectionWrap } from '../../../style/basic/commonStyle';
import TitlePart from '../../common/part/TitlePart';
import StarItem from '../item/StarItem';
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { apiGetReviewList } from '../../../api/api_review';
import { textStarToCircle } from '../../../utils/format/string';

const LIST = [
    {id: 1, name: '김ㅇㅇ', addr: '경기도 용인', desc: '매장 인터넷 설치가 필요해서 고민했었는데 현주친구에서 친절한 상담을 받고 합리적인 비용으로 좋은 상품, 좋은 혜택으로 받은 것 같아서 매우 만족합니다.', star: 5},
    {id: 2, name: '이ㅇㅇ', addr: '경기도 용인', desc: '매장 인터넷 설치가 필요해서 고민했었는데 현주친구에서 친절한 상담을 받고 합리적인 비용으로 좋은 상품, 좋은 혜택으로 받은 것 같아서 매우 만족합니다.', star: 5},
    {id: 3, name: '박ㅇㅇ', addr: '경기도 용인', desc: '매장 인터넷 설치가 필요해서 고민했었는데 현주친구에서 친절한 상담을 받고 합리적인 비용으로 좋은 상품, 좋은 혜택으로 받은 것 같아서 매우 만족합니다.', star: 5},
    {id: 4, name: '김ㅇㅇ', addr: '경기도 용인', desc: '매장 인터넷 설치가 필요해서 고민했었는데 현주친구에서 친절한 상담을 받고 합리적인 비용으로 좋은 상품, 좋은 혜택으로 받은 것 같아서 매우 만족합니다.', star: 5},
]

const Item = ({data}:any) =>{
    return (
        <ItemWrap>
            <StarItem count={Number(data.review_rate)}/>
            <TextWrap>
                <BasicText marginTop={3} fontSize={28} mFontSize={20} align='center'>{data.review_content}</BasicText>
            </TextWrap>
            <BasicText marginTop={3} fontSize={24} mFontSize={17} align='center'>{textStarToCircle(data.review_user_name)}</BasicText>
        </ItemWrap>
    )
}

export default function ReviewPart() {
    const swiperRef = useRef<any>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [list,setList] = useState<any>([]);

    useEffect(()=>{
        getList();
    },[])

    const getList = async() =>{
        const res = await apiGetReviewList(1,4);
        if(res.result){
            setList(res.data);
        }
    }

    const handlePaginationClick = (index:any) => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slideTo(index);
            setActiveIndex(index);
        }
    };

    return (
        <SectionWrap padding='110px 0'>
            <MaxWidthWrap>
                <Wrap>
                    <TitlePart desc='고객님들의 생생한 후기를 통해<br/>신뢰할 수 있는 현주친구' />
                    <ContentWrap>
                        {list === undefined || list.length === 0 ?
                            <></> :
                            <>
                                <Swiper
                                    ref={swiperRef}
                                    onSlideChange={(swiper:any) => setActiveIndex(swiper.activeIndex)}
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    modules={[Autoplay]}
                                    autoplay={{
                                        delay: 3000,
                                        disableOnInteraction: false,
                                    }}
                                >
                                    {list.map((data:any,index:any)=>{
                                        return(
                                            <SwiperSlide key={`review-${index}`}>
                                                <Item data={data} />
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                                <BtnWrap>
                                    {list.map((data:any,index:any)=>{
                                        return(
                                            <SwiperBtnItem 
                                                key={`swiper-btn-${index}`} 
                                                onClick={()=>handlePaginationClick(index)}
                                                activeOn={activeIndex === index}
                                            />
                                        )
                                    })}
                                </BtnWrap>
                            </>
                        }
                    </ContentWrap>
                </Wrap>
            </MaxWidthWrap>
        </SectionWrap>
    )
}

const Wrap = styled.div``;
const ContentWrap = styled.div`
    margin-top: 50px;
`;

const ItemWrap = styled(Center)`
    background-color: ${addTheme.section_color};
    border-radius: 30px;
    overflow: hidden;
    padding: 55px 20px;
    flex-direction: column;
    @media ${addTheme.media.s}{
        padding: 55px 10px;
    }
`;

const TextWrap = styled(Center)`
    width: 100%;
    max-width: 680px;
`;

const BtnWrap = styled(Center)`
    margin-top: 20px;
    gap: 6px;
`;

const SwiperBtnItem = styled.div<{activeOn:boolean}>`
    width: 7px;
    height: 7px;
    background-color: ${addTheme.color};
    border-radius: 10px;
    opacity: ${({activeOn}) => activeOn ? '1' : '0.2'};
    cursor: pointer;
`;