import React, { useEffect } from 'react'
import styled from 'styled-components'
import LayoutPage from '../../common/page/LayoutPage';
import { Center, MaxWidthWrap, Row, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { useNavigate, useParams } from 'react-router';
import PartnerMyInfoPart from '../part/PartnerMyInfoPart';
import PartnerMyPointPart from '../part/PartnerMyPointPart';
import { useGlobalPartner } from '../../../utils/custom-hooks/common/useGlobalPartner';
import { useDispatch } from 'react-redux';
import { globalPartnerLogoutCreator } from '../../../store/common/globalPartner';
import { addTheme } from '../../../style/addTheme';


export default function PartnerMyPage() {
    const {type} = useParams();
    const navigate = useNavigate();
    const partner = useGlobalPartner();
    const dispatch = useDispatch();

    useEffect(()=>{
        if(partner.partner_code === ''){
            navigate('/');
        }
    },[partner.partner_code])
    
    const menuClick = (menu:string) =>{
        navigate(`/partnerMyPage/${menu}${menu === 'myPoint' ? '/1':''}`);
    }

    const logoutAction = () =>{
        if(window.confirm('로그아웃 하시겠습니까?')){
            dispatch(globalPartnerLogoutCreator());
        }
    }
    
    return (
        <LayoutPage>
            <MaxWidthWrap>
                <TitleWrap>
                    <BasicText fontSize={36} mFontSize={22} fontWeight={700} align='center'>파트너스 마이페이지</BasicText>
                </TitleWrap>
                <Wrap>

                    <MenuWrap>
                        <MenuItem onClick={()=>menuClick('myInfo')} state={type === undefined || type === 'myInfo'}>내 정보</MenuItem>
                        <MenuItem onClick={()=>menuClick('myPoint')} state={type === 'myPoint'}>포인트 내역</MenuItem>
                    </MenuWrap>
                    <ContentWrap>
                        {type === 'myPoint' ?
                            <PartnerMyPointPart /> :
                            <>
                                <PartnerMyInfoPart />
                                <Center style={{marginTop:'50px'}}>
                                    <BasicText cr='pointer' color={addTheme.color} onClick={logoutAction}>로그아웃</BasicText>
                                </Center>
                            </>
                        }
                    </ContentWrap>
                </Wrap>
            </MaxWidthWrap>
        </LayoutPage>
    )
}

const TitleWrap = styled.div`
    margin-top: 150px;
`;

const Wrap = styled.div`
    width: 100%;
    max-width: 720px;
    margin: 0 auto 50px;
`;

const MenuWrap = styled(Row)`
    margin-top: 60px;
    border-bottom: 1px solid #e5e5e5;
`;

const MenuItem = styled(Center)<{state?:boolean}>`
    border: 1px solid ${({state}) => state ? '#000':'#e5e5e5'};
    border-bottom: none;
    width: 136px;
    height: 46px;
    margin-top: -1px;
    cursor: pointer;
`;

const ContentWrap = styled.div``;