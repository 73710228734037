import React from 'react'
import styled from 'styled-components'
import { addTheme } from '../../../style/addTheme';
import { Center, MaxWidthWrap, RowBetween } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { numberWithComma } from '../../../utils/format/number';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { setGlobalSelectData } from '../../../store/common/globalSelectData';
import { useGlobalCompanyInfo } from '../../../utils/custom-hooks/common/useGlobalCompanyInfo';

export default function PayPart({selectData}:any) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const companyInfo = useGlobalCompanyInfo();

    // 바로 신청
    const goApplication = ()=>{
        dispatch(setGlobalSelectData({...selectData}));
        navigate(`/applicationForm/${selectData.bundle_code}/home`);
    }

    // 바로 상담
    const phoneClick = () =>{
        if(companyInfo['config_company_call_number'] === undefined) return;
        window.location.href = 'tel:' + companyInfo['config_company_call_number'];
    }
    return (
        <Wrap>
            <MaxWidthWrap>
                <ContentWrap>
                    <ContentItemWrap>
                        <BasicText fontWeight={700} color='#686868' fontSize={24} mFontSize={18}>결합전 요금</BasicText>
                        <BasicText fontWeight={700} color='#686868' fontSize={24} mFontSize={18}>{numberWithComma(selectData?.calc?.basic_price ?? '')}원</BasicText>
                    </ContentItemWrap>
                    <ContentItemWrap>
                        <BasicText fontWeight={700} fontSize={26} mFontSize={20}>유무선 결합 요금(추가 혜택)</BasicText>
                        <BasicText fontWeight={700} fontSize={26} mFontSize={20}>{numberWithComma(selectData?.calc?.combine_price ?? '')}원</BasicText>
                    </ContentItemWrap>
                    <ContentItemWrap>
                        <BasicText color='#686868' fontSize={17} mFontSize={15}>부가가치세, 셋톱 임대료 등 포함</BasicText>
                        <BasicText color={addTheme.color} fontSize={17} mFontSize={15}>현금 사은품 {numberWithComma(selectData?.calc?.freegift_price ?? '')}원</BasicText>
                    </ContentItemWrap>
                    <ContentItemWrap>
                        <BasicText color='#686868' fontSize={17} mFontSize={15}>추천인 코드 입력하고 추가 포인트도 꼭 챙기세요!</BasicText>
                    </ContentItemWrap>
                    <BtnWrap>
                        <BtnItem color={addTheme.color} bg='#fff' onClick={goApplication}>
                            <img alt='plus-icon' src='/img/asset/mbtn01.png'></img>
                            바로 신청
                        </BtnItem>
                        <BtnItem color='#fff' bg={addTheme.color} onClick={phoneClick}>
                            <img alt='phone-icon' src='/img/asset/mbtn02.png'></img>
                            바로 상담
                        </BtnItem>
                    </BtnWrap>
                </ContentWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #e7e7e7;
`;

const ContentWrap = styled.div`
    padding: 35px 0;
`;
const ContentItemWrap = styled(RowBetween)``;
const BtnWrap = styled(RowBetween)`
    margin-top: 20px;
    gap: 15px;
`;

const BtnItem = styled(Center)<{color:string,bg:string}>`
    width: 48%;
    color: ${({color}) => color ?? '#000'};
    background-color: ${({bg}) => bg ?? '#fff'};
    border: 1px solid ${addTheme.color};
    gap: 12px;
    height: 48px;
    font-size: 22px;
    border-radius: 5px;
    cursor: pointer;
    & img{
        width: 32px;
    }
    @media ${addTheme.media.s}{
        font-size: 18px;
    }
    & img{
        width: 26px;
    }
`;