import React, { useRef } from 'react'
import styled from 'styled-components'
import LayoutPage from '../../common/page/LayoutPage';
import MainPart from '../part/MainPart';
import ApplyPart from '../part/ApplyPart';
import HomeInternetPart from '../part/HomeInternetPart';
import StoreInternetPart from '../part/StoreInternetPart';
import OfficeInternetPart from '../part/OfficeInternetPart';
import BenefitPart from '../part/BenefitPart';
import UserListPart from '../part/UserListPart';
import PhoneNumberPart from '../part/PhoneNumberPart';
import CounselingPart from '../part/CounselingPart';
import ReviewPart from '../part/ReviewPart';

export default function MainPage() {
    const applyRef = useRef<any>(null);
    const homeBenefitClick = () =>{
        if (applyRef.current) {
            applyRef.current.scrollIntoView({ behavior: 'smooth',inline: 'start', });
        }
    }
    return (
        <LayoutPage>
            <Wrap>
                <MainPart homeBenefitClick={homeBenefitClick} />
                <div ref={applyRef} style={{padding: '75px 0'}}>
                    <ApplyPart />
                </div>
                <HomeInternetPart />
                <StoreInternetPart />
                <OfficeInternetPart />
                <BenefitPart />
                <UserListPart />
                <CounselingPart />
                <PhoneNumberPart />
                <ReviewPart />
            </Wrap>
        </LayoutPage>
    )
}

const Wrap = styled.div``;