import React, { useEffect, useState } from 'react'
import { ContentWrap, InputWrap, ItemWrap } from '../../../../style/basic/commonStyle'
import BasicText from '../../../basic/BasicText'
import SelectItem from '../../../common/item/SelectItem'
import { MenuItem } from '@mui/material'
import { onlyNumber } from '../../../../utils/format/string'
import { numberWithComma } from '../../../../utils/format/number'
import { apiGetPayment, apiGetTelList } from '../../../../api/api_home'

export default function AdmProductBundleHomePart({info,valueChange,type}:any) {
    const [telList,setTelList] = useState([]);
    const [list,setList] = useState<any>();
    
    useEffect(()=>{
        if(type === undefined || type === 0) return;
        getTelList();
    },[type]);

    useEffect(()=>{
        if(type === undefined || type === 0) return;
        if(info?.company_code === undefined || info?.company_code === '') return
        getList();
    },[info?.company_code,type])

    // 통신사 리스트
    const getTelList = async() =>{
        const res = await apiGetTelList(type);
        if(res.result){
            setTelList(res.data);
        }
    }

    // 인터넷,tv,전화 리스트
    const getList = async() =>{
        const res = await apiGetPayment(info.company_code,type);
        if(res.result){
            setList(res.data); 
        }
    }

    const telChange = (val:any) =>{
        valueChange('company_code',val);
        valueChange('product_001_code','');
        valueChange('product_002_code','');
        valueChange('product_005_code','');
    }

    return (
        <ContentWrap>
            <ItemWrap pWidth={120}>
                <BasicText>통신사</BasicText>
                {telList === undefined || telList.length === 0 ?
                    <></> :
                    <SelectItem value={info?.company_code ?? ''} setValue={(val:any)=>telChange(val)} none='no'>
                        <MenuItem value={''} disabled>통신사를 선택해주세요</MenuItem>
                        {telList.map((data:any,index:any)=>{
                            return(
                                <MenuItem key={`internet-tel-${index}`} value={data.company_code}>{data.company_name}</MenuItem>
                            )
                        })}
                    </SelectItem>
                }
            </ItemWrap>
            <ItemWrap pWidth={120}>
                <BasicText>인터넷</BasicText>
                {list?.internet === undefined || list?.internet.length === 0 ?
                    <InputWrap>
                        없음
                    </InputWrap> :
                    <SelectItem value={info?.product_001_code ?? ''} setValue={(val:any)=>valueChange('product_001_code',val,'bundle_product_001')} none='no'>
                        <MenuItem value={''} disabled>인터넷을 선택해주세요</MenuItem>
                        {list?.internet.map((data:any,index:any)=>{
                            return(
                                <MenuItem key={`home-internet-${index}`} value={data?.product_code}>{data.product_name}</MenuItem>
                            )
                        })}
                    </SelectItem>
                }
            </ItemWrap>
            <ItemWrap pWidth={120}>
                <BasicText>TV</BasicText>
                {list?.tv === undefined || list?.tv.length === 0 ?
                    <InputWrap>
                        없음
                    </InputWrap> :
                    <SelectItem value={info?.product_002_code ?? ''} setValue={(val:any)=>valueChange('product_002_code',val,'bundle_product_002')} none='no'>
                        <MenuItem value={''}>선택안함</MenuItem>
                        {list?.tv.map((data:any,index:any)=>{
                            return(
                                <MenuItem key={`home-tv-${index}`} value={data.product_code}>{data.product_name}</MenuItem>
                            )
                        })}
                    </SelectItem>
                }
            </ItemWrap>
            <ItemWrap pWidth={120}>
                <BasicText>전화</BasicText>
                {list?.landline === undefined || list?.landline.length === 0 ?
                    <InputWrap>
                        없음
                    </InputWrap> :
                    <SelectItem value={info?.product_005_code ?? ''} setValue={(val:any)=>valueChange('product_005_code',val,'bundle_product_005')} none='no'>
                        <MenuItem value={''}>선택안함</MenuItem>
                        {list?.landline.map((data:any,index:any)=>{
                            return(
                                <MenuItem key={`home-landline-${index}`} value={data.product_code}>{data.product_name}-{data.product_summary}</MenuItem>
                            )
                        })}
                    </SelectItem>
                }
            </ItemWrap>
            <ItemWrap pWidth={120}>
                <BasicText>사은품</BasicText>
                <InputWrap>
                    <input value={numberWithComma(info?.bundle_freegift_basic_price ?? '')} onChange={(e:any)=>valueChange('bundle_freegift_basic_price',onlyNumber(e))} placeholder='사은품 금액을 입력해주세요'/>
                </InputWrap>
            </ItemWrap>
            {/* <ItemWrap pWidth={120}>
                <BasicText>결합가</BasicText>
                <InputWrap>
                    <input value={numberWithComma(info?.bundle_price_combine ?? '')} onChange={(e:any)=>valueChange('bundle_price_combine',onlyNumber(e))} placeholder='결합가를 입력해주세요'/>
                </InputWrap>
            </ItemWrap> */}
        </ContentWrap>
    )
}

