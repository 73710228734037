import React from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';

export default function MainPart({homeBenefitClick}:any) {
    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    <ContentWrap>
                        <BasicText fontSize={24} mFontSize={18} color='#000' align='center'>유일무이 전국 최저가 보장 현주친구</BasicText>
                        <BasicText fontSize={44} mFontSize={24} fontWeight={700} color={addTheme.color} align='center' marginTop={1}>인터넷, 가전렌탈에 숨어있는</BasicText>
                        <BasicText fontSize={44} mFontSize={24} fontWeight={700} color={addTheme.color} align='center'>내 혜택 80만원 꼭 받으세요!</BasicText>
                        <BtnItem onClick={()=>homeBenefitClick()}>
                            <BasicText fontSize={24} mFontSize={18} fontWeight={700} color={'#fff'}>내 혜택 지원금 신청하기</BasicText>
                        </BtnItem>
                    </ContentWrap>
                    <ImgWrap>
                        <img alt='sc1-img' src='/img/asset/ani01-min.gif'></img>
                    </ImgWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    background: #fffae1;
`;

const InnerWrap = styled.div`
    padding-top: 180px;
    @media ${addTheme.media.s}{
        padding-top: 140px;
    }
`;

const ContentWrap = styled(Center)`
    flex-direction: column;
`;
const BtnItem = styled(Center)`
    margin-top: 20px;
    background-color: ${addTheme.color};
    width: 300px;
    height: 58px;
    border-radius: 30px;
    cursor: pointer;
    @media ${addTheme.media.s}{
        margin-top: 30px;
        height: 48px;
    }
`;

const ImgWrap = styled(Center)`
    margin-top: 30px;
    & img{
        max-width: 100%;
        max-height: 400px;
    }
`;