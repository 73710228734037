import React from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import { numberWithComma } from '../../../utils/format/number';

const TEL_LIST = [
    {pay: 9970, title: 'LG', img: '/img/asset/tel_log/lg2.png'},
    {pay: 8438, title: 'SK', img: '/img/asset/tel_log/sk.png'},
    {pay: 10500, title: 'KT', img: '/img/asset/tel_log/kt.png'},
]

export default function CenterBannerPart() {
    return (
        <Wrap>
            <InnerWrap>
                <MaxWidthWrap>
                    <ContentWrap>
                        <TextWrap>
                            <BasicText fontSize={24} mFontSize={18}>온/오프라인 <strong>월 평균 가입자 2,000명 · 누적 가입자 38만명</strong> 돌파!</BasicText>
                            <BasicText marginTop={1} fontSize={48} mFontSize={24} fontWeight={700} ls={1} spColor={addTheme.color}>다회선도 역시 <span>현주친구가 끝판왕!</span></BasicText>
                            <Center style={{justifyContent:'start',marginTop:'12px'}}>
                                <BgTextItem>기가인터넷·무료WIFI·IPTV(VOD)·넷플릭스</BgTextItem>
                            </Center>
                            <BasicText marginTop={8} fontSize={20} mFontSize={18}><strong>통신사별 최저요금</strong>을 약속드리며 고객님의 상황에 맞는 완벽한 플랜을 제공합니다.</BasicText>
                        </TextWrap>
                        <ImgWrap>
                            <img alt='banner-icon' src='/img/asset/masset06.png'></img>
                        </ImgWrap>
                    </ContentWrap>
                </MaxWidthWrap>
            </InnerWrap>
            <BottomWrap>
                <MaxWidthWrap>
                    <TelWrap>
                        {TEL_LIST.map((data:any,index:any)=>{
                            return(
                                <TelItemWrap key={`multi-tel-${index}`}>
                                    <TelLogoWrap>
                                        <img alt='tel-logo' src={data.img}></img>
                                    </TelLogoWrap>
                                    <TelTextWrap>
                                        <BasicText fontWeight={700} fontSize={24} mFontSize={16}>{numberWithComma(data.pay)}원</BasicText>
                                        <BasicText fontSize={16} mFontSize={14}>{data.title}월 이용료(VAT 포함)</BasicText>
                                    </TelTextWrap>
                                </TelItemWrap>
                            )
                        })}
                    </TelWrap>
                </MaxWidthWrap>
            </BottomWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 25px;
   
`;

const InnerWrap = styled.div`
    background-color: #ffedd5;
`;

const ContentWrap = styled(Row)`
    padding: 80px 0 50px;
    
    align-items: start;
    gap: 10px;
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
        &>div{
            width: 100%;
        }
    }
`;
const TextWrap = styled.div`
    width: 65%;
`;
const BgTextItem = styled.div`
    background-color: #fff;
    padding: 12px 24px;
    color: ${addTheme.color};
    font-weight: 700;
    font-size: 24px;
    width: auto;
    border-radius: 30px;
    @media ${addTheme.media.s}{
        font-size: 16px;
    }
`;
const ImgWrap = styled.div`
    width: 35%;
    text-align: center;
    & img{
        width: 100%;
        max-width: 280px;
    }
    @media ${addTheme.media.s}{
        order: -1;
        
    }
`;

const BottomWrap = styled.div`
    margin-top: -20px;
    @media ${addTheme.media.s}{
        margin-top: 15px;
    }
`;

const TelWrap = styled(Row)`
    gap: 20px;
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
    }
`;
const TelItemWrap = styled(Row)`
    background-color: #fff8f1;
    border-radius: 6px;
    padding: 36px;
    @media ${addTheme.media.m}{
        padding: 20px 15px;
    }
`;

const TelLogoWrap = styled(Center)`
    width: 30%;
    flex-shrink: 0;
    text-align: center;
    & img{
        width: 100%;
        max-width: 90px;
        @media ${addTheme.media.s}{
            max-width: 65px;
        }
    }
`;
const TelTextWrap = styled.div``;