import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_USER_LOGIN = 'subox-dev/common/SET_USER_LOGIN';
const SET_USER_LOGOUT = 'subox-dev/common/SET_USER_LOGOUT';

export const setUserInfoLoginActionCreator = createAction(SET_USER_LOGIN);
export const setUserInfoLogOutActionCreator = createAction(SET_USER_LOGOUT);

export function setUserInfo(data: any): ThunkAction<void, RootState, null, ReturnType<typeof setUserInfoLoginActionCreator>> {
  return async function (dispatch) {
    try {
      dispatch(setUserInfoLoginActionCreator({
        data: data
      }));
    } catch (error) {
      
    }
  };
}

export interface userInfo {
  "jwt_token": string;
  "user_address":string;
  "user_code":string;
  "user_id":number;
  "user_membership":string;
  "user_nickname":string;
  "user_profile":string;
  "user_token":string;
  "user_timestamp": string;
  "user_country":string;
  "user_point": number;
  "user_relation_list": Array<any>;
  "user_follower_list": Array<any>;
  "user_sns_like_list": Array<any>;
  "user_music_like_list": Array<any>;
  "user_type": 'web'|'webview' | string;
  "target_follower": number;
  "target_followee": number;
  "target_user_code": string;
  platform_os: string;
  app_version: string;
  locales: any;
  refer_code: string;
}

export const userState: userInfo = {
  "jwt_token":'',
  "user_address":"",
  "user_code":"",
  "user_id":0,
  "user_membership":'0',
  "user_nickname":"",
  "user_profile":"",
  "user_token":"",
  "user_timestamp":'0',
  "user_country":"",
  "user_point":0,
  "user_relation_list":[],
  "user_follower_list":[],
  "user_sns_like_list": [],
  "user_music_like_list": [],
  "user_type": '',
  "target_follower": 0,
  "target_followee": 0,
  target_user_code: '',
  platform_os: 'android',
  app_version: '0',
  locales: undefined,
  refer_code: '',
}

export const user = handleActions<userInfo, any>({
  [SET_USER_LOGIN]: (state, action: ReturnType<typeof setUserInfoLoginActionCreator>) => ({
    jwt_token: action.payload.data.jwt_token ?? state.jwt_token,
    user_address: action.payload.data.user_address ?? state.user_address,
    user_code: action.payload.data.user_code ?? state.user_code,
    user_id: action.payload.data.user_id ?? state.user_id,
    user_membership: action.payload.data.user_membership ?? state.user_membership,
    user_nickname: action.payload.data.user_nickname ?? state.user_nickname,
    user_profile: action.payload.data.user_profile ?? state.user_profile,
    user_token: action.payload.data.user_token ?? state.user_token,
    user_timestamp: action.payload.data.user_timestamp ?? state.user_timestamp,
    user_point: action.payload.data.user_point ?? state.user_point,
    user_country: action.payload.data.user_country ?? state.user_country,
    user_relation_list: action.payload.data.user_relation_list ?? state.user_relation_list,
    user_follower_list: action.payload.data.user_follower_list ?? state.user_follower_list,
    user_sns_like_list: action.payload.data.user_sns_like_list ?? state.user_sns_like_list,
    user_music_like_list: action.payload.data.user_music_like_list ?? state.user_music_like_list,
    user_type: action.payload.data.user_type ?? state.user_type,
    target_follower: action.payload.data.target_follower ?? state.target_follower,
    target_followee: action.payload.data.target_followee ?? state.target_followee,
    target_user_code: action.payload.data.target_user_code ?? state.target_user_code,
    platform_os: action.payload.data.platform_os ?? state.platform_os,
    app_version: action.payload.data.app_version ?? state.app_version,
    locales: action.payload.data.locales ?? state.locales,
    refer_code: action.payload.data.refer_code ?? state.refer_code,
  })
,
  [SET_USER_LOGOUT]: (state, action: ReturnType<typeof setUserInfoLogOutActionCreator>) => (userState),
}, userState);
