import { Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { numberWithComma } from '../../../utils/format/number';
import { momentFormat } from '../../../utils/format/time';
import { useNavigate, useParams } from 'react-router';
import { AdmBtnItem, RowBetween } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import { apiGetPartnerPointList } from '../../../api/api_partner';
import { useGlobalPartner } from '../../../utils/custom-hooks/common/useGlobalPartner';

const TYPE:any = {
    '1': '입금',
    '2': '출금',
}
const STATUS:any = {
    '0': '대기',
    '1': '완료',
}

export default function PartnerMyPointPart() {
    const navigate = useNavigate();
    const {page} = useParams();
    const [list,setList] = useState<any>([]);
    const [total,setTotal] = useState(0);
    const partner = useGlobalPartner();

    useEffect(()=>{
        if(partner?.partner_code === undefined || partner?.partner_code === '') return;
        getList();
    },[page,partner?.partner_code])

    const getList = async() =>{
        const res = await apiGetPartnerPointList(partner.partner_code,page,10);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    const pageChange = (p:any)=>{
        navigate(`/partnerMyPage/myPoint/${p ?? 1}`);
    }

    const btnClick = () =>{
        navigate('/partnerWithdrawal');
    }

    return (
        <Wrap>
            <TableContainer>
                <Table sx={{ minWidth: 500}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{width:`15%`,padding:'10px',border:'none',backgroundColor: '#f3f3f3',color: '#000'}} align={'center'}>유형</TableCell>
                            <TableCell sx={{width:`20%`,padding:'10px',border:'none',backgroundColor: '#f3f3f3',color: '#000'}} align={'center'}>포인트</TableCell>
                            <TableCell sx={{width:`15%`,padding:'10px',border:'none',backgroundColor: '#f3f3f3',color: '#000'}} align={'center'}>상태</TableCell>
                            <TableCell sx={{width:`25%`,padding:'10px',border:'none',backgroundColor: '#f3f3f3',color: '#000'}} align={'center'}>신청일</TableCell>
                            <TableCell sx={{width:`25%`,padding:'10px',border:'none',backgroundColor: '#f3f3f3',color: '#000'}} align={'center'}>완료일</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list === undefined || list.length === 0 ?
                            <></> :
                            list.map((data:any,index:any)=>{
                                return(
                                    <TableRow key={`partner-point-${index}`}>
                                        <TableCell sx={{padding:'10px'}} align='center'>{TYPE[data.point_payment_type]}</TableCell>
                                        <TableCell sx={{padding:'10px'}} align='center'>{numberWithComma(data.point_payment_amount)}</TableCell>
                                        <TableCell sx={{padding:'10px'}} align='center'>{STATUS[data.point_payment_status]}</TableCell>
                                        <TableCell sx={{padding:'10px'}} align='center'>{momentFormat('YYYY-MM-DD hh:mm',data.point_payment_req_timestamp)}</TableCell>
                                        <TableCell sx={{padding:'10px'}} align='center'>{momentFormat('YYYY-MM-DD hh:mm',data.point_payment_process_timestamp)}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <PaginWrap>
                <Pagination shape="rounded" page={typeof page === 'string' ? Number(page) : page} count={total} onChange={(e:any, page:any) => pageChange(page)} sx={{'.MuiPagination-ul li button':{color:'#7d7d7d'},'.MuiPagination-ul li .Mui-selected':{color:'#111'}}} color='secondary'></Pagination>
                <AdmBtnItem onClick={()=>btnClick()} bg={addTheme.color}>출금신청하기</AdmBtnItem>
            </PaginWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const PaginWrap = styled(RowBetween)`
    margin-top: 20px;
    flex-wrap: wrap;
`;