import { Dialog, DialogContent, TableCell, TableRow } from '@mui/material';
import React, { useRef } from 'react'
import styled from 'styled-components';
import { AdmBtnItem, Center } from '../../../../style/basic/commonStyle';
import AdminTablePart from '../../common/part/AdminTablePart';
import { ADM_POINT_SEND_SEARCH_LIST } from '../../data/table';
import { addTheme } from '../../../../style/addTheme';
import { PARTNER_STATUS, PARTNER_TYPE } from '../../partner/data/dummy';

const ListItem = ({data,selectAction}:any) =>{
    return(
        <TableRow>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_SEND_SEARCH_LIST[0].align}>{data.partner_account_code}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_SEND_SEARCH_LIST[1].align}>{PARTNER_TYPE[data.partner_account_type]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_SEND_SEARCH_LIST[2].align}>{data.partner_account_type === '1' ? data.partner_account_name : data.partner_account_business_name}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_SEND_SEARCH_LIST[3].align}>{data.partner_account_phone_number}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_SEND_SEARCH_LIST[4].align}>{PARTNER_STATUS[data.partner_account_status]}</TableCell>
            <TableCell sx={{padding:'10px'}} align={ADM_POINT_SEND_SEARCH_LIST[5].align}>
                <AdmBtnItem bg={addTheme.admBtn2} round={30} padding='6px 16px' onClick={selectAction}>선택</AdmBtnItem>
            </TableCell>
        </TableRow>
    )
}

export default function OpenSendSearchPart({open,list,page,setPage,total,setOpen,valueChange}:any) {
    const ref = useRef<any>(null);

    const close = () =>{
        setOpen(false);
    }

    const selectAction = (data:any) =>{
        valueChange('code',data.partner_account_code);
        close();
    }
    
    return (
        <Dialog
            ref={ref}
            open={open} 
            onClose={close}
            sx={{
                '.MuiPaper-root':{margin:'8px', maxWidth:'560px',width:'100%',borderRadius: '6px'}
            }}
        >
            <DialogContent dividers={true}>
                <Wrap>
                    {list === undefined || list.length === 0 ?
                        <Center>검색 결과가 없습니다.</Center> :
                        <AdminTablePart bg={addTheme.adminColor} color='#fff' title={ADM_POINT_SEND_SEARCH_LIST} minWidth={500} page={page ?? 1} total={total} setPage={setPage}>
                            {list.map((data:any,index:any)=>{
                                return(
                                    <ListItem key={`search-${index}`} data={data} selectAction={()=>selectAction(data)}/>
                                )
                            })}
                        </AdminTablePart>
                    }
                </Wrap>
            </DialogContent>
        </Dialog>
    )
}

const Wrap = styled.div`
    & img{
        width: 100%;
        max-width: 720px;
    }
`;
