import React from 'react'
import styled from 'styled-components'
import { numberWithComma } from '../../../utils/format/number'
import StepTitleItem from './StepTitleItem'
import { Row, SectionWrap } from '../../../style/basic/commonStyle'
import StepInnerTitleItem from './StepInnerTitleItem'
import BasicText from '../../basic/BasicText'
import { FaCheckCircle } from "react-icons/fa";
import { addTheme } from '../../../style/addTheme'

const LIST = [
    {name: '인터넷', channel:0, M100:{pay0:22000,pay1:17600,gift:11}, M500:{pay0:33000,pay1:22000,gift:17}, G1:{pay0:38500,pay1:25300,gift:17}},
    {name: '인터넷 + 이코노미', channel:184, M100:{pay0:34100,pay1:30800,gift:40}, M500:{pay0:41800,pay1:35200,gift:48}, G1:{pay0:47300,pay1:38500,gift:48}},
    {name: '인터넷 + Btv 스탠다드', channel:226, M100:{pay0:37400,pay1:34100,gift:40}, M500:{pay0:45100,pay1:38500,gift:48}, G1:{pay0:50600,pay1:41800,gift:48}},
    {name: '인터넷 + Btv 올', channel:252, M100:{pay0:40700,pay1:37400,gift:40}, M500:{pay0:48400,pay1:41800,gift:48}, G1:{pay0:53900,pay1:45100,gift:48}},
    
]

const DISCOUNT_FAMILY = [
    {name: '특수 상황', list: [
        {product: '신혼플러스', terms: '예비 부부, 신혼 부부 5g: 85요금제 LTE 78 요금제 이상', benefit: '모바일 요금 6개월 무료 + 월 최대 22,850원 할인'},
        {product: '펫플러스', terms: '스마트홈', benefit: '공기청정기 렌탈료 최대 21,000원 할인 구매 시 약 23만원 할인'},
        {product: '시니어 플러스', terms: '약 65세 이상 사용자', benefit: '인터넷 3,100원 추가 할인'},
    ] },
    {name: '인터넷 + 휴대폰', list: [
        {product: '투게더 결합', terms: '모바일 5회선, 인터넷 5회선까지 요금제에 따라 할인 금액 변동', benefit: '인터넷 11,000원/ 휴대폰 최대 20,000원 할인'},
        {product: '참 쉬운 가족결합', terms: '모바일 10회선,인터넷 3회선까지 요금제에 따라 할인 금액 변동', benefit: '인터넷 13,200원/ 휴대폰 최대 5,500원 할인'},
    ] },
    {name: '인터넷 + 인터넷', list: [{product: '패밀리 결합', terms: '인터넷 2대 이상', benefit: '인터넷 5,500원'}]},
]

const DISCOUNT_CARD = [
    {name: '롯데카드', list: [
        {name: '롯데 TELLO SE 카드(SKT)', benefit: '40만원 이상 실적 8,000원 할인'},
        {name: 'SK브로드밴드 B롯데카드', benefit: '50만원 이상 실적 10,000원 할인'},
    ]},
    {name: '삼성카드', list: [
        {name: '삼성 T나는 혜택카드 (SKT)', benefit: '30만원 이상 실적 7,000원 할인'},
        {name: 'SK브로드밴드 삼성카드', benefit: '30만원 이상 실적 7,000원 할인'},
    ]},
    {name: '국민카드', list: [
        {name: 'T-Premium KB국민카드', benefit: '40만원 이상 실적 5,000원 할인'},
        {name: 'T-economy KB국민카드', benefit: '30만원 이상 실적 12,000원 할인'},
    ]},
    
]

export default function SkStepItem({selectData}:any) {
    return (
        <Wrap>
            <StepTitleItem step='01' title={`SK 요금표 한눈에 보기`} />
            <TableWrap>
                <TableWrapItem margin='40px 0 0'>
                    <thead>
                        <tr>
                            <th className='title-bg' rowSpan={2} colSpan={2}> 인터넷 상품</th>
                            <th className='title-bg' rowSpan={2}>모바일<br/>결합여부</th>
                            <th className='title-bg' colSpan={2} rowSpan={1}>100M</th>
                            <th className='title-bg' colSpan={2} rowSpan={1}>500M</th>
                            <th className='title-bg' colSpan={2} rowSpan={1}>1G</th>
                        </tr>
                        <tr>
                            <th className='title-bg'>요금</th>
                            <th className='title-bg'>사은품</th>
                            <th className='title-bg'>요금</th>
                            <th className='title-bg'>사은품</th>
                            <th className='title-bg'>요금</th>
                            <th className='title-bg'>사은품</th>
                        </tr>
                    </thead>
                    <tbody>
                        {LIST.map((data:any,index:any)=>{
                            const evenState = (index+1) % 2 === 0;
                            return(
                                <React.Fragment key={`kt-pay-${index}`}>
                                    <tr className={evenState ? 'content-bg' : ''}>
                                        <th className='content-title' rowSpan={2} colSpan={2}>
                                            {data.name}
                                            {data.channel !== 0 &&
                                                <>
                                                    <br/><span>{data.channel}채널</span>
                                                </>
                                            }
                                        </th>
                                        <td className='first-text'>미결합</td>
                                        <td className='first-text'>{numberWithComma(data['M100']['pay0'])}원</td>
                                        <td rowSpan={2} className='gift'>{numberWithComma(data['M100']['gift'])}만원</td>
                                        <td className='first-text'>{numberWithComma(data['M500']['pay0'])}원</td>
                                        <td rowSpan={2} className='gift'>{numberWithComma(data['M500']['gift'])}만원</td>
                                        <td className='first-text'>{numberWithComma(data['G1']['pay0'])}원</td>
                                        <td rowSpan={2} className='gift'>{numberWithComma(data['G1']['gift'])}만원</td>
                                    </tr>
                                    <tr className={evenState ? 'content-bg' : ''}>
                                        <td>1대결합</td>
                                        <td>{numberWithComma(data['M100']['pay1'])}원</td>
                                        <td>{numberWithComma(data['M500']['pay1'])}원</td>
                                        <td>{numberWithComma(data['G1']['pay1'])}원</td>
                                    </tr>
                                </React.Fragment>
                            )
                        })}
                    </tbody>
                </TableWrapItem>
            </TableWrap>
            <TableWrap>
                <TableWrapItem>
                    <colgroup>
                        <col />
                        <col />
                        <col width="14.8%" />
                        <col width="15%" />
                        <col width="13%" />
                        <col />
                    </colgroup>
                    <tbody className='title-bg'>
                        <tr>
                            <th rowSpan={2}>TV 추가</th>
                            <td>IP TV결합</td>
                            <td>이코노미</td>
                            <td>스탠다드</td>
                            <td>ALL</td>
                            <td rowSpan={2} className='gift'>3만원</td>
                        </tr>
                        <tr>
                            <td>월요금</td>
                            <td>8,250원</td>
                            <td>9,900원</td>
                            <td>11,550원</td>
                        </tr>
                    </tbody>
                </TableWrapItem>
            </TableWrap>
            <SectionWrap padding={'80px 0 0'}>
                <StepTitleItem step='02' title={'할인혜택 모르면 나만 손해'} />
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={1} title='결합할인은 필수' />
                    <BasicText>인터넷 가입 시 결합할인을 생각하지 않는 건,<br/>돈을 버린다는 의미와 같습니다.</BasicText>
                    <BasicText marginTop={2}>반드시 확인하여 최저요금에 도전해봅시다!</BasicText>
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0'>
                            <colgroup>
                                <col width="16%" />
                                <col width="28%" />
                                <col width="28%" />
                                <col width="28%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>결합 종류</th>
                                    <th>결합 조건</th>
                                    <th>인터넷 할인</th>
                                    <th>휴대폰 할인</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>온 가족할인</th>
                                    <td>가족 가입 연수<br/>총합에 따라 차등적용</td>
                                    <td>-10년 미만 10%
                                        <br/>-20년 미만 20%
                                        <br/>-30년 미만 30%
                                        <br/>-30년 이상 50%
                                    </td>
                                    <td>-20년 미만 0%
                                        <br/>-20년 이상 10%
                                        <br/>-30년 이상 30%
                                        <br/>(2015.04 이후 요금제)
                                    </td>
                                </tr>
                                <tr>
                                    <th>요즘 가족결합</th>
                                    <td>유무선 결합상품,
                                        <br/>휴대폰 1회선~5회선까지
                                        <br/>결합 가능
                                    </td>
                                    <td>100M - 4,400원
                                        <br/>500M - 11,000원
                                        <br/>1G - 13,200원
                                    </td>
                                    <td>최소 3,500 ~ 최대 24,000원</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <BasicText marginTop={2}>혹시 어려우시다면 언제든 문의 주세요.</BasicText>
                </SectionWrap>
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={2} title='있으면 더 좋아요, 제휴카드 할인' />
                    <BasicText marginTop={0.5}>제휴카드 발급 후 통신요금 자동이체 시 할인혜택이 적용됩니다.<br/>단, <strong>실적조건</strong>이 있어요.</BasicText>
                    <TableWrap>
                        <TableWrapItem margin='15px 0 0'>
                            <colgroup>
                                <col width="17.5%" />
                                <col width="41.5%" />
                                <col width="41%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>카드사</th>
                                    <th>카드명</th>
                                    <th>할인 혜택</th>
                                </tr>
                            </thead>
                            <tbody>
                                {DISCOUNT_CARD.map((data:any,index:any)=>{
                                    return(
                                        <React.Fragment key={`kt-card-discount-${index}`}>
                                            <tr>
                                                <th rowSpan={data.list.length}>{data.name}</th>
                                                {data.list.map((item:any,idx:any)=>{
                                                    if(idx !== 0) return;
                                                    return(
                                                        <React.Fragment key={`kt-card-item-${index}-${idx}`}>
                                                            <td>{item['name']}</td>
                                                            <td>{item['benefit']}</td>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </tr>
                                            {data.list.map((item:any,idx:any)=>{
                                                if(idx === 0) return;
                                                return(
                                                    <tr key={`kt-card-item-${index}-${idx}`}>
                                                        <td>{item['name']}</td>
                                                        <td>{item['benefit']}</td>
                                                    </tr>
                                                )
                                            })}
                                        </React.Fragment>
                                    )
                                })}
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                </SectionWrap>
            </SectionWrap>
            <SectionWrap padding={'80px 0 0'}>
                <StepTitleItem step='03' title={'인터넷 전화도 필요하시다면'} />
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={1} title='설치 일정 및 사은품 지급' />
                    <BasicText marginTop={0.5}>혹시 집이나 매장에서 쓸 전화기도 함께 필요하신가요?<br/>그렇다면 인터넷선만 있으면 되는 인터넷 전화를 추천드립니다.</BasicText>
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0'>
                            <colgroup>
                                <col width="24.5%" />
                                <col width="34.5%" />
                                <col width="41%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>요금제</th>
                                    <th>월 요금</th>
                                    <th>무료 서비스</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>집전화 프리 5000</td>
                                    <td>(3년 약정 전화단독) 7,700원<br/>
                                        (3년 약정 인터넷 결합) 4,400원<br/>
                                        (3년 약정 인터넷+TV 결합) 2,200원
                                    </td>
                                    <td>발신자 번호표시(CID)</td>
                                </tr>
                                <tr>
                                    <td>전화기본 요금제</td>
                                    <td>3년 약정 시 2,200원</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td>무료100(정액형 요금제)	</td>
                                    <td>3년 약정 시 6,600원</td>
                                    <td>무료통화 최대 100분<br/>(시내전화, 인터넷전화)</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                </SectionWrap>
            </SectionWrap>
            <SectionWrap padding={'80px 0 0'}>
                <StepTitleItem step='04' title={'잠깐!! 설치 전 확인하셨나요? 필수 체크리스트'} />
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={1} title='대칭형/비대칭형 조회' />
                    <BasicText marginTop={0.5}>인터넷 가입 전 대칭형/비대칭형 조회는 필수입니다!</BasicText>
                    <InnerStep02Wrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>대칭형 인터넷(FTTH 회선)</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='transparent' size={22} />
                            <BasicText>업로드 및 다운로드 속도가 일정한 초고속 인터넷(고품질)</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='20px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>비대칭형 인터넷(HFC 회선)</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='transparent' size={22} />
                            <BasicText>업로드 및 다운로드 속도가 달라 불안정한 인터넷(저품질)</BasicText>
                        </TextRowWrap>
                    </InnerStep02Wrap>
                    <BasicText marginTop={2}>품질은 달라도 요금은 같기 때문에 반드시<br/><strong>대칭형 인터넷</strong>이 들어오는 지 확인해주세요!</BasicText>
                    <BasicText marginTop={2} spColor={addTheme.color}>저희 <span>현주친구</span>로 문의주시면<br/>KT, LG, SK 모두 확인해서 알려드리겠습니다^^</BasicText>
                    <BasicText marginTop={2}>(📞 1533-9360)</BasicText>
                    <BasicText marginTop={2} >전화가 부담스러우신 분은<br/>아래링크에 주소를 입력해서 확인해주세요!</BasicText>
                    <BasicText marginTop={2} fontWeight={700} deco='underline'><a href="https://www.bworld.co.kr/myb/product/join/address/search.do" target="_blank" rel="noopener noreferrer">※ SK 대칭형 인터넷 설치지역 조회 링크</a></BasicText>
                    <BasicText marginTop={2} >거주지 조회 후 1기가 인터넷 설치가 가능하다면 대칭형입니다.</BasicText>
                </SectionWrap>
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={2} title='IPTV 셋톱박스/공유기' />
                    <BasicText marginTop={0.5}>현주친구에 명시된 요금은 <strong>셋톱박스, 모뎀 임대료 및 부가가치세가</strong><br/>전부 포함된 실 납부액입니다.</BasicText>
                    <BasicText marginTop={2}>공유기와 셋톱박스는 임대로 제공되며 약정 후 꼭 반납을 해야합니다.<br/>혹시나 잃어버리지 않게 주의하세요!</BasicText>
                    <TableWrap>
                        <TableWrapItem margin='15px 0 0'>
                            <colgroup>
                                <col width="35%" />
                                <col width="28%" />
                                <col width="35%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>셋톱박스</th>
                                    <th>월 임대료</th>
                                    <th>특징</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>스마트3 미니</td>
                                    <td>4,400원</td>
                                    <td>기본형보다 작은 크기</td>
                                </tr>
                                <tr>
                                    <td>스마트3</td>
                                    <td>4,400원</td>
                                    <td>기본형 셋톱박스</td>
                                </tr>
                                <tr>
                                    <td>AI NUGU</td>
                                    <td>6,600원</td>
                                    <td>AI 음성인식 스피커<br/>블루투스 스피커 지원</td>
                                </tr>
                                <tr>
                                    <td>사운드 맥스</td>
                                    <td>8,800원</td>
                                    <td>사운드바형 셋톱박스</td>
                                </tr>
                                <tr>
                                    <td>애플tv</td>
                                    <td>6,600원</td>
                                    <td>다양한 앱 설치 가능<br/>아이폰 호환 가능</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <BasicText marginTop={2}>TV 채널 편성이 궁금하다면 <strong><a style={{display:'inline-block'}} href="https://www.bworld.co.kr/content/realtime/realtime_list.do?menu_id=P03030100" target="_blank" rel="noopener noreferrer">여기</a></strong>를 클릭해주세요.</BasicText>
                    <TextRowWrap gap={8} margin='20px 0 0'>
                        <FaCheckCircle color='#ff7500' size={22} />
                        <BasicText fontSize={17} fontWeight={700}>주요 OTT 시청 가능 유무</BasicText>
                    </TextRowWrap>
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0'>
                            <colgroup>
                                <col width=""/>
                                <col width="10.5%" />
                                <col width="10.5%" />
                                <col width="10.5%" />
                                <col width="10.5%" />
                                <col width="10.5%" />
                                <col width="10.5%" />
                                <col width="10.5%" />
                                <col width="10.5%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>구분</th>
                                    <th>유튜브</th>
                                    <th>넷플릭스</th>
                                    <th>디즈니+</th>
                                    <th>웨이브</th>
                                    <th>왓챠</th>
                                    <th>티빙</th>
                                    <th>애플TV</th>
                                    <th>쿠팡플레이</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>애플TV</th>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                </tr>
                                <tr>
                                    <th>스마트 3</th>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                </tr>
                                <tr>
                                    <th>스마트 3 미니</th>
                                    <td>O</td>
                                    <td>X</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>X</td>
                                    <td>X</td>
                                </tr>
                                <tr>
                                    <th>AI NUGU</th>
                                    <td>O</td>
                                    <td>X</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                </tr>
                                <tr>
                                    <th>사운드 맥스</th>
                                    <td>O</td>
                                    <td>X</td>
                                    <td>X</td>
                                    <td>X</td>
                                    <td>X</td>
                                    <td>X</td>
                                    <td>X</td>
                                    <td>X</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <TextRowWrap gap={8} margin='20px 0 0'>
                        <FaCheckCircle color='#ff7500' size={22} />
                        <BasicText fontSize={17} fontWeight={700}>공유기 임대료(3년 약정 결합기준)</BasicText>
                    </TextRowWrap>
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0'>
                            <colgroup>
                                <col width="23%" />
                                <col width="30%" />
                                <col width="15.6%" />
                                <col width="15.6%" />
                                <col width="15.6%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>구분</th>
                                    <th>특징</th>
                                    <th>100M</th>
                                    <th>500M</th>
                                    <th>1G</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>GIGA WiFi</th>
                                    <td>기본형 와이파이</td>
                                    <td colSpan={3}>1,100원</td>
                                </tr>
                                <tr>
                                    <th>GIGA WiFi 6</th>
                                    <td>WIFI-6 적용</td>
                                    <td colSpan={3}>1,100원</td>
                                </tr>
                                <tr>
                                    <th>GIGA WiFi 프리미엄</th>
                                    <td>고급형 와이파이 최대 1.7Gbps<br/>(2.5G이상 프리미엄 인터넷 신청시 가능)</td>
                                    <td colSpan={3}> 인터넷 결합시 5,500원<br/> 인터넷 + TV 결합시 3,300원 </td>
                                </tr>
                                <tr>
                                    <th>윙즈</th>
                                    <td>와이파이 증폭기기</td>
                                    <td colSpan={3}>1,650원</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <BasicText marginTop={2}>공유기 임대는 필수가 아니기 때문에<br/>직접 구매해서 쓰시거나 집에 있는 기기를 쓰셔도 무방합니다.</BasicText>
                    <TextRowWrap gap={8} margin='20px 0 0'>
                        <FaCheckCircle color='#ff7500' size={22} />
                        <BasicText fontSize={17} fontWeight={700}>공유기 임대 VS 구매</BasicText>
                    </TextRowWrap>
                    <InnerStep02Wrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>임대가 나은 경우</BasicText>
                        </TextRowWrap>
                        <BasicText marginTop={3}>1. 와이파이 안될 때 도움을 요청하고 싶은 분 (A/S 편리)</BasicText>
                        <BasicText>2. 프로모션 등으로 할인, 혹은 무료 사용이 가능할 경우</BasicText>
                        <TextRowWrap gap={8} margin='20px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>구매가 더 나은 경우</BasicText>
                        </TextRowWrap>
                        <BasicText marginTop={3}>1. 고성능 공유기가 필요하거나 저가 공유기로 충분하신 분</BasicText>
                        <BasicText>2. 약정으로 묶이기 싫으신 분</BasicText>
                    </InnerStep02Wrap>
                    <BasicText marginTop={2}>참고로 기본 제공되는 공유기는<br/><strong>구매가 최소 5만원 이상 공유기와 비슷한 스펙</strong>입니다.</BasicText>
                </SectionWrap>
            </SectionWrap>
            <SectionWrap padding={'80px 0 0'}>
                <StepTitleItem step='05' title={'설치 및 사은품 지급 안내'} />
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={1} title='설치 일정 및 사은품 지급' />
                    <InnerStep02Wrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>설치는 신청 후 기사님과 조율하시면 됩니다. 평균 1~3일 내로 가능합니다</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>원하시는 날짜가 있다면 미리 말씀주세요. 최대한 맞춰드릴 수 있도록 도와드리겠습니다.</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>당일 설치는 기사님 일정에 따라 정해져요. 먼저 말씀해주시면 도와드리겠습니다!</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>설치시간은 평균 30분 내외이며 환경에 따라 조금 더 시간이 걸릴 수 있어요.</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>설치 후 1533-9360번으로 바로 연락주세요. 사은품 지급을 도와드리겠습니다.</BasicText>
                        </TextRowWrap>
                    </InnerStep02Wrap>
                    <BasicText marginTop={2} spColor={addTheme.color}>*<span>현주친구</span>는 설치 후 9개월 사용으로 사은품 반환의무가 없어진답니다.<br/>(타사는 대부분 12개월)</BasicText>
                </SectionWrap>
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={2} title='설치비용' />
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0'>
                            <colgroup>
                                <col width="10%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="12%" />
                                <col width="15%" />
                                <col width="20%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>구분</th>
                                    <th>인터넷 단독</th>
                                    <th>TV 단독</th>
                                    <th>인터넷<br/>+ TV</th>
                                    <th>TV추가<br/>(1대 당)</th>
                                    <th>인터넷 전화<br/>(인터넷과 동시<br/>진행시)</th>
                                    <th>일반 전화<br/>(인터넷과 동시<br/>진행시)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>평일</th>
                                    <td>36,300원</td>
                                    <td>34,100원</td>
                                    <td>56,100원</td>
                                    <td>+17,600원</td>
                                    <td>+13,200원</td>
                                    <td>+6,600원</td>
                                </tr>
                                <tr>
                                    <th>주말</th>
                                    <td>45,375원</td>
                                    <td>42,625원</td>
                                    <td>70,125원</td>
                                    <td>+22,500원</td>
                                    <td>+13,200원</td>
                                    <td>+6,600원</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <TextRowWrap gap={8} margin='10px 0 0'>
                        <FaCheckCircle color='#ff7500' size={20} />
                        <BasicText>최초 가입시 1회 설치비가 발생하며, 이사를 한다면 이전설치비가 청구됩니다(비용동일)</BasicText>
                    </TextRowWrap>
                    <TextRowWrap gap={8} margin='10px 0 0'>
                        <FaCheckCircle color='#ff7500' size={20} />
                        <BasicText>일요일, 공휴일은 설치가 불가능한 경우가 많습니다.</BasicText>
                    </TextRowWrap>
                </SectionWrap>
            </SectionWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const TableWrap = styled.div`
    overflow-x: auto;
    &>table{
        min-width: 560px;
    }
`;
const TableWrapItem = styled.table<{margin?:string}>`
    margin: ${({margin}:any)=> margin ?? '0'};
`;
const InnerStep02Wrap = styled.div`
    margin-top: 10px;
    background-color: #fbf5ec;
    padding: 20px;
`;
const TextRowWrap = styled(Row)<{margin?:string,gap?:number}>`
    margin: ${(({margin}) => margin ?? '0')};
    gap: ${(({gap}) => gap ?? 0)}px;
`;