import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { AdmBtnItem, AdminWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { useParams } from 'react-router';
import { BUNDLE_CATE } from '../data/dummy';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import AdmProductBundleHomePart from '../part/AdmProductBundleHomePart';
import { apiAdmSetBundleHomeAdd } from '../../../../api/api_adm_product';

const INIT = {
    product_001_code: '',
    product_002_code: '',
    product_005_code: '',
    product_006_code: '',
}
export default function AdmProductBundleAddPage() {
    const adminUser = useAdminUser();
    const {type} = useParams();
    const item = BUNDLE_CATE.filter((data:any)=>data.id == (type ?? 1));
    const name = item[0]?.name === undefined ? '' : item[0]?.name;
    const [info,setInfo] = useState<any>(INIT);

    // 등록
    const btnAction = async() =>{
        if(info?.company_code === undefined || info?.company_code === ''){
            alert('통신사를 선택해주세요');
            return;
        }
        if(info?.product_001_code === undefined || info?.product_001_code === ''){
            alert('인터넷을 선택해주세요');
            return;
        }
        if(info?.bundle_freegift_basic_price === undefined || info?.bundle_freegift_basic_price === ''){
            alert('사은품 금액을 입력해주세요');
            return;
        }
        const res = await apiAdmSetBundleHomeAdd(adminUser.admin_token,adminUser.admin_id,info,item[0].code,);
        if(res.result){
            alert('상품이 등록되었습니다');
            setInfo(INIT);
        }else{
            alert(res.error);
        }
    }

    // input 변경
    const valueChange = (name:string,val:any) =>{
        setInfo((obj:any)=>({...obj,[name]:val}));
    }

    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={18} mFontSize={16} fontWeight={700}>상품관리-{name}</BasicText>
                <InnerWrap>
                    <AdmProductBundleHomePart type={`0${type ?? ''}`} info={info} valueChange={valueChange} />
                    {/* {type === '1' &&
                        <AdmProductBundleHomePart type={`0${type ?? ''}`} info={info} valueChange={valueChange} />
                    } */}
                </InnerWrap>
                <Row style={{marginTop:'20px',gap:'15px'}}>
                    <AdmBtnItem bg={'#ad63ff'} padding='10px 25px' onClick={btnAction}>등록</AdmBtnItem>
                </Row>
            </AdminWrap>
           
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    width: 100%;
    max-width: 640px;
    margin-top: 30px;
`;