import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AdmBtnItem, AdminWrap, Center, Row, RowBetween } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import AdminPageTotalPart from '../../common/part/AdminPageTotalPart';
import { addTheme } from '../../../../style/addTheme';
import AdminTablePart from '../../common/part/AdminTablePart';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { useNavigate, useParams } from 'react-router';
import { ADM_INQUIRY_LIST } from '../../data/table';
import { MenuItem, TableCell, TableRow } from '@mui/material';
import { apiAdmGetInquiry, apiAdmGetProductInquiry, apiAdmSetInquiryDel, apiAdmSetInquiryProductDel } from '../../../../api/api_adm_inquiry';
import { momentFormat } from '../../../../utils/format/time';
import { INQUIRY_STATE } from '../../../../api/data';
import SelectItem from '../../../common/item/SelectItem';
import { apiGetInquiryCate } from '../../../../api/api_qna';
import AdmInquiryListItem from '../item/AdmInquiryListItem';
import AdmInquiryProductListItem from '../item/AdmInquiryProductListItem';

const MENU_LIST = [
    {id:1,name:'1:1 문의'},
    {id:2,name:'상품 문의'},
]

export default function AdmInquiryPage() {
    const {page,menu} = useParams();
    const navigate = useNavigate();
    const adminUser = useAdminUser();
    const [list,setList] = useState([]);
    const [total,setTotal] = useState(0);
    const [titleTotalReload,setTitleTotalReload] = useState(false);
    const [cate,setCate] = useState('0');
    const [cateList,setCateList] = useState<any>([]);

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        getCateList();
        if(menu === '1' || menu === undefined){
            getList();
        }
        if(menu === '2'){
            getProductList();
        }
    },[page,adminUser.admin_id,cate,menu]);

    // 1:1 문의 리스트 가져오기
    const getList = async() =>{
        const res = await apiAdmGetInquiry(adminUser.admin_token,adminUser.admin_id,page ?? 1, 15,cate);
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }

    // 상품 문의 리스트 가져오기
    const getProductList = async() =>{
        const res = await apiAdmGetProductInquiry(adminUser.admin_token,adminUser.admin_id,page ?? 1, 15);
        
        if(res.result){
            setList(res.data);
            setTotal(res.total);
        }
    }
    
    // 페이지 이동
    const setPage = (p:any) =>{
        navigate(`/adm/inquiry/${menu}/${p ?? 1}`);
    }

    // 추가페이지
    const goAdd = () =>{
        navigate(`/adm/inquiryUpdate`);
    }
    // 상세페이지
    const goUpdate = (id:any) =>{
        navigate(`/adm/inquiryAnswer/${id}`);
    }
    // 상품문의 상세
    const goProductUpdate = (id:any)=>{
        navigate(`/adm/inquiryProductAnswer/${id}`)
    }

    // 삭제
    const delClick = async(id:any) =>{
        if(window.confirm('1:1 문의를 삭제 하시겠습니까?')){
            const res = await apiAdmSetInquiryDel(adminUser.admin_token,adminUser.admin_id,id);
            if(res.result){
                alert('1:1 문의를 삭제했습니다.');
                getList();
                setTitleTotalReload(!titleTotalReload);
            }else{
                alert(res.error);
            }
        }
    }
    // 상품 문의 삭제
    const delProductClick = async(id:any) =>{
        if(window.confirm('상품문의를 삭제 하시겠습니까?')){
            const res = await apiAdmSetInquiryProductDel(adminUser.admin_token,adminUser.admin_id,id);
            if(res.result){
                alert('상품문의를 삭제했습니다.');
                getProductList();
                setTitleTotalReload(!titleTotalReload);
            }else{
                alert(res.error);
            }
        }
    }

    // 카테고리 리스트
    const getCateList = async() =>{
        const res = await apiGetInquiryCate(1,50);
        if(res.result){
            setCateList(res.data);
        }
    }

    // 카테고리 변경
    const cateChange = (val:any) =>{
        navigate('/adm/inquiry/1/1');
        setCate(val);
    }

    const tabChange = (id:any) =>{
        navigate(`/adm/inquiry/${id}/1`);
    }

    return (
        <Wrap>
            <AdminPageTotalPart title='전체 문의' part={menu === '2' ? 'productInquiry' : 'inquiry' } reload={titleTotalReload}>
                {menu === '2' ? 
                    <div style={{height:'36px'}}></div> :
                    <AdmBtnItem bg={addTheme.admBtn1} onClick={()=>goAdd()}>1:1 문의 등록</AdmBtnItem>
                }
            </AdminPageTotalPart>
            <AdminWrap>
                <TitleWrap>
                    <TitleInnerWrap>
                        {MENU_LIST.map((data:any,index:any)=>{
                            return(
                                <TitleMenuItem state={data.id == menu} key={`menu-${index}`} onClick={()=>tabChange(data.id)}>{data.name}</TitleMenuItem>
                            )
                        })}
                    </TitleInnerWrap>
                    {cateList === undefined || cateList.length === 0 || !(menu === '1') ?
                        <></> :
                        <SelectItem width='auto' value={cate} setValue={cateChange} none='no'>
                            <MenuItem value='0'>전체</MenuItem>
                            {cateList.map((data:any,index:any)=>{
                                return(
                                    <MenuItem key={`inquiry-sort-${index}`} value={data.inquiry_category_id}>{data.inquiry_title}</MenuItem>
                                )
                            })}
                        </SelectItem>
                    }
                </TitleWrap>
                <TableWrap>
                    {menu === '1' &&
                        <AdminTablePart bg={addTheme.adminColor} color='#fff' title={ADM_INQUIRY_LIST} minWidth={1000} page={page ?? 1} total={total} setPage={setPage}>
                            {list === undefined || list.length === 0 ?
                                <></> :
                                list.map((data:any,index:any)=>{
                                    return(
                                        <AdmInquiryListItem key={`review-item-${index}`} data={data} update={()=>goUpdate(data.inquiry_id)} del={()=>delClick(data.inquiry_id)}/>
                                    )
                                })
                            }
                        </AdminTablePart>
                    }
                    {menu === '2' &&
                        <AdminTablePart bg={addTheme.adminColor} color='#fff' title={ADM_INQUIRY_LIST} minWidth={1000} page={page ?? 1} total={total} setPage={setPage}>
                            {list === undefined || list.length === 0 ?
                                <></> :
                                list.map((data:any,index:any)=>{
                                    return(
                                        <AdmInquiryProductListItem key={`review-item-${index}`} data={data} update={()=>goProductUpdate(data.inquiry_product_id)} del={()=>delProductClick(data.inquiry_product_id)}/>
                                    )
                                })
                            }
                        </AdminTablePart>
                    }
                </TableWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;

const TitleWrap = styled(RowBetween)`
    flex-wrap: wrap;
`;

const TitleMenuItem = styled(Center)<{state: boolean}>`
    border: 1px solid ${({state}) => state ? '#898f98': '#e9e9e9'};
    border-bottom: none;
    width: 160px;
    height: 56px;
    cursor: pointer;
    text-align: center;
`;

const TitleInnerWrap = styled(Row)`
    width: auto;
`;

const TableWrap = styled.div`
    margin-top: 20px;
`;