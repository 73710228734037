import { axiosConfigInstance } from "./config"

// 유저 방문
export const apiGetMyOrder = async(user_name:string,user_phone_number:string,user_password:string) =>{
    try {
        const res = await await axiosConfigInstance('/api/order').post('',{
            method: 'getMyOrderList',
            params: {
                user_name,user_phone_number,user_password
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}
