import React from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { ContentWrap, InputWrap, ItemWrap, Row } from '../../../style/basic/commonStyle';
import { RELATION_VAl } from '../../applicationForm/data/dummy';

export default function MyPageBenefitPart({info}:any) {
    return (
        <Wrap>
            <BasicText fontWeight={700} fontSize={18}>사은품 계좌 정보</BasicText>
            <ContentWrap mt={20}>
                <ItemWrap>
                    <BasicText>받으시는 분</BasicText>
                    <InputWrap>
                        <BasicText>{info?.order_freegift?.order_freegift_name}</BasicText>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>가입자와의 관계</BasicText>
                    <InputWrap>
                        <BasicText>{RELATION_VAl[info?.order_freegift?.order_freegift_relation ?? '1']}</BasicText>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>주민등록번호</BasicText>
                    <InputWrap>
                        <BasicText>{info?.order_freegift?.order_freegift_resident_number}</BasicText>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>계좌번호</BasicText>
                    <InputWrap>
                        <BasicText>{info?.order_freegift?.order_freegift_bank} / {info?.order_freegift?.order_freegift_account_number}</BasicText>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>상품권</BasicText>
                    <InputWrap>
                        <BasicText>{info?.order_freegift?.freegift_name}</BasicText>
                    </InputWrap>
                </ItemWrap>
            </ContentWrap>
          
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
`;