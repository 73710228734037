import { Dialog, DialogContent, MenuItem } from '@mui/material';
import React, { useRef, useState } from 'react'
import styled from 'styled-components';
import BasicText from '../../../basic/BasicText';
import { AdmBtnItem, Center } from '../../../../style/basic/commonStyle';
import { addTheme } from '../../../../style/addTheme';
import SelectItem from '../../../common/item/SelectItem';
import { useNavigate } from 'react-router';
import { BUNDLE_CATE } from '../data/dummy';

export default function OpenBundlePart({open,setOpen}:any) {
    const ref = useRef<any>(null);
    const [cate,setCate] = useState(1);
    const navigate = useNavigate();

    const close = () =>{
        setOpen(false);
    }

    const handleComplete = () => {
        navigate(`/adm/productBundleAdd/${cate}`)
        close();
    }

    return (
        <Dialog
            ref={ref}
            open={open} 
            onClose={close}
            sx={{
                // '.MuiDialog-container':{alignItems:mediaQuery ? 'end' :'center',},
                '.MuiPaper-root':{margin:'8px', maxWidth:'460px',width:'100%',borderRadius: '6px'}
            }}
        >
            <DialogContent dividers={true}>
                <Wrap>
                    <BasicText align='center' fontSize={17}>입력할 상품 유형을 선택해주세요</BasicText>
                    <SelectItem value={cate} setValue={setCate} none='no' margin='20px 0 0'>
                        {BUNDLE_CATE.map((data:any,index:any)=>{
                            return(
                                <MenuItem key={`product-cate-${index}`} value={data.id}>{data.name}</MenuItem>
                            )
                        })}
                    </SelectItem>
                    <Center style={{marginTop:'80px'}}>
                        <AdmBtnItem bg={addTheme.admBtn4} onClick={handleComplete}>등록하기</AdmBtnItem>
                    </Center>
                </Wrap>
            </DialogContent>
        </Dialog>
    )
}

const Wrap = styled.div`
    padding: 20px 0;
`;
