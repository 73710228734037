import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AdminWrap, Center, ContentWrap, InputWrap, ItemWrap, Row, AdmBtnItem } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { addTheme } from '../../../../style/addTheme';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { onlyNumber } from '../../../../utils/format/string';
import { numberWithComma } from '../../../../utils/format/number';
import { apiAdmGetMinWithdrawal, apiAdmSetMinWithdrawal } from '../../../../api/api_adm_point';

export default function AdmPointSettingPage() {
    const adminUser = useAdminUser();
    const [point,setPoint] = useState('');

    useEffect(()=>{
        if(adminUser.admin_token === '') return;
        getDetail();
    },[adminUser.admin_token]);

    // 정보 가져오기
    const getDetail = async() =>{
        const res = await apiAdmGetMinWithdrawal(adminUser.admin_token,adminUser.admin_id);
        if(res.result){
            setPoint(res.data.min_point_amount);
        }
    }
    
    // 수정
    const btnAction = async() =>{
        if(point === ''){
            alert('포인트를 입력해주세요.');
            return;
        }
        if(window.confirm('최소 출금 포인트를 수정 하시겠습니까?')){
            const res = await apiAdmSetMinWithdrawal(adminUser.admin_token,adminUser.admin_id,point);
            if(res.result){
                alert('최소 출금 포인트를 수정했습니다.');
            }else{
                alert(res.error);
            }
        }
    }

    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={18} mFontSize={16} fontWeight={700}>포인트 설정</BasicText>
                <InnerWrap>
                    <ContentWrap>
                        <ItemWrap pWidth={180}>
                            <BasicText>최소 출금 신청 포인트</BasicText>
                            <InputWrap>
                                <input onChange={(e:any)=>setPoint(onlyNumber(e))} value={numberWithComma(point)} placeholder='최소 포인트 금액을 입력해주세요'/>
                            </InputWrap>
                        </ItemWrap>
                    </ContentWrap>
                </InnerWrap>
                <Row style={{marginTop:'20px',gap:'15px'}}>
                    <AdmBtnItem bg={addTheme.admBtn2} padding='10px 25px' onClick={btnAction}>포인트 수정</AdmBtnItem>
                </Row>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    width: 100%;
    max-width: 640px;
    margin-top: 30px;
`;