import React from 'react'
import styled from 'styled-components';
import { Center } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';

export default function BtnItem({title,titleColor,bg,clickEvent,w,h,border,mh}:any) {
    const click = () =>{
        if(clickEvent === undefined) return;
        clickEvent();
    }
    return (
        <Wrap>
            <Item onClick={click} bg={bg} titleColor={titleColor} w={w} h={h} mh={mh} border={border}>{title}</Item>
        </Wrap>
    )
}

const Wrap = styled(Center)`
    margin-top: 60px;
`;

const Item = styled(Center)<{bg?:string,titleColor?:string,w?:number,h?:number,border?:boolean,mh?:number}>`
    background-color: ${({bg})=> bg ?? addTheme.color};
    border-radius: 30px;
    width: ${({w}) => w ?? 300}px;
    height: ${({h}) => h ?? 50}px;
    max-width: 100%;
    color: ${({titleColor}) => titleColor ?? '#fff'};
    font-weight: 700;
    font-size: 20px;
    cursor: pointer;
    border: ${({border}) => border ? `1px solid ${addTheme.color}` : 'none'};
    @media ${addTheme.media.s}{
        font-size: 18px;
        height: ${({h,mh}) => (mh ?? h) ?? 50}px;
    }
`;