import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LayoutPage from '../../common/page/LayoutPage';
import { Center, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import { useLocation, useNavigate } from 'react-router';
import validator from 'validator';
import { apiGetPartnerKakaoLogin, apiGetPartnerLogin } from '../../../api/api_partner';
import { useDispatch } from 'react-redux';
import { setGlobalPartner } from '../../../store/common/globalPartner';
import { useGlobalPartner } from '../../../utils/custom-hooks/common/useGlobalPartner';
import { KAKAO_REG_LINK } from '../../../api/config';

export default function PartnerLoginPage() {
    const navigate = useNavigate();
    const [email,setEmail] = useState('');
    const [pw,setPw] = useState('');
    const dispatch = useDispatch();
    const location = useLocation();
    const pc = new URLSearchParams(location.search).get('pc') ?? '';
    const lc = new URLSearchParams(location.search).get('lc') ?? '';
    const partner = useGlobalPartner();

    useEffect(()=>{
        if(lc !== ''){
            kakaoLoginAction();
            return
        }
        // if(pc !== ''){
        //     alert('가입되지 않은 계정입니다.');
        //     navigate(`/partnerJoin?pc=${pc}`);
        //     return
        // }
    },[pc,lc])

    useEffect(()=>{
        if(partner.partner_code === '') return;
        navigate('/partnerMyPage/myInfo');
    },[partner.partner_code])

    const loginAction = async(e:any) =>{
        e.preventDefault();
        const emailChk = validator.isEmail(email);
        if(!emailChk){
            alert('이메일을 확인해주세요.');
            return;
        }
        const res = await apiGetPartnerLogin(email,pw);
        if(res.result){
            dispatch(setGlobalPartner({...res.data}));
        }else{
            alert(res.error);
        }
    }

    const goPage = () =>{
        navigate('/partnerJoin');
    }

    const kakaoLogin = (e:any)=>{
        window.location.href = `${KAKAO_REG_LINK}/api/social/oauth/kakao`;
    }

    const kakaoLoginAction = async() =>{
        const res = await apiGetPartnerKakaoLogin(lc);
        if(res.result){
            dispatch(setGlobalPartner({...res.data}));
            navigate('/partnerMyPage/myInfo');
        }else{
            alert(res.error);
        }
    }

    return (
        <LayoutPage>
            <MaxWidthWrap>
                <TitleWrap>
                    <BasicText fontSize={36} mFontSize={22} fontWeight={700} align='center'>현주친구 파트너스 로그인</BasicText>
                </TitleWrap>
                <Wrap>
                    <form onSubmit={loginAction}>
                        <ContentWrap>
                            <ItemWrap>
                                <BasicText fontSize={17}>이메일</BasicText>
                                <ItemInputWrap>
                                    <input value={email} onChange={(e:any)=> setEmail(e.target.value)} required placeholder='이메일을 입력해주세요.'></input>
                                </ItemInputWrap>
                            </ItemWrap>
                            <ItemWrap>
                                <BasicText fontSize={17}>비밀번호</BasicText>
                                <ItemInputWrap>
                                    <input type='password' value={pw} onChange={(e:any)=> setPw(e.target.value)} required placeholder='비밀번호를 입력해주세요.'></input>
                                </ItemInputWrap>
                            </ItemWrap>
                        </ContentWrap>
                        <BtnWrap type='submit'>로그인</BtnWrap>
                    </form>
                    <SnsWrap>
                        <BasicText fontSize={17} align='center'>SNS 계정으로 간편 로그인</BasicText>
                        <LoginBtnItem bg='#ffe500' onClick={kakaoLogin}>
                            <img src="/img/asset/icon15.png" alt="kakao-icon" />
                            카카오 로그인
                        </LoginBtnItem>
                    </SnsWrap>
                    <BasicText align='center' marginTop={5}>아이디가 없으신가요? <span style={{textDecoration:'underline',cursor:'pointer'}} onClick={goPage}>이메일로 회원가입</span></BasicText>
                </Wrap>
            </MaxWidthWrap>
        </LayoutPage>
    )
}

const TitleWrap = styled.div`
    margin-top: 150px;
`;

const Wrap = styled.div`
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    padding-bottom: 50px;
`;

const ContentWrap = styled(Row)`
    margin-top: 50px;
    align-items: start;
    flex-direction: column;
    gap: 15px;
`;

const ItemWrap = styled(Row)<{align?:string}>`
    align-items: ${({align}) => align ?? 'center'};
    &>p{
        width: 185px;
        flex-shrink: 0;
    }
    @media ${addTheme.media.sx}{
        flex-wrap: wrap;
    }
`;

const ItemInputWrap = styled(Row)`
    border: 1px solid #e6e6e6;
    padding: 10px 14px;
    border-radius: 6px;
    & input{
        width: 100%;
        padding: 4px;
    }
    & textarea{
        width: 100%;
    }
`;

const BtnWrap = styled.button`
    width: 100%;
    background-color: ${addTheme.color};
    height: 52px;
    color: #fff;
    border-radius: 6px;
    margin-top: 50px;
`;

const SnsWrap = styled.div`
    margin-top: 60px;
`;

const LoginBtnItem = styled(Center)<{bg?:string}>`
    gap: 8px;
    margin-top: 20px;
    border-radius: 6px;
    background-color: ${({bg}) => bg ?? '#fff'};
    padding: 10px;
    cursor: pointer;
    & img{
        width: 28px;
    }
`;