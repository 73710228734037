import { axiosConfigInstance } from "./config";

// 방문자 수
export const apiAdmGetVisit = async(token:string,admin_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/stat',token).post('',{
            method: 'adminGetStatUserVisitCount',
            params:{
                admin_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 방문자 차트
export const apiAdmGetChartVisit = async(token:string,admin_id:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/stat',token).post('',{
            method: 'adminGetStatChartUserVisit',
            params:{
                admin_id,count,period_str:"1day"
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 주문 차트
export const apiAdmGetChartOrder = async(token:string,admin_id:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/stat',token).post('',{
            method: 'adminGetStatChartOrder',
            params:{
                admin_id,count,period_str:"1day"
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 문의 차트
export const apiAdmGetChartInquiry = async(token:string,admin_id:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/stat',token).post('',{
            method: 'adminGetStatChartInquiry',
            params:{
                admin_id,count,period_str:"1day"
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 리뷰 차트
export const apiAdmGetChartReview = async(token:string,admin_id:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/stat',token).post('',{
            method: 'adminGetStatChartReview',
            params:{
                admin_id,count,period_str:"1day"
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 이번달 총 포인트 / 출금 포인트
export const apiAdmGetMonthPoint = async(token:string,admin_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/stat',token).post('',{
            method: 'adminGetStatPoint',
            params:{
                admin_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

