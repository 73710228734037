import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AdmBtnItem, AdminWrap, Center, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { Grid } from '@mui/material';
import { useParams } from 'react-router';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { addTheme } from '../../../../style/addTheme';
import AdmOrderUpdateProcessPart from '../part/AdmOrderUpdateProcessPart';
import { apiAdmGetOrderDetail, apiAdmSetOrderUpdateBenefit, apiAdmSetOrderUpdateOrderer, apiAdmSetOrderUpdatePayment, apiAdmSetOrderUpdateProduct } from '../../../../api/api_adm_order';
import AdmOrderUpdateOrdererPart from '../part/AdmOrderUpdateOrdererPart';
import AdmOrderUpdateProductPart from '../part/AdmOrderUpdateProductPart';
import AdmOrderUpdatePaymentPart from '../part/AdmOrderUpdatePaymentPart';
import AdmOrderUpdateBenefitPart from '../part/AdmOrderUpdateBenefitPart';

const MENU_LIST = [
    {id:1, name: '고객 정보'},
    {id:2, name: '상품 정보'},
    {id:3, name: '납부자 정보'},
    {id:4, name: '혜택 정보'},
]

const MENU_NAME:any = {
    1: '고객정보변경',
    2: '상품정보변경',
    3: '납부자정보변경',
    4: '혜택정보변경',
}

export default function AdmOrderUpdatePage() {
    const {code} = useParams();
    const adminUser = useAdminUser();
    const [info,setInfo] = useState<any>({});
    const [menu,setMenu] = useState(1);

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        if(code === undefined) return;
        getDetail();
    },[adminUser.admin_id,code]);

    // 디테일 정보 가져오기
    const getDetail = async() =>{
        const res = await apiAdmGetOrderDetail(adminUser.admin_token,adminUser.admin_id,code);
        if(res.result){
            setInfo(res.data);
        }
    }

    // input 데이터 변경
    const valueChange = (objName:string,name:string, val:any)=>{
        setInfo((obj:any)=>({
            ...obj,
            [objName]: {
                ...obj[objName],
                [name]:val,
            },
        }))
    }

    const btnAction = async() =>{
        if(window.confirm('변경하시겠습니까?')){
            if(menu === 1){
                const res = await apiAdmSetOrderUpdateOrderer(adminUser.admin_token,adminUser.admin_id,info);
                if(res.result){
                    alert(`${MENU_NAME[menu]} 되었습니다.`);
                }else{
                    alert(res.error);
                }
            }
            if(menu === 2){
                const res = await apiAdmSetOrderUpdateProduct(adminUser.admin_token,adminUser.admin_id,info);
                if(res.result){
                    alert(`${MENU_NAME[menu]} 되었습니다.`);
                }else{
                    alert(res.error);
                }
            }
            if(menu === 3){
                const res = await apiAdmSetOrderUpdatePayment(adminUser.admin_token,adminUser.admin_id,info);
                if(res.result){
                    alert(`${MENU_NAME[menu]} 되었습니다.`);
                }else{
                    alert(res.error);
                }
            }
            if(menu === 4){
                const res = await apiAdmSetOrderUpdateBenefit(adminUser.admin_token,adminUser.admin_id,info);
                if(res.result){
                    alert(`${MENU_NAME[menu]} 되었습니다.`);
                }else{
                    alert(res.error);
                }
            }
        }
    }
    
    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <ItemWrap pWidth={120}>
                    <BasicText fontSize={18} mFontSize={16} fontWeight={700}>주문관리</BasicText>
                    <div>
                        <BasicText fontSize={17} mFontSize={15} color={addTheme.admBtn2}>{code}</BasicText>
                    </div>
                </ItemWrap>
                <InnerWrap>
                    <Grid container={true} spacing={4}>
                        <Grid item md={6} xs={12}>
                            <BasicText marginBottom={2} fontSize={17} mFontSize={16} fontWeight={700}>처리정보</BasicText>
                            <AdmOrderUpdateProcessPart info={info} valueChange={valueChange} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TitleWrap>
                                {MENU_LIST.map((data:any,index:any)=>{
                                    return(
                                        <MenuItem state={data.id == menu} key={`menu-${index}`} onClick={()=>setMenu(data.id)}>{data.name}</MenuItem>
                                    )
                                })}
                            </TitleWrap>
                            <ContentWrap>
                                {menu === 1 &&
                                    <AdmOrderUpdateOrdererPart info={info} valueChange={valueChange} />
                                }
                                {menu === 2 &&
                                    <AdmOrderUpdateProductPart info={info} valueChange={valueChange} />
                                }
                                {menu === 3 &&
                                    <AdmOrderUpdatePaymentPart info={info} valueChange={valueChange} />
                                }
                                {menu === 4 &&
                                    <AdmOrderUpdateBenefitPart info={info} valueChange={valueChange} />
                                }
                                <Row style={{marginTop:'30px'}}>
                                    <AdmBtnItem onClick={btnAction} bg={addTheme.admBtn1}>{MENU_NAME[menu]}</AdmBtnItem>
                                </Row>
                            </ContentWrap>
                        </Grid>
                    </Grid>
                </InnerWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    margin-top: 30px;
`;

const TitleWrap = styled(Row)``;
const MenuItem = styled(Center)<{state: boolean}>`
    border: 1px solid ${({state}) => state ? '#898f98': '#e9e9e9'};
    border-bottom: none;
    width: 160px;
    height: 56px;
    cursor: pointer;
    text-align: center;
`;

const ContentWrap = styled.div`
    margin-top: 20px;
`;