import { axiosConfigInstance } from "./config"

// 가입 신청하기
export const apiSetPartnerApply = async(info:any) =>{
    try {
        const res = await await axiosConfigInstance('/api/partners/apply').post('',{
            method: 'applyPartnersAccount',
            params: {
                ...info,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 이메일 중복 체크
export const apiSetPartnerEmailChk = async(partner_email:any) =>{
    try {
        const res = await await axiosConfigInstance('/api/partners/apply').post('',{
            method: 'checkPartnersAccountEmail',
            params: {
                partner_email
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 휴대폰번호 중복 체크
export const apiSetPartnerPhoneChk = async(partner_phone_number:any) =>{
    try {
        const res = await await axiosConfigInstance('/api/partners/apply').post('',{
            method: 'checkPartnersAccountPhoneNumber',
            params: {
                partner_phone_number
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 카카오 정보 가져오기
export const apiGetKakaoInfo = async(temp_partner_code:any) =>{
    try {
        const res = await await axiosConfigInstance('/api/partners/apply').post('',{
            method: 'getTempPartnerInfo',
            params: {
                temp_partner_code
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 파트너 일반 로그인
export const apiGetPartnerLogin = async(partner_email:any,partner_password:any) =>{
    try {
        const res = await await axiosConfigInstance('/api/partners/apply').post('',{
            method: 'partnerAccountLogin',
            params: {
                partner_email,partner_password
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 파트너 카카오톡 로그인
export const apiGetPartnerKakaoLogin = async(login_code:any) =>{
    try {
        const res = await await axiosConfigInstance('/api/partners/apply').post('',{
            method: 'partnerAccountSocialLogin',
            params: {
                login_code
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 파트너 출금 인증
export const apiSetPartnerAuth = async(partner_code:any,partner_resident_number:any,partner_password:any) =>{
    try {
        const res = await await axiosConfigInstance('/api/partners/apply').post('',{
            method: 'applyPartnerAuth',
            params: {
                partner_code,
                partner_resident_number,
                partner_password
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 파트너 보유 포인트, 최소 출금 포인트 가져오기
export const apiGetPartnerPoint = async(partner_code:any,) =>{
    try {
        const res = await await axiosConfigInstance('/api/partners/apply').post('',{
            method: 'getPartnerWithdrawalInfo',
            params: {
                partner_code,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 파트너 출금
export const apiSetPartnerPoint = async(partner_code:any,withdrawal_point:any) =>{
    try {
        const res = await await axiosConfigInstance('/api/partners/apply').post('',{
            method: 'applyPartnerWithdrawal',
            params: {
                partner_code,
                withdrawal_point,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 파트너 포인트 내역
export const apiGetPartnerPointList = async(partner_code:any,page:any,count:any) =>{
    try {
        const res = await await axiosConfigInstance('/api/partners/apply').post('',{
            method: 'getPartnerPointTxList',
            params: {
                partner_code,
                page,
                count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}
