import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { AdmBtnItem, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { FormControlLabel, MenuItem, Radio } from '@mui/material';
import { onlyNumber } from '../../../../utils/format/string';
import { apiGetOuterBundle, apiGetPayment, apiGetTelList, apiGetTotalResPay } from '../../../../api/api_home';
import SelectItem from '../../../common/item/SelectItem';
import { numberWithComma } from '../../../../utils/format/number';

export default function AdmOrderUpdateProductPart({info,valueChange}:any) {
    const [telList,setTelList] = useState([]);
    const [list,setList] = useState<any>();
    const [pay,setPay] = useState<any>();

    useEffect(()=>{
        if(info?.order_basic?.bundle_type === undefined) return;
        getTelList();
        getList();
    },[info?.order_basic?.bundle_type,info?.order_basic?.company_code]);

    useEffect(()=>{
        if(info?.order_basic?.company_code === undefined || info?.order_basic?.bundle_product_001 === undefined) return;
        if(info?.order_basic?.bundle_type === '01'){
            getPay();
        }else{
            getOuterBundle();
        }
    },[info?.order_basic?.company_code,info?.order_basic?.bundle_product_001,info?.order_basic?.bundle_product_002,info?.order_basic?.bundle_product_005,info?.order_basic?.bundle_type])

    const getTelList = async() =>{
        const res = await apiGetTelList(info?.order_basic?.bundle_type);
        if(res.result){
            setTelList(res.data);
        }
    }

    // 인터넷,tv,전화 리스트
    const getList = async() =>{
        const res = await apiGetPayment(info?.order_basic?.company_code,info?.order_basic?.bundle_type);
        if(res.result){
            setList(res.data);
            if(info?.order_basic?.bundle_type === '01'){
                if(info?.order_basic.bundle_product_001 === undefined) return;
                if(info?.order_basic.bundle_product_001 === ''){
                    valueChange('order_basic','bundle_product_001',res.data.internet[0].product_code);
                }
            }
        }
    }

    // 통신사 수정
    const telChange = (val:any) =>{
        valueChange('order_basic','company_code',val);
        valueChange('order_basic','bundle_product_001','');
        valueChange('order_basic','bundle_product_002','');
        valueChange('order_basic','bundle_product_005','');
        setPay(undefined);
    }

    // 홈 일때 가격,번들 가져오기
    const getPay = async() =>{
        const res = await apiGetTotalResPay(info?.order_basic?.company_code,info?.order_basic?.bundle_product_001,info?.order_basic?.bundle_product_002 ?? '',info?.order_basic?.bundle_product_005 ?? '', '');
        if(res.result){
            setPay(res.data);
            valueChange('order_basic','bundle_code',res.data.info.bundle_code);
        }
    }

    // 홈 아닐때 번들 가져오기
    const getOuterBundle = async() =>{
        const res = await apiGetOuterBundle(info?.order_basic?.company_code,info?.order_basic?.bundle_type);
        if(res.result){
            valueChange('order_basic','bundle_code',res.data.bundle_code);
        }
    }

    return (
        <Wrap>
            <ContentWrap gap={15}>
                <ItemWrap pWidth={120}>
                    <BasicText>상품 유형</BasicText>
                    <Row>
                        <FormControlLabel control={<Radio name='bundle-type' value={'01'} checked={info?.order_basic?.bundle_type === '01'} />} label="홈" />
                        <FormControlLabel control={<Radio name='bundle-type' value={'02'} checked={info?.order_basic?.bundle_type === '02'} />} label="매장" />
                        <FormControlLabel control={<Radio name='bundle-type' value={'03'} checked={info?.order_basic?.bundle_type === '03'} />} label="오피스" />
                        <FormControlLabel control={<Radio name='bundle-type' value={'04'} checked={info?.order_basic?.bundle_type === '04'} />} label="다회선" />
                    </Row>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>통신사</BasicText>
                    {telList === undefined || telList.length === 0 ?
                        <></> :
                        <SelectItem value={info?.order_basic?.company_code ?? ''} setValue={(val:any)=>telChange(val)} none='no'>
                            <MenuItem value={''} disabled>통신사를 선택해주세요</MenuItem>
                            {telList.map((data:any,index:any)=>{
                                return(
                                    <MenuItem key={`tel-${index}`} value={data.company_code}>{data.company_name}</MenuItem>
                                )
                            })}
                        </SelectItem>
                    }
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>인터넷</BasicText>
                    {list?.internet === undefined || list?.internet.length === 0 ?
                        <InputWrap>
                            <BasicText minHeight='18px'></BasicText>
                        </InputWrap> :
                        <SelectItem value={info?.order_basic?.bundle_product_001 ?? ''} setValue={(val:any)=>valueChange('order_basic','bundle_product_001',val)} none='no'>
                            <MenuItem value={''} disabled>인터넷을 선택해주세요</MenuItem>
                            {list?.internet.map((data:any,index:any)=>{
                                return(
                                    <MenuItem key={`home-internet-${index}`} value={data.product_code}>{data.product_name}</MenuItem>
                                )
                            })}
                        </SelectItem>
                    }
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>TV</BasicText>
                    {list?.tv === undefined || list?.tv.length === 0 ?
                        <InputWrap>
                            <BasicText minHeight='18px'></BasicText>
                        </InputWrap> :
                        <SelectItem value={info?.order_basic?.bundle_product_002 ?? ''} setValue={(val:any)=>valueChange('order_basic','bundle_product_002',val)} none='no'>
                            <MenuItem value={''}>선택안함</MenuItem>
                            {list?.tv.map((data:any,index:any)=>{
                                return(
                                    <MenuItem key={`home-tv-${index}`} value={data.product_code}>{data.product_name}</MenuItem>
                                )
                            })}
                        </SelectItem>
                    }
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>전화</BasicText>
                    {list?.landline === undefined || list?.landline.length === 0 ?
                        <InputWrap>
                            <BasicText minHeight='18px'></BasicText>
                        </InputWrap> :
                        <SelectItem value={info?.order_basic?.bundle_product_005 ?? ''} setValue={(val:any)=>valueChange('order_basic','bundle_product_005',val)} none='no'>
                            <MenuItem value={''}>선택안함</MenuItem>
                            {list?.landline.map((data:any,index:any)=>{
                                return(
                                    <MenuItem key={`home-landline-${index}`} value={data.product_code}>{data.product_name}-{data.product_summary}</MenuItem>
                                )
                            })}
                        </SelectItem>
                    }
                </ItemWrap>
                <ItemWrap pWidth={120} align='start'>
                    <BasicText marginTop={1}>추가결합메모</BasicText>
                    <InputWrap>
                        <textarea rows={5} onChange={(e:any)=>valueChange('order_basic','order_combine_comment',e.target.value)} value={info?.order_basic?.order_combine_comment ?? ''} />
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>월납부</BasicText>
                    <InputWrap>
                        {/* <input readOnly value={numberWithComma(pay?.calc?.combine_price ?? '')}/> */}
                        <BasicText minHeight='18px'>{numberWithComma(pay?.calc?.combine_price ?? '')}</BasicText>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>설치비 평일</BasicText>
                    <InputWrap>
                        {/* <input readOnly value={numberWithComma(pay?.calc?.equip_weekday_price ?? '')}/> */}
                        <BasicText minHeight='18px'>{numberWithComma(pay?.calc?.equip_weekday_price ?? '')}</BasicText>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap pWidth={120}>
                    <BasicText>설치비 주말</BasicText>
                    <InputWrap>
                        {/* <input readOnly value={numberWithComma(pay?.calc?.equip_weekend_price ?? '')}/> */}
                        <BasicText minHeight='18px'>{numberWithComma(pay?.calc?.equip_weekend_price ?? '')}</BasicText>
                    </InputWrap>
                </ItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;