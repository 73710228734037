import React, { useEffect, useState } from 'react'
import { ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle'
import BasicText from '../../../basic/BasicText'
import SelectItem from '../../../common/item/SelectItem'
import { MenuItem } from '@mui/material'
import { apiGetProductType, apiGetTelList } from '../../../../api/api_home'
import { onlyNumber, speedChange } from '../../../../utils/format/string'
import { numberWithComma } from '../../../../utils/format/number'

export default function AdmProductTvPart({info,valueChange}:any) {
    const [telList,setTelList] = useState([]);
    const [typeList,setTypeList] = useState<any>([]);

    useEffect(()=>{
        getTelList();
        getTypeList();
    },[])

    // 통신사 리스트
    const getTelList = async() =>{
        const res = await apiGetTelList('01');
        if(res.result){
            setTelList(res.data);
        }
    }

    // 타입 리스트
    const getTypeList = async() =>{
        const res = await apiGetProductType(1,50);
        if(res.result){
            const newList:any = [];
            res.data.map((data:any)=>{
                if(data.category_code === '01' || data.category_code === '02' || data.category_code === '03'){
                    newList.push(data);
                }
            })
            setTypeList(newList);
            if(info?.product_category_code === undefined){
                valueChange('product_category_code',newList[0].category_code);
            }
        }
    }
    
    return (
        <ContentWrap>
            <ItemWrap pWidth={120}>
                <BasicText>타입</BasicText>
                {typeList === undefined || typeList.length === 0 ?
                    <></> :
                    <SelectItem required value={info?.product_category_code ?? ''} setValue={(val:any)=>valueChange('product_category_code',val)} none='no'>
                        {typeList.map((data:any,index:any)=>{
                            return(
                                <MenuItem value={data.category_code} key={`internet-cate-${index}`}>{data.category_name}</MenuItem>
                            )
                        })}
                    </SelectItem>
                }
            </ItemWrap>
            <ItemWrap pWidth={120}>
                <BasicText>통신사</BasicText>
                {telList === undefined || telList.length === 0 ?
                    <></> :
                    <SelectItem required value={info?.company_code ?? ''} setValue={(val:any)=>valueChange('company_code',val)} none='no'>
                        <MenuItem value={''} disabled>통신사 정보를 선택해주세요</MenuItem>
                        {telList.map((data:any,index:any)=>{
                            return(
                                <MenuItem key={`internet-tel-${index}`} value={data.company_code}>{data.company_name}</MenuItem>
                            )
                        })}
                    </SelectItem>
                }
            </ItemWrap>
            <ItemWrap pWidth={120}>
                <BasicText>제목명</BasicText>
                <InputWrap>
                    <input required value={info?.product_name ?? ''} onChange={(e:any)=>valueChange('product_name',e.target.value)} placeholder='상품 데이터 명을 입력해주세요'/>
                </InputWrap>
            </ItemWrap>
            <ItemWrap pWidth={120}>
                <BasicText>지원채널수</BasicText>
                <InputWrap>
                    <input required value={numberWithComma(info?.product_channel_count ?? '')} onChange={(e:any)=>valueChange('product_channel_count',onlyNumber(e))} placeholder='지원하는 채널 수를 입력해주세요'/>
                </InputWrap>
            </ItemWrap>
            <ItemWrap pWidth={120}>
                <BasicText>기본가</BasicText>
                <InputWrap>
                    <input required value={numberWithComma(info?.product_basic_price ?? '')} onChange={(e:any)=>valueChange('product_basic_price',onlyNumber(e))} placeholder='기본 가격을 입력해주세요'/>
                </InputWrap>
            </ItemWrap>
            <ItemWrap pWidth={120}>
                <BasicText>셋탑박스</BasicText>
                <InputWrap>
                    <input required value={numberWithComma(info?.product_settop_price_gap ?? '')} onChange={(e:any)=>valueChange('product_settop_price_gap',onlyNumber(e))} placeholder='셋탑박스 가격을 입력해주세요'/>
                </InputWrap>
            </ItemWrap>
            <ItemWrap pWidth={120} align='start'>
                <BasicText marginTop={1}>공유기</BasicText>
                <Row style={{flexDirection:'column', gap:'8px'}}>
                    {info?.product_combine_internet_router_price === undefined || info?.product_combine_internet_router_price.length === 0 ?
                        <></> :
                        info?.product_combine_internet_router_price.map((data:any,index:any)=>{
                            
                            return(
                                <ItemWrap pWidth={140} key={`tv-router-${index}`}>
                                    <BasicText>{data.product_name}: {data.product_max_speed}</BasicText>
                                    <InputWrap>
                                        <input required value={numberWithComma(data.router_price ?? '')} onChange={(e)=>valueChange(`product_combine_internet_router_price-${index}`,onlyNumber(e))}  placeholder='공유기 가격을 입력해주세요'/>
                                    </InputWrap>
                                </ItemWrap>
                            )
                        })
                    }
                </Row>
            </ItemWrap>
        </ContentWrap>
    )
}

