import { axiosConfigInstance } from "./config"

// 파트너 리스트 가져오기
export const apiAdmGetPartnerList = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/partner',token).post('',{
            method: 'adminGetPartnerList',
            params: {
                admin_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 파트너 리스트 검색 가져오기
export const apiAdmGetPartnerSearchList = async(token:string,admin_id:any,page:any,count:any,search_query:string) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/partner',token).post('',{
            method: 'adminSearchPartnerList',
            params: {
                admin_id,page,count,search_query
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 파트너 디테일
export const apiAdmGetPartnerDetail = async(token:string,admin_id:any,partner_code:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/partner',token).post('',{
            method: 'adminPartnerDetailInfo',
            params: {
                admin_id,partner_code
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 파트너 디테일 수정
export const apiAdmSetPartnerUpdate = async(token:string,admin_id:any,info:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/partner',token).post('',{
            method: 'adminEditPartnerInfo',
            params: {
                admin_id,
                partner_code: info.partner_account_code,
                partner_name: info.partner_account_name,
                partner_resident_number: info.partner_account_resident_number,
                partner_type: info.partner_account_type,
                partner_business_number: info.partner_account_business_number,
                partner_business_name: info.partner_account_business_name,
                partner_phone_number: info.partner_account_phone_number,
                partner_email: info.partner_account_email,
                partner_birth: info.partner_account_birth,
                partner_account_bank: info.partner_account_bank,
                partner_account_bank_number: info.partner_account_bank_account_number,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 파트너 디테일 메모 수정
export const apiAdmSetPartnerMemoUpdate = async(token:string,admin_id:any,partner_code:any,partner_account_memo:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/partner',token).post('',{
            method: 'adminEditPartnerMemo',
            params: {
                admin_id,partner_code,partner_account_memo
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}


// 파트너 디테일 포인트 리스트
export const apiAdmGetPartnerDetailPointList = async(token:string,admin_id:any,partner_code:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/point',token).post('',{
            method: 'adminGetPartnerPointTxList',
            params: {
                admin_id,partner_code,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 파트너 상태 변경
// [거절된 상태일 때] partner_status = 1만 가능
// [승인된 상태일 때]partner_status = 2만 가능
// [대기 중일 때] partner_status = 1 or 2만 가능
// partner status: 0: 대기중 / 1: 승인 / 2: 거절
export const apiAdmSetPartnerStatus = async(token:string,admin_id:any,partner_code:string,partner_status:string) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/partner',token).post('',{
            method: 'adminChangePartnerStatus',
            params: {
                admin_id,partner_code,partner_status
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 파트너 포인트 리스트
export const apiAdmGetPartnerPointList = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/partner',token).post('',{
            method: 'adminGetPointPartnerList',
            params: {
                admin_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}