import React from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { ContentWrap, InputWrap, ItemWrap, Row } from '../../../style/basic/commonStyle';
import { USER_TYPE_VAL } from '../../applicationForm/data/dummy';

export default function MyPageInfoPart({info}:any) {
    return (
        <Wrap>
            <BasicText fontWeight={700} fontSize={18}>가입자 정보</BasicText>
            <ContentWrap mt={10}>
                <ItemWrap>
                    <BasicText>고객 유형</BasicText>
                    <InputWrap>
                        <BasicText>{USER_TYPE_VAL[info?.order_orderer?.orderer_type ?? '1']}</BasicText>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>가입자명</BasicText>
                    <InputWrap>
                        <BasicText>{info?.order_orderer?.orderer_name}</BasicText>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>주민등록번호</BasicText>
                    <InputWrap>
                        <BasicText>{info?.order_orderer?.orderer_resident_number}</BasicText>
                    </InputWrap>
                </ItemWrap>
                <ItemWrap>
                    <BasicText>가입자 명의 연락처</BasicText>
                    <InputWrap>
                        <BasicText>{info?.order_orderer?.orderer_phone_number}</BasicText>
                    </InputWrap>
                </ItemWrap>
                {info?.order_orderer?.orderer_add_phone_number === '' ?
                    <></> :
                    <ItemWrap>
                        <BasicText>추가 연락처</BasicText>
                        <InputWrap>
                            <BasicText>{info?.order_orderer?.orderer_add_phone_number}</BasicText>
                        </InputWrap>
                    </ItemWrap>
                }
                <ItemWrap>
                    <BasicText>이메일</BasicText>
                    <InputWrap>
                        <BasicText>{info?.order_orderer?.orderer_email}</BasicText>
                    </InputWrap>
                </ItemWrap>
            </ContentWrap>
            <InnerWrap>
                <BasicText fontWeight={700} fontSize={18}>설치 주소 정보</BasicText>
                <ContentWrap mt={10}>
                    <ItemWrap align='start'>
                        <BasicText marginTop={1}>주소</BasicText>
                        <ContentItemWrap>
                            <InputWrap max='100px'>
                                <BasicText>{info?.order_orderer?.orderer_post_code}</BasicText>
                            </InputWrap>
                            <InputWrap>
                                <BasicText>{info?.order_orderer?.orderer_address}</BasicText>
                            </InputWrap>
                            <InputWrap>
                                <BasicText>{info?.order_orderer?.orderer_address_detail}</BasicText>
                            </InputWrap>
                        </ContentItemWrap>
                    </ItemWrap>
                    <ItemWrap>
                        <BasicText>설치예정일</BasicText>
                        <InputWrap>
                            <BasicText minHeight='24px'>{info?.order_eqiup_date}</BasicText>
                        </InputWrap>
                    </ItemWrap>
                </ContentWrap>
            </InnerWrap>
            <InnerWrap>
                <BasicText fontWeight={700} fontSize={18}>추천인 정보</BasicText>
                <ContentWrap mt={10}>
                    <ItemWrap>
                        <BasicText>추천인 코드</BasicText>
                        <InputWrap>
                            <BasicText minHeight='24px'>{info?.order_refer_code}</BasicText>
                        </InputWrap>
                    </ItemWrap>
                </ContentWrap>
            </InnerWrap>
            <InnerWrap>
                <BasicText fontWeight={700} fontSize={18}>요청사항</BasicText>
                <ContentWrap mt={10}>
                    <ItemWrap align='start'>
                        <BasicText marginTop={1}>요청사항</BasicText>
                        <InputWrap>
                            <BasicText minHeight='100px' wrapText={'true'}>{info?.order_request_comment}</BasicText>
                        </InputWrap>
                    </ItemWrap>
                </ContentWrap>
            </InnerWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;
`;

const InnerWrap = styled.div`
    margin-top: 30px;
`;

const ContentItemWrap = styled(Row)`
    flex-direction: column;
    gap: 8px;
    align-items: start;
`;