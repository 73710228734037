import React from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { Center, Row } from '../../../style/basic/commonStyle';
import { FaCheckCircle } from 'react-icons/fa';
import { addTheme } from '../../../style/addTheme';
import { useMediaQuery } from '@mui/material';
import { LIST } from '../data/data';

export default function NeedPart() {
    const mediaQuery = useMediaQuery(addTheme.media.s);
    return (
        <Wrap>
            <BasicText marginTop={9} fontWeight={700} fontSize={26} mFontSize={20} >이 업종이면 다회선이 필요해요!</BasicText>
            <ContentWrap>
                {LIST.map((data:any,index:any)=>{
                    return(
                        <ContentItemWrap key={`need-${index}`}>
                            <img alt={data.name} src={data.img}></img>
                            <HoverWrap>
                                <FaCheckCircle color={addTheme.color} size={mediaQuery ? 32 : 55} />
                            </HoverWrap>
                        </ContentItemWrap>
                    )
                })}
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    padding: 60px 0 80px;
`;

const ContentWrap = styled(Row)`
    margin-top: 15px;
    flex-wrap: wrap;
    gap: 10px;
    &>div{
        width: calc(50% - 5px);
    }
`;

const ContentItemWrap = styled(Center)`
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    & img{
        width: 100%;
    }
    &:hover div{
        display: flex;
    }
`;

const HoverWrap = styled(Center)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
`;