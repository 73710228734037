import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import BasicText from '../../basic/BasicText';
import { Row } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import SelectItem from '../../common/item/SelectItem';
import { MenuItem } from '@mui/material';
import { onlyNumber } from '../../../utils/format/string';
import { BANK, RELATION } from '../data/dummy';
import { apiGetGiftList } from '../../../api/api_home';

export default function InputBenefitPart({detail,order_freegift,valueChange}:any) {
    const [giftList,setGiftList] = useState<any>([]);

    useEffect(()=>{
        if(detail?.info?.company_code === undefined || detail?.info?.company_code === '') return;
        getGiftList();
    },[detail?.info?.company_code]);

    // 사은품 리스트 가져오기
    const getGiftList = async() =>{
        const res = await apiGetGiftList(detail?.info?.company_code);
        
        if(res.result){
            setGiftList(res.data);
            if(res.data.length === 0) return;
            valueChange('freegift','freegift_code',res.data[0].freegift_code);
        }
    }

    return (
        <Wrap>
            <BasicText fontWeight={700} fontSize={18}>사은품 계좌 정보</BasicText>
            <BgTitle>사은품예금주가 가입자와 다를 경우 가족관계증명서 제출이 필요합니다</BgTitle>
            <ContentWrap>
                <ItemWrap>
                    <TitleItem>받으시는 분</TitleItem>
                    <InputItem>
                        <input required type="text" value={order_freegift?.order_freegift_name ?? ''} onChange={(e:any)=>valueChange('freegift','order_freegift_name',e.target.value)}/>
                    </InputItem>
                </ItemWrap>
                <ItemWrap>
                    <TitleItem>가입자와의 관계</TitleItem>
                    <SelectItem required value={order_freegift?.order_freegift_relation ?? '1'} setValue={(val:any)=>valueChange('freegift','order_freegift_relation',val)} none='no'>
                        {RELATION.map((data:any,index:any)=>{
                            return(
                                <MenuItem key={`rel-${index}`} value={data.id}>{data.name}</MenuItem>
                            )
                        })}
                    </SelectItem>
                </ItemWrap>
                <ItemWrap>
                    <TitleItem>주민등록번호</TitleItem>
                    <InputItem>
                        <input maxLength={15} required value={order_freegift?.order_freegift_resident_number ?? ''} onChange={(e:any)=>valueChange('freegift','order_freegift_resident_number',onlyNumber(e))} placeholder='ex) 주민등록번호 앞자리-주민등록번호 뒷자리'></input>
                    </InputItem>
                </ItemWrap>
                <ItemWrap>
                    <TitleItem>계좌번호</TitleItem>
                    <SelectItem required width={'40%'} value={order_freegift?.order_freegift_bank ?? 'NH농협'} setValue={(val:any)=>valueChange('freegift','order_freegift_bank',val)} none='none'>
                        {BANK.map((data:any,index:any)=>{
                            return(
                                <MenuItem key={`bank-${index}`} value={data.name}>{data.name}</MenuItem>
                            )
                        })}
                    </SelectItem>
                    <InputItem>
                        <input required value={order_freegift?.order_freegift_account_number ?? ''} onChange={(e:any)=>valueChange('freegift','order_freegift_account_number',onlyNumber(e))} placeholder='ex) 계좌번호 입력'></input>
                    </InputItem>
                </ItemWrap>
                <ItemWrap>
                    <TitleItem>사은품 선택</TitleItem>
                    {giftList === undefined || giftList.length === 0 ?
                        <></> :
                        <SelectItem value={order_freegift?.freegift_code ?? ''} setValue={(val:any)=>valueChange('freegift','freegift_code',val)} none='no'>
                            {order_freegift?.freegift_code ?? <MenuItem value=''>선택해주세요</MenuItem>}
                            {giftList.map((data:any,index:any)=>{
                                return(
                                    <MenuItem key={`voucher-${index}`} value={data.freegift_code}>{data.freegift_name}</MenuItem>
                                )
                            })}
                        </SelectItem>
                    }
                </ItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 80px;
`;
const ContentWrap = styled(Row)`
    flex-direction: column;
    gap: 25px;
    margin-top: 15px;
`;

const BgTitle = styled.div`
    padding: 18px;
    margin: 15px 0;
    border-radius: 4px;
    background-color: #d8d8d8;
    text-align: left;
`;

const ItemWrap = styled(Row)`
    gap: 10px;
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
    }
`;

const TitleItem = styled.div`
    width: 20%;
    flex-shrink: 0;
    text-align: left;
    font-size: 16px;
    @media ${addTheme.media.s}{
        width: 100%;
    }
`;

const InputItem = styled.div<{w?:string}>`
    border: 1px solid #e9e9e9;
    height: 42px;
    width: ${({w}) => w ?? '100%'};
    border-radius: 4px;
    &>input{
        height: 100%;
        width: 100%;
        padding: 10px 15px;
    }
`;
