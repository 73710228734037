import { Dialog, DialogContent } from '@mui/material';
import React, { useRef } from 'react'
import styled from 'styled-components';
import { Center } from '../../../../style/basic/commonStyle';

export default function OpenImgPart({open,img,setOpen}:any) {
    const ref = useRef<any>(null);

    const close = () =>{
        setOpen(false);
    }
    
    return (
        <Dialog
            ref={ref}
            open={open} 
            onClose={close}
            sx={{
                '.MuiPaper-root':{margin:'8px', maxWidth:'560px',width:'100%',borderRadius: '6px'}
            }}
        >
            <DialogContent dividers={true}>
                <Wrap>
                    <Center>
                        <img src={img} alt=''></img>
                    </Center>
                </Wrap>
            </DialogContent>
        </Dialog>
    )
}

const Wrap = styled.div`
    & img{
        width: 100%;
        max-width: 720px;
    }
`;
