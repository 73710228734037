// 유저 종류
export const USER_TYPE = [
    {id:1,name:'개인'},
    {id:2,name:'사업자'},
    {id:3,name:'외국인'},
]

// 유저 종류 
export const USER_TYPE_VAL:any = {
    '1': '개인',
    '2': '사업자',
    '3': '외국인',
}

// 핸드폰 통신사 리스트
export const TEL_LIST = [
    {id: 1, name: 'SKT', },
    {id: 2, name: 'KT', },
    {id: 3, name: 'LG', },
    {id: 4, name: 'SKT 알뜰폰', },
    {id: 5, name: 'KT 알뜰폰', },
    {id: 6, name: 'LG 알뜰폰', },
    {id: 7, name: '기타', },
]

// 관계 리스트
export const RELATION = [
    {id: 1, name:'본인'},
    {id: 2, name:'배우자'},
    {id: 3, name:'가족'},
    {id: 4, name:'기타'},
]
export const RELATION_VAl:any = {
    '1': '본인',
    '2': '배우자',
    '3': '가족',
    '4': '기타',
}

// 은행 리스트
export const BANK = [
    {id: 1, name: 'NH농협'},
    {id: 2, name: '카카오뱅크'},
    {id: 3, name: 'KB국민'},
    {id: 4, name: '토스뱅크'},
    {id: 5, name: '신한'},
    {id: 6, name: '우리'},
    {id: 7, name: 'IBK기업'},
    {id: 8, name: '하나'},
    {id: 9, name: '새마을'},
    {id: 10, name: '부산'},
    {id: 11, name: 'iM뱅크(대구)'},
    {id: 12, name: '케이뱅크'},
    {id: 13, name: '신협'},
    {id: 14, name: '우체국'},
    {id: 15, name: 'SC제일'},
    {id: 16, name: '경남'},
    {id: 17, name: '저축은행'},
    {id: 18, name: '제주'},
    {id: 19, name: '씨티'},
    {id: 20, name: 'KDB산업'},
    {id: 21, name: '산림조합'},
    {id: 22, name: 'SBI저축은행'},
];

// 납부정보
export const PAYER_TYPE = [
    {id: 1, name: '은행 자동이체'},
    {id: 2, name: '카드 결제'},
]
export const PAYER_TYPE_VAL:any = {
    '1': '은행 자동이체',
    '2': '카드 결제',
}

const TERMS_READ = `◆필독사항

1. 먼저 신규 인터넷을 설치 마친 뒤, 기존 인터넷을 직접 해지 신청하셔야 합니다.

2. 미납 및 연체금이 있을 경우 납부 후 신청 가능합니다.

3. 교육청할인, 복지할인 신청의 경우 사은품 환수 될 수 있습니다.

4. PC/테블릿/노트북 등 전자기기로 Window OS를 3대 이상 사용할 경우, 추가 단말 요금이 발생합니다(최대 2대까지)

5. 설치비가 한번 발생하며, 익월에 한번 월요금에 합산청구되어집니다.

6. KT의 휴대폰결합은 고객님께서 설치받고나서, 100번 KT 본사를 통해 직접 결합요청을 하셔야 합니다.

7. 필독사항을 충분히 숙지한 뒤 신청바랍니다.`;
const TERMS_COLLECT = `현주친구는 개인정보보호법 제15조 제1항 제4호 등에 근거하여 이용자 확인, 문의상담 등의 목적으로써 이용자에게 최적의 서비스를 제공하고자 개인정보를 수집·이용하고 있습니다. 수집하는 개인정보 항목에 따른 구체적인 수집 및 이용의 목적은 다음과 같습니다.

1. 수집하려는 개인정보의 항목
- 성명
- 생년월일
- 휴대폰번호
- 이메일
- 주소
- 사용료 자동이체 및 사은품 지급을 위한 계좌관련정보
- 관련 첨부파일 및 기타 자료

2. 수집목적
- 계약 체결을 위한 본인인증, 접수내용 확인 서비스 제공 등 계약의 체결 및 이행을 위함.

3. 본 개인정보 수집은 원활한 서비스 가입 및 이용을 위해 진행되고 있으며, 개인정보 수집에 동의하지 않으실 경우 서비스의 가입 및 이용에 제약이 있을 수 있습니다.

4. (개인정보 제3자 제공의 경우) 수집 개인정보의 제3자 제공
제공받는 자: 렌탈상품 공급업체
제공받는 자의 개인정보 이용목적: 계약 이행, 접수내용 확인
제공받는 개인정보의 항목: 제1항의 내용과 동일
보유 및 이용 기간: 계약 해지시까지
본 개인정보 제공은 원활한 서비스 가입 및 이용을 위해 진행되고 있으며, 개인정보 제공에 동의하지 않으실 경우 서비스의 가입 및 이용에 제약이 있을 수 있습니다.

5. 가입자 본인 여부를 확인하기 위하여 본인인증을 필수적으로 거치고 있습니다. 타인의 명의를 도용할 경우 사문서위조 및 동행사죄(형법 제231조 및 제234조)등 관련 법률에 의거하여 형사처벌 및 민사상 불법행위 손해배상의 대상이 될 수 있음을 유의하여 주시기 바랍니다.

6. 수집한 개인정보는 신청상품의 유치대리점에서 고객식별, 본인확인, 서비스 가용확인, 서비스 제공매체결정, 고객상담, 유치, 상품소개, 기타 고객커뮤니케이션 등을 위해 활용되며, 서비스 이용을 원치 않을 경우 계약 해지와 함께 개인정보의 삭제를 요청할 수 있습니다.

* 본 신청상품의 개인정보수집 동의사항과 자동이체 신청 및 신청서 대행작성에 동의합니다.

** 계약의 체결 및 이행을 위하여 수집에 동의한 개인정보를 제3자(렌탈상품 공급업체)에 제공하는 것에 동의합니다.

현주친구는 이용자의 개인정보를 소중하게 생각하며 관련 법령을 철저히 준수하고, 서비스 제공 이외의 목적으로 이용자의 개인정보를 사용하거나 이용자의 동의 없이 제3자에게 제공하지 않습니다. 현주친구의 개인정보 관리 노력은 현주친구 홈페이지의 개인정보처리방침을 통하여 확인하실 수 있습니다.`;

const TERMS_USE = `1. 설치비는 전액 고객 부담입니다.
2.신청 통신사와 본인 및 가족의 명의로 동일 통신사를 사용 중이시면 1년 필수 유지하여야 하며,해지 시 사은품은 전액 환수됩니다. 
3. 3년 약정기준이며, 1년이내 모든해지(위면해지,직권해지포함)및 정지 시 위약금과 별도로 사은품 환수가 발생합니다.
4. 결합할인 상품 가능여부는 통신사 본사 고객센터 확인 후 진행해주셔야 하며,추후 결합이 불가한 경우 책임지지 않습니다.
5. 약정기간내 해지시 위약금이 발생됩니다.
6. 1년간 자동이체,카드이체만 가능하시며 지로납부로 변경시 전액 환수 조치됩니다
7. PC/테블릿/노트북 등 단말기를 동시에3대 이상 사용할 경우 추가 단말 요금이 발생합니다
8. 1년 이내 해지시 해지위약금에 할인 받으신 설치비도 포함되십니다.
9.설치 후에 동일장소 해지 이력으로 간혹 소명 요청이 발생할 수 있습니다.이 때 간단한 증명 서류가 필요하며,만약 증빙불가한 경우 통신사 통신사 본사로부터 사은품 환수가 발생할 수 있는 점 유의바랍니다.
(필요한 서류:부동산계약서/등본/기존회선 가입증명원/납부내역서9개월치)
10. 통신사에서 정해놓은 하루 데이터 사용 초과시 자정까지 속도가 제어되며, 가정용으로 너무 많은 pc 노트북을 동시에 사용하실 수는 없으십니다.(3대이상 시)`;

// 약관
export const TERMS = [
    {id: 1, name: '필독사항', state: false, content: TERMS_READ},
    {id: 2, name: '개인정보 수집·이용 및 제3자 제공 동의서', state: false, content: TERMS_COLLECT},
    {id: 3, name: '이용약관', state: false, content: TERMS_USE},
]

// 통신사
export const TEL_OBJ:any = {
    "1": 'kt',
    "2": 'lg',
    "3": 'sk',
    "4": 'skylife',
    "5": 'lghellovision',
    "6": 'skb',
}

export const TEL_OBJ_NUM:any = {
    'kt': 1,
    'lg': 2,
    'sk': 3,
    'skylife': 4,
    'lghellovision': 5,
    'skb': 6,
}