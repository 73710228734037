import React, { useState } from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row, RowBetween } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import OpenSelectPart from './OpenSelectPart';
import { useGlobalCompanyInfo } from '../../../utils/custom-hooks/common/useGlobalCompanyInfo';

export default function BtnPart() {
    const [open,setOpen] = useState(false);
    const companyInfo = useGlobalCompanyInfo();
    // 바로 상담
    const phoneClick = () =>{
        if(companyInfo['config_company_call_number'] === undefined) return;
        window.location.href = 'tel:' + companyInfo['config_company_call_number'];
    }
    return (
        <Wrap>
            <MaxWidthWrap>
                <BtnWrap>
                    <BtnItem color={addTheme.color} bg='#fff' onClick={()=>setOpen(true)}>
                        <img alt='plus-icon' src='/img/asset/mbtn01.png'></img>
                        바로 신청
                    </BtnItem>
                    <BtnItem color='#fff' bg={addTheme.color} onClick={phoneClick}>
                        <img alt='phone-icon' src='/img/asset/mbtn02.png'></img>
                        바로 상담
                    </BtnItem>
                </BtnWrap>
            </MaxWidthWrap>
            <OpenSelectPart open={open} setOpen={setOpen} />
        </Wrap>
    )
}

const Wrap = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
`;

const BtnWrap = styled(RowBetween)`
    margin-top: 20px;
    gap: 15px;
    padding: 15px 0;
`;

const BtnItem = styled(Center)<{color:string,bg:string}>`
    width: 48%;
    color: ${({color}) => color ?? '#000'};
    background-color: ${({bg}) => bg ?? '#fff'};
    border: 1px solid ${addTheme.color};
    gap: 12px;
    height: 48px;
    font-size: 22px;
    border-radius: 5px;
    cursor: pointer;
    & img{
        width: 32px;
    }
    @media ${addTheme.media.s}{
        font-size: 18px;
    }
    & img{
        width: 26px;
    }
`;