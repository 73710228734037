import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LayoutPage from '../../common/page/LayoutPage';
import BasicText from '../../basic/BasicText';
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import MyPageLoginPart from '../part/MyPageLoginPart';
import MyPagePart from '../part/MyPagePart';
import { useGlobalPartner } from '../../../utils/custom-hooks/common/useGlobalPartner';
import { useNavigate } from 'react-router';

export default function MyPage() {
    const navigate = useNavigate();
    const [list,setList] = useState<any>([]);
    const partner = useGlobalPartner();

    useEffect(()=>{
        if(partner.partner_code === '') return;
        navigate('/partnerMyPage');
    },[partner.partner_code])

    return (
        <LayoutPage>
            <MaxWidthWrap>
                <TitleWrap>
                    <BasicText fontSize={38} mFontSize={22} fontWeight={700} align='center'>마이페이지</BasicText>
                </TitleWrap>
                <Wrap>
                    {list.length === 0 ? 
                        <MyPageLoginPart setList={setList} /> :
                        <MyPagePart list={list} />
                    }
                </Wrap>
            </MaxWidthWrap>
        </LayoutPage>
    )
}


const Wrap = styled.div`
    width: 100%;
    margin: 0 auto 50px;
`;

const TitleWrap = styled.div`
    margin-top: 150px;
`;