import React from 'react'
import styled from 'styled-components'
import LayoutPage from '../../common/page/LayoutPage';
import PartnerBannerPart from '../part/PartnerBannerPart';
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import PartnerActivePart from '../part/PartnerActivePart';
import PartnerProcess from '../part/PartnerProcess';
import PartnerBtnPart from '../part/PartnerBtnPart';
import { addTheme } from '../../../style/addTheme';

export default function PartnerPage() {
    return (
        <LayoutPage>
            <Wrap>
                <PartnerBannerPart />
                <MaxWidthWrap>
                    <PartnerActivePart />
                    <PartnerProcess />
                    <PartnerBtnPart />
                </MaxWidthWrap>
            </Wrap>
        </LayoutPage>
    )
}

const Wrap = styled.div`
    padding: 75px 0;
    @media ${addTheme.media.s}{
        padding: 60px 0;
    }
`;