import React, { useState } from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import { Checkbox, FormControlLabel } from '@mui/material';
import { numberWithComma } from '../../../utils/format/number';
import { speedChange } from '../../../utils/format/string';

const TEL_MORE = [
    {id: 1, code:'', name: '공유기와 함께', disable: [1,2,3,4,5,6]},
    {id: 2, code:'tv', name: 'TV와 함께', disable: [0]},
    {id: 3, code:'landline', name: '전화와 함께', disable: [0]},
]

export default function InternetPart({selectData,setSelectData,more,setMore,payList}:any) {
    const moreClick = (id:any) =>{
        const chkState = more.filter((item:any)=>item === id);
        if(chkState.length === 0){
            setMore((arr:any)=>[...arr,id]);
        }else{
            setMore((arr:any)=>arr.filter((item:any)=>item !== id));
        }
    }

    const internetClick = (data:any) =>{
        setSelectData((obj:any)=>({
            ...obj,
            internet: data,
        }))
    }

    const newPayCal = (data:any) =>{
        // let newPay = pay;
        let newPay = Number(data.product_basic_price);
        const routerChk = more.filter((item:any)=> item === 1);
        const tvChk = more.filter((item:any)=> item === 2);
        const phoneChk = more.filter((item:any)=> item === 3);
        if(routerChk.length > 0 && tvChk.length > 0){
            const newRouter = selectData?.tv?.product_combine_internet_router_price;
            if(newRouter === undefined) return '';
            const tvPriceChk = newRouter.filter((item:any)=>item.product_code === data.product_code);
            const tvPrice = tvPriceChk.length === 0 ? 0 : tvPriceChk[0].router_price
            newPay = Number(data.product_combine_tv_price) + Number(tvPrice);
        }else{
            if(routerChk.length > 0){
                newPay = Number(data.product_basic_price) + Number(data.product_router_price_gap);
            }
            if(tvChk.length > 0){
                newPay = Number(data.product_combine_tv_price);
            }
        }
        return newPay;
    }
    return (
        <Wrap>
            <MaxWidthWrap>
                <InnerWrap>
                    <BasicText fontSize={26} mFontSize={22} fontWeight={700}>인터넷</BasicText>
                    <TelWrap>
                        {payList?.internet.map((data:any,index:any)=>{
                            const selectChk = selectData?.internet?.product_id === data.product_id;
                            const {speed,unit} = speedChange(data.product_max_speed);
                            return(
                                <TelItemWrap key={`internet-${index}`} onClick={()=>internetClick(data)} selectChk={selectChk}>
                                    <BasicText align='center' fontSize={24} mFontSize={22}><strong>{speed}</strong> {unit}</BasicText>
                                    <BasicText align='center' fontSize={15} color='#333'>{data.product_name}</BasicText>
                                    <BasicText align='center' fontSize={22} mFontSize={20} fontWeight={700} color={addTheme.color}>월 {numberWithComma(newPayCal(data))}원</BasicText>
                                </TelItemWrap>
                            )
                        })}
                    </TelWrap>
                    <ChkWrap>
                        {(payList !== undefined) && TEL_MORE.map((data:any,index:any)=>{
                            const chkState = more.filter((item:any)=>item === data.id);
                            const disState = data.disable.filter((item:any) => item === selectData.tel.company_id);
                            const onOnly = disState.length === 0 ? false : true;
                            const listLength = data.code === '' ? 1 :  payList[data.code].length;
                            if(listLength === 0 || listLength === undefined) return;
                            return(
                                <FormControlLabel key={`more-select-${index}`} control={<Checkbox sx={{color:addTheme.color,'&.Mui-checked': {color:addTheme.color}}} checked={chkState.length > 0} onChange={(e:any)=>moreClick(data.id)} disabled={onOnly}/> } label={data.name} />
                            )
                        })}
                    </ChkWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    padding: 50px 0;
`;
const InnerWrap = styled.div``;
const TelWrap = styled(Row)`
    flex-wrap: wrap;
    margin-top: 15px;
    gap: 15px;
    align-items: start;
    &>div{
        width: calc(33% - 7.5px);
    }
    @media (max-width:800px){
        &>div{
            width: calc(50% - 7.5px);
        }
    }
`;
const TelItemWrap = styled(Center)<{selectChk:boolean}>`
    flex-direction: column;
    border: 2px solid ${({selectChk}) => selectChk ? addTheme.color : '#bebebe'};
    border-radius: 6px;
    padding: 20px;
    gap: 8px;
    cursor: pointer;
    & img{
        height: 38px;
    }
`;

const ChkWrap = styled(Row)`
    gap: 15px;
    margin-top: 15px;
`;