import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_COMPANY_INFO= '/SET_COMPANY_INFO';

export const globalCompanyInfoCreator = createAction(SET_COMPANY_INFO);

export function setGlobalCompanyInfo(data: any): ThunkAction<void, RootState, null, ReturnType<typeof globalCompanyInfoCreator>> {
    return async function (dispatch) {
    try {
      dispatch(globalCompanyInfoCreator({
        data: data
      }));
    } catch (error) {
      
    }
  };
}

export interface CompanyInfoState {
  "config_company_name": string,
  "config_company_repre_name": string,
  "config_business_number": string,
  "c_config_business_name": string,
  "config_company_address": string,
  "config_private_info_name": string,
  "config_private_info_email": string,
  "config_company_email": string,
  "config_partner_company": string,
  "config_online_prev_approve_code": string,
  "config_company_call_number": string,
  "config_social_kakao_link": string,
  "config_social_instagram_link": string,
  config_social_youtube_link: string,
}

const initialState: CompanyInfoState = {
  "config_company_name": "긱플레이스",
  "config_company_repre_name": "김형경",
  "config_business_number": "236-88-03133",
  "c_config_business_name": "현주친구",
  "config_company_address": "서울특별시 강남구 선릉로92길 28, 4층",
  "config_private_info_name": "김형경",
  "config_private_info_email": "",
  "config_company_email": "",
  "config_partner_company": "",
  "config_online_prev_approve_code": "",
  "config_company_call_number": "1533-9360",
  "config_social_kakao_link": "",
  "config_social_instagram_link": '',
  config_social_youtube_link: ''
};

export const globalCompanyInfo = handleActions<CompanyInfoState, any>({
  [SET_COMPANY_INFO]: (state, action: ReturnType<typeof globalCompanyInfoCreator>) => ({
    config_company_name: action.payload.data.config_company_name ?? state.config_company_name,
    config_company_repre_name: action.payload.data.config_company_repre_name ?? state.config_company_repre_name,
    config_business_number: action.payload.data.config_business_number ?? state.config_business_number,
    c_config_business_name: action.payload.data.c_config_business_name ?? state.c_config_business_name,
    config_company_address: action.payload.data.config_company_address ?? state.config_company_address,
    config_private_info_name: action.payload.data.config_private_info_name ?? state.config_private_info_name,
    config_private_info_email: action.payload.data.config_private_info_email ?? state.config_private_info_email,
    config_company_email: action.payload.data.config_company_email ?? state.config_company_email,
    config_partner_company: action.payload.data.config_partner_company ?? state.config_partner_company,
    config_online_prev_approve_code: action.payload.data.config_online_prev_approve_code ?? state.config_online_prev_approve_code,
    config_company_call_number: action.payload.data.config_company_call_number ?? state.config_company_call_number,
    config_social_kakao_link: action.payload.data.config_social_kakao_link ?? state.config_social_kakao_link,
    config_social_instagram_link: action.payload.data.config_social_instagram_link ?? state.config_social_instagram_link,
    config_social_youtube_link: action.payload.data.config_social_youtube_link ?? state.config_social_youtube_link,
  }),
}, initialState);
