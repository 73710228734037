import React from 'react'
import styled from 'styled-components'
import { Center, Row, RowBetween } from '../../../style/basic/commonStyle';
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';
import { numberWithComma } from '../../../utils/format/number';
import { speedChange } from '../../../utils/format/string';
import { useGlobalSelectData } from '../../../utils/custom-hooks/common/useGlobalSelectData';

const TitleItem = ({title,content}:any) =>{
    return (
        <ContentItem>
            <BasicText>{title}</BasicText>
            <BasicText>{content}</BasicText>
        </ContentItem>
    )
}

const SubTitleItem = ({title,content}:any)=>{
    return(
        <ContentItem>
            <BasicText color='#9a9a9a'>- {title}</BasicText>
            <BasicText color='#9a9a9a'>{content}</BasicText>
        </ContentItem>
    )
}

export default function FormInfoPart({type,detail}:any) {
    const {speed,unit} = speedChange(detail?.internet?.product_max_speed);
    const selectData = useGlobalSelectData();
    const multiName = selectData.place?.bundle_name === undefined ? '다회선' : `${selectData?.place?.bundle_name} | ${selectData?.tel?.company_name} | ${selectData?.place?.name}`
    return (
        <Wrap>
            {type === 'home' &&
                <>
                    <ContentWrap>
                        <BasicText fontSize={18} fontWeight={700}>신청 정보</BasicText>
                        <ContentItemWrap>
                            <TitleItem title={'인터넷'} content={`${detail?.info?.company_name}-${speed} ${unit}`} />
                            <SubTitleItem title={'wifi'} content={'있음'} />
                            {detail?.tv !== undefined &&
                                    <TitleItem title={'TV'} content={detail?.tv?.product_name} />
                            }
                            {detail?.landline !== undefined &&
                                <TitleItem title={'전화기'} content={detail?.landline?.product_name} />
                            }
                            <TitleItem title={'결합'} content={'총액 결합할인'} />
                            <TitleItem title={'약정'} content={'3년 약정'} />
                            <TitleItem title={'평일 설치비(VAT포함)'} content={numberWithComma(detail?.calc?.equip_weekday_price)} />
                            <TitleItem title={'주말 설치비(VAT포함)'} content={numberWithComma(detail?.calc?.equip_weekend_price)} />
                            <TitleItem title={'사은품(VAT포함)'} content={numberWithComma(detail?.calc?.freegift_price)} />
                        </ContentItemWrap>
                    </ContentWrap>
                    <ContentWrap>
                        <ResWrap>
                            <BasicText color='#fff' fontSize={20} mFontSize={18}>최종 월 납부 금액</BasicText>
                            <BasicText color='#fff' fontSize={30} mFontSize={26} fontWeight={700} ls={2}>{numberWithComma(detail?.calc?.combine_price)}원</BasicText>
                        </ResWrap>
                    </ContentWrap>
                </>
            }
            {type === 'multi' &&
                <>
                    <ContentWrap>
                        <BasicText fontSize={18} fontWeight={700}>신청 정보</BasicText>
                        <ContentItemWrap>
                            <TitleItem title={'인터넷'} content={multiName} />
                            <SubTitleItem title={'wifi'} content={'있음'} />
                            <TitleItem title={'결합'} content={'총액 결합할인'} />
                            <SubTitleItem title={'약정'} content={'3년 약정'} />
                            <TitleItem title={'설치비(VAT포함)'} content={'상담 후 구매'} />
                            <TitleItem title={'사은품(VAT포함)'} content={'상담 후 구매'} />
                        </ContentItemWrap>
                    </ContentWrap>
                    <ContentWrap>
                        <ResWrap>
                            <BasicText color='#fff' fontSize={20} mFontSize={18}>최종 월 납부 금액</BasicText>
                            <BasicText color='#fff' fontSize={30} mFontSize={26} fontWeight={700} ls={2}>상담 후 구매</BasicText>
                        </ResWrap>
                    </ContentWrap>
                </>
                
            }
    </Wrap>
)
}

const Wrap = styled.div``;
const ImgWrap = styled(Center)`
    width: 100%;
    & img{
        width: 100%;
        max-width: 300px;
    }
`;

const ContentWrap = styled.div`
    margin-top: 18px;
`;

const ContentItemWrap = styled(Row)`
    margin-top: 10px;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
`;

const ContentItem = styled(RowBetween)``;
const ResWrap = styled(Row)`
    flex-direction: column;
    align-items: end;
    gap: 6px;
    padding: 20px 15px;
    background-color: ${addTheme.color};
    border-radius: 6px;
    margin-top: 40px;
`;