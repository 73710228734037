import React from 'react'
import styled from 'styled-components'
import { numberWithComma } from '../../../utils/format/number'
import StepTitleItem from './StepTitleItem'
import { Row, SectionWrap } from '../../../style/basic/commonStyle'
import StepInnerTitleItem from './StepInnerTitleItem'
import BasicText from '../../basic/BasicText'
import { FaCheckCircle } from "react-icons/fa";
import { addTheme } from '../../../style/addTheme'

const LIST = [
    {name: '인터넷 단독', channel:0, M100:{pay0:20790,pay1:16630,gift:13},M160:{pay0:25410,pay1:20320,gift:13}, M500:{pay0:29260,pay1:23400,gift:18}, G1:{pay0:30800,pay1:24640,gift:18}},
    {name: '인터넷 + UHD 이코노미', channel:109, M100:{pay0:29530,pay1:25370,gift:30},M160:{pay0:33160,pay1:28080,gift:30}, M500:{pay0:36190,pay1:30330,gift:35}, G1:{pay0:37400,pay1:31240,gift:40}},
    {name: '인터넷 + UHD 뉴베이직', channel:245, M100:{pay0:31730,pay1:27570,gift:30},M160:{pay0:35360,pay1:30280,gift:30}, M500:{pay0:38390,pay1:32530,gift:35}, G1:{pay0:39600,pay1:33400,gift:40}},
    {name: '인터넷 + UHD 뉴프리미엄', channel:245, M100:{pay0:33930,pay1:29770,gift:30},M160:{pay0:37560,pay1:32480,gift:30}, M500:{pay0:40590,pay1:34730,gift:35}, G1:{pay0:41800,pay1:35640,gift:40}},
    {name: '인터넷 단독 + UHD 뉴베이직 + 디즈니스탠다드', channel:248, M100:{pay0:39830,pay1:35670,gift:30},M160:{pay0:43460,pay1:38380,gift:30}, M500:{pay0:46490,pay1:40630,gift:35}, G1:{pay0:47700,pay1:41540,gift:40}},
    {name: '인터넷 단독 + UHD 뉴베이직 + 디즈니프리미엄', channel:248, M100:{pay0:43830,pay1:39670,gift:30},M160:{pay0:47460,pay1:43380,gift:30}, M500:{pay0:50490,pay1:44630,gift:35}, G1:{pay0:51700,pay1:45540,gift:40}},
    {name: '인터넷 + 프로라이트', channel:218, M100:{pay0:'-',pay1:'-',gift:'-'},M160:{pay0:37560,pay1:32480,gift:30}, M500:{pay0:40590,pay1:34730,gift:35}, G1:{pay0:41800,pay1:35640,gift:40}},
    {name: '인터넷 + 프로맥스', channel:246, M100:{pay0:'-',pay1:'-',gift:'-'},M160:{pay0:39760,pay1:34680,gift:30}, M500:{pay0:42790,pay1:36930,gift:35}, G1:{pay0:44000,pay1:37840,gift:40}},
]

const DISCOUNT_FAMILY = [
    {name: '특수 상황', list: [
        {product: '신혼플러스', terms: '예비 부부, 신혼 부부 5g: 85요금제 LTE 78 요금제 이상', benefit: '모바일 요금 6개월 무료 + 월 최대 22,850원 할인'},
        {product: '펫플러스', terms: '스마트홈', benefit: '공기청정기 렌탈료 최대 21,000원 할인 구매 시 약 23만원 할인'},
        {product: '시니어 플러스', terms: '약 65세 이상 사용자', benefit: '인터넷 3,100원 추가 할인'},
    ] },
    {name: '인터넷 + 휴대폰', list: [
        {product: '투게더 결합', terms: '모바일 5회선, 인터넷 5회선까지 요금제에 따라 할인 금액 변동', benefit: '인터넷 11,000원/ 휴대폰 최대 20,000원 할인'},
        {product: '참 쉬운 가족결합', terms: '모바일 10회선,인터넷 3회선까지 요금제에 따라 할인 금액 변동', benefit: '인터넷 13,200원/ 휴대폰 최대 5,500원 할인'},
    ] },
    {name: '인터넷 + 인터넷', list: [{product: '패밀리 결합', terms: '인터넷 2대 이상', benefit: '인터넷 5,500원'}]},
]

const DISCOUNT_CARD = [
    {name: '국민카드', list: [
        {name: 'LG헬로비전 KB국민카드', benefit: '전월 30만원 이상 실적 12,000원 할인'},
    ]},
    {name: '롯데카드	', list: [
        {name: 'LG헬로비전 롯데카드', benefit: '전월 30만원 이상 실적 15,000원 할인'},
    ]},
    {name: '하나카드', list: [
        {name: 'LG헬로비전 더 심플 하나카드', benefit: '전월 30만원 이상 실적 10,000원 할인'},
    ]},
    
]

export default function LgHelloStepItem({selectData}:any) {
    return (
        <Wrap>
            <StepTitleItem step='01' title={`LG 헬로비전 요금표 한눈에 보기!`} />
            <TableWrap>
                <TableWrapItem margin='40px 0 0'>
                    <thead>
                        <tr>
                            <th className='title-bg' rowSpan={2} colSpan={2}>인터넷 상품</th>
                            <th className='title-bg' rowSpan={2}>모바일<br/>결합여부</th>
                            <th className='title-bg' colSpan={2} rowSpan={1}>100M</th>
                            <th className='title-bg' colSpan={2} rowSpan={1}>160M</th>
                            <th className='title-bg' colSpan={2} rowSpan={1}>500M</th>
                            <th className='title-bg' colSpan={2} rowSpan={1}>1G</th>
                        </tr>
                        <tr>
                            <th className='title-bg'>요금</th>
                            <th className='title-bg'>사은품</th>
                            <th className='title-bg'>요금</th>
                            <th className='title-bg'>사은품</th>
                            <th className='title-bg'>요금</th>
                            <th className='title-bg'>사은품</th>
                            <th className='title-bg'>요금</th>
                            <th className='title-bg'>사은품</th>
                        </tr>
                    </thead>
                    <tbody>
                        {LIST.map((data:any,index:any)=>{
                            const evenState = (index+1) % 2 === 0;
                            return(
                                <React.Fragment key={`kt-pay-${index}`}>
                                    <tr className={evenState ? 'content-bg' : ''}>
                                        <th className='content-title' rowSpan={2} colSpan={2}>
                                            {data.name}
                                            {data.channel !== 0 &&
                                                <>
                                                    <br/><span>{data.channel}채널</span>
                                                </>
                                            }
                                        </th>
                                        <td className='first-text'>미결합</td>
                                        <td className='first-text'>{data['M100']['pay0'] === '-' ? '-' : `${numberWithComma(data['M100']['pay0'])}원`}</td>
                                        <td rowSpan={2} className='gift'>{data['M100']['gift'] === '-' ? '-' : `${numberWithComma(data['M100']['gift'])}만원`}</td>
                                        <td className='first-text'>{numberWithComma(data['M160']['pay0'])}원</td>
                                        <td rowSpan={2} className='gift'>{numberWithComma(data['M160']['gift'])}만원</td>
                                        <td className='first-text'>{numberWithComma(data['M500']['pay0'])}원</td>
                                        <td rowSpan={2} className='gift'>{numberWithComma(data['M500']['gift'])}만원</td>
                                        <td className='first-text'>{numberWithComma(data['G1']['pay0'])}원</td>
                                        <td rowSpan={2} className='gift'>{numberWithComma(data['G1']['gift'])}만원</td>
                                    </tr>
                                    <tr className={evenState ? 'content-bg' : ''}>
                                        <td>1대결합</td>
                                        <td>{data['M100']['pay1'] === '-' ? data['M100']['pay1'] : `${numberWithComma(data['M100']['pay1'])}원`}</td>
                                        <td>{data['M160']['pay1'] === '-' ? data['M160']['pay1'] : `${numberWithComma(data['M160']['pay1'])}원`}</td>
                                        <td>{data['M500']['pay1'] === '-' ? data['M500']['pay1'] : `${numberWithComma(data['M500']['pay1'])}원`}</td>
                                        <td>{data['G1']['pay1'] === '-' ? data['G1']['pay1'] : `${numberWithComma(data['G1']['pay1'])}원`}</td>
                                    </tr>
                                </React.Fragment>
                            )
                        })}
                    </tbody>
                </TableWrapItem>
            </TableWrap>
            <SectionWrap padding={'80px 0 0'}>
                <StepTitleItem step='02' title={'할인혜택 모르면 나만 손해'} />
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={1} title='결합할인은 필수' />
                    <BasicText>인터넷 가입 시 결합할인을 생각하지 않는 건,<br/>돈을 버린다는 의미와 같습니다.</BasicText>
                    <BasicText marginTop={2}>반드시 확인하여 최저요금에 도전해봅시다!</BasicText>
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0'>
                            <colgroup>
                                <col width="23%" />
                                <col width="24%" />
                                <col width="53%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>결합 종류</th>
                                    <th>결합 조건</th>
                                    <th>인터넷 할인</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th rowSpan={4}>인터넷 + 휴대폰</th>
                                    <td>헬로모바일 결합</td>
                                    <td>헬로모바일 + 헬로tv 뉴베이직/뉴프리미엄</td>
                                </tr>
                                <tr>
                                    <td>온가족케이블플랜</td>
                                    <td>SKT 모바일 2회선 이상 + 헬로비전 인터넷</td>
                                </tr>
                                <tr>
                                    <th>케이블 총액 결합할인</th>
                                    <td>KT 모바일 1회선 이상 + 헬로비전 인터넷</td>
                                </tr>
                                <tr>
                                    <th>참 쉬운 케이블 가족결합</th>
                                    <td>LG U+ 모바일 1회선 이상 + 헬로비전 인터넷</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <TableWrap>
                        <TableWrapItem margin='20px 0 0'>
                            <colgroup>
                                <col width="23%" />
                                <col width="30%" />
                                <col width="15.6%" />
                                <col width="15.6%" />
                                <col width="15.6%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>구분</th>
                                    <th>결합 종류</th>
                                    <th>100M</th>
                                    <th>500M</th>
                                    <th>1G</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th rowSpan={2}>LG / KT / SK</th>
                                    <td>인터넷 단독</td>
                                    <td>5,090원</td>
                                    <td>5,860원</td>
                                    <td>6,160원</td>
                                </tr>
                                <tr>
                                    <td>인터넷 + TV</td>
                                    <td>2,537원</td>
                                    <td>2,922원</td>
                                    <td>6,160원</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <BasicText marginTop={2}>혹시 어려우시다면 언제든 문의 주세요.</BasicText>
                </SectionWrap>
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={2} title='있으면 더 좋아요, 제휴카드 할인' />
                    <BasicText marginTop={0.5}>제휴카드 발급 후 통신요금 자동이체 시 할인혜택이 적용됩니다.<br/>단, <strong>실적조건</strong>이 있어요.</BasicText>
                    <TableWrap>
                        <TableWrapItem margin='15px 0 0'>
                            <colgroup>
                                <col width="17.5%" />
                                <col width="41.5%" />
                                <col width="41%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>카드사</th>
                                    <th>카드명</th>
                                    <th>할인 혜택</th>
                                </tr>
                            </thead>
                            <tbody>
                                {DISCOUNT_CARD.map((data:any,index:any)=>{
                                    return(
                                        <React.Fragment key={`kt-card-discount-${index}`}>
                                            <tr>
                                                <th rowSpan={data.list.length}>{data.name}</th>
                                                {data.list.map((item:any,idx:any)=>{
                                                    if(idx !== 0) return;
                                                    return(
                                                        <React.Fragment key={`kt-card-item-${index}-${idx}`}>
                                                            <td>{item['name']}</td>
                                                            <td>{item['benefit']}</td>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </tr>
                                            {data.list.map((item:any,idx:any)=>{
                                                if(idx === 0) return;
                                                return(
                                                    <tr key={`kt-card-item-${index}-${idx}`}>
                                                        <td>{item['name']}</td>
                                                        <td>{item['benefit']}</td>
                                                    </tr>
                                                )
                                            })}
                                        </React.Fragment>
                                    )
                                })}
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                </SectionWrap>
            </SectionWrap>
            <SectionWrap padding={'80px 0 0'}>
                <StepTitleItem step='03' title={'인터넷 전화도 필요하시다면'} />
                <SectionWrap padding='40px 0 0'>
                    <BasicText marginTop={0.5}>혹시 집이나 매장에서 쓸 전화기도 함께 필요하신가요?<br/>그렇다면 인터넷선만 있으면 되는 인터넷 전화를 추천드립니다.</BasicText>
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0'>
                            <colgroup>
                                <col width="17%" />
                                <col width="28%" />
                                <col width="26%" />
                                <col width="29%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>요금제</th>
                                    <th>월 요금</th>
                                    <th>특징</th>
                                    <th>기본제공</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>인터넷전화<br/>(일반형)</td>
                                    <td>인터넷전화 단품 가입4,400원~<br/>인터넷+인터넷전화2,200원~</td>
                                    <td>저렴한 월정액</td>
                                    <td>무료 통화&<br/>부가서비스혜택</td>
                                </tr>
                                <tr>
                                    <td>일반형</td>
                                    <td>2,200원~</td>
                                    <td>저렴한 기본료</td>
                                    <td>가입고객 간 무료통화</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                </SectionWrap>
            </SectionWrap>
            <SectionWrap padding={'80px 0 0'}>
                <StepTitleItem step='04' title={'잠깐!! 설치 전 확인하셨나요? 필수 체크리스트'} />
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={1} title='대칭형/비대칭형 조회' />
                    <BasicText marginTop={0.5}>인터넷 가입 전 대칭형/비대칭형 조회는 필수입니다!</BasicText>
                    <InnerStep02Wrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>대칭형 인터넷(FTTH 회선)</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='transparent' size={22} />
                            <BasicText>업로드 및 다운로드 속도가 일정한 초고속 인터넷(고품질)</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='20px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>비대칭형 인터넷(HFC 회선)</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='transparent' size={22} />
                            <BasicText>업로드 및 다운로드 속도가 달라 불안정한 인터넷(저품질)</BasicText>
                        </TextRowWrap>
                    </InnerStep02Wrap>
                    <BasicText marginTop={2}>품질은 달라도 요금은 같기 때문에 반드시<br/><strong>대칭형 인터넷</strong>이 들어오는 지 확인해주세요!</BasicText>
                    <BasicText marginTop={2} spColor={addTheme.color}>헬로비전은 웹상에서 따로 조회할 수 있는 곳이 없기 때문에<br/>저희 <span>현주친구</span>로 문의주시면 확인해서 알려드리겠습니다^^</BasicText>
                    <BasicText marginTop={2}>(📞 1533-9360)</BasicText>
                    <BasicText marginTop={2} >또한 헬로비전은 한정된 지역에서만 설치가 가능하기 때문에 반드시 확인해주세요.</BasicText>
                    <TextRowWrap gap={8} margin='20px 0 0'>
                        <FaCheckCircle color='#ff7500' size={22} />
                        <BasicText fontSize={17} fontWeight={700}>설치 가능 지역</BasicText>
                    </TextRowWrap>
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0' className='table-map'>
                            <colgroup>
                                <col width="12%" />
                                <col width="47%" />
                                <col width="51%" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>서울</th>
                                    <td>양천구, 은평구</td>
                                    <td rowSpan={11}>
                                        <img loading="lazy" src="https://cdn.ajd.kr/images/platform/internet/must-read/hellovison_map.jpg?w=284&amp;h=361&amp;q=80&amp;f=webp" alt="전국 지도" />
                                        <p> LG헬로비전은 지역 케이블 중 <span>최고의 품질</span>을 자랑합니다. <br/> 지방을 중심으로 발전해 <span>수도권 설치는 한정적</span>일 수 있습니다. </p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>인천</th>
                                    <td>부평구, 계양구</td>
                                </tr>
                                <tr>
                                    <th>경기</th>
                                    <td> 부천시, 김포시, 의정부시, 양주시, 포천시, 동두천시, 연천군 </td>
                                </tr>
                                <tr>
                                    <th>충남</th>
                                    <td>서산시, 당진시, 홍성군, 예산군, 태안군, 청양군</td>
                                </tr>
                                <tr>
                                    <th>대구</th>
                                    <td>수성구, 동구</td>
                                </tr>
                                <tr>
                                    <th>부산</th>
                                    <td>영도구, 동구, 종구, 부산진구, 금정구, 해운대구, 기장군</td>
                                </tr>
                                <tr>
                                    <th>경남</th>
                                    <td> 창원시 의창구, 성산구, 진해구, 함안군, 의령군, 창원시 마산회원구, 마산합포구, 거제시, 통영시, 고성군, 김해시, 양산시, 밀양시, 창녕군, 거창군, 합천군 </td>
                                </tr>
                                <tr>
                                    <th>경북</th>
                                    <td> 안동시, 영주시, 문경시, 의성군, 예천군, 봉화군, 청송군, 영양군, 경주시, 경산시, 영천시, 청도군 </td>
                                </tr>
                                <tr>
                                    <th>강원</th>
                                    <td> 강릉시, 동해시, 속초시, 삼척시, 태백시, 양양군, 고성군, 원주시, 평창군, 정선군, 영월군, 횡성군, 춘천시, 철원군, 화천군, 양구군, 인제군, 홍천군 </td>
                                </tr>
                                <tr>
                                    <th>전남</th>
                                    <td> 여수시, 순천시, 광양시, 고흥군, 목포시, 해남군, 무안군, 영암군, 완도군, 신안군, 장흥군, 강진군, 진도군 </td>
                                </tr>
                                <tr>
                                    <th>전북</th>
                                    <td>정읍시, 김제시, 남원시, 고창군, 부안군, 순창군, 임실군</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                </SectionWrap>
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={2} title='IPTV 셋톱박스/공유기' />
                    <BasicText marginTop={0.5}>현주친구에 명시된 요금은 <strong>셋톱박스, 모뎀 임대료 및 부가가치세가</strong>전부 포함된 실 납부액입니다.</BasicText>
                    <BasicText marginTop={2}>공유기와 셋톱박스는 임대로 제공되며 약정 후 꼭 반납을 해야합니다.<br/>혹시나 잃어버리지 않게 주의하세요!</BasicText>
                    <BasicText marginTop={2}>와이파이 공유기는 무료로 제공됩니다.</BasicText>
                    <TableWrap>
                        <TableWrapItem margin='15px 0 0'>
                            <colgroup>
                                <col width="35%" />
                                <col width="28%" />
                                <col width="35%" />
                            </colgroup>
                            <thead>
                                <tr className='title-bg'>
                                    <th>셋톱박스</th>
                                    <th>월 임대료</th>
                                    <th>3/4년 약정 시</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>UHD 셋톱박스</td>
                                    <td>7,700원</td>
                                    <td>4,400원</td>
                                </tr>
                                <tr>
                                    <td>HD 셋톱박스</td>
                                    <td>7,700원</td>
                                    <td>2,200원</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <BasicText marginTop={2}>TV 채널 편성이 궁금하다면 <strong><a style={{display:'inline-block'}} href="https://www.lghellovision.net/product/tv/channelView.do" target="_blank" rel="noopener noreferrer">여기</a></strong>를 클릭해주세요.</BasicText>
                    <TextRowWrap gap={8} margin='20px 0 0'>
                        <FaCheckCircle color='#ff7500' size={22} />
                        <BasicText fontSize={17} fontWeight={700}>주요 OTT 시청 가능 유무</BasicText>
                    </TextRowWrap>
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0'>
                            <thead>
                                <tr className='title-bg'>
                                    <th>구분</th>
                                    <th>유튜브</th>
                                    <th>넷플릭스</th>
                                    <th>디즈니+</th>
                                    <th>웨이브</th>
                                    <th>왓챠</th>
                                    <th>티빙</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>UHD 셋톱박스</th>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                    <td>O</td>
                                </tr>
                                <tr>
                                    <th>HD 셋톱박스</th>
                                    <td>X</td>
                                    <td>X</td>
                                    <td>X</td>
                                    <td>X</td>
                                    <td>X</td>
                                    <td>X</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <BasicText marginTop={2}>UHD 셋톱박스의 경우 앱설치를 통해 웨이브나 왓챠를 즐길 수 있습니다.</BasicText>
                </SectionWrap>
            </SectionWrap>
            <SectionWrap padding={'80px 0 0'}>
                <StepTitleItem step='05' title={'설치 및 사은품 지급 안내'} />
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={1} title='설치 일정 및 사은품 지급' />
                    <InnerStep02Wrap>
                        <TextRowWrap gap={8}>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>설치는 신청 후 기사님과 조율하시면 됩니다. 평균 1~3일 내로 가능합니다</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>원하시는 날짜가 있다면 미리 말씀주세요. 최대한 맞춰드릴 수 있도록 도와드리겠습니다.</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>당일 설치는 기사님 일정에 따라 정해져요. 먼저 말씀해주시면 도와드리겠습니다!</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>설치시간은 평균 30분 내외이며 환경에 따라 조금 더 시간이 걸릴 수 있어요.</BasicText>
                        </TextRowWrap>
                        <TextRowWrap gap={8} margin='10px 0 0'>
                            <FaCheckCircle color='#ff7500' size={22} />
                            <BasicText fontSize={17} fontWeight={700}>설치 후 1533-9360번으로 바로 연락주세요. 사은품 지급을 도와드리겠습니다.</BasicText>
                        </TextRowWrap>
                    </InnerStep02Wrap>
                    <BasicText marginTop={2} spColor={addTheme.color}>*<span>현주친구</span>는 설치 후 9개월 사용으로 사은품 반환의무가 없어진답니다.<br/>(타사는 대부분 12개월)</BasicText>
                </SectionWrap>
                <SectionWrap padding='40px 0 0'>
                    <StepInnerTitleItem num={2} title='설치비용' />
                    <TableWrap>
                        <TableWrapItem margin='10px 0 0'>
                            <thead>
                                <tr className='title-bg'>
                                    <th>구분</th>
                                    <th>인터넷 단독</th>
                                    <th>TV 단독</th>
                                    <th>인터넷<br/>+ TV</th>
                                    <th>TV추가<br/>(1대 당)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>가입 / 이전 설치비</th>
                                    <td>36,300원</td>
                                    <td>22,000원</td>
                                    <td>56,100원</td>
                                    <td>+22,000원</td>
                                </tr>
                            </tbody>
                        </TableWrapItem>
                    </TableWrap>
                    <TextRowWrap gap={8} margin='10px 0 0'>
                        <FaCheckCircle color='#ff7500' size={20} />
                        <BasicText>최초 가입시 1회 설치비가 발생하며, 이사를 한다면 이전설치비가 청구됩니다(비용동일)</BasicText>
                    </TextRowWrap>
                    <TextRowWrap gap={8} margin='10px 0 0'>
                        <FaCheckCircle color='#ff7500' size={20} />
                        <BasicText>헬로비전의 경우 일요일, 공휴일은 설치가 불가능한 경우가 많습니다.</BasicText>
                    </TextRowWrap>
                </SectionWrap>
            </SectionWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const TableWrap = styled.div`
    overflow-x: auto;
    &>table{
        min-width: 560px;
    }
`;
const TableWrapItem = styled.table<{margin?:string}>`
    margin: ${({margin}:any)=> margin ?? '0'};
`;
const InnerStep02Wrap = styled.div`
    margin-top: 10px;
    background-color: #fbf5ec;
    padding: 20px;
`;
const TextRowWrap = styled(Row)<{margin?:string,gap?:number}>`
    margin: ${(({margin}) => margin ?? '0')};
    gap: ${(({gap}) => gap ?? 0)}px;
`;