import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row } from '../../../style/basic/commonStyle';
import BasicText from '../../basic/BasicText';
import { addTheme } from '../../../style/addTheme';
import { useGlobalSelectData } from '../../../utils/custom-hooks/common/useGlobalSelectData';
import { useParams } from 'react-router';

export default function InputRequestPart({order_basic,valueChange}:any) {
    const {type} = useParams();
    const selectData = useGlobalSelectData();
    useEffect(()=>{
        if(type === 'multi'){
            valueChange('basic','order_request_comment',`${selectData?.place?.bundle_name} | ${selectData?.place?.name}`)
        }
    },[selectData,type])
    return (
        <Wrap>
            <BasicText fontWeight={700} fontSize={18}>요청사항</BasicText>
            <ContentWrap>
                <ItemWrap>
                    <TitleItem style={{marginTop:'12px'}}>요청사항</TitleItem>
                    <InputItem>
                        <textarea value={order_basic?.order_request_comment ?? ''} onChange={(e:any)=>valueChange('basic','order_request_comment',e.target.value)} rows={8} placeholder='인터넷, 휴대폰 결합 진행 원해요. 제휴 카드를 같이 등록하려고 합니다. 등등'></textarea>
                    </InputItem>
                </ItemWrap>
            </ContentWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
    margin-top: 80px;
`;

const ContentWrap = styled(Row)`
    flex-direction: column;
    gap: 25px;
    margin-top: 15px;
    align-items: start;
`;

const ItemWrap = styled(Row)`
    gap: 10px;
    align-items: start;
    @media ${addTheme.media.s}{
        flex-wrap: wrap;
    }
`;

const TitleItem = styled.div`
    width: 20%;
    flex-shrink: 0;
    text-align: left;
    font-size: 16px;
    @media ${addTheme.media.s}{
        width: 100%;
    }
`;

const InputItem = styled.div<{w?:string}>`
    border: 1px solid #e9e9e9;
    width: ${({w}) => w ?? '100%'};
    border-radius: 4px;
    &>textarea{
        width: 100%;
        padding: 10px 15px;
    }
`;