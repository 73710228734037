import React, { useEffect, useState } from 'react'
import { AdmBtnItem, ContentWrap, InputWrap, ItemWrap, Row } from '../../../../style/basic/commonStyle'
import BasicText from '../../../basic/BasicText'
import SelectItem from '../../../common/item/SelectItem'
import { MenuItem } from '@mui/material'
import { apiGetTelList } from '../../../../api/api_home'
import { onlyNumber } from '../../../../utils/format/string'
import { numberWithComma } from '../../../../utils/format/number'
import styled from 'styled-components'
import { addTheme } from '../../../../style/addTheme'

export default function AdmProductCctvPart({info,valueChange,setInfo}:any) {
    const [telList,setTelList] = useState([]);

    useEffect(()=>{
        getTelList();
    },[])

    useEffect(()=>{
        if(info?.product_combine_cctv_gap_price === undefined || info?.product_combine_cctv_gap_price === 0){
            valueChange('product_combine_cctv_gap_price-1',0);
        }
    },[info?.product_combine_cctv_gap_price])

    // 통신사 리스트
    const getTelList = async() =>{
        const res = await apiGetTelList('05');
        if(res.result){
            setTelList(res.data);
        }
    }

    const listChange = (type:string) =>{
        const list = info.product_combine_cctv_gap_price;
        if(type === 'add'){
            const newList = [...list,''];
            setInfo((obj:any)=>({...obj,['product_combine_cctv_gap_price']:newList}));
        }
        if(type === 'remove'){
            const newList = list.slice(0, list.length - 1);
            setInfo((obj:any)=>({...obj,['product_combine_cctv_gap_price']:newList}));
        }
    }
    
    return (
        <ContentWrap>
            <ItemWrap pWidth={120}>
                <BasicText>통신사</BasicText>
                {telList === undefined || telList.length === 0 ?
                    <></> :
                    <SelectItem required value={info?.company_code ?? ''} setValue={(val:any)=>valueChange('company_code',val)} none='no'>
                        <MenuItem value={''} disabled>통신사 정보를 선택해주세요</MenuItem>
                        {telList.map((data:any,index:any)=>{
                            return(
                                <MenuItem key={`internet-tel-${index}`} value={data.company_code}>{data.company_name}</MenuItem>
                            )
                        })}
                    </SelectItem>
                }
            </ItemWrap>
            <ItemWrap pWidth={120}>
                <BasicText>제목명</BasicText>
                <InputWrap>
                    <input required value={info?.product_name ?? ''} onChange={(e:any)=>valueChange('product_name',e.target.value)} placeholder='상품 데이터 명을 입력해주세요'/>
                </InputWrap>
            </ItemWrap>
            <ItemWrap pWidth={120}>
                <BasicText>기본가</BasicText>
                <InputWrap>
                    <input required value={numberWithComma(info?.product_basic_price ?? '')} onChange={(e:any)=>valueChange('product_basic_price',onlyNumber(e))} placeholder='기본 가격을 입력해주세요'/>
                </InputWrap>
            </ItemWrap>
            <ItemWrap pWidth={120}>
                <BasicText>최소수량</BasicText>
                <InputWrap>
                    <input required value={numberWithComma(info?.product_min_cctv_count ?? '')} onChange={(e:any)=>valueChange('product_min_cctv_count',onlyNumber(e))} placeholder='최소 수량을 입력해주세요'/>
                </InputWrap>
            </ItemWrap>
            <ItemWrap pWidth={120} align='start'>
                <BasicText marginTop={1}>추가금액</BasicText>
                <Row style={{flexDirection:'column', gap:'8px'}}>
                    {info?.product_combine_cctv_gap_price === undefined || info?.product_combine_cctv_gap_price.length === 0 ?
                        <></> :
                        info?.product_combine_cctv_gap_price.map((data:any,index:any)=>{
                            return(
                                <ItemWrap pWidth={50} key={`tv-router-${index}`} gap='6px'>
                                    <BasicText>{index+(Number(info?.product_min_cctv_count ?? 0))}대 </BasicText>
                                    <InputWrap>
                                        <input value={numberWithComma(data ?? '')} onChange={(e)=>valueChange(`product_combine_cctv_gap_price-${index}`,onlyNumber(e))}  placeholder='cctv 가격'/>
                                    </InputWrap>
                                    {index+1 === info?.product_combine_cctv_gap_price.length &&
                                        <BtnWrap>
                                            <AdmBtnItem bg='#000' onClick={()=>listChange('add')}>개수 추가</AdmBtnItem>
                                            {index !== 0 &&
                                                <AdmBtnItem bg={addTheme.admBtn3} onClick={()=>listChange('remove')}>개수 빼기</AdmBtnItem>
                                            }
                                        </BtnWrap>
                                    }
                                </ItemWrap>
                            )
                        })
                    }
                </Row>
            </ItemWrap>
        </ContentWrap>
    )
}

const BtnWrap = styled(Row)`
    width: auto;
    flex-shrink: 0;
    gap: 10px;
`;