import { axiosConfigInstance } from "./config";

// 관리자 faq 수
export const apiAdmGetFaqCount = async(token:string,admin_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/faq',token).post('',{
            method: 'getAdminFaqCount',
            params:{
                admin_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 FAQ 카테고리 추가하기
export const apiAdmSetFaqCategory = async(token:string,admin_id:any,info:any) =>{
    try {
        const {faq_category_title,faq_category_order} = info;
        const res = await axiosConfigInstance('/api/admin/faq',token).post('',{
            method: 'adminFaqAddCategory',
            params:{
                admin_id,faq_category_title,
                faq_category_order: faq_category_order === '' ? '100' : faq_category_order
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 FAQ 카테고리 리스트 가져오기
export const apiAdmGetFaqCategory = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/faq',token).post('',{
            method: 'getAdminFaqCategoryList',
            params:{
                admin_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 FAQ 카테고리 수정
export const apiAdmSetFaqCategoryUpdate = async(token:string,admin_id:any,info:any) =>{
    try {
        const {faq_category_id,faq_category_title,faq_category_order} = info;
        const res = await axiosConfigInstance('/api/admin/faq',token).post('',{
            method: 'adminFaqChangeCategoryOrder',
            params:{
                admin_id,faq_category_id,faq_category_title,
                faq_category_order: faq_category_order === '' ? '100' : faq_category_order,
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 FAQ 카테고리 삭제
export const apiAdmSetFaqCategoryDel = async(token:string,admin_id:any,faq_category_id:any,) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/faq',token).post('',{
            method: 'adminFaqCategoryDelete',
            params:{
                admin_id,faq_category_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 FAQ 카테고리 디테일
export const apiAdmGetFaqCateDetail = async(token:string,admin_id:any,faq_category_id:any,) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/faq',token).post('',{
            method: 'getAdminFaqCategoryInfo',
            params:{
                admin_id,faq_category_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}


// 관리자 FAQ 추가하기
export const apiAdmSetFaq = async(token:string,admin_id:any,info:any) =>{
    try {
        const {faq_category_id,faq_question,faq_answer} = info;
        const res = await axiosConfigInstance('/api/admin/faq',token).post('',{
            method: 'adminFaqAdd',
            params:{
                admin_id,faq_category_id,faq_question,faq_answer
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 FAQ 수정하기
export const apiAdmSetFaqUpdate = async(token:string,admin_id:any,info:any) =>{
    try {
        const {faq_id,faq_category_id,faq_question,faq_answer} = info;
        const res = await axiosConfigInstance('/api/admin/faq',token).post('',{
            method: 'adminFaqEdit',
            params:{
                admin_id,faq_id,faq_category_id,faq_question,faq_answer
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 FAQ 리스트 가져오기
export const apiAdmGetFaq = async(token:string,admin_id:any,page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/faq',token).post('',{
            method: 'getAdminFaqList',
            params:{
                admin_id,page,count
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 FAQ 디테일 가져오기
export const apiAdmGetFaqDetail = async(token:string,admin_id:any,faq_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/faq',token).post('',{
            method: 'getAdminFaqDetailInfo',
            params:{
                admin_id,faq_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 관리자 FAQ 삭제
export const apiAdmSetFaqDel = async(token:string,admin_id:any,faq_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/admin/faq',token).post('',{
            method: 'adminFaqDelete',
            params:{
                admin_id,faq_id
            }
        })
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

