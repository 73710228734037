import axios from 'axios';

export const axiosConfigInstance = (path: string,token?:string,type?:any) => axios.create({
    baseURL: `https://hjpd.nvpworld.com${path}`,
    // baseURL: `http://192.168.11.11:13131${path}`,
    timeout: 50000,
    headers: {
        'authorization':token,
        ...type
    }
});

export const IMG_URL = '';
// export const KAKAO_REG_LINK = 'http://192.168.11.11:13131';
export const KAKAO_REG_LINK = 'https://hjpd.nvpworld.com';
// export const IMG_URL = 'https://music.subox.co.kr/platform';
export const ADMIN_IMG_URL = '';
// "homepage": "https://music.subox.co.kr/platform",

export const PLATFORM_TYPES = {
    order: {
        customer: {
            title: "고객 유형",
            data: [
                { name: "개인", value: "1" },
                { name: "사업자", value: "2" },
                { name: "외국인", value: "3" }
            ]
        },
        relation: {
            title: "관계",
            data: [
                { name: "본인", value: "1" },
                { name: "배우자", value: "2" },
                { name: "가족", value: "3" },
                { name: "기타", value: "4" }
            ]
        },
        payment: {
            title: "결제 유형",
            data: [
                { name: "은행 자동이체", value: "1" },
                { name: "카드 결제", value: "2" }
            ]
        },
        process: {
            title: "처리 상태",
            data: [
                {name: "주문접수", value: "0"},
                {name: "상담완료", value: "1"},
                {name: "계약서 작성완료", value: "2"},
                {name: "설치 접수", value: "3"},
                {name: "설치 완료", value: "4"},
                {name: "지급 완료", value: "5"},
                {name: "주문 취소", value: "100"},
            ]
        }
    },
    partner: {
        partner: {
            title: "계정 유형",
            data: [
                {name: "개인", value: "1"},
                {name: "사업자", value: "2"}
            ]
        }
    },
    social: {
        sign: {
            title: "가입 유형",
            data: [
                {name: "일반 가입", value: "1"},
                {name: "카카오 가입", value: "2"}
            ]
        }
    }
}