import { axiosConfigInstance } from "./config"

// 리뷰 가져오기
export const apiGetReviewList = async(page:any,count:any) =>{
    try {
        const res = await axiosConfigInstance('/api/review/info').post('',{
            method: 'getReviewList',
            params: {
                page,
                count,
            }
        });
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 리뷰 디테일
export const apiGetReviewDetail = async(review_id:any) =>{
    try {
        const res = await axiosConfigInstance('/api/review/info').post('',{
            method: 'getReviewDetailInfo',
            params: {
                review_id,
            }
        });
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}

// 리뷰 작성
export const  apiSetReview = async(info:any) =>{
    try {
        const {review_user_name,review_title,review_content,review_rate,review_image} = info;
        const res = await axiosConfigInstance('/api/review/info').post('',{
            method: 'submitReview',
            params: {
                review_user_name,
                review_title,
                review_content,
                review_rate: review_rate === '' ? '5' : review_rate,
                review_image
            }
        });
        return res.data;
    } catch (error) {
        return {
            error,
            result: false,
        }
    }
}