import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_ADM_USER_LOGIN = 'subox/SET_ADM_USER_LOGIN';
const SET_ADM_USER_LOGOUT = 'subox/SET_ADM_USER_LOGOUT';

export const setAdminUserActionCreator = createAction(SET_ADM_USER_LOGIN);
export const setAdminUserOutActionCreator = createAction(SET_ADM_USER_LOGOUT);

export function setAdminUser(data: any): ThunkAction<void, RootState, null, ReturnType<typeof setAdminUserActionCreator>> {
  
  return async function (dispatch) {
    try {
      dispatch(setAdminUserActionCreator({
        data: data
      }));
    } catch (error) {
      
    }
  };
}

export interface adminUser {
  "admin_token": string;
  "admin_id":number;
}
export const userState: adminUser = {
  "admin_token":'',
  "admin_id":0,
}

export const adminUser = handleActions<adminUser, any>({
  [SET_ADM_USER_LOGIN]: (state, action: ReturnType<typeof setAdminUserActionCreator>) => ({
    admin_token: action.payload.data.admin_token ?? state.admin_token,
    admin_id: action.payload.data.admin_id ?? state.admin_id,
  })
,
  [SET_ADM_USER_LOGOUT]: (state, action: ReturnType<typeof setAdminUserOutActionCreator>) => (userState),
}, userState);
