import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { AdmBtnItem, AdminWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { useParams } from 'react-router';
import { BUNDLE_CATE } from '../data/dummy';
import { useAdminUser } from '../../../../utils/custom-hooks/common/useAdminUser';
import { apiAdmGetBundleDetail, apiAdmSetBundleStatusUpdate, apiAdmSetBundleUpdate, STATUS_CHANGE } from '../../../../api/api_adm_product';
import { addTheme } from '../../../../style/addTheme';
import AdmProductBundleHomePart from '../part/AdmProductBundleHomePart';

const INIT = {
    product_001_code: '',
    product_002_code: '',
    product_005_code: '',
    product_006_code: '',
}
export default function AdmProductBundleUpdatePage() {
    const {type,code} = useParams();
    const adminUser = useAdminUser();
    const item = BUNDLE_CATE.filter((data:any)=>data.id == (type ?? 1));
    const name = item[0]?.name === undefined ? '' : item[0]?.name;
    const [info,setInfo] = useState<any>(INIT);

    useEffect(()=>{
        if(adminUser.admin_id === 0) return;
        if(code === undefined) return;
        getInfo();
    },[code,adminUser.admin_id])

    const getInfo = async() =>{
        const res = await apiAdmGetBundleDetail(adminUser.admin_token,adminUser.admin_id,code);
        
        if(res.result){
            setInfo((obj:any)=>({
                ...obj,
                ...res.data,
                product_001_code: res.data.bundle_product_001,
                product_002_code: res.data.bundle_product_002,
                product_005_code: res.data.bundle_product_005,
                product_006_code: res.data.bundle_product_006,
            }));
        }
    }

    // 수정
    const btnAction = async() =>{
        if(window.confirm('수정 하시겠습니까?')){
            const res = await apiAdmSetBundleUpdate(adminUser.admin_token,adminUser.admin_id,info);
            if(res.result){
                alert(`수정했습니다.`);
                location.reload();
            }else{
                alert(res.error);
            }
        }
    }

    // 활성 / 비활성
    const statusChange = async()=>{
        if(info?.bundle_visible === undefined) return;
        if(window.confirm(`${STATUS_CHANGE[info.bundle_visible].resTxt ?? ''} 하시겠습니까?`)){
            const res = await apiAdmSetBundleStatusUpdate(adminUser.admin_token,adminUser.admin_id,info?.bundle_code,STATUS_CHANGE[info?.bundle_visible].res);
            if(res.result){
                alert(`${STATUS_CHANGE[info.bundle_visible].resTxt ?? ''} 되었습니다`);
                getInfo();
            }else{
                alert(res.error);
            }
        }
    }

    // input 변경
    const valueChange = (name:string,val:any,name2?:string) =>{
        setInfo((obj:any)=>({...obj,
            [name]:val,
        }));
        if(name2 === undefined) return;
        setInfo((obj:any)=>({...obj,
            [name2]:val,
        }));
    }
    
    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={18} mFontSize={16} fontWeight={700}>기본 데이터 수정-{name}</BasicText>
                <InnerWrap>
                    <AdmProductBundleHomePart type={`0${type ?? ''}`} info={info} valueChange={valueChange} />
                    {/* {type === '1' &&
                        <AdmProductBundleHomePart type={`0${type ?? ''}`} info={info} valueChange={valueChange} />
                    } */}
                    {/* {type === '2' &&
                        <AdmProductBundleHomePart info={info} valueChange={valueChange} />
                    }
                    {type === '3' &&
                        <AdmProductBundleHomePart info={info} valueChange={valueChange} />
                    } */}
                </InnerWrap>
                <Row style={{marginTop:'30px',gap:'15px'}}>
                    <AdmBtnItem bg={addTheme.admBtn4} padding='10px 25px' onClick={btnAction}>수정</AdmBtnItem>
                    <AdmBtnItem bg={addTheme.admBtn1} padding='10px 25px' onClick={statusChange}>{STATUS_CHANGE[info?.bundle_visible]?.resTxt ?? ''}</AdmBtnItem>
                </Row>
            </AdminWrap>
           
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    width: 100%;
    max-width: 640px;
    margin-top: 30px;
`;