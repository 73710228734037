import React, { useState } from 'react'
import styled from 'styled-components'
import { AdmBtnItem, AdminWrap, Row } from '../../../../style/basic/commonStyle';
import BasicText from '../../../basic/BasicText';
import { Grid } from '@mui/material';
import AdmInquiryPart from '../part/AdmInquiryPart';
import { addTheme } from '../../../../style/addTheme';
import { useNavigate } from 'react-router';
import { apiSetInquiry } from '../../../../api/api_qna';
import validator from 'validator';

export default function AdmInquiryUpdatePage() {
    const navigate = useNavigate();
    const [cate,setCate] = useState({
        "inquiry_category_id": 1,
        "inquiry_title": "구매문의"
    });
    const [info,setInfo] = useState({
        i_category_title: '',
        inquiry_answer_image: '',
        inquiry_id: '',
        inquiry_question: '',
        inquiry_user_name: '',
        inquiry_user_email: '',
        inquiry_user_phone_number: '',
        inquiry_answer: '',
        fileName: '',
        inquiry_question_image: '',
        questionFileName: '',
        inquiry_answer_timestamp: '0',
    });

    // input 데이터 변경
    const valueChange = (name:string, val:any)=>{
        setInfo((obj:any)=>({
            ...obj,
            [name]: val,
        }))
    }

    // 등록
    const update = async() =>{
        if(info.inquiry_user_name === ''){
            alert('작성자를 입력해주세요.');
            return;
        }
        if(info.inquiry_user_phone_number === ''){
            alert('연락처를 입력해주세요.');
            return;
        }
        if(info.inquiry_user_email === ''){
            alert('작성자를 입력해주세요.');
            return;
        }
        const emailChk = validator.isEmail(info.inquiry_user_name);
        if(!emailChk){
            alert('이메일을 확인해주세요.');
            return;
        }
        const res = await apiSetInquiry(info,cate,'adm_123!');
        if(res.result){
            alert('문의를 등록했습니다.');
            navigate(-1);
        }else{
            alert(res.error);
        }
    }
    return (
        <Wrap>
            <AdminWrap marginTop={0}>
                <BasicText fontSize={18} mFontSize={16} fontWeight={700}>문의등록</BasicText>
                <InnerWrap>
                    <Grid container={true} spacing={4}>
                        <Grid item md={6} xs={12}>
                            <AdmInquiryPart info={info} valueChange={valueChange} cate={cate} setCate={setCate} />
                            <Row style={{marginTop: '30px'}}>
                                <AdmBtnItem onClick={update} bg={addTheme.admBtn2} padding='12px 24px'>답변 등록</AdmBtnItem>
                            </Row>
                        </Grid>
                    </Grid>
                </InnerWrap>
            </AdminWrap>
        </Wrap>
    )
}

const Wrap = styled.div``;
const InnerWrap = styled.div`
    margin-top: 30px;
`;